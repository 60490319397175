import React from 'react';
import { observer } from 'mobx-react';
import { workPlacesStore } from 'stores';
import { WorkPlaceListModel } from 'shared/models';
import WorkPlace from './WorkPlace';

const WorkPlaces = () => {
  return (
    <>
      {workPlacesStore.workPlaces.map((_: WorkPlaceListModel) => (
        <WorkPlace key={_.id} mapObject={_} />
      ))}
    </>
  );
};

export default observer(WorkPlaces);
