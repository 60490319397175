import React, { useState } from 'react';
import { IIconProps, IThemeExtendedColors } from 'shared/interfaces/app';

import { useTheme } from '@mui/material';

const EditPencilIcon: React.FC<IIconProps> = ({ color }) => {
  const theme = useTheme();

  const mainColor = (theme.palette as IThemeExtendedColors).icons?.stroke;
  const [strokeColor, setStrokeColor] = useState(mainColor);

  const onMouseOver = () => {
    setStrokeColor(theme.palette.primary.main);
  };

  const onMouseLeave = () => {
    setStrokeColor(mainColor);
  };

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <path d="M11.4561 17.0358H17.4999" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.65 3.16233C11.2964 2.38982 12.4583 2.27655 13.2469 2.90978C13.2905 2.94413 14.6912 4.03232 14.6912 4.03232C15.5575 4.55599 15.8266 5.66925 15.2912 6.51882C15.2627 6.56432 7.34329 16.4704 7.34329 16.4704C7.07981 16.7991 6.67986 16.9931 6.25242 16.9978L3.21961 17.0358L2.53628 14.1436C2.44055 13.7369 2.53628 13.3098 2.79975 12.9811L10.65 3.16233Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.18408 5.00074L13.7276 8.49001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EditPencilIcon;
