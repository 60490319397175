import { addDays } from 'date-fns';
import { makeAutoObservable } from 'mobx';

import { userAuthStore } from 'stores';
import { getEndOfDay, getStartOfDay } from 'utils/DateTimeUtils';
import { IReservationFilterDto } from '../../interfaces/api';

export default class ReservationFilter {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  floorId: string | null = null;

  workplaceId: string | null = null;

  isOnlyMine = true;

  userId = '';

  fromTime: Date | null = getStartOfDay();

  toTime: Date | null = getEndOfDay(addDays(new Date(), 6));

  fromTimeInDay: Date | null = null;

  toTimeInDay: Date | null = null;

  pageIndex = 0;

  pageSize = 8;

  public updateWorkPlaceId(workPlaceId: string | null) {
    this.workplaceId = workPlaceId;
  }

  public updateFloorId(floorId: string | null) {
    this.floorId = floorId;
  }

  public onOnlyMineChange(isOnlyMine: boolean) {
    this.isOnlyMine = isOnlyMine;
    this.userId = isOnlyMine ? (this.userId = userAuthStore.userId) : '';
  }

  public onPeriodUpdate(fromTime: Date | null, toTime: Date | null) {
    this.fromTime = fromTime;
    this.toTime = toTime;
  }

  public getFilterDto(): IReservationFilterDto {
    return {
      floorId: this.floorId,
      workplaceId: this.workplaceId,
      userId: this.userId,

      fromTime: this.fromTime,
      toTime: this.toTime,

      fromTimeInDay: this.fromTimeInDay,
      toTimeInDay: this.toTimeInDay,

      pageIndex: this.pageIndex,
      pageSize: this.pageSize,

      isDescendingOrder: true,
      isActiveOnly: false,
    };
  }

  public getCalendarFilterDto(): IReservationFilterDto {
    return {
      floorId: this.floorId,
      workplaceId: this.workplaceId,
      userId: this.userId,
      fromTime: this.fromTime,
      toTime: this.toTime,
      pageIndex: null,
      pageSize: null,
      isDescendingOrder: true,
      isActiveOnly: true,
    };
  }

  public setDateInterval(fromTime: Date | null, toTime: Date | null) {
    this.fromTime = fromTime;
    this.toTime = toTime;
  }

  public setTimeInterval(fromTime: Date | null, toTime: Date | null) {
    this.fromTimeInDay = fromTime;
    this.toTimeInDay = toTime;
  }

  public deInit() {
    this.floorId = '';
    this.userId = '';
    this.workplaceId = '';
    this.isOnlyMine = true;
  }
}
