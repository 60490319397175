import React, { useRef, useState, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { departmentsStore, rolesStore, workPlacesStore, zonesStore } from 'stores';
import { DropDownChip, Input, ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { IZoneDialog } from 'shared/interfaces/app';

const ZoneDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);
  const formRef = useRef<FormikProps<IZoneDialog>>(null);
  const dialogTitle = zonesStore.selectedZone.id ? 'Редактировать зону' : 'Создать зону';

  useEffect(() => {
    Promise.all([departmentsStore.getDepartmentsList(), rolesStore.getRolesList(), workPlacesStore.getAllWorkPlainPlaces()]);
  }, []);

  const handleCloseDialog = () => {
    zonesStore.setDialogViewMode(DialogStatus.Closed);
  };

  const onSubmit = async (values: IZoneDialog) => {
    zonesStore.selectedZone.updateFromDialogForm(values);

    if (zonesStore.dialogViewMode === DialogStatus.Creating) {
      await zonesStore.createZone();
    } else if (zonesStore.dialogViewMode === DialogStatus.Editing) {
      await zonesStore.updateZone();
    }

    zonesStore.getZonesList();
    zonesStore.setDialogViewMode(DialogStatus.Closed);
  };

  return (
    <ModalDialog
      isModelHasId={zonesStore.selectedZone.id}
      title={dialogTitle}
      isValid={validated}
      isOpen={zonesStore.dialogViewMode !== DialogStatus.Closed}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          name: zonesStore.selectedZone.name,
          workplacesIds: zonesStore.selectedZone.workplacesIds,
          departmentIds: zonesStore.selectedZone.departmentIds,
          roleIds: zonesStore.selectedZone.roleIds,
          depOpt: departmentsStore.departmentsForOptions,
          roleOpt: rolesStore.rolesForOptions,
          workplaceOpt: workPlacesStore.workPlacesPlainList,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Обязательное поле'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
          setValidated(isValid);

          const handleDeleteDep = (e: React.ChangeEvent<string>, value: string) => {
            e.preventDefault();

            const idx = values.departmentIds.indexOf(value);
            if (idx !== -1) {
              values.departmentIds.splice(idx, 1);
            }
            setFieldValue('departmentIds', values.departmentIds);
          };

          const handleDeleteRole = (e: React.ChangeEvent<string>, value: string) => {
            e.preventDefault();

            const idx = values.roleIds.indexOf(value);
            if (idx !== -1) {
              values.roleIds.splice(idx, 1);
            }
            setFieldValue('roleIds', values.roleIds);
          };

          const handleDeleteWP = (e: React.ChangeEvent<string>, value: string) => {
            e.preventDefault();

            const idx = values.workplacesIds.indexOf(value);
            if (idx !== -1) {
              values.workplacesIds.splice(idx, 1);
            }
            setFieldValue('workplacesIds', values.workplacesIds);
          };

          return (
            <>
              <Input
                mb={5}
                name="name"
                inputLabel="Название зоны *"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <DropDownChip
                name="departmentIds"
                options={values.depOpt}
                values={values.departmentIds}
                placeholder="Выберите отделы"
                dropDownLabel="Отделы"
                onDelete={handleDeleteDep}
                onChange={(e: React.ChangeEvent<any>) => {
                  setFieldValue('departmentIds', e.target.value);
                }}
              />

              <DropDownChip
                name="roleIds"
                options={values.roleOpt}
                values={values.roleIds}
                placeholder="Выберите роли"
                dropDownLabel="Роли"
                onDelete={handleDeleteRole}
                onChange={(e: React.ChangeEvent<any>) => {
                  setFieldValue('roleIds', e.target.value);
                }}
              />

              <DropDownChip
                name="workplacesIds"
                options={values.workplaceOpt}
                values={values.workplacesIds}
                placeholder="Выберите рабочие места"
                dropDownLabel="Рабочие места"
                onDelete={handleDeleteWP}
                onChange={(e: React.ChangeEvent<any>) => {
                  setFieldValue('workplacesIds', e.target.value);
                }}
              />
            </>
          );
        }}
      </Formik>
    </ModalDialog>
  );
};

export default observer(ZoneDialog);
