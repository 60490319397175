import React, { useState } from 'react';

import { Eye, EyeOff } from 'react-feather';
import { Input } from 'components';
import EyeClose from 'components/Icons/EyeClose';
import EyeOpen from 'components/Icons/EyeOpen';
import { IconButton } from '@mui/material';

import * as S from './PasswordField.styles';

interface IPasswordFieldProps {
  name: string;
  password: string;
  error: boolean | undefined;
  helperText: any;
  label: string;
  readonly?: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
}

const PasswordField: React.FC<IPasswordFieldProps> = (props) => {
  const { password, helperText, error, handleChange, handleBlur, name, label, readonly } = props;
  const [isHidePassword, setIsHidePassword] = useState(true);

  const handleHidePassword = () => {
    setIsHidePassword(!isHidePassword);
  };

  const passVisibleIcon = isHidePassword ? <EyeClose /> : <EyeOpen />;

  return (
    <Input
      type={isHidePassword ? 'password' : 'text'}
      name={name}
      readonly={readonly}
      inputLabel={label}
      value={password}
      error={error}
      helperText={helperText}
      endIcon={passVisibleIcon}
      onBlur={handleBlur}
      onChange={handleChange}
      onIconClick={handleHidePassword}
    />
  );
};

export default PasswordField;
