import React from 'react';

const AdminIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5905 4.26237C10.9895 4.25833 10.4208 3.98765 10.0393 3.52292L9.23167 2.40635C8.85098 1.93051 8.27092 1.65745 7.66085 1.6669H5.92701C2.81499 1.6669 1.66651 3.49334 1.66651 6.59903V8.96225C1.66701 8.6026 1.68679 8.24322 1.72578 7.88567C1.73412 7.8219 1.74662 7.75917 1.75899 7.6971C1.77961 7.59361 1.79988 7.49192 1.79988 7.39024C1.87509 6.95163 2.0123 6.5259 2.2074 6.12578C2.78535 4.8909 3.97088 4.26237 5.91218 4.26237H11.5905ZM18.3306 9.95658C18.3306 9.95643 18.3306 9.95628 18.3306 9.95613V8.98005L18.3306 9.95658ZM18.2906 7.9813C18.1146 6.06467 17.3448 4.87199 15.6803 4.44263C16.1859 4.59856 16.663 4.84672 17.0858 5.17928C17.1844 5.26293 17.2761 5.35443 17.36 5.45288C17.6266 5.76461 17.8326 6.12321 17.9675 6.51029C18.1272 6.98877 18.2353 7.48189 18.2906 7.9813Z"
        fill="white"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.36 5.45288C17.6266 5.76461 17.8326 6.12321 17.9675 6.51029C18.233 7.30586 18.3559 8.1419 18.3306 8.98005V13.3576C18.3295 13.7263 18.3023 14.0945 18.2491 14.4594C18.1478 15.1033 17.9212 15.7213 17.5822 16.2784C17.4264 16.5475 17.2372 16.796 17.0191 17.0179C16.0318 17.924 14.7206 18.3957 13.381 18.3267H6.60868C5.26692 18.3952 3.95368 17.9237 2.96318 17.0179C2.74767 16.7956 2.56098 16.5471 2.40746 16.2784C2.07046 15.7217 1.84875 15.1031 1.75542 14.4594C1.69608 14.0951 1.66634 13.7266 1.6665 13.3576V8.98005C1.66636 8.61445 1.68615 8.24912 1.72578 7.88567C1.73412 7.8219 1.74662 7.75917 1.75899 7.6971C1.77961 7.59361 1.79988 7.49192 1.79988 7.39024C1.87509 6.95163 2.0123 6.5259 2.2074 6.12578C2.78535 4.8909 3.97088 4.26237 5.91218 4.26237H14.0627C15.15 4.17827 16.2292 4.5056 17.0858 5.17928C17.1844 5.26293 17.2761 5.35443 17.36 5.45288ZM5.80845 12.9509H14.1961H14.2109C14.3949 12.9589 14.5745 12.893 14.7096 12.768C14.8446 12.6429 14.9238 12.4691 14.9296 12.2854C14.94 12.1239 14.8869 11.9647 14.7814 11.8417C14.6602 11.6765 14.468 11.5778 14.2628 11.5755H5.80845C5.42787 11.5755 5.11936 11.8834 5.11936 12.2632C5.11936 12.643 5.42787 12.9509 5.80845 12.9509Z"
        fill="white"
      />
    </svg>
  );
};

export default AdminIcon;
