import React from 'react';
import { TypographyProps } from '@mui/material';

import * as S from './Title.styles';

interface ITitleProps extends TypographyProps {
  label: string;
}

const Title: React.FC<ITitleProps> = (props) => {
  const { label, ...rest } = props;

  return (
    <S.TitleWrapper variant="body2" {...rest}>
      {label}
    </S.TitleWrapper>
  );
};

export default Title;
