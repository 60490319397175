import { makeAutoObservable } from 'mobx';
import { IZoneGetDto, IZonePostPutDto } from 'shared/interfaces/api';
import { IZoneDialog } from 'shared/interfaces/app';

class ZoneModel {
  constructor(dto?: IZoneGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.comment = dto.comment;
    this.workplacesIds = dto.workplacesIds;
    this.departmentIds = dto.departmentIds;
    this.roleIds = dto.roleIds;
  }

  public id = '';

  public name = '';

  public comment = '';

  public workplacesIds: string[] = [];

  public departmentIds: string[] = [];

  public roleIds: string[] = [];

  public get postPutDto(): IZonePostPutDto | null {
    return {
      name: this.name,
      comments: this.comment,
      roleIds: this.roleIds,
      departmentIds: this.departmentIds,
      workplaceIds: this.workplacesIds,
    };
  }

  public updateFromDialogForm(model: IZoneDialog) {
    this.name = model.name;
    this.workplacesIds = model.workplacesIds;
    this.departmentIds = model.departmentIds;
    this.roleIds = model.roleIds;
  }
}

export default ZoneModel;
