import React from 'react';

import { MessagesModel, NotificationsModel } from 'shared/models';
import { Box, Button, Typography } from '@mui/material';
import * as S from './PopupList.styles';

interface IPopupListProps {
  title: string;
  btnLabel: string;
  listData: MessagesModel[] | NotificationsModel[];
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

const PopupList: React.FC<IPopupListProps> = (props) => {
  const { listData, title, btnLabel, onMouseEnter, onMouseLeave, onClick } = props;

  return (
    <S.Paper elevation={24} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Typography variant="h3" sx={{ paddingLeft: 3, lineHeight: '36px' }}>
        {title}
      </Typography>
      {/*<List listItems={listData} listMode={'small'} />*/}
      <Box sx={{ padding: 3 }}>
        <Button color="secondary" variant={'contained'} fullWidth onClick={onClick} size={'large'}>
          {btnLabel}
        </Button>
      </Box>
    </S.Paper>
  );
};

export default PopupList;
