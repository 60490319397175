import React from 'react';
import { LeafletMouseEvent } from 'leaflet';
import { observer } from 'mobx-react';
import { mapStore, reservationStore, workPlacesStore } from 'stores';
import { DraggableMarker } from 'components';
import { WorkPlaceAvailableBookingModel } from 'shared/models';

interface IWorkPlaceAvailableBookingProps {
  mapObject: WorkPlaceAvailableBookingModel;
}

const WorkPlaceAvailableBooking: React.FC<IWorkPlaceAvailableBookingProps> = (props) => {
  const { mapObject } = props;

  const onMarkerClick = async (e: LeafletMouseEvent) => {
    if (!mapObject.id) return;

    workPlacesStore.selectedWorkPlace.setId(mapObject.id);
    await workPlacesStore.getWorkPlaceById(workPlacesStore.selectedWorkPlace.id);
    await reservationStore.getReservationListForMap(
      workPlacesStore.selectedWorkPlace.id,
      workPlacesStore.queryFilterParams.fromTime,
      workPlacesStore.queryFilterParams.toTime
    );
    workPlacesStore.setAnchorEl(e.sourceTarget.getElement());
    workPlacesStore.getWorkPlaceImageList();
  };

  return (
    <DraggableMarker
      icon={mapStore.getIcon(mapObject, mapObject.availableBooking, mapObject.isSelected)}
      position={{ lat: mapObject.position.y, lng: mapObject.position.x }}
      draggable={false}
      onClick={onMarkerClick}
    />
  );
};

export default observer(WorkPlaceAvailableBooking);
