export const FileAccepts = {
  SVG: 'image/svg+xml',
  PNG: 'image/png',
  JPG: 'image/jpeg',
};

export class ImageFormat {
  static readonly SVG = new ImageFormat('SVG', FileAccepts.SVG, 'svg', 'data:' + FileAccepts.SVG + ';base64,');
  static readonly PNG = new ImageFormat('PNG', FileAccepts.PNG, 'png', 'data:' + FileAccepts.PNG + ';base64,');
  static readonly JPG = new ImageFormat('JPG', FileAccepts.JPG, 'jpg', 'data:' + FileAccepts.JPG + ';base64,');

  private constructor(private readonly key: string, public readonly accept: string, public readonly title: string, public readonly prefix: string) {}

  toString() {
    return this.key;
  }
}

export const getBase64Image = (type: ImageFormat, image: string, toBase64 = false): string => {
  switch (type) {
    case ImageFormat.SVG:
      return ImageFormat.SVG.prefix + (toBase64 ? new Buffer(image).toString('base64') : image);
    case ImageFormat.PNG:
      return ImageFormat.PNG.prefix + image;
    case ImageFormat.JPG:
      return ImageFormat.JPG.prefix + image;
  }

  return '';
};

export const BaseImageFormat = ImageFormat.SVG;
