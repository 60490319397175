import { makeAutoObservable } from 'mobx';
import { MAP_IMAGES_STORAGE_PATH, HOST } from 'shared/constants';
import { ImageType, WorkplacePositionAction } from 'shared/enums';
import { IFloorImageGetDto } from 'shared/interfaces/api';
import { IFloorImageDialog } from 'shared/interfaces/app';
import { getImgSize, isValidMap, toBase64 } from 'utils/FileUtils';

class FloorImageModel {
  public constructor(dto?: IFloorImageGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.pixelsPerMeter = dto.pixelsPerMeter;
    this.floorId = dto.floorId ?? '';
    this.imageName = dto.imageName ?? '';
    this.type = dto.type ?? ImageType.PNG;
  }

  public id = '';

  public name = '';

  public pixelsPerMeter = 10;

  public floorId = '';

  public imageName = '';

  public type = ImageType.PNG;

  public imageBlob: File | null = null;

  public imageBase64 = '';

  public isValidMap = true;

  public workplacePositionAction: WorkplacePositionAction | null = WorkplacePositionAction.Save;

  public width = 0;

  public height = 0;

  public prevWidth?: number | null;

  public prevHeight?: number | null;

  public get postPutDto(): FormData {
    const formData = new FormData();

    formData.append('name', this.name);

    if (this.pixelsPerMeter) formData.append('pixelsPerMeter', this.pixelsPerMeter.toString());
    if (this.imageBlob) formData.append('image', this.imageBlob);
    if (this.workplacePositionAction) formData.append('workplacePositionAction', this.workplacePositionAction.toString());

    return formData;
  }

  public imageURL(): string {
    return HOST + MAP_IMAGES_STORAGE_PATH + this.imageName;
  }

  public setFloorImageId(id: string) {
    this.id = id;
  }

  public setValidMap(isValid: boolean) {
    this.isValidMap = isValid;
  }

  public async updateImageSize(url: string): Promise<void> {
    const size = await getImgSize(url);

    this.prevHeight = this.height;
    this.prevWidth = this.width;

    this.width = size.width;
    this.height = size.height;
  }

  public updateFromDialogForm(model: IFloorImageDialog): void {
    this.name = model.name;
    this.pixelsPerMeter = model.pixelsPerMeter;
  }

  public async uploadImage(event: any): Promise<void> {
    if (event.target.files) {
      this.imageBlob = isValidMap(event.target.files[0]) ? event.target.files[0] : this.setValidMap(false);
    } else if (event.dataTransfer.files) {
      this.imageBlob = isValidMap(event.dataTransfer.files[0]) ? event.dataTransfer.files[0] : this.setValidMap(false);
    } else this.setValidMap(false);

    this.imageBase64 = await toBase64(this.imageBlob!);
    await this.updateImageSize(this.imageBase64);
    this.setValidMap(true);
  }

  public clear() {
    this.width = 0;
    this.height = 0;
    this.imageBase64 = '';
  }

  public clearMapId() {
    this.id = '';
  }
}

export default FloorImageModel;
