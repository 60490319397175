import React from 'react';

import { CircularProgress } from '@mui/material';
import * as S from './Spinner.styles';

const Spinner = () => {
  return (
    <S.Spinner>
      <CircularProgress size={100} />
    </S.Spinner>
  );
};

export default Spinner;
