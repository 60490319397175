import React from 'react';

const Loupe: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.80541" cy="9.80541" r="7.49047" stroke="#003A5D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.0151 15.4043L17.9518 18.3334" stroke="#003A5D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Loupe;
