import { makeAutoObservable } from 'mobx';

class UserFilterState {
  constructor(doFilter: () => void) {
    this.doFilter = doFilter;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  /* Call-back наружу из объекта для действия при изменении фильтров */
  private readonly doFilter: () => void;

  public pattern = '';

  public roleId = '';

  public setPattern(pattern: string) {
    this.pattern = pattern;
    this.doFilter();
  }

  public setRole(roleId: string) {
    this.roleId = roleId;
    this.doFilter();
  }

  public clear() {
    this.pattern = '';
    this.roleId = '';
    this.doFilter();
  }
}

export default UserFilterState;
