import styled from 'styled-components/macro';

export const ScrollXWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    & .MuiToggleButton-root {
      background-color: transparent;
    }

    background: -webkit-linear-gradient(270deg, rgb(255, 255, 255) 1%, rgb(231, 231, 231) 50%, rgb(255, 255, 255) 99%);
    background: -moz-linear-gradient(270deg, rgb(255, 255, 255) 1%, rgb(231, 231, 231) 50%, rgb(255, 255, 255) 99%);
    background: linear-gradient(270deg, rgb(255, 255, 255) 1%, rgb(231, 231, 231) 50%, rgb(255, 255, 255) 99%);

    & .MuiToggleButtonGroup-root {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      & .MuiButtonBase-root :first-child {
        margin-right: 0;
      }

      & ::-webkit-scrollbar {
        height: 0;
      }

      & *::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      & *::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: ${(props) => props.theme.spacing(1)};
  border-radius: ${(props) => props.theme.spacing(3)};
  pointer-events: all;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: ${(props) => props.theme.spacing(1)};
  border-radius: ${(props) => props.theme.spacing(3)};
  pointer-events: all;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
`;

export const CaptionWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
`;
