import { makeAutoObservable } from 'mobx';
import { reservationStatus } from 'utils/ReservationStatus';
import { getDateOnly, getNearHour, getTimeSpan } from '../../../utils/DateTimeUtils';
import { WorkPlaceType } from '../../enums';
import { IAttributeDto } from './IWorkPlacesApi';

import { IUsersDetailsDto } from './users';

export interface IReservationRowItemDto {
  id: string;
  workplaceId: string;
  timeFrom: Date;
  timeTo: Date;
  createdAt: Date;
  userId: string;
  userName: string;
  description: string;
  workplaceName: string;
  status: number;
  isCompleted: boolean;
  workplaceType: number | null;
}

export interface IReservationDto {
  id?: string | null;

  // Юзер у которого сейчас бронь
  userId?: string | null;
  fullUserName?: string | null;
  // Доп. инфа. появляется если есть разрешение ReservationUserInfoView
  userDetails?: IUsersDetailsDto | null;

  // Рабочее место
  workplaceId: string;
  workplaceNumber?: string | null;
  workplaceType?: WorkPlaceType | null;
  attributes?: IAttributeDto[] | null;
  comment?: string | null;

  // Бронь
  status?: reservationStatus;
  fromTime: Date;
  toTime?: Date | null;
  cancelationAtTime?: Date | null;
}

export class ReservationDto implements IReservationDto {
  constructor(dto: IReservationDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.id = dto.id;
    this.userId = dto.userId;
    this.fullUserName = dto.fullUserName;
    this.userDetails = dto.userDetails;

    this.workplaceId = dto.workplaceId;
    this.workplaceNumber = dto.workplaceNumber ?? '';
    this.workplaceType = dto.workplaceType;
    this.attributes = dto.attributes ?? [];
    this.comment = dto.comment ?? '';

    this.status = dto.status ?? reservationStatus.none;
    this.fromTime = dto.fromTime ?? getNearHour();
    this.toTime = dto.toTime;
    this.cancelationAtTime = dto.cancelationAtTime;
  }

  public id?: string | null = null;
  public isCreateBooking: boolean | null = null;

  // Юзер у которого сейчас бронь
  public userId?: string | null = null;
  public fullUserName?: string | null = null;
  public userDetails?: IUsersDetailsDto | null = null; // Доп. инфа. появляется если есть разрешение ReservationUserInfoView

  // Рабочее место
  public workplaceId: string;
  public workplaceNumber: string;
  public workplaceType?: WorkPlaceType | null;
  public attributes: IAttributeDto[];
  public comment: string;

  // Бронь
  public status: reservationStatus;
  public fromTime: Date;
  public toTime?: Date | null = null;
  public cancelationAtTime?: Date | null = null;

  // Рантайм поля
  public reservationText = 'Свободно в настоящий момент';
  public statusText = '';

  public setTimeInterval(fromTime?: Date | null, toTime?: Date | null) {
    if (!fromTime) fromTime = getNearHour();

    this.fromTime = fromTime;
    this.toTime = toTime;
  }

  public get getTime(): string {
    if (!this.toTime) return '';
    return `${getDateOnly(this.fromTime)} ${getTimeSpan(this.fromTime, this.toTime)}`;
  }
}

export class ReservationCreateOrUpdateDto {
  public workplaceId: string;
  public fromTime: Date;
  public toTime: Date;
  public userId: string;

  constructor(workplaceId: string, userId: string, fromTime: Date, toTime: Date) {
    this.workplaceId = workplaceId;
    this.fromTime = fromTime;
    this.toTime = toTime;
    this.userId = userId;
  }
}
