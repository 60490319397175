import styled from 'styled-components/macro';
import { FormControl as MuiFormControl } from '@mui/material';

export const FormControl = styled(MuiFormControl)`
  ${(_) => _.theme.breakpoints.down('sm')} {
    flex-direction: column;
    margin-right: 0;

    & :last-child {
      margin-left: 0;
      margin-top: 0;
    }
  }
  width: 100%;
`;

export const SearchInputWrapper = styled.div`
  ${(_) => _.theme.breakpoints.down('sm')} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }

  ${(_) => _.theme.breakpoints.up('sm')} {
    & > *:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing(2)};
    }
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;
