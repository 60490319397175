import React, { ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { messagesStore, notificationsStore, appStore, usersStore, rolesStore, companiesStore, departmentsStore, zonesStore, mapStore } from 'stores';
import { GlobalStyle, Sidebar, PopupList, ContentHeader, MainContent, Toggler } from 'components';
import { ITabToggler } from 'components/UIKit/Toggler/Toggler';
import { admin as adminRoutes, ROUTE } from 'routes/index';
import { MESSAGES_TYPES } from 'shared/constants';
import { Permissions } from 'shared/enums';
import { ChildElementType } from 'shared/interfaces/app';

import * as S from './AdminLayout.styles';

const AdminLayout: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();
  const [btnLabel, setBtnLabel] = useState('Создать');
  const [createBtnDisabled, setDisabled] = useState(false);

  useEffect(() => {
    const tabId = history.location.pathname;
    goToPage(tabId);
  }, []);

  useEffect(() => {
    if (appStore.adminSelectedTab === ROUTE.Users && !rolesStore.isAllow(Permissions.UserCreating)) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.Roles && !rolesStore.isAllow(Permissions.RolesCreating)) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.Departments && !rolesStore.isAllow(Permissions.DepartmentCreating)) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.Zones && !rolesStore.isAllow(Permissions.ZonesCreating)) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.MapConstructor && !rolesStore.isAllow(Permissions.WorkplaceCreating)) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.Companies) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.PassTemplates) setDisabled(true);
    if (appStore.adminSelectedTab === ROUTE.MapConstructor) setBtnLabel('Добавить новый объект');
  }, [appStore.adminSelectedTab]);

  const goToPage = (tabId: string) => {
    appStore.setAdminTab(tabId);

    const tabRoute = adminRoutes[0].children?.find((i) => i.path === tabId);
    if (tabRoute?.path) history.push(tabRoute?.path);
  };

  const handleOnClick = () => {
    messagesStore.popup.popupIsOpen && messagesStore.popup.setPopupMode(false);
    notificationsStore.popup.popupIsOpen && notificationsStore.popup.setPopupMode(false);

    history.replace(appStore.currentCategory.toLocaleLowerCase());
  };

  const getPopupList = (): ReactElement | null => {
    if (messagesStore.popup.popupIsOpen) {
      appStore.setCursorOnCategory(MESSAGES_TYPES.MESSAGES);

      const handleMessagesMouseEnter = () => {
        if (appStore.popupTimeoutID) window.clearTimeout(appStore.popupTimeoutID);
      };

      const handleMessagesMouseLeave = () => {
        messagesStore.popup.setPopupMode(false);
      };

      return appStore.currentCategory ? (
        <PopupList
          title={'Сообщения'}
          btnLabel={'Смотреть все сообщения'}
          onMouseEnter={handleMessagesMouseEnter}
          onMouseLeave={handleMessagesMouseLeave}
          listData={messagesStore.messagesForPopup}
          onClick={handleOnClick}
        />
      ) : null;
    }

    if (notificationsStore.popup.popupIsOpen) {
      appStore.setCursorOnCategory(MESSAGES_TYPES.NOTIFICATIONS);

      const handleNotificationsMouseEnter = () => {
        if (appStore.popupTimeoutID) window.clearTimeout(appStore.popupTimeoutID);
      };

      const handleNotificationsMouseLeave = () => {
        notificationsStore.popup.setPopupMode(false);
      };

      return appStore.currentCategory ? (
        <PopupList
          title={'Уведомления'}
          btnLabel={'Смотреть все уведомления'}
          onMouseEnter={handleNotificationsMouseEnter}
          onMouseLeave={handleNotificationsMouseLeave}
          listData={notificationsStore.notificationsForPopup}
          onClick={handleOnClick}
        />
      ) : null;
    }

    return null;
  };

  const handleChangeTab = (event: React.MouseEvent<HTMLElement>, tabId: string): void => {
    goToPage(tabId);
  };

  const handleCreateBtn = (): void => {
    switch (appStore.adminSelectedTab) {
      case ROUTE.Users:
        usersStore.setCreatingMode();
        break;
      case ROUTE.Roles:
        rolesStore.setCreatingMode();
        break;
      case ROUTE.Companies:
        companiesStore.setCreatingMode();
        break;
      case ROUTE.Departments:
        departmentsStore.setCreatingMode();
        break;
      case ROUTE.Zones:
        zonesStore.setCreatingMode();
        break;
      case ROUTE.MapConstructor:
        mapStore.setIsPosition(false);
        mapStore.setIsDraw(true);
        break;
      default:
        return usersStore.setCreatingMode();
    }
  };

  if (!adminRoutes[0].children) return null;

  const adminTabs: Array<ITabToggler> = [];

  adminRoutes[0].children?.forEach((item: ChildElementType) => {
    // Разрешения на модули админки
    if (item.path == ROUTE.Users && !rolesStore.isAllow(Permissions.UsersModuleView)) return;
    if (item.path == ROUTE.Roles && !rolesStore.isAllow(Permissions.RolesModuleView)) return;
    if (item.path == ROUTE.Departments && !rolesStore.isAllow(Permissions.DepartmentModuleView)) return;
    if (item.path == ROUTE.Companies && !rolesStore.isAllow(Permissions.CompaniesModuleView)) return;
    if (item.path == ROUTE.Zones && !rolesStore.isAllow(Permissions.ZonesModuleView)) return;
    if (item.path == ROUTE.PassTemplates && !rolesStore.isAllow(Permissions.PassesConstructorModuleView)) return;
    if (item.path == ROUTE.MapConstructor && !rolesStore.isAllow(Permissions.MapConstructorModuleView)) return;

    adminTabs.push({ id: item.path, name: item.name });
  });

  const adminSelectedTab = appStore.adminSelectedTab !== ROUTE.NONE ? appStore.adminSelectedTab : adminTabs[0]?.id;

  return (
    <S.Root>
      <GlobalStyle />

      <Sidebar />

      <S.AppContent onKeyDown={mapStore.setKeyDownEvent} onKeyUp={mapStore.setKeyDownEvent}>
        <ContentHeader title="Администрирование" btnLabel={btnLabel} btnDisabled={createBtnDisabled} onClick={handleCreateBtn} />

        <MainContent>
          <S.TogglerWrapper>
            <Toggler adminMode tabs={adminTabs} value={adminSelectedTab} onChange={handleChangeTab} />
          </S.TogglerWrapper>

          {children}
        </MainContent>

        {getPopupList()}
      </S.AppContent>
    </S.Root>
  );
};

export default observer(AdminLayout);
