import { endOfDay, startOfDay } from 'date-fns';
import endOfWeek from 'date-fns/endOfWeek';
import ruLocale from 'date-fns/locale/ru';
import startOfWeek from 'date-fns/startOfWeek';
import { makeAutoObservable } from 'mobx';
import { CalendarAction, CalendarViewMode } from 'shared/enums';
import CalendarViewData from 'shared/models/Calendar/CalendarViewData';
import ReservationFilter from 'shared/models/ReservationCalendar/ReservationFilter';
import { ReservationApi } from '../api';
import { ROUTE } from '../routes';
import { IReservationRowItemDto } from '../shared/interfaces/api/reservationDto';
import { calendarStore, reservationStore, userAuthStore, workPlacesStore } from './index';

class CalendarStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isFetching = false;

  public calendarFilter = new ReservationFilter();

  public viewPeriodMode = CalendarViewMode.Day;

  public anchorEl: HTMLElement | null = null;

  public reservations: CalendarViewData[] = [];

  public headerDateCellElems: HTMLElement[] = [];

  public currentDate: Date | null = null;

  public showActionButton = true;

  public getEventParams(eventId: string): CalendarViewData | null {
    const viewData = this.reservations.find((cV) => cV.id === eventId);

    return viewData || null;
  }

  public getLink() {
    const typeId = workPlacesStore.workPlacesPlainList.find((_) => _.id === calendarStore.calendarFilter.workplaceId)?.type;
    return `${ROUTE.Main}?workplaceId=${calendarStore.calendarFilter.workplaceId}&typeId=${typeId}`;
  }

  public async getCalendarViewData() {
    // Если нет места, то и нет календаря
    if (!calendarStore.calendarFilter.workplaceId && !calendarStore.calendarFilter.isOnlyMine) {
      this.reservations = [];
      return;
    }

    try {
      this.isFetching = true;

      const filter = this.calendarFilter.getCalendarFilterDto();
      const dto = await ReservationApi.getRowItemList(filter);

      const viewOthersReservation = !calendarStore.calendarFilter.isOnlyMine && !!calendarStore.calendarFilter.workplaceId;

      const reservations = dto.rows.filter((_: IReservationRowItemDto) => {
        return viewOthersReservation || userAuthStore.userId === _.userId;
      });

      this.reservations = reservations.map((_: IReservationRowItemDto) => {
        return new CalendarViewData(_, userAuthStore.userId === _.userId);
      });

      this.buildCardPositions();
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public setAnchorEl(e: any, id: string) {
    this.anchorEl = e.target;
    reservationStore.get(id).finally();
  }

  public setHTMLElements(elems: HTMLElement[]) {
    this.headerDateCellElems = elems;
  }

  public setShowActionButton(isShow: boolean) {
    this.showActionButton = isShow;
  }

  public updateCurrentDate(newDate: string, action: CalendarAction) {
    let date = new Date(newDate);

    let shiftSizeInDays = 1;

    if (this.viewPeriodMode === CalendarViewMode.Day) {
      shiftSizeInDays = 1;
    }

    if (this.viewPeriodMode === CalendarViewMode.Week) {
      shiftSizeInDays = 7;
    }

    if (action === CalendarAction.Prev) {
      date.setDate(date.getDate() - shiftSizeInDays);
    }

    if (action === CalendarAction.TODAY) {
      date = new Date();
    }

    if (action === CalendarAction.Next) {
      date.setDate(date.getDate() + shiftSizeInDays);
    }

    this.currentDate = date;

    this.formFilterPeriod();
  }

  private formFilterPeriod() {
    if (!this.currentDate) return;

    if (this.viewPeriodMode === CalendarViewMode.Day) {
      const startDay = startOfDay(this.currentDate);
      const endDay = endOfDay(this.currentDate);

      this.calendarFilter.onPeriodUpdate(startDay, endDay);
    }

    if (this.viewPeriodMode === CalendarViewMode.Week) {
      const startWeek = startOfWeek(this.currentDate, { locale: ruLocale });
      const endWeek = endOfWeek(this.currentDate, { locale: ruLocale });

      this.calendarFilter.onPeriodUpdate(startWeek, endWeek);
    }

    reservationStore.getRowItemList().finally();
  }

  public buildCardPositions() {
    for (let i = 0; i < this.reservations.length; i++) {
      for (let j = 0; j < this.reservations.length; j++) {
        if (CalendarStore.isCrosses(this.reservations[i], this.reservations[j])) {
          this.reservations[i].setWidth(50);
        }
      }
    }
  }

  private static isCrosses(timeCardA: CalendarViewData, timeCardB: CalendarViewData): boolean {
    if (timeCardA.id === timeCardB.id) return false;

    if (!timeCardA.start || !timeCardA.end || !timeCardB.start || !timeCardB.end) return false;

    if (timeCardB.start >= timeCardA.start && timeCardB.end <= timeCardA.end) {
      return true;
    }

    if (timeCardB.start <= timeCardA.start && timeCardB.end >= timeCardA.end) {
      return true;
    }

    if (timeCardB.end > timeCardA.start && timeCardB.end < timeCardA.end) {
      return true;
    }

    if (timeCardB.start > timeCardA.start && timeCardB.start < timeCardA.end) {
      return true;
    }

    return false;
  }

  public selectViewPeriodMode(event: React.MouseEvent<HTMLElement>, value: string) {
    if (!value) return;

    this.viewPeriodMode = value as CalendarViewMode;

    calendarStore.updateCurrentDate(new Date().toString(), CalendarAction.TODAY);
    this.formFilterPeriod();
    this.getCalendarViewData().finally();
  }

  public onOnlyMineChange(isOnlyMine: boolean) {
    this.calendarFilter.onOnlyMineChange(isOnlyMine);
    this.reservations = [];
  }

  public hideInfo() {
    this.anchorEl = null;
  }

  public deInit() {
    this.headerDateCellElems = [];
    this.reservations = [];
    this.currentDate = null;
    this.anchorEl = null;
    this.isFetching = false;

    this.calendarFilter.deInit();
  }
}

export default new CalendarStore();
