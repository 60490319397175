import React, { MouseEvent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Plus } from 'react-feather';
import { mapStore, rolesStore } from 'stores';
import { Tag } from 'components';
import { DialogStatus } from 'shared/enums';
import { FloorImageModel } from 'shared/models';
import { Permissions } from 'shared/enums';

import * as S from './FloorsPanel.styles';

const FloorsPanel: React.FC = () => {
  const handleChangeFloor = async (event: React.MouseEvent<HTMLElement>, value: string): Promise<void> => {
    if (value !== null) {
      mapStore.selectedFloor.setFloorImageId(value);
      await mapStore.getFloorImagesList(mapStore.selectedFloor.id);
      mapStore.selectedFloorImage = new FloorImageModel(mapStore.floorImages[0]);
      await mapStore.getFloorImageById();
    }
  };

  const handleCreateFloor = () => {
    mapStore.setCreatingMode();
  };

  const handleEditFloor = async (e: MouseEvent, id: string): Promise<void> => {
    e.stopPropagation();
    await mapStore.getFloorById(id);

    mapStore.setDialogViewMode(DialogStatus.Editing);
  };

  const handleDeleteFloor = async (e: MouseEvent, id: string): Promise<void> => {
    e.stopPropagation();
    await mapStore.getFloorById(id);
    mapStore.dialogFloorViewMode = DialogStatus.Deleting;
  };

  const handleDeleteFloorImage = async (e: MouseEvent, id: string): Promise<void> => {
    e.stopPropagation();
    await mapStore.getFloorImageById(id);
    mapStore.dialogFloorImageViewMode = DialogStatus.Deleting;
  };

  const handleChangeFloorImage = async (event: React.MouseEvent<HTMLElement>, value: string): Promise<void> => {
    if (value !== null) {
      mapStore.selectedFloorImage.setFloorImageId(value);

      mapStore.selectedFloorImage.clear();
      await mapStore.getFloorImageById();
    }
  };

  const handleCreateFloorImage = () => {
    mapStore.openFloorImageDialog();
  };

  const handleEditFloorImage = async (e: MouseEvent, id: string): Promise<void> => {
    e.stopPropagation();
    await mapStore.getFloorImageById(id);
    mapStore.selectedFloorImage.imageBase64 = mapStore.selectedFloorImage.imageURL();

    mapStore.setDialogFloorImageMode(DialogStatus.Editing);
  };

  const mapOrFloorCreatingAllow = rolesStore.isAllow(Permissions.MapOrFloorCreating);
  const mapOrFloorUpdatingAllow = rolesStore.isAllow(Permissions.MapOrFloorUpdating);
  const mapOrFloorRemovingAllow = rolesStore.isAllow(Permissions.MapOrFloorRemoving);

  return (
    <S.MapActions>
      <S.Column>
        <Tag
          size="small"
          orientation="vertical"
          value={mapStore.selectedFloor.id}
          onCustomChange={handleChangeFloor}
          tags={mapStore.floorsTags}
          allowEdit={mapOrFloorUpdatingAllow}
          allowDelete={mapOrFloorRemovingAllow}
          onEditClick={handleEditFloor}
          onDeleteClick={handleDeleteFloor}
        />
        {/* TODO: mikhail.broch: change to button component from UI Kit */}
        {mapOrFloorCreatingAllow && (
          <S.AddFloor onClick={handleCreateFloor} data-for="tooltip" data-tip="Добавить этаж">
            <Plus />
          </S.AddFloor>
        )}
      </S.Column>

      <S.Column>
        {!!mapStore.floorImages.length && (
          <Tag
            size="small"
            orientation="vertical"
            value={mapStore.selectedFloorImage.id}
            onCustomChange={handleChangeFloorImage}
            tags={mapStore.floorImagesTags}
            allowEdit={mapOrFloorUpdatingAllow}
            allowDelete={mapOrFloorRemovingAllow}
            onEditClick={handleEditFloorImage}
            onDeleteClick={handleDeleteFloorImage}
          />
        )}
        {/* TODO: mikhail.broch: change to button component from UI Kit */}
        {!!mapStore.floors.length && mapOrFloorCreatingAllow && (
          <S.AddFloor onClick={handleCreateFloorImage} data-for="tooltip" data-tip="Добавить карту этажа">
            <Plus />
          </S.AddFloor>
        )}
      </S.Column>
    </S.MapActions>
  );
};

export default observer(FloorsPanel);
