import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { messagesStore } from 'stores';
import Linkify from 'react-linkify';

import { ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { MessagesModel } from 'shared/models';
import { getFullDateTime } from 'utils/DateTimeUtils';
import { Button } from '@mui/material';
import * as S from './MessageViewDialog.styles';

interface IMessageViewDialogProps {
  onClose: () => void;
}

const MessageViewDialog: React.FC<IMessageViewDialogProps> = (props) => {
  useEffect(() => {
    return () => {
      messagesStore.selectedMessage = new MessagesModel();
    };
  }, []);

  const { onClose } = props;

  const date = new Date(messagesStore.selectedMessage.createdAt!);
  const content = messagesStore.selectedMessage.content;

  return (
    <ModalDialog
      title={messagesStore.selectedMessage.caption}
      subTitle={messagesStore.selectedMessage.fullNameValue}
      date={getFullDateTime(date)}
      onClose={onClose}
      onCancel={onClose}
      createBtnLabel={'Отправить'}
      isOpen={messagesStore.dialogViewMode !== DialogStatus.Closed}>
      <S.ContentWrapper>
        <div>
          <Linkify
            componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
              <a target="blank" href={decoratedHref} key={key}>
                {' '}
                {decoratedText}{' '}
              </a>
            )}>
            {content}
          </Linkify>
        </div>
        <Button variant="outlined" fullWidth size="large" onClick={onClose}>
          Закрыть
        </Button>
      </S.ContentWrapper>
    </ModalDialog>
  );
};

export default observer(MessageViewDialog);
