import { DateRange } from '@mui/lab';
import { makeAutoObservable } from 'mobx';
import { IDropDownOption, IPassFilter } from 'shared/interfaces/app';
import { getEndOfDay, getStartOfDay, nowToLocal } from 'utils/DateTimeUtils';
import { addDays, addMonths } from 'date-fns';

class PassFilterModel {
  constructor(dto?: IPassFilter) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.from = dto.from;
    this.to = dto.to;
    this.userId = dto.userId;
    this.isMyPass = dto.myPass;
    this.status = dto.status;
  }

  public statusOpt: IDropDownOption[] = [
    {
      id: 'all',
      name: 'Все',
    },
    {
      id: 'confirmed',
      name: 'Подтверждён',
    },
    {
      id: 'unConfirmed',
      name: 'Отклонён',
    },
  ];

  // Сегодня
  public from: Date = getStartOfDay();

  // Неделя
  public to: Date = getEndOfDay(addDays(new Date(), 6));

  public userId = '';

  public isMyPass = true;

  public status = 'all';

  public get queryParamsDto(): Omit<IPassFilter, 'myPass' | 'status'> {
    return {
      from: this.from,
      to: this.to,
      userId: this.userId,
    };
  }

  public get queryDateRange(): DateRange<Date> {
    return [this.from, this.to];
  }

  public setDateRange(dateRange: DateRange<Date>) {
    if (!dateRange) return;

    this.from = dateRange[0]!;
    this.to = dateRange[1]!;
  }

  public setUserId(userId: string) {
    this.userId = userId;
  }

  public setMyPass(isMyPass: boolean) {
    this.isMyPass = isMyPass;
  }

  public setStatus(status: string) {
    this.status = status;
  }
}

export default PassFilterModel;
