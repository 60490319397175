import React, { useEffect } from 'react';

import { Badge } from 'components';
import { useBreakpoint } from 'utils/hooks';
import { ToggleButton as MuiToggleButton, ToggleButtonGroup, ToggleButtonProps } from '@mui/material';

import * as S from './Toggler.styles';
import { styled } from '@mui/material/styles';

const ToggleButton = styled((props: ToggleButtonProps) => <MuiToggleButton {...props} />)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
  border: 'none',
  opacity: 0.6,
  height: `${theme.spacing(10)}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: `${theme.spacing(2)} !important`,

  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
  },

  '&.MuiToggleButton-sizeSmall': {
    padding: `${theme.spacing(0.5)} ${theme.spacing(4)}`,
    height: '24px',
  },

  '&.Mui-selected': {
    opacity: 1,
    backgroundColor: theme.palette.common.white,
    boxShadow: `
      0px 4px 8px -4px rgba(0, 58, 93, 0.1), 
      inset 0px -1px 1px rgba(0, 0, 0, 0.04), 
      inset 0px 2px 0px rgba(255, 255, 255, 0.25)
    `,

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: 0.8,
    },
  },

  '&:first-child': {
    marginRight: theme.spacing(1),
  },
}));

export interface ITabToggler {
  id: string;
  name?: string | null;
  count?: number;
  icon?: JSX.Element;
}

interface ITogglerProps {
  tabs: ITabToggler[];
  value: string;
  size?: 'small' | 'medium';
  adminMode?: boolean;
  onChange: (event: React.MouseEvent<HTMLElement>, newTab: string) => void;
}

const Toggler: React.FC<ITogglerProps> = (props) => {
  const { tabs, value, size, adminMode, onChange } = props;

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  useEffect(() => {
    const btn = document.querySelector('.Mui-selected')?.getBoundingClientRect();
    const btnGR = document.querySelector('.MuiToggleButtonGroup-root');
    if (btn == undefined || btnGR == undefined) return;
    btnGR.scrollLeft = btnGR.scrollLeft + btn.x - btn.width;
  });

  const onChangeHandler = (event: React.MouseEvent<HTMLElement>, tabId: string) => {
    if (!tabId) return;
    onChange(event, tabId);
  };

  const Caption: React.FC<{ caption: string }> = (props) => <S.CaptionWrapper>{props.caption}</S.CaptionWrapper>;

  return (
    <>
      {adminMode ? (
        <S.ScrollXWrapper>
          <ToggleButtonGroup sx={{ display: 'flex', flexWrap: 'wrap' }} size={size} value={value} exclusive onChange={onChangeHandler}>
            {tabs.map((tab) => {
              return (
                <ToggleButton key={`${tab.id}`} value={tab.id} disableRipple style={{ width: 'fit-content' }}>
                  {tab.icon ? <S.IconWrapper> {tab.icon} </S.IconWrapper> : null}
                  {tab.count ? (
                    <S.BadgeWrapper>
                      <Caption caption={tab.name || ''} /> <Badge count={tab.count} />
                    </S.BadgeWrapper>
                  ) : (
                    <Caption caption={tab.name || ''} />
                  )}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </S.ScrollXWrapper>
      ) : (
        <S.Wrapper>
          <ToggleButtonGroup sx={{ display: 'flex', flexWrap: 'wrap' }} size={size} value={value} exclusive onChange={onChangeHandler}>
            {tabs.map((tab) => {
              return (
                <ToggleButton key={`${tab.id}`} value={tab.id} disableRipple style={{ width: 'fit-content' }}>
                  {tab.icon ? <S.IconWrapper> {tab.icon} </S.IconWrapper> : null}
                  {tab.count ? (
                    <S.BadgeWrapper>
                      <Caption caption={tab.name || ''} /> <Badge count={tab.count} />
                    </S.BadgeWrapper>
                  ) : (
                    <Caption caption={tab.name || ''} />
                  )}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </S.Wrapper>
      )}
    </>
  );
};

export default Toggler;
