import styled from 'styled-components/macro';

export const FiltersWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    margin-bottom: 0;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    & > *:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing(2)};
    }
  }

  & > * {
    margin-bottom: ${(props) => props.theme.spacing(2)};
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;
