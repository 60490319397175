import React, { useCallback } from 'react';

import { ChromePicker, ColorResult } from 'react-color';

import { appStore, companiesStore } from 'stores';

import { THEMES } from 'shared/constants';
import { ColorPickStatus } from 'shared/enums';
import { Popover } from '@mui/material';
import * as S from './ColorPickerList.styles';

interface ColorPickerProps {
  isDefault: boolean;
}

const themeOptionsNames = {
  primary: 'Основной',
  secondary: 'Дополнительный',
};

const themeOptionsNames2 = {
  success: 'Успешное действие',
  error: 'Ошибка',
  warning: 'Предупреждение',
};

const fontColorOptionsNames = {
  primary: 'Основной',
  secondary: 'Дополнительный',
  inverted: 'Инвертированный',
};

const backgroundColorOptionsNames = {
  primary: 'Основной',
  secondary: 'Дополнительный',
};

const ColorPickerList = (props: ColorPickerProps) => {
  const { isDefault } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [colorValue, setColorValue] = React.useState<string>('#a1a1a1');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, key: string, type: ColorPickStatus) => {
    setAnchorEl(event.currentTarget);

    companiesStore.selectedCompany.colorPickState = type;
    companiesStore.selectedCompany.selectedColorKey = key;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = useCallback(
    (color: ColorResult) => {
      setColorValue(color.hex);
      companiesStore.selectedCompany.setSelectedColor(colorValue);
    },
    [colorValue]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'color-picker' : undefined;

  return (
    <div>
      <S.ColorPickerListWrapper aria-disabled={isDefault} aria-describedby={id}>
        <S.ColorPickerList subheader={<S.ColorPickerTitle>Основные</S.ColorPickerTitle>}>
          {Object.entries(themeOptionsNames).map(([key, value], index) => {
            return (
              <S.ColorPickerListItem key={index} disabled={!isDefault}>
                <S.ColorPickerButton
                  style={{ background: companiesStore.selectedCompany.themeData?.mainColor[key] ?? '#a1a1a1' }}
                  onClick={(event) => {
                    if (!isDefault) return;
                    handleClick(event, key, ColorPickStatus.Main);
                  }}
                />
                <S.ColorPickerLabel>{value}</S.ColorPickerLabel>
              </S.ColorPickerListItem>
            );
          })}
        </S.ColorPickerList>

        <S.ColorPickerList subheader={<S.ColorPickerTitle>Статус</S.ColorPickerTitle>}>
          {Object.entries(themeOptionsNames2).map(([key, value], index) => {
            return (
              <S.ColorPickerListItem key={index} disabled={!isDefault}>
                <S.ColorPickerButton
                  style={{ background: companiesStore.selectedCompany.themeData?.statusColor[key] ?? '#a1a1a1' }}
                  onClick={(event) => {
                    if (!isDefault) return;
                    handleClick(event, key, ColorPickStatus.Status);
                  }}
                />
                <S.ColorPickerLabel>{value}</S.ColorPickerLabel>
              </S.ColorPickerListItem>
            );
          })}
        </S.ColorPickerList>

        <S.ColorPickerList subheader={<S.ColorPickerTitle>Шрифт</S.ColorPickerTitle>}>
          {Object.entries(fontColorOptionsNames).map(([key, value], index) => {
            return (
              <S.ColorPickerListItem key={index} disabled={!isDefault}>
                <S.ColorPickerButton
                  style={{ background: companiesStore.selectedCompany.themeData?.fontColor[key] ?? '#a1a1a1' }}
                  onClick={(event) => {
                    if (!isDefault) return;
                    handleClick(event, key, ColorPickStatus.Font);
                  }}
                />
                <S.ColorPickerLabel>{value}</S.ColorPickerLabel>
              </S.ColorPickerListItem>
            );
          })}
        </S.ColorPickerList>

        <S.ColorPickerList subheader={<S.ColorPickerTitle>Задний фон</S.ColorPickerTitle>}>
          {Object.entries(backgroundColorOptionsNames).map(([key, value], index) => {
            return (
              <S.ColorPickerListItem key={index} disabled={!isDefault}>
                <S.ColorPickerButton
                  style={{ background: companiesStore.selectedCompany.themeData?.backgroundColor[key] ?? '#a1a1a1' }}
                  onClick={(event) => {
                    if (!isDefault) return;
                    handleClick(event, key, ColorPickStatus.Background);
                  }}
                />
                <S.ColorPickerLabel>{value}</S.ColorPickerLabel>
              </S.ColorPickerListItem>
            );
          })}
        </S.ColorPickerList>
      </S.ColorPickerListWrapper>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <ChromePicker color={colorValue} onChange={handleChangeColor} />
      </Popover>
    </div>
  );
};

export default ColorPickerList;
