import styled from 'styled-components/macro';

export const WorkPlaceCount = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: ${(p) => p.theme.palette.common.white};
  padding: ${(p) => p.theme.spacing(4)};
  border-radius: 0 24px 0 0;
  position: relative;
`;
