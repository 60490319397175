import { makeAutoObservable } from 'mobx';
import { PassTemplatesApi } from 'api';

import { PassTemplatesModel } from 'shared/models';

class PassTemplatesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public passTemplates: PassTemplatesModel[] = [];

  public selectedPassTemplate: PassTemplatesModel = new PassTemplatesModel();

  public isFetching = false;

  public async getPassTemplates() {
    try {
      this.isFetching = false;

      const passTemplates = await PassTemplatesApi.getPassTemplates();
      if (!passTemplates) return;

      this.passTemplates = passTemplates.map((passTemplate) => {
        return new PassTemplatesModel(passTemplate);
      });
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = true;
    }
  }

  public async getPassTemplate(passTemplateId: string) {
    try {
      this.isFetching = true;

      const passTemplate = await PassTemplatesApi.getPassTemplate(passTemplateId);

      if (!passTemplate) return;

      this.selectedPassTemplate = new PassTemplatesModel(passTemplate);
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async postPassTemplate() {
    try {
      this.isFetching = true;

      if (!this.selectedPassTemplate.postPutDto) return;

      const id = await PassTemplatesApi.postPassTemplate(this.selectedPassTemplate.postPutDto);

      if (!id) return;
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async putPassTemplate() {
    try {
      this.isFetching = true;

      if (!this.selectedPassTemplate.postPutDto) return;

      await PassTemplatesApi.putPassTemplate(this.selectedPassTemplate.id, this.selectedPassTemplate.postPutDto);
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async deletePassTemplate() {
    try {
      this.isFetching = true;

      await PassTemplatesApi.deletePassTemplate(this.selectedPassTemplate.id);
      await this.getPassTemplates();
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }
}

export default new PassTemplatesStore();
