import styled from 'styled-components/macro';
import { Paper as MuiPaper } from '@mui/material';

export const Paper = styled(MuiPaper)`
  height: max-content;
  z-index: 100;
  max-width: 392px;
  position: absolute;
  left: 0;
  top: 0;
  padding: ${(props) => props.theme.spacing(3)};
  background-color: ${(props) => props.theme.content.popupList.paper.background};
  border-radius: ${(props) => props.theme.spacing(4)};
`;
