import React from 'react';

const PlusCubeIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#00B8B0" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0001 8.32731V15.6537" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.6668 11.9905H8.3335" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusCubeIcon;
