import styled from 'styled-components/macro';

import {
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';

export const DialogTitle = styled(MuiDialogTitle)`
  padding: 0;
  justify-content: flex-start;
  overflow-x: hidden;
`;

export const Title = styled.div<{ titleFontSize?: string }>`
  font-size: ${(_) => _.titleFontSize};
`;

export const DialogContent = styled(MuiDialogContent)`
  flex-direction: column;
`;

export const DialogActions = styled(MuiDialogActions)`
  flex-direction: column;
`;

export const TopMobileTitle = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(3)};
`;

export const MobileData = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  margin-bottom: ${(props) => props.theme.spacing(0.5)};
`;

export const MobileSubtitle = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const DialogWrapper = styled(MuiDialog)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    & .MuiPaper-root {
      margin: ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)}
        ${(props) => props.theme.spacing(2)};
    }
  }
  backdrop-filter: blur(4px);
  & .MuiBackdrop-root {
    background-color: rgba(2, 33, 52, 0.3);
  }
`;
