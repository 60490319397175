import React, { ChangeEvent } from 'react';
import { DateRange } from '@mui/lab';
import { observer } from 'mobx-react';
import { Checkbox, DropDown } from '../../../../components';
import DateRangePicker from '../../../../components/UIKit/DateRangePicker';
import { passesStore, usersStore } from '../../../../stores';
import * as S from './PassesFilters.styles';

const PassesFilters = () => {
  const handleChangeDateRange = (dateRange: DateRange<Date>): void => {
    passesStore.queryFilterParams.setDateRange(dateRange);
    passesStore.getPassesList().finally();
  };

  const onChangeFilterUser = (event: ChangeEvent<{ value: string }>) => {
    passesStore.queryFilterParams.setUserId(event.target.value);
    passesStore.getPassesList().finally();
  };

  const onChangeFilterMyPass = (e: any, checked: boolean) => {
    passesStore.queryFilterParams.setUserId('');
    passesStore.queryFilterParams.setMyPass(checked);
    passesStore.getPassesList().finally();
  };

  const onChangeFilterStatus = (event: ChangeEvent<{ value: string }>) => {
    passesStore.queryFilterParams.setStatus(event.target.value);
    passesStore.getPassesList().finally();
  };

  return (
    <S.FiltersWrapper>
      <DateRangePicker onChange={handleChangeDateRange} range={passesStore.queryFilterParams.queryDateRange} />

      {passesStore.isViewAll && (
        <DropDown
          name="userId"
          disabled={passesStore.queryFilterParams.isMyPass}
          options={usersStore.usersForOptions}
          value={passesStore.queryFilterParams.userId}
          placeholder="ФИО"
          onChange={onChangeFilterUser}
        />
      )}

      <DropDown
        name="status"
        hideNotSelectItem={true}
        withoutSearch={true}
        options={passesStore.queryFilterParams.statusOpt}
        value={passesStore.queryFilterParams.status}
        placeholder="Статус заявления"
        onChange={onChangeFilterStatus}
      />

      {passesStore.isViewAll && <Checkbox label="Мои пропуска" checked={passesStore.queryFilterParams.isMyPass} onChange={onChangeFilterMyPass} />}
    </S.FiltersWrapper>
  );
};

export default observer(PassesFilters);
