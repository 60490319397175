import React from 'react';
import SelectExpand from 'components/Icons/SelectExpand';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import * as S from './Accordion.styles';

interface IAccordionProps {
  title: string;
  children: JSX.Element;
}

const Accordion: React.FC<IAccordionProps> = (props) => {
  const { title, children } = props;
  return (
    <S.Accordion>
      <AccordionSummary expandIcon={<SelectExpand />}>
        <Typography variant="body2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </S.Accordion>
  );
};

export default Accordion;
