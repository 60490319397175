import styled from 'styled-components';

export const WorkPlaceCount = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: ${(p) => p.theme.palette.common.white};
  padding: ${(p) => p.theme.spacing(4)};
  border-radius: 0 24px 0 0;
  position: relative;
`;

export const PanelWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 100%;
    justify-content: flex-end;
  }

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: 9;
  max-width: 200px;
  min-width: 150px;
  margin-top: ${(p) => p.theme.spacing(-4)};
  margin-left: ${(p) => p.theme.spacing(2)};
  pointer-events: none;
`;

export const FloorsLegendWrapper = styled.div`
  height: 66%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
