import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { ShowcaseEmbedWindow } from 'shared/sdk.d';
import { MATTERPORT_SDK_KEY, MATTERPORT_MODEL_HOST } from 'shared/constants';
import { reservationStore, workPlacesStore, mapStore } from 'stores';
import * as S from './Tour.styles';
import { IMattertagGetDto } from 'shared/interfaces/app';
import { toastStore } from 'components/UIKit/Toast';

const Tour: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    tourInit().finally();

    return () => {
      workPlacesStore.setTourEnable(false);
    };
  }, []);

  const onShowcaseConnect = async (mpSdk: any) => {
    const mattertags: IMattertagGetDto[] = await mpSdk.Mattertag.getData();
    workPlacesStore.setTourArray(mattertags);
  };

  const tagClickHandler = (tagId: string) => {
    const workplaceId = workPlacesStore.workplaceIdByTagId(tagId);

    if (workplaceId) {
      const filter = workPlacesStore.queryFilterParams;
      reservationStore.createDialog(workplaceId, filter.fromTime, filter.toTime).finally();
    } else {
      toastStore.showError('Рабочее место не найдено');
    }
  };

  const mpSdkInit = async () => {
    const showcaseIframe = iframeRef.current;
    const embeddingWindow = window as ShowcaseEmbedWindow;

    return await embeddingWindow.MP_SDK.connect(
      showcaseIframe!,
      MATTERPORT_SDK_KEY || '',
      '' // Unused but needs to be a valid string
    );
  };

  const setMattertagsDialogInvisible = async (mpSdk: any) => {
    const data = await mpSdk.Mattertag.getData();
    await data.forEach((element: any) => {
      mpSdk.Mattertag.preventAction(element.sid, { opening: true });
    });
  };

  const tourInit = async () => {
    try {
      await workPlacesStore.getAvailableWorkPlainPlaces();
      const mpSdk = await mpSdkInit();
      await setMattertagsDialogInvisible(mpSdk);
      mpSdk.on(mpSdk.Mattertag.Event.CLICK, tagClickHandler);
      await onShowcaseConnect(mpSdk);
    } catch (e) {
      toastStore.showError(`${e}`);
    }
  };

  return (
    <>
      <S.TourContentWrapper>
        <iframe
          title="Matterport"
          style={{ backgroundColor: 'black' }}
          src={`${MATTERPORT_MODEL_HOST}/show?m=${mapStore.selectedFloor.tourModelName}&play=1&applicationKey=${MATTERPORT_SDK_KEY}`}
          frameBorder={0}
          allowFullScreen
          allow="xr-spatial-tracking"
          id="showcase-iframe"
          ref={iframeRef}
        />
      </S.TourContentWrapper>
    </>
  );
};

export default observer(Tour);
