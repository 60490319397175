import { makeAutoObservable } from 'mobx';
import { userAuthStore } from 'stores';
import { NotificationsApi } from 'api';
import { DialogStatus } from 'shared/enums';
import { NotificationsModel, NotificationsQueryModel, NotificationsPopupModel } from 'shared/models';
import { ITabToggler } from '../components/UIKit/Toggler/Toggler';

enum NotificationsTabActions {
  All = 'all',
  UnRead = 'unread',
}

class NotificationsStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    // Для dev период 10 сек. Для prod - 1 мин
    const timeIntervalSec = process.env.REACT_APP_IS_DEVELOPMENT === 'true' ? 10 : 60;

    setInterval(this.iteration, 1000 * timeIntervalSec);
    setTimeout(this.iteration, 0);
  }

  /**
   * Опрос новых уведомлоений
   * @private
   */
  // todo kvv: в дальнейшем заменить на сокеты
  private async iteration(): Promise<void> {
    const count = this.unreadNotificationsCount;
    await this.getUnreadCount();

    // Если увеличилось кол-во, то запрашиваем не прочитанные для попапа
    if (this.unreadNotificationsCount > count) await this.getUnreadNotifications();

    // Если увеличилось кол-во и активна вкладка, то запрашиваем все
    if (this.isActivePage && this.unreadNotificationsCount > count) await this.getNotificationsList();
  }

  public isActivePage = false;

  public notifications: NotificationsModel[] = [];

  public unreadNotifications: NotificationsModel[] = [];

  public selectedNotification = new NotificationsModel();

  public queryParams = new NotificationsQueryModel();

  public popup = new NotificationsPopupModel();

  public totalCount = 0;

  public unreadNotificationsCount = 0;

  public dialogViewMode = DialogStatus.Closed;

  public isFetching = false;

  public selectedTab: string = NotificationsTabActions.All;

  public get notificationsTabs(): Array<ITabToggler> {
    return [
      {
        id: NotificationsTabActions.All,
        name: 'Все',
      },
      {
        id: NotificationsTabActions.UnRead,
        name: 'Непрочитанные',
        count: this.unreadNotificationsCount,
      },
    ];
  }

  public get notificationsForList(): NotificationsModel[] {
    if (this.tab === NotificationsTabActions.UnRead) {
      return this.notifications.filter((_) => !_.isRead);
    }
    return this.notifications;
  }

  public get notificationsForPopup(): NotificationsModel[] {
    return this.unreadNotifications;
  }

  public get notificationsQueryParams() {
    this.queryParams.userId = userAuthStore.userId;
    return this.queryParams;
  }

  public get tab() {
    return this.selectedTab;
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCreatingMode() {
    this.selectedNotification = new NotificationsModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public setTab(value: string) {
    this.queryParams.pageIndex = 0;
    this.selectedTab = value;
  }

  public closeNotificationsDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public async getNotificationsList() {
    try {
      this.isFetching = true;

      const notifications = await NotificationsApi.getNotifications(this.notificationsQueryParams, this.tab === NotificationsTabActions.UnRead);

      if (!notifications) return;

      this.totalCount = notifications.totalCount;
      this.notifications = notifications.rows.map((item) => new NotificationsModel(item));
    } catch (e) {
      this.notifications = [];
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getUnreadCount() {
    try {
      if (!userAuthStore.userId) return;
      const count = await NotificationsApi.getUnreadCount(userAuthStore.userId);
      if (!count) return;

      this.setUnreadCount(count);
    } catch (e) {
      //ignore
    }
  }

  public setUnreadCount(count: number) {
    this.unreadNotificationsCount = count;
  }

  public async readNotification() {
    try {
      await NotificationsApi.putNotificationStatus(userAuthStore.userId, this.selectedNotification.id);
      await this.getNotificationsList();

      // Уменьшаяем непрочитанные на 1
      if (this.unreadNotificationsCount > 0) this.unreadNotificationsCount -= 1;
    } catch (e) {
      //ignore
    }
  }

  public async getUnreadNotifications() {
    try {
      this.isFetching = true;

      const notifications = await NotificationsApi.getLastUnreadNotifications(userAuthStore.userId, 4);

      if (!notifications) return;

      this.unreadNotifications = notifications.map((item) => new NotificationsModel(item));
    } catch (e) {
      this.unreadNotifications = [];
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public setActivePage(value: boolean) {
    this.isActivePage = value;
  }
}

export default new NotificationsStore();
