import {
  IDepartmentListGetDto,
  ISendEmailDto,
  ICompanyGetDto,
  ICompaniesGetDto,
  IDepartmentPostPutDto,
  IDepartmentGetDto,
  ISmtpParams,
} from 'shared/interfaces/api/ICompaniesApi';

import HTTPService, { IHttpRequest } from './HTTPService';
import { userAuthStore } from '../stores';

class CompaniesApi extends HTTPService {
  public constructor() {
    super('Companies');
  }

  public getCompanies(): Promise<ICompaniesGetDto[]> {
    return this.GET<ICompaniesGetDto[]>('', {}).then((companies: ICompaniesGetDto[]) => companies);
  }

  public getCompany(companyId: string): Promise<ICompanyGetDto> {
    return this.GET<ICompanyGetDto>(`${companyId}`, {}).then((company: ICompanyGetDto) => company);
  }

  public postCompany(companyData: FormData): Promise<string> {
    return this.POST('', companyData, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public putCompany(companyId: string, data: FormData): Promise<void> {
    const request: IHttpRequest<FormData> = {
      path: `${companyId}`,
      data,
      options: { headers: { 'Content-Type': 'multipart/form-data' } },
    };
    return this.PUT<FormData, void>(request);
  }

  public deleteCompany(companyId: string): Promise<void> {
    return this.DELETE<void>(`${companyId}`, {}).then((response) => response);
  }

  public sendTestEmail(smtpParamsData: ISendEmailDto): Promise<void> {
    return this.POST('send-test-email', smtpParamsData, {});
  }

  public checkSmtp(smtpParamsData: ISmtpParams): Promise<void> {
    return this.POST('check-smtp', smtpParamsData, {});
  }

  public checkSmtpByCompany(): Promise<void> {
    const companyId = userAuthStore.companyId;
    return this.POST(`check-default-smtp`, { companyId });
  }

  public getDepartments(companyId: string): Promise<IDepartmentListGetDto[]> {
    return this.GET(`${companyId}/departments`);
  }

  public getDepartmentById(companyId: string, id: string): Promise<IDepartmentGetDto> {
    return this.GET(`${companyId}/departments/${id}`);
  }

  public postDepartment(companyId: string, newDepartment: IDepartmentPostPutDto): Promise<string> {
    return this.POST(`${companyId}/departments`, newDepartment);
  }

  public putDepartment(companyId: string, id: string, data: IDepartmentPostPutDto): Promise<void> {
    const request: IHttpRequest<IDepartmentPostPutDto> = {
      path: `${companyId}/departments/${id}`,
      data,
    };
    return this.PUT<IDepartmentPostPutDto, void>(request);
  }

  public deleteDepartment(companyId: string, id: string): Promise<void> {
    return this.DELETE(`${companyId}/departments/${id}`);
  }
}

export default new CompaniesApi();
