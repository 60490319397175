import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { messagesStore, notificationsStore, appStore } from 'stores';
import { GlobalStyle, Sidebar, PopupList } from 'components';
import { MESSAGES_TYPES } from 'shared/constants';

import * as S from './MainLayout.styles';

const MainLayout: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();

  const handleOnClick = () => {
    messagesStore.popup.popupIsOpen && messagesStore.popup.setPopupMode(false);
    notificationsStore.popup.popupIsOpen && notificationsStore.popup.setPopupMode(false);

    history.replace(appStore.currentCategory.toLocaleLowerCase());
  };

  const getPopupList = (): ReactElement | null => {
    if (messagesStore.popup.popupIsOpen) {
      appStore.setCursorOnCategory(MESSAGES_TYPES.MESSAGES);

      const handleMessagesMouseEnter = () => {
        if (appStore.popupTimeoutID) window.clearTimeout(appStore.popupTimeoutID);
      };

      const handleMessagesMouseLeave = () => {
        messagesStore.popup.setPopupMode(false);
      };

      return appStore.currentCategory ? (
        <PopupList
          title={'Сообщения'}
          btnLabel={'Смотреть все сообщения'}
          onMouseEnter={handleMessagesMouseEnter}
          onMouseLeave={handleMessagesMouseLeave}
          listData={messagesStore.messagesForPopup}
          onClick={handleOnClick}
        />
      ) : null;
    }

    if (notificationsStore.popup.popupIsOpen) {
      appStore.setCursorOnCategory(MESSAGES_TYPES.NOTIFICATIONS);

      const handleNotificationsMouseEnter = () => {
        if (appStore.popupTimeoutID) window.clearTimeout(appStore.popupTimeoutID);
      };

      const handleNotificationsMouseLeave = () => {
        notificationsStore.popup.setPopupMode(false);
      };

      return appStore.currentCategory ? (
        <PopupList
          title={'Уведомления'}
          btnLabel={'Смотреть все уведомления'}
          onMouseEnter={handleNotificationsMouseEnter}
          onMouseLeave={handleNotificationsMouseLeave}
          listData={notificationsStore.notificationsForPopup}
          onClick={handleOnClick}
        />
      ) : null;
    }

    return null;
  };

  return (
    <S.Root>
      <GlobalStyle />

      <Sidebar />

      <S.AppContent>
        {children}
        {getPopupList()}
      </S.AppContent>
    </S.Root>
  );
};

export default observer(MainLayout);
