const components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        '*::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },

        '*::-webkit-scrollbar-track': {
          backgroundColor: '#fff',
        },

        '*::-webkit-scrollbar-thumb': {
          backgroundColor: '#d9dbe4',
          borderRadius: '5px',
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      sizeMedium: {
        fontSize: 15,
        fontWeight: 600,
        height: '48px',
      },
      sizeSmall: {
        fontSize: 14,
        fontWeight: 500,
        height: '32px',
      },
    },
  },
  MuiFab: {
    defaultProps: {
      disableRipple: true,
      disableFocusRipple: true,
    },
    styleOverrides: {
      root: {
        ':active': {
          boxShadow: 'unset',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        borderRadius: '12px',
        backgroundColor: theme.palette.background.paper,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        input: {
          height: '24px',
          padding: '12px 16px',
          fontSize: 14,
          fontWeight: 600,
          linerHeight: 24,
          ':focus': {
            backgroundColor: theme.palette.common.white,
          },
        },
        textarea: {
          padding: '12px 16px',
          fontSize: 14,
          fontWeight: 600,
          ':focus': {
            backgroundColor: theme.palette.common.white,
          },
        },
        ':focus': {
          backgroundColor: theme.palette.common.white,
        },
      }),

      notchedOutline: ({ theme }: any) => ({
        border: '0px solid',
        borderColor: `${theme.palette.secondary.main} !important`,
      }),
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        borderRadius: '12px',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        paddingRight: '12px',
        paddingLeft: '12px',
        height: '48px',
        maxHeight: '100%',
      },
    },
  },
  MuiMenuItem: {
    defaultProps: {
      selected: {
        backgroundColor: 'red',
      },
    },
    styleOverrides: {
      root: {
        selected: {
          backgroundColor: 'red',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        color: '#fff',
      },
      sizeMedium: {
        borderRadius: '12px',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '16px',
        padding: '12px 24px',
      },

      textSizeSmall: {
        padding: 0,
        minWidth: 0,
        ':hover': {
          color: '#fff',
        },
      },
      startIcon: {
        marginLeft: 0,
      },
      outlined: ({ theme }: any) => ({
        color: theme.palette.primary.main,
      }),
      contained: ({ theme }: any) => ({
        color: theme.palette.common.white,
      }),
      outlinedSizeLarge: {
        height: '48px',
      },
      outlinedSizeMedium: {
        height: '32px',
      },
      containedSizeLarge: {
        height: '48px',
      },
      containedSizeMedium: {
        height: '32px',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      focusRipple: false,
      disableTouchRipple: true,
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        ':hover': {
          backgroundColor: 'unset',
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ ownerState, theme }: any) => ({
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
          padding: '16px 8px 16px 8px',
        },
        width: '584px',
        borderRadius: '32px',
        padding: '32px 32px 40px 32px',
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {},
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0,
        marginTop: '20px',
        overflowY: 'unset',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
        marginTop: '36px',
      },
    },
  },
  MuiCheckbox: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }: any) => ({
        ...(ownerState.color === 'primary' && {
          color: theme.palette.text.secondary,
          opacity: 0.5,

          '&:hover': {
            color: theme.palette.secondary.main,
            opacity: 1,
          },

          '&.Mui-checked': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            opacity: 1,
          },

          '&.Mui-disabled': {
            borderColor: theme.palette.primary.disabled,
            backgroundColor: theme.palette.background.paper,
          },

          '&.Mui-disabled&.Mui-checked': {
            border: `1px solid ${theme.palette.primary.disabled}`,
            color: theme.palette.primary.disabled,
            backgroundColor: theme.palette.background.paper,
          },
        }),
        ...(ownerState.color === 'secondary' && {
          color: theme.palette.text.secondary,
          opacity: 0.5,

          '&:hover': {
            color: theme.palette.primary.main,
            opacity: 1,
          },

          '&.Mui-checked': {
            border: `1px solid ${theme.palette.primary.disabled}`,
            color: theme.palette.primary.main,
            opacity: 1,
          },

          '&.Mui-disabled': {
            backgroundColor: theme.palette.background.paper,
          },

          '&.Mui-disabled&.Mui-checked': {
            border: `1px solid ${theme.palette.primary.disabled}`,
            color: theme.palette.primary.disabled,
            backgroundColor: theme.palette.background.paper,
          },
        }),
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ ownerState, theme }: any) => ({
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '20px',
        ...(ownerState.disabled && {
          color: theme.palette.primary.disabled,
        }),
      }),
    },
  },
};

export default components;
