import { makeAutoObservable } from 'mobx';
import { ZERO_POSITION } from 'shared/constants';
import { WorkPlaceType } from 'shared/enums';
import { IAttributeDto, IWorkPlaceListGetDto } from 'shared/interfaces/api';
import { IPosition } from 'shared/interfaces/app';

class WorkPlaceListModel {
  public constructor(dto?: IWorkPlaceListGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.type = dto.type;
    this.isActive = dto.isActive;
    this.position = dto.position;
    this.number = dto.number;
    this.attributes = dto.attributes;
    this.zoneIds = dto.zoneIds;
  }

  public id = '';

  public type = WorkPlaceType.WorkPlace;

  public isActive = false;

  public position: IPosition = ZERO_POSITION;

  public number = '';

  public attributes: IAttributeDto[] = [];

  public zoneIds: string[] = [];

  public selected = false;

  public setSelected(isSelect: boolean) {
    this.selected = isSelect;
  }

  public setPosition(position: IPosition) {
    this.position = position;
  }

  public getWorkPlaceIconUrl(): string {
    if (this.type == WorkPlaceType.Meeting) {
      return this.selected ? '/images/meeting-room-free-selected.svg' : '/images/meeting-room-free.svg';
    }

    return this.selected ? '/images/work-place-free-icon-selected.svg' : '/images/work-place-free-icon.svg';
  }
}

export default WorkPlaceListModel;
