import styled from 'styled-components/macro';

export const MapToolbar = styled.section`
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: center;
    margin-bottom: ${(props) => props.theme.spacing(6)};
  }
  display: flex;
`;

export const MapSettingsWrapper = styled.div`
  // position: absolute;
  //z-index: 9;
`;
