import { makeAutoObservable } from 'mobx';
import { DEFAULT_COMPANY_ID } from 'shared/constants';
import { IRolePostPutDto, IRoleGetDto } from 'shared/interfaces/api';
import { IRoleDialog } from 'shared/interfaces/app';

class RoleModel {
  constructor(dto?: IRoleGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.companyId = dto.companyId;
    this.isCompanyAdmin = dto.isCompanyAdmin;
    this.userIds = dto.userIds;
  }

  public id = '';

  public name = '';

  public companyId = '';

  public userIds: string[] = [];

  public isCompanyAdmin = false;

  public permissions: string[] = [];

  public get postPutDto(): IRolePostPutDto | null {
    return {
      name: this.name,
      companyId: DEFAULT_COMPANY_ID,
      userIds: this.userIds,
    };
  }

  public updateFromDialogForm(model: IRoleDialog) {
    this.name = model.name;
    this.userIds = model.userIds;
  }
}

export default RoleModel;
