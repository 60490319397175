import { MapContainer as RLMapContainer } from 'react-leaflet';
import styled from 'styled-components/macro';
import { Box } from '@mui/material';

export const MapContentWrapper = styled.div`
  position: relative;

  margin-top: ${(_) => _.theme.spacing(6)};
  height: -moz-calc(100% - 80px);
  height: -webkit-calc(100% - 80px);
  height: calc(100% - 80px);
`;

export const MapContainer = styled(RLMapContainer)<{ height?: string }>`
  height: ${(_) => (_.height ? _.height : '100%')};
  width: 100%;
  z-index: 0;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  & > div:not(:last-child) {
    margin-bottom: ${(_) => _.theme.spacing(2)};
  }
`;

export const DateTimePickerWrapper = styled.div`
  margin-left: ${(_) => _.theme.spacing(2)};
  width: 450px;
`;

export const MapLegendTitle = styled.div`
  height: 30px;
`;

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export const Puller = styled(Box)`
  width: 30px;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: -moz-calc(50% - 15px);
  left: -webkit-calc(50% - 15px);
  left: calc(50% - 15px);
`;
