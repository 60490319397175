import { makeAutoObservable } from 'mobx';
import { IRoleListGetDto } from 'shared/interfaces/api';
import { IDropDownOption } from 'shared/interfaces/app';

class RoleListModel {
  constructor(dto?: IRoleListGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.companyName = dto.companyName;
    this.isCompanyAdmin = dto.isCompanyAdmin;
  }

  public id = '';

  public name = '';

  public companyName = '';

  public isCompanyAdmin = false;

  public getDropDownOption(): IDropDownOption {
    return { id: this.id, name: this.name };
  }
}

export default RoleListModel;
