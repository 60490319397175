import React from 'react';

import { GlobalStyle } from 'components';

import * as S from './AuthLayout.styles';

const AuthLayout: React.FC = ({ children }) => {
  return (
    <S.Root>
      <GlobalStyle />
      <S.Brand>
        <img src={'/Logo_Color.svg'} alt={''} />
      </S.Brand>
      <S.AuthWrapper>{children}</S.AuthWrapper>
      <div style={{ height: '50px', bottom: '0', position: 'absolute', width: '100%', backgroundImage: 'url("/images/foother.svg")' }} />
    </S.Root>
  );
};

export default AuthLayout;
