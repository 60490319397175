import { WorkPlaceType } from 'shared/enums/common';
import {
  IAttributeDto,
  IWorkPlaceListGetDto,
  IWorkPlaceGetDto,
  IWorkPlacesPlainGetDto,
  IWorkPlacesPostDto,
  IWorkPlacesPutDto,
  IWorkPlacesAvailableBookingGetDto,
  IWorkPlacesOutsideGetDto,
  IWorkPlacesImageGetDto,
} from 'shared/interfaces/api';
import { IPosition } from 'shared/interfaces/app';
import { IWorkPlaceFilter } from 'shared/interfaces/app/IWorkPlaceApp';
import { toUtc } from '../utils/DateTimeUtils';
import HTTPService, { IHttpRequest } from './HTTPService';

class WorkPlacesApi extends HTTPService {
  public constructor() {
    super('workplaces');
  }

  public getList(mapId: string): Promise<IWorkPlaceListGetDto[]> {
    return this.GET(`?MapId=${mapId}`);
  }

  public getById(id: string): Promise<IWorkPlaceGetDto> {
    return this.GET(`${id}`);
  }

  public getPlainList(floorId?: string | null, userId?: string | null): Promise<IWorkPlacesPlainGetDto[]> {
    if (!floorId) return this.GET(`plain`);
    return this.GET(`plain/?floorId=${floorId}&userId=${userId}`);
  }

  public getOutsideList(mapId: string): Promise<IWorkPlacesOutsideGetDto[]> {
    return this.GET(`map/${mapId}/outside`);
  }

  public getWorkPlacesAvailableBooking(filterDto: IWorkPlaceFilter): Promise<IWorkPlacesAvailableBookingGetDto[]> {
    filterDto.fromTime = toUtc(filterDto.fromTime);
    filterDto.toTime = toUtc(filterDto.toTime);

    return this.GET('available-booking', { params: { ...filterDto } });
  }

  public getWorkPlace(id: string, mapId: string): Promise<IWorkPlaceGetDto> {
    return this.GET(`${id}/map/${mapId}`);
  }

  public postWorkPlace(newWorkPlace: IWorkPlacesPostDto): Promise<string> {
    return this.POST('', newWorkPlace);
  }

  public putWorkPlace(id: string, updateWorkPlace: IWorkPlacesPutDto): Promise<void> {
    const request: IHttpRequest<IWorkPlacesPutDto> = {
      path: `${id}`,
      data: updateWorkPlace,
      showAccessAlert: false,
    };
    return this.PUT<IWorkPlacesPutDto, void>(request);
  }

  public deleteWorkPlace(id: string): Promise<void> {
    return this.DELETE(`${id}/`);
  }

  public hideWorkPlace(id: string, mapId: string): Promise<void> {
    return this.DELETE(`${id}/map/${mapId}`);
  }

  public postWorkPlacePosition(id: string, mapId: string, position: IPosition): Promise<string> {
    return this.POST(`${id}/map/${mapId}`, position);
  }

  public putWorkPlacePosition(id: string, mapId: string, newPosition: IPosition): Promise<IPosition> {
    const request: IHttpRequest<IPosition> = {
      path: `${id}/map/${mapId}`,
      data: newPosition,
      showAccessAlert: false,
    };
    return this.PUT(request);
  }

  public getWorkPlaceAttributes(type: WorkPlaceType): Promise<IAttributeDto[]> {
    return this.GET(`${type}/attributes`);
  }

  public getWorkPlaceImages(workplaceId: string): Promise<IWorkPlacesImageGetDto[]> {
    return this.GET(`${workplaceId}/images`);
  }

  public postWorkPlaceImage(workplaceId: string, image: FormData): Promise<string> {
    return this.POST(`${workplaceId}/images`, image, { headers: { 'Content-Type': 'multipart/form-data' } });
  }

  public deleteWorkPlaceImage(workplaceId: string, imageIds: string[]): Promise<void> {
    return this.DELETE(`${workplaceId}/images`, imageIds);
  }
}

export default new WorkPlacesApi();
