import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button as MuiButton } from '@mui/material';

export const AttrWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin: 15px;
  min-width: 300px;
`;

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  object-fit: cover;
`;

export const ButtonWrapper = styled.div`
  padding: 0 10px 0 10px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin: 0 5px 0 5px;
    height: 60px;
    min-width: 60px;
  }
`;

export const NamesWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

export const NameRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
