import styled from 'styled-components/macro';
import { Select as MuiSelect, MenuItem as MuiMenuItem } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const TimeWrapper = styled.div<{ direction?: string }>`
  display: flex;
  align-items: center;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  margin-top: ${(props) => (props.direction === 'vertical' ? props.theme.spacing(4) : 'unset')};
  margin-bottom: ${(props) => (props.direction === 'vertical' ? props.theme.spacing(4) : 'unset')};
  margin-left: ${(props) => (props.direction === 'horizontal' ? props.theme.spacing(4) : 'unset')};
`;

export const TimeSelect = muiStyled(MuiSelect)(({}) => ({
  height: '48px',
  minWidth: '100px',
  width: '100%',
  '&.Mui-focused': {
    '&.MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: '#fff',
    },
  },
}));

export const MenuItem = muiStyled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#F2F5F7',
  },
}));

export const MenuItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;
