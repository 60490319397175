import React, { useEffect, useState, useRef, useMemo } from 'react';
import { IconButton, Title, Input } from 'components';
import CheckIcon from 'components/Icons/CheckIcon';
import Loupe from 'components/Icons/Loupe';
import SelectExpand from 'components/Icons/SelectExpand';
import XCircle from 'components/Icons/XCircle';
import { IDropDownOption } from 'shared/interfaces/app';
import { Typography, useTheme } from '@mui/material';

import * as S from './DropDownChip.styles';

interface IDropDownChipProps {
  name: string;
  values: string[];
  withoutSearch?: boolean;
  placeholder?: string;
  options: IDropDownOption[];
  dropDownLabel?: string;
  onChange: (e: any) => void;
  onDelete: (e: React.ChangeEvent<any>, value: string) => void;
}

const DropDownChip: React.FC<IDropDownChipProps> = (props) => {
  const { dropDownLabel, values, options, name, withoutSearch, placeholder, onChange, onDelete } = props;
  const theme = useTheme();

  const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const [searchText, setSearchText] = useState('');

  const displayedOptions = useMemo(() => options.filter((option) => containsText(option.name, searchText)), [options, searchText]);

  const [widthSelect, setWidthSelect] = useState<number>(0);
  const [widthChips, setWidthChips] = useState<number>(0);

  const chipsRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      setWidthSelect(selectRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (values.length !== 0 && chipsRef.current) {
      setWidthChips(chipsRef.current.clientWidth);
    }
  }, [values, chipsRef.current]);

  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: 'common.white',
      },
    },
    autoFocus: false,

    sx: {
      '& .MuiMenu-paper': {
        maxHeight: 300,
        overflow: 'auto',
      },
      '&& .Mui-selected': {
        backgroundColor: '#FFF',
        ':hover': {
          backgroundColor: '#FFF',
        },
      },
    },
  };

  const iconRenderer = (props: any) => {
    return <div {...props}>{<SelectExpand />} </div>;
  };

  const isRenderChips = useMemo(() => widthSelect >= widthChips, [widthSelect, widthChips]);

  return (
    <S.DropDownWrapper>
      {dropDownLabel && <Title label={dropDownLabel} />}

      <S.Select
        ref={selectRef}
        sx={{ mb: 4 }}
        id={name}
        name={name}
        displayEmpty
        multiple
        MenuProps={MenuProps}
        fullWidth
        value={values}
        IconComponent={iconRenderer}
        onChange={onChange}
        renderValue={(selected: string[]) => {
          return (
            <>
              {selected.length > 0 && options.length > 0 ? (
                <S.Wrapper>
                  <S.Chips ref={chipsRef}>
                    {selected.map((value) => {
                      const opt = options.find((_) => _.id === value);
                      if (!opt) return null;

                      const label = opt.name;
                      return isRenderChips ? (
                        <S.Chip key={value} label={label} deleteIcon={<IconButton icon={<XCircle />} />} onDelete={(e) => onDelete(e, value)} />
                      ) : (
                        <div style={{ width: '89px' }} />
                      );
                    })}
                  </S.Chips>
                  {!isRenderChips && (
                    <>
                      <S.Chip label={`${dropDownLabel} : ${selected.length}`} />
                    </>
                  )}
                </S.Wrapper>
              ) : (
                <Typography variant="body2" sx={{ opacity: 0.4 }}>
                  {placeholder}
                </Typography>
              )}
            </>
          );
        }}>
        {!withoutSearch && (
          <S.ListSubheader>
            <Input
              mb={4}
              autoFocus={true}
              name="search"
              value={searchText}
              placeholder="Поиск..."
              startIcon={<Loupe />}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </S.ListSubheader>
        )}
        {displayedOptions.map((option) => (
          <S.MenuItem disableRipple key={option.id} value={option.id}>
            <S.MenuItemRow>
              <Typography>{option.name}</Typography>
              {values.includes(option.id) && <CheckIcon color={theme.palette.primary.main} />}
            </S.MenuItemRow>
          </S.MenuItem>
        ))}
      </S.Select>
    </S.DropDownWrapper>
  );
};

export default DropDownChip;
