import { makeAutoObservable } from 'mobx';
import { THEMES } from 'shared/constants';
import { DialogStatus } from 'shared/enums';
import createTheme from 'theme/theme';
import { ROUTE } from '../routes';
import { Theme } from '@mui/material/styles';

class AppStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public visibleSidebar = false;

  public dialogViewMode = DialogStatus.Closed;

  public cursorOnCategory = '';

  public popupMenuAnchorEl: HTMLElement | null = null;

  public savePopupMenuAnchorEl: HTMLElement | null = null;

  public adminSelectedTab: string = ROUTE.NONE;

  public popupTimeoutID: number | undefined = undefined;

  public currentTheme: Theme = createTheme(THEMES.DEFAULT);

  public get currentCategory(): string {
    return this.cursorOnCategory;
  }

  public setAdminTab(tabId: string) {
    this.adminSelectedTab = tabId;
  }

  public setCurrentTheme(theme: string) {
    this.currentTheme = createTheme(theme);
  }

  public setAnchorEl(anchorEl: HTMLElement | null) {
    this.popupMenuAnchorEl = anchorEl;
  }

  public setSavePopupMenuAnchorEl(anchorEl: HTMLElement | null) {
    this.savePopupMenuAnchorEl = anchorEl;
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCursorOnCategory(categoryName: string) {
    this.cursorOnCategory = categoryName;
  }

  public setPopupTimeoutID(timeoutID: number) {
    this.popupTimeoutID = timeoutID;
  }

  public setVisibleSideBar(visible: boolean) {
    this.visibleSidebar = visible;
  }
}

export default new AppStore();
