import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { userAuthStore } from 'stores';
import { Avatar, Button, Typography } from '@mui/material';

import * as S from './SidebarUser.styles';

const SidebarUser: React.FC = () => {
  const [letterAvatar, setLetterAvatar] = useState('');

  useEffect(() => {
    const shortName = userAuthStore.shortName;
    if (!shortName) return;

    const indexSecondLetter = shortName.replace('.', '').indexOf(' ');
    const firstLetter = shortName[0].toUpperCase();

    if (indexSecondLetter !== -1) {
      const secondLetter = shortName[indexSecondLetter + 1].toUpperCase();
      setLetterAvatar(`${firstLetter}${secondLetter}`);
    } else {
      setLetterAvatar(`${firstLetter}`);
    }
  }, [userAuthStore.shortName]);

  return (
    <S.UserWrapper>
      <Avatar sx={{ bgcolor: 'background.paper', color: 'primary.main' }}>
        <Typography sx={{ mt: 0.5 }} variant="body2">
          {letterAvatar}
        </Typography>
      </Avatar>
      <S.UserInfo>
        <Typography variant="body1">
          <S.Ellipsis>{userAuthStore.lastName}</S.Ellipsis>
        </Typography>
        <Typography variant="body1">
          <S.Ellipsis>
            {userAuthStore.firstName} {userAuthStore.patronymic}
          </S.Ellipsis>
        </Typography>
        <Button sx={{ color: 'rgba(255, 255, 255, 0.6)' }} size="small" variant="text" onClick={userAuthStore.signOut}>
          Выйти
        </Button>
      </S.UserInfo>
    </S.UserWrapper>
  );
};

export default observer(SidebarUser);
