import React from 'react';

// Icons components
import AdminIcon from 'components/Icons/AdminIcon';
import LogoIcon from 'components/Icons/LogoIcon';
import MainIcon from 'components/Icons/MainIcon';
import MessagesIcon from 'components/Icons/MessagesIcon';
import NotificationsIcon from 'components/Icons/NotificationsIcon';
import PassIcon from 'components/Icons/PassIcon';
import ReservationIcon from 'components/Icons/ReservationIcon';

// Admin components
import Companies from 'pages/Admin/Companies';
import Departments from 'pages/Admin/Departments';
import Map from 'pages/Admin/Map';
import PassTemplates from 'pages/Admin/PassTemplates';
import Roles from 'pages/Admin/Roles';
import Users from 'pages/Admin/Users';
import Zones from 'pages/Admin/Zones';

// Auth components
import ConfirmUser from 'pages/Auth/ConfirmUser';
import ResetPassword from 'pages/Auth/ResetPassword';
import SignIn from 'pages/Auth/SignIn';

// Components
import ConfirmPass from 'pages/ConfirmPass';
import Main from 'pages/Main';
import Messages from 'pages/Messages';
import Notifications from 'pages/Notifications';
import Passes from 'pages/Passes';
import RejectPass from 'pages/RejectPass';
import { ReservationList, ReservationCalendar, ReservationSingle } from 'pages/Reservations';
import { RouteInfoType } from 'shared/interfaces/app';

// Guards
import { AuthGuard } from './Components';

export enum ROUTE {
  NONE = '',
  Main = '/',
  Reservation = '/reservation/list',
  PassTemplates = '/admin/pass-templates',
  Passes = '/passes',
  Notifications = '/notifications',
  Messages = '/messages',
  ConfirmPass = '/confirm-pass',
  RejectPass = '/reject-pass',
  Admin = '/admin',
  Users = '/admin/users',
  Departments = '/admin/departments',
  Companies = '/admin/companies',
  Roles = '/admin/roles',
  MapConstructor = '/admin/map',
  Auth = '/auth',
  SignIn = '/auth/sign-in',
  ConfirmUser = '/auth/authentication',
  ResetPassword = '/auth/reset-password',
  Zones = '/admin/zones',
  ReservationList = '/reservation/list',
  ReservationCalendar = '/reservation/calendar',
  ReservationSingle = '/reservation/single',
}

const mainRoute: RouteInfoType = {
  path: ROUTE.Main,
  icon: <LogoIcon />,
  name: 'Главная',
  component: Main,
  guard: AuthGuard,
  children: null,
};

const reservationRoute: RouteInfoType = {
  path: ROUTE.Reservation,
  icon: <ReservationIcon color="#FFF" />,
  name: 'Бронирование',
  component: null,
  guard: null,
  children: [
    {
      path: ROUTE.ReservationList,
      name: 'Список',
      component: ReservationList,
      guard: AuthGuard,
    },
    {
      path: ROUTE.ReservationCalendar,
      name: 'Календарь',
      component: ReservationCalendar,
      guard: AuthGuard,
    },
  ],
};

const passesRoute: RouteInfoType = {
  path: ROUTE.Passes,
  icon: <PassIcon />,
  name: 'Пропуска',
  component: Passes,
  guard: AuthGuard,
  children: null,
};

const passesSingleRoute: RouteInfoType = {
  path: ROUTE.ReservationSingle,
  name: 'Календарь объекта',
  component: ReservationSingle,
  guard: AuthGuard,
  children: null,
};

const notificationsRoute: RouteInfoType = {
  path: ROUTE.Notifications,
  icon: <NotificationsIcon />,
  name: 'Уведомления',
  component: Notifications,
  guard: AuthGuard,
  children: null,
};

const messagesRoute: RouteInfoType = {
  path: ROUTE.Messages,
  icon: <MessagesIcon />,
  name: 'Сообщения',
  component: Messages,
  guard: AuthGuard,
  children: null,
};

const confirmPassRoute: RouteInfoType = {
  path: ROUTE.ConfirmPass,
  name: 'Подтверждение пропуска',
  component: ConfirmPass,
  guard: null,
  children: null,
};

const rejectPassRoute: RouteInfoType = {
  path: ROUTE.RejectPass,
  name: 'Отклонение пропуска',
  component: RejectPass,
  guard: null,
  children: null,
};

const adminRoute: RouteInfoType = {
  icon: <AdminIcon />,
  path: ROUTE.Admin,
  name: 'Администрирование',
  component: null,
  guard: AuthGuard,
  children: [
    {
      path: ROUTE.Users,
      name: 'Пользователи',
      component: Users,
      guard: AuthGuard,
    },
    {
      path: ROUTE.Roles,
      name: 'Роли',
      component: Roles,
      guard: AuthGuard,
    },
    {
      path: ROUTE.Departments,
      name: 'Отделы',
      component: Departments,
      guard: AuthGuard,
    },
    {
      path: ROUTE.Companies,
      name: 'Компания',
      component: Companies,
      guard: AuthGuard,
    },
    {
      path: ROUTE.Zones,
      name: 'Зоны',
      component: Zones,
      guard: AuthGuard,
    },
    {
      path: ROUTE.PassTemplates,
      name: 'Шаблон пропусков',
      component: PassTemplates,
      guard: AuthGuard,
    },
    {
      path: ROUTE.MapConstructor,
      name: 'Конструктор карты',
      component: Map,
      guard: AuthGuard,
    },
  ],
};

const authRoute: RouteInfoType = {
  path: ROUTE.Auth,
  name: 'Главная',
  component: null,
  guard: null,
  children: [
    {
      path: ROUTE.SignIn,
      name: 'Sign In',
      component: SignIn,
      guard: null,
    },
    {
      path: ROUTE.ConfirmUser,
      name: 'Authentication',
      component: ConfirmUser,
      guard: null,
    },
    {
      path: ROUTE.ResetPassword,
      name: 'Reset Password',
      component: ResetPassword,
      guard: null,
    },
  ],
};

export const main = [mainRoute, passesRoute, messagesRoute, notificationsRoute];

export const auth = [authRoute];

export const admin = [adminRoute];

export const reservation = reservationRoute;

export const confirmPass = confirmPassRoute;

export const rejectPass = rejectPassRoute;

export const passesSingle = passesSingleRoute;

export default [mainRoute, reservationRoute, authRoute, adminRoute, passesRoute, messagesRoute, notificationsRoute];
