import React from 'react';
import { observer } from 'mobx-react';
import { mapStore, workPlacesStore } from 'stores';
import { DateTimePicker, FloorsPanel, TagImages } from 'components';

import { MATTERPORT_TOOGLE_ID } from 'shared/constants';
import { useBreakpoint } from 'utils/hooks';
import { Box } from '@mui/material';
import * as S from './MainFilters.styles';

const MainFilters: React.FC = () => {
  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const onChangeDate = (fromTime?: Date | null, toTime?: Date | null) => {
    if (!fromTime || !toTime) return;
    workPlacesStore.queryFilterParams.setTimeInterval(fromTime, toTime);
    workPlacesStore.getWorkPlacesAvailableBookingList().finally();
  };

  const onChangeFloorType = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value !== null) {
      mapStore.selectedFloorType = parseInt(value);

      mapStore.floorTypes.forEach((item) => {
        if (item.selected) {
          item.selected = false;
        }
      });

      const index = mapStore.floorTypes.findIndex((item) => item.value === value);
      if (index > -1) {
        mapStore.floorTypes[index].selected = true;
      }

      await mapStore.initMap(0).finally();
    }
  };

  const onChangeFloor = async (event: React.MouseEvent<HTMLElement>, value: string): Promise<void> => {
    if (value === null) return;

    await mapStore.getFloorById(value);
    await mapStore.getFloorImagesList(mapStore.selectedFloor.id);
    mapStore.setActiveMap(0);

    // Получаем параметры карты и размещеные объекты
    await Promise.all([mapStore.getFloorImageById(), workPlacesStore.getWorkPlacesAvailableBookingList()]);
  };

  const onChangeMap = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value === null) return;

    if (value === MATTERPORT_TOOGLE_ID) {
      mapStore.selectedFloorImage.setFloorImageId(value);
      workPlacesStore.setTourEnable(true);
      return;
    } else {
      workPlacesStore.setTourEnable(false);
    }

    mapStore.setActiveMap(value);
    // Получаем параметры карты и размещеные объекты
    await Promise.all([mapStore.getFloorImageById(), workPlacesStore.getWorkPlacesAvailableBookingList()]);
  };

  return (
    <S.FilterWrapper>
      <TagImages
        tags={mapStore.floorTypes}
        onChange={onChangeFloorType}
        onTagChange={() => {
          // ignore
        }}
      />

      <Box>
        <DateTimePicker
          timeFrom={workPlacesStore.queryFilterParams.fromTime}
          timeTo={workPlacesStore.queryFilterParams.toTime}
          onChange={onChangeDate}
        />
      </Box>

      {isMobile && (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <FloorsPanel
            floors={mapStore.floorTagsMain}
            floorImages={mapStore.floorImagesWith3DTour}
            selectedFloor={mapStore.selectedFloor.id}
            selectedFloorImage={mapStore.selectedFloorImage.id}
            onChangeFloorImage={onChangeMap}
            onChangeFloor={onChangeFloor}
          />
        </Box>
      )}
    </S.FilterWrapper>
  );
};

export default observer(MainFilters);
