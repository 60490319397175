import styled from 'styled-components/macro';

import { CalendarViewMode } from 'shared/enums';

export const CardContainer = styled.div<{ widthInPercents: number; widthOffset: number; height: number }>`
  display: flex;
  overflow: hidden;
  background-color: ${(_) => _.theme.palette.background.paper};
  border-radius: 16px;
  padding: 0 15px 0 0;

  // -moz-calc
  // -webkit-calc
  // calc

  width: ${(_) => `-moz-calc(${_.widthInPercents}% - ${_.widthOffset}px)`};
  width: ${(_) => `-webkit-calc(${_.widthInPercents}% - ${_.widthOffset}px)`};
  width: ${(_) => `calc(${_.widthInPercents}% - ${_.widthOffset}px)`};

  height: ${(_) => `-moz-calc(${_.height}% - 2px)`};
  height: ${(_) => `-webkit-calc(${_.height}% - 2px)`};
  height: ${(_) => `calc(${_.height}% - 2px)`};
`;

export const CardStatusLine = styled.div<{ isActive: boolean | null; viewMode: CalendarViewMode; isMobileOrTablet: boolean }>`
  height: calc(100% - 16px);
  min-width: 4px;
  max-width: 4px;
  margin: ${(_) => (_.isMobileOrTablet && _.viewMode == CalendarViewMode.Week ? '8px 3px;' : '8px 6px;')};
  background-color: ${(_) => (_.isActive ? _.theme.palette.secondary.main : _.theme.palette.error.light)};

  border-radius: 30px;
`;

export const InfoWrapper = styled.div`
  margin: 8px;
  min-width: 350px;
`;

export const BtnWrapper = styled.div`
  margin: 12px 8px 8px 8px;
  & button {
    border-radius: 12px;
  }
`;

export const CardTitleRow = styled.div<{ viewMode: CalendarViewMode; isMobileOrTablet: boolean }>`
  display: flex;
  justify-content: ${(_) => (_.isMobileOrTablet ? 'flex-start' : 'flex-end')};
  position: relative;
  align-items: start;
  width: fit-content;
  height: fit-content;

  button {
    padding: ${(_) => (_.isMobileOrTablet && _.viewMode == CalendarViewMode.Week ? '0' : '8px')};
    margin-top: ${(_) => (_.isMobileOrTablet && _.viewMode == CalendarViewMode.Week ? '10px' : '0')};
  }
`;

export const CardHolderName = styled.div<{ viewMode: CalendarViewMode }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(_) => (_.viewMode === CalendarViewMode.Day ? 'nowrap' : 'nowrap')};
  padding-top: 6px;
  padding-left: 6px;
  width: 100%;
`;

export const CardActions = styled.div`
  z-index: 2;
  background-color: ${(_) => _.theme.palette.background.paper};
  display: flex;
  justify-content: flex-end;
  width: 20%;
  min-width: fit-content;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
