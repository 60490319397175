import { MapContainer as RLMapContainer } from 'react-leaflet';
import styled from 'styled-components/macro';

import { Accordion, Button as MuiButton } from '@mui/material';

export const MapContainer = styled(RLMapContainer)`
  height: 70vh;
  width: 100%;
  z-index: 0;
`;

export const MapContentWrapper = styled.div`
  outline: none;
  height: 100%;
`;

export const Spiner = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 20;
  left: 50%;
  top: 40%;
`;

export const Button = styled(MuiButton)`
  svg {
    width: 22px;
    height: 22px;
    fill: ${(p) => p.theme.palette.primary.main} !important;
  }
`;

export const FilterAccordion = styled(Accordion)`
  background-color: white;
  box-shadow: none;
  margin-bottom: 0;
`;
