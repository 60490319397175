import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ChevronDown } from 'react-feather';
import { usersStore, rolesStore } from 'stores';
import { PopupMenu, Spinner, Table } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import Trash from 'components/Icons/Trash';
import confirmDialog from 'components/UIKit/ConfirmDialog';
import { IConfirmDialog } from 'components/UIKit/ConfirmDialog/confirmDialogStore';
import { DialogStatus, Permissions } from 'shared/enums';
import { Column, IUsersTableRow } from 'shared/interfaces/app';
import { useBreakpoint } from 'utils/hooks';
import { Typography, Divider, MenuItem, AccordionSummary } from '@mui/material';
import UserDialog from './Components';

import UserFilters from './Components/UserFilters/UserFilters';
import * as S from './Users.styles';

const columns: Column[] = [
  {
    id: 'fullName',
    label: 'ФИО',
    minWidth: 240,
  },
  {
    id: 'position',
    label: 'Должность',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'Электронная почта',
    minWidth: 240,
  },
  {
    id: 'phone',
    label: 'Телефон',
    minWidth: 170,
  },
  {
    id: 'subCompanyName',
    label: 'Название компании',
    minWidth: 150,
  },
  {
    id: 'department',
    label: 'Отдел',
    minWidth: 240,
  },
  {
    id: 'role',
    label: 'Роль',
    minWidth: 240,
  },
];

const Users: React.FC = () => {
  useEffect(() => {
    Promise.all([usersStore.getUsersList(), rolesStore.getRolesList()]).finally();
  }, []);

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTable = bp === 'md';

  const onDelete = async (data: IUsersTableRow) => {
    const deleteDialogParams: IConfirmDialog = {
      title: 'Удалить пользователя?',
      content: data.fullName,
    };
    const isConfirm = await confirmDialog.open(deleteDialogParams);
    if (!isConfirm) return;

    await usersStore.removeUser(data.id);
  };

  const onEdit = async (data: IUsersTableRow) => await usersStore.setEditingMode(data.id);

  const isEditDisabled = !rolesStore.isAllow(Permissions.UserUpdating);
  const isDeleteDisabled = !rolesStore.isAllow(Permissions.UserRemoving);

  const rows = usersStore.usersForTable.map((data: IUsersTableRow) => {
    const menuItems = [
      <MenuItem disabled={isEditDisabled} disableRipple key={data.id} onClick={async () => onEdit(data)}>
        <EditPencilIcon />
        <Typography variant="caption" sx={{ ml: 3 }}>
          Редактировать
        </Typography>
      </MenuItem>,
      <MenuItem disabled={isDeleteDisabled} disableRipple key={data.id} onClick={async () => onDelete(data)}>
        <Trash />
        <Typography variant="caption" sx={{ ml: 3 }}>
          Удалить
        </Typography>
      </MenuItem>,
    ];

    if (isMobile || isTable) {
      const theRow: JSX.Element = (
        <S.Cell>
          <S.Info>
            <Typography variant="body2">{data.fullName}</Typography>
            <Typography variant="body2">{data.position}</Typography>
            <Typography variant="body1">{data.roleName}</Typography>
            <Typography variant="body1">{data.email}</Typography>
            <Typography variant="body1">{data.phone}</Typography>
            <Typography variant="body1">{data.departmentName}</Typography>
          </S.Info>
          <PopupMenu key={data.id} menuItems={menuItems} />
        </S.Cell>
      );
      return [theRow];
    } else {
      const row: Array<JSX.Element> = Object.values(data)
        .map((value, index) => {
          if (index === Object.values(data).length - 1) {
            return (
              <S.CellWrapper key={value.id}>
                <Typography>{value}</Typography>
                <PopupMenu key={data.id} menuItems={menuItems} />
              </S.CellWrapper>
            );
          }

          return <Typography key={index}>{value}</Typography>;
        })
        .slice(1);

      return row;
    }
  });

  return (
    <>
      <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />

      {isMobile ? (
        <>
          <S.FilterAccordion>
            <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="body2">Фильтры</Typography>
            </AccordionSummary>
            <S.AccordionDetails>
              <UserFilters />
            </S.AccordionDetails>
          </S.FilterAccordion>
          <Divider sx={{ mb: 4, mt: 0 }} orientation="horizontal" flexItem />
        </>
      ) : (
        <UserFilters />
      )}

      {usersStore.isFetching ? <Spinner /> : <Table columns={isTable || isMobile ? [] : columns} rows={rows} />}

      {usersStore.dialogViewMode !== DialogStatus.Closed && usersStore.dialogViewMode !== DialogStatus.Deleting && <UserDialog />}
    </>
  );
};

export default observer(Users);
