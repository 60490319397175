import { makeAutoObservable } from 'mobx';
import { IField, IPassTemplatePostPutDto, IPassTemplatesGetDto, IPassTemplateGetDto } from 'shared/interfaces/api/passTemplates';
import { IPassTemplatesDialog } from 'shared/interfaces/app';

class PassTemplatesModel {
  constructor(dto?: IPassTemplatesGetDto & IPassTemplateGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.email = dto.email;
    this.comments = dto.comments;
    this.companyId = dto.companyId;
    this.fields = dto.fields ?? [];
  }

  public id = '';

  public name = '';

  public email = '';

  public comments = '';

  public companyId = '';

  public fields: IField[] = [];

  public get currentFields() {
    return this.fields;
  }

  public get postPutDto(): IPassTemplatePostPutDto {
    return {
      name: this.name,
      email: this.email,
      comments: this.comments,
      companyId: this.companyId,
      fields: this.fields,
    };
  }

  public updateFromDialogForm(model: IPassTemplatesDialog) {
    this.email = model.email;
  }

  public handleAddField() {
    this.fields.push({ name: '' });
  }

  public handleDeleteField(index: number) {
    this.fields.splice(index, 1);
  }
}

export default PassTemplatesModel;
