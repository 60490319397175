import styled from 'styled-components/macro';
import { Box } from '@mui/material';

export const Badge = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing(2)};
  width: ${(props) => props.theme.spacing(6)};
  height: ${(props) => props.theme.spacing(6)};
  border-radius: 50%;
`;
