import { MobileDatePicker as MuiMobileDatePicker } from '@mui/lab';
import styled from 'styled-components/macro';
import { TextField as MuiTextField, Box as MuiBox, Select as MuiSelect, MenuItem as MuiMenuItem } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const TimeWrapper = styled.div<{ direction?: string }>`
  display: flex;
  align-items: center;
  width: 100%;

  margin-top: ${(props) => (props.direction === 'vertical' ? props.theme.spacing(4) : 'unset')};
  margin-bottom: ${(props) => (props.direction === 'vertical' ? props.theme.spacing(4) : 'unset')};
  margin-left: ${(props) => (props.direction === 'horizontal' ? props.theme.spacing(4) : 'unset')};

  & > div:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing(2)};
  }
`;

export const DateTextField = muiStyled(MuiTextField)(({ theme, focus }) => ({
  width: '100%',
  '& input': {
    cursor: 'pointer',
  },
  '& .MuiInputAdornment-root': {
    backgroundColor: focus ? theme.palette.common.white : '#F2F5F7',
    borderRadius: '0 12px 12px 0',
  },
}));

export const Box = styled(MuiBox)`
  display: flex;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;

    & > div:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }
`;

export const TimeSelect = muiStyled(MuiSelect)(({}) => ({
  height: '48px',
  minWidth: '100px',
  width: '100%',
  '&.Mui-focused': {
    '&.MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: '#fff',
    },
  },
}));

export const MenuItem = muiStyled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#F2F5F7',
  },
}));

export const MobileDatePicker = muiStyled(MuiMobileDatePicker)(({ theme }) => ({
  padding: `0px !important`,
  '&.MuiDialog-paper': {
    maxWidth: '100%',
  },
}));

export const MenuItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;

export const DateWrapper = styled.div`
  min-width: 160px;
  width: 100%;
`;
