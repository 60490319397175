import React, { MouseEventHandler } from 'react';

import * as S from './IconButton.styles';

interface IProps {
  mediumSize?: boolean;
  icon: JSX.Element;
  disabled?: boolean;
  hint?: string;
  onClick?: MouseEventHandler;
}

const IconButton = (props: IProps) => {
  const { onClick, disabled, hint, mediumSize } = props;

  return (
    <S.Tooltip title={hint || ''}>
      <S.IconButton
        style={{ margin: '0' }}
        size={mediumSize ? 'medium' : 'small'}
        onClick={onClick}
        onMouseDown={(event) => event.stopPropagation()}
        disabled={disabled}>
        {props.icon}
      </S.IconButton>
    </S.Tooltip>
  );
};

export default IconButton;
