import { IPassTemplatesGetDto, IPassTemplateGetDto, IPassTemplatePostPutDto } from 'shared/interfaces/api/passTemplates';

import HTTPService, { IHttpRequest } from './HTTPService';

class PassTemplatesApi extends HTTPService {
  public constructor() {
    super('pass-templates');
  }

  public getPassTemplates(): Promise<IPassTemplatesGetDto[]> {
    return this.GET<IPassTemplatesGetDto[]>('', {});
  }

  public getPassTemplate(passTemplateId: string): Promise<IPassTemplateGetDto> {
    return this.GET<IPassTemplateGetDto>(`${passTemplateId}`, {});
  }

  public postPassTemplate(passTemplateData: IPassTemplatePostPutDto): Promise<string> {
    return this.POST('', passTemplateData, {});
  }

  public putPassTemplate(passTemplateId: string, data: IPassTemplatePostPutDto): Promise<void> {
    const request: IHttpRequest<IPassTemplatePostPutDto> = {
      path: `${passTemplateId}`,
      data,
    };
    return this.PUT<IPassTemplatePostPutDto, void>(request);
  }

  public deletePassTemplate(passTemplateId: string): Promise<void> {
    return this.DELETE(`${passTemplateId}`, {});
  }
}

export default new PassTemplatesApi();
