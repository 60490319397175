import { appStore, companiesStore } from 'stores';
import { THEMES } from 'shared/constants';
import breakpoints from './breakpoints';
import components from './components';
import typography from './typography';
import variants from './variants';

import { createTheme as createMuiTheme, Theme } from '@mui/material/styles';

const createTheme = (name: string): Theme => {
  let themeConfig;

  if (name === THEMES.DEFAULT) {
    themeConfig = variants[0].palette;
  } else {
    themeConfig = companiesStore.companyThemeColors;
  }

  return createMuiTheme({
    spacing: 4,
    breakpoints: breakpoints,
    // @ts-ignore
    palette: themeConfig,
    // @ts-ignore
    components: components,

    typography: typography,
  });
};

export default createTheme;
