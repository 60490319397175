import React from 'react';
import { Divider } from '@mui/material';

import * as S from './NumberInput.styles';

interface INumberInputProps {
  id: string;
  label?: string;
  value?: number;
  onChange: (value: number, id: string) => void;
}

const NumberInput: React.FC<INumberInputProps> = (props) => {
  const { id, label, value, onChange, ...rest } = props;
  return (
    <>
      <S.NumberInputWrapper>
        {label && <S.NumberInputLabel>{label}</S.NumberInputLabel>}

        <S.NumberInput
          style={{
            NumberInputUp: {
              borderBottomColor: 'red',
            },
          }}
          min={0}
          step={1}
          snap
          mobile
          strict
          id={id}
          value={value ?? 0}
          onChange={(value) => onChange(value!, id)}
          {...rest}
        />
      </S.NumberInputWrapper>
      <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
    </>
  );
};

export default NumberInput;
