import { IAuthGetDto, IAuthPostDto, IAuthPutDto, IUserPostPutDto, IUsersGetDto } from 'shared/interfaces/api/users';

import HTTPService, { IHttpRequest } from './HTTPService';

class UsersApi extends HTTPService {
  public constructor() {
    super('Users');
  }

  public getUsers(departmentId?: string, roleId?: string): Promise<IUsersGetDto[]> {
    return this.GET('');
  }

  public getUser(id: string): Promise<IUsersGetDto> {
    return this.GET(`${id}/`);
  }

  public postUser(newUser: IUserPostPutDto): Promise<IUsersGetDto> {
    return this.POST('', newUser);
  }

  public putUser(id: string, updateUser: IUserPostPutDto): Promise<IUsersGetDto> {
    const request: IHttpRequest<IUserPostPutDto> = {
      path: `${id}/`,
      data: updateUser,
    };
    return this.PUT<IUserPostPutDto, IUsersGetDto>(request);
  }

  public deleteUser(id?: string): Promise<void> {
    return this.DELETE(`${id}/`);
  }

  public getAuthData(authData: IAuthPostDto): Promise<string> {
    return this.POST('auth', authData, {});
  }

  public getAuthUserData(userId: string): Promise<IAuthGetDto> {
    return this.GET(`${userId}/full-info`);
  }

  public putCopyMessagesSetting(userId: string | null, enabled: boolean): Promise<void> | null {
    if (!userId) return null;

    const request: IHttpRequest<void> = {
      path: `${userId}/copy-messages-to-email/${enabled}/`,
    };
    return this.PUT<void, void>(request);
  }

  public putCopyNotificationsSetting(userId: string | null, enabled: boolean): Promise<void> | null {
    if (!userId) return null;

    const request: IHttpRequest<void> = {
      path: `${userId}/copy-notification-to-email/${enabled}/`,
    };
    return this.PUT<void, void>(request);
  }

  public putResetPass(email: string): Promise<void> {
    const request: IHttpRequest<void> = {
      path: `reset-password/${email}/`,
    };
    return this.PUT<void, void>(request);
  }

  public initializationUser(initData: IAuthPutDto): Promise<void> {
    const request: IHttpRequest<IAuthPutDto> = {
      path: `init`,
      data: initData,
    };
    return this.PUT<IAuthPutDto, void>(request);
  }
}

export default new UsersApi();
