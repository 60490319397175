import React, { useEffect } from 'react';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import ru from 'date-fns/locale/ru';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';

import { observer } from 'mobx-react';

import { dateFnsLocalizer, Views } from 'react-big-calendar';

import { mapStore, calendarStore, workPlacesStore } from 'stores';
import { useBreakpoint } from 'utils/hooks';
import { Box, Divider } from '@mui/material';

import { Accordion, FloorAndPlaceControlPanel } from '../../../components';
import { QrDialog, ReservationDialog } from '../Components';
import CustomDateHeaderCell from './Components/CustomDateHeaderCell';
import CustomToolBar from './Components/CustomToolBar/CustomToolBar';
import EventCard from './Components/EventCard/EventCard';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import * as S from './ReservationCalendar.styles';

const locales = { 'ru-RU': ru };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const ReservationCalendar: React.FC = () => {
  useEffect(() => {
    // Получем данные из url
    const url = new URL(document.location.href);
    const workplaceId = url.searchParams.get('workplaceId');
    const fromTimeInDay = url.searchParams.get('fromTimeInDay');
    //const toTimeInDay = url.searchParams.get('toTimeInDay');
    url.searchParams.delete('workplaceId');
    url.searchParams.delete('fromTimeInDay');
    url.searchParams.delete('toTimeInDay');
    window.history.replaceState(null, 'key', url.href);

    if (workplaceId) {
      calendarStore.calendarFilter.workplaceId = workplaceId;
      calendarStore.calendarFilter.isOnlyMine = false;
    } else {
      calendarStore.calendarFilter.isOnlyMine = true;
    }

    if (fromTimeInDay) {
      calendarStore.currentDate = new Date(fromTimeInDay);
    }

    calendarStore.getCalendarViewData().finally();

    // Для dev период 10 сек. Для prod - 1 мин
    const timeIntervalSec = process.env.REACT_APP_IS_DEVELOPMENT === 'true' ? 10 : 60;
    const interval = setInterval(() => calendarStore.getCalendarViewData(), 1000 * timeIntervalSec);

    return () => {
      clearInterval(interval);
      calendarStore.deInit();
    };
  }, []);

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  useEffect(() => {
    const daysHeaderElems = document.getElementsByClassName('rbc-button-link');
    const matchedElems: HTMLElement[] = [];

    try {
      for (const key in daysHeaderElems) {
        if (daysHeaderElems[key].outerHTML) {
          const res = daysHeaderElems[key].outerHTML.toString().match(/\d+ пнд|втр|срд|чтв|птн|суб|вск /) || 'not';

          if (res) {
            matchedElems.push(daysHeaderElems[key] as HTMLElement);
          }
        }
      }

      calendarStore.setHTMLElements(matchedElems);
    } catch (e) {
      console.log(e);
    }
  }, [calendarStore.viewPeriodMode, calendarStore.currentDate]);

  const onSelectOnlyMine = (isOnlyMine: boolean) => {
    calendarStore.onOnlyMineChange(isOnlyMine);
    calendarStore.getCalendarViewData().finally();
  };

  const onChangeFloor = (id: string | null) => {
    calendarStore.calendarFilter.updateFloorId(id);
    calendarStore.getCalendarViewData().finally();
  };

  const onChangePlace = (id: string | null) => {
    calendarStore.calendarFilter.updateWorkPlaceId(id);
    calendarStore.getCalendarViewData().finally();
  };

  const Filters = () => {
    return (
      <S.FloorAndPlaceSelectWrapper>
        <FloorAndPlaceControlPanel
          link={calendarStore.getLink()}
          getFloorList={mapStore.getFloorsDropDownList}
          getWorkPlaces={workPlacesStore.getAvailableWorkPlainPlaces}
          onChangeFloor={onChangeFloor}
          onChangePlace={onChangePlace}
          onSelectOnlyMine={onSelectOnlyMine}
          floorsList={mapStore.floorsDropDownList}
          placesList={workPlacesStore.workPlacesPlainList}
          onlyMineSelected={calendarStore.calendarFilter.isOnlyMine}
          selectedWorkPlaceId={calendarStore.calendarFilter.workplaceId}
          selectedFloorId={calendarStore.calendarFilter.floorId}
        />
      </S.FloorAndPlaceSelectWrapper>
    );
  };
  return (
    <Box sx={{ mt: 1 }}>
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
      {isMobile ? (
        <>
          <Accordion title="Фильтры">
            <Filters />
          </Accordion>
          <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
        </>
      ) : (
        <Filters />
      )}

      {calendarStore.headerDateCellElems.map((cell, index) => (
        <CustomDateHeaderCell key={index} parentNode={cell} />
      ))}

      <S.CalendarWrapper
        culture={'ru-RU'}
        components={{ eventWrapper: EventCard, toolbar: CustomToolBar }}
        defaultView={Views.DAY}
        date={calendarStore.currentDate!}
        view={calendarStore.viewPeriodMode}
        localizer={localizer}
        events={calendarStore.reservations}
        scrollToTime={new Date(0, 0, 0, 7, 0)}
      />

      <ReservationDialog />
      <QrDialog />
    </Box>
  );
};

export default observer(ReservationCalendar);
