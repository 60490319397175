import styled from 'styled-components/macro';
import { Alert } from '@mui/material';

export const UploadField = styled.div`
  height: 100%;
`;

export const AlertMapSize = styled(Alert)`
  border-radius: 16px;
  color: #ff6a55;
  background-color: rgba(255, 106, 85, 0.1);
`;

export const AlertWrapper = styled.div`
  margin-top: 15px;

  label {
    margin-top: 15px;
  }
`;
