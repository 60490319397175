import React, { PropsWithChildren } from 'react';
import { LatLngLiteral, LeafletMouseEvent } from 'leaflet';
import { observer } from 'mobx-react';
import { mapStore, workPlacesStore, rolesStore } from 'stores';
import { DraggableMarker } from 'components';
import { Permissions } from 'shared/enums';
import { WorkPlaceListModel } from 'shared/models';

interface IWorkPlaceProps {
  mapObject: WorkPlaceListModel;
}

const WorkPlace: React.FC<IWorkPlaceProps> = (props: PropsWithChildren<IWorkPlaceProps>) => {
  const { mapObject } = props;

  const onMarkerClick = (e: LeafletMouseEvent) => {
    if (mapStore.isSelected) {
      const obj = workPlacesStore.workPlaces.find((_: WorkPlaceListModel) => _.id === mapObject.id);
      obj?.setSelected(!obj?.selected);
    } else {
      // Отображение меню
      if (!mapObject.id) return;
      workPlacesStore.setAnchorEl(e.sourceTarget.getElement());
      workPlacesStore.selectedWorkPlace.setId(mapObject.id);
    }
  };

  const onMoveMarkerStart = () => {
    if (!mapObject.id) return;
    workPlacesStore.selectedWorkPlace.setId(mapObject.id);
  };

  const onMoveMarkerEnd = (position: LatLngLiteral) => {
    workPlacesStore.selectedWorkPlace.setPosition(position);
    workPlacesStore.updateWorkPlacePosition().finally();
  };

  const icon = mapObject.getWorkPlaceIconUrl();

  const workplaceUpdatingAllow = rolesStore.isAllow(Permissions.WorkplaceUpdating);

  return (
    <DraggableMarker
      icon={mapStore.getIcon(mapObject, true, false, icon)}
      position={{ lat: mapObject.position.y, lng: mapObject.position.x }}
      draggable={workplaceUpdatingAllow}
      onClick={onMarkerClick}
      onDragStart={onMoveMarkerStart}
      onDragEnd={onMoveMarkerEnd}
    />
  );
};

export default observer(WorkPlace);
