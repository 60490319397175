import React from 'react';

const MoreActions: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={'#B3C4CE'} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.334 2.7501H7.665C4.644 2.7501 2.75 4.8891 2.75 7.9161V16.0841C2.75 19.1111 4.634 21.2501 7.665 21.2501H16.333C19.364 21.2501 21.25 19.1111 21.25 16.0841V7.9161C21.25 4.8891 19.364 2.7501 16.334 2.7501Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15.9393 12.013H15.9483" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.9301 12.013H11.9391" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.92128 12.013H7.93028" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MoreActions;
