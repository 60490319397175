import styled from 'styled-components/macro';

export const UserWrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.common.white};
  padding-top: ${(props) => props.theme.spacing(2)};
  padding-left: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(5)};
  align-items: center;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${(props) => props.theme.spacing(3)};
`;

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
`;
