import styled from 'styled-components/macro';

export const UserInfoWrapper = styled.div`
  padding: 10px 0;
`;

export const UserInfoRow = styled.div`
  display: flex;
`;

export const RowLabel = styled.span`
  width: 130px;
`;
