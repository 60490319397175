import { IMessagesGetDto, IMessagesPostDto, IMessagesRow } from 'shared/interfaces/api';
import MessagesQueryModel from 'shared/models/MessagesModel/MessagesQueryModel';
import { toUtc, utcToLocal } from 'utils/DateTimeUtils';
import HTTPService, { IHttpRequest } from './HTTPService';

class MessagesApi extends HTTPService {
  public constructor() {
    super('Messages');
  }

  public async getMessagesInput(filter: MessagesQueryModel): Promise<IMessagesGetDto> {
    if (!filter.from || !filter.to) return new Promise(() => null);

    const params = this.getUtcParams(filter);

    const result = await this.GET<IMessagesGetDto>('input', { params: params });
    if (!result) return new Promise(() => null);

    result.rows.forEach((row: IMessagesRow) => (row.createdAt = utcToLocal(row.createdAt!)));
    return result;
  }

  public async getMessagesOutput(props: MessagesQueryModel): Promise<IMessagesGetDto> {
    if (!props.from || !props.to) return new Promise(() => null);

    const params = this.getUtcParams(props);

    const result = await this.GET<IMessagesGetDto>('output', { params });
    if (!result) return new Promise(() => null);

    result.rows.forEach((row: IMessagesRow) => (row.createdAt = utcToLocal(row.createdAt!)));
    return result;
  }

  private getUtcParams(filter: MessagesQueryModel) {
    if (!filter) return new MessagesQueryModel();

    const params = { ...filter };
    params.from = toUtc(filter.from!);
    params.to = toUtc(filter.to!);

    return params;
  }

  public async postMessage(passTemplateData: IMessagesPostDto): Promise<string> {
    return await this.POST('', passTemplateData, {});
  }

  public async getUnreadCount(userId: string): Promise<number> {
    return await this.GET(`${userId}/unread-count`, {});
  }

  public async putMessageStatus(userId: string, id: string): Promise<number> {
    const request: IHttpRequest<null> = { path: `${id}/users/${userId}`, showAccessAlert: false };
    return await this.PUT<null, number>(request);
  }

  public async getLastUnreadMessages(userId: string, count: number): Promise<IMessagesRow[]> {
    const result = await this.GET<IMessagesRow[]>(`${userId}/last-unread/${count}`, {});
    if (!result) return new Promise(() => null);

    result.forEach((row: IMessagesRow) => (row.createdAt = utcToLocal(row.createdAt!)));

    return result;
  }
}

export default new MessagesApi();
