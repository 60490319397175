import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    white-space: normal;
    & div {
      margin-bottom: ${(props) => props.theme.spacing(9)};
    }
  }
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  white-space: pre;
  & div {
    margin-bottom: ${(props) => props.theme.spacing(5)};
    white-space: normal;
    overflow-wrap: break-word;
  }
`;
