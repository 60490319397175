import { makeAutoObservable } from 'mobx';
import { TBtnColor } from 'shared/constants';

type ConfirmType = (value: boolean | Promise<boolean>) => void;

export interface IConfirmDialog {
  title: string;
  content?: string;
  buttonName?: string;
  buttonColor?: TBtnColor;
}

class confirmDialogStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isOpen = false;
  public title = '';
  public content: string | null = null;
  public buttonName = 'Удалить';
  public buttonColor: TBtnColor = 'error';

  private resolveConfirm?: ConfirmType;

  public open(params: IConfirmDialog): Promise<boolean> {
    this.isOpen = true;
    this.title = params.title ?? 'Удалить?';
    this.content = params.content ?? null;
    this.buttonName = params.buttonName ?? 'Удалить';
    this.buttonColor = params.buttonColor ?? 'error';

    return new Promise<boolean>((func: ConfirmType) => (this.resolveConfirm = func));
  }

  public onSubmit() {
    this.resolveConfirm?.call(this, true);
    this.isOpen = false;
  }

  public onCancel() {
    this.resolveConfirm?.call(this, false);
    this.isOpen = false;
  }
}

export default new confirmDialogStore();
