import styled from 'styled-components/macro';
import { Drawer as MuiDrawer, ListItem, List as MuiList } from '@mui/material';

export const Drawer = styled(MuiDrawer)`
  width: 228px;
  flex-shrink: 0;

  border-right: 0;
  position: relative;

  .MuiDrawer-paper {
    width: 228px;
    overflow-y: clip;
    margin-left: ${(props) => props.theme.spacing(3)};
    margin-top: ${(props) => props.theme.spacing(3)};
    margin-bottom: ${(props) => props.theme.spacing(3)};
    height: initial;
    bottom: 0;
    background-color: unset;
    box-shadow: unset;
  }

  > div {
    border-right: 0;
  }

  & .MuiPaper-root {
    background-color: transparent;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: unset;
    z-index: 20;

    & .MuiDrawer-paper {
      width: 319px;
      overflow-y: clip;
      margin-top: 44px;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    width: unset;
  }
`;

export const Brand = styled(ListItem)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: flex-start;
    border-radius: 0;
    padding: ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(6)};
  }

  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    border-radius: 0;
  }
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(6)} 0 0;
  width: 100%;
  cursor: pointer;
`;

export const Logo = styled.img`
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  object-fit: contain;
  max-height: 60px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

export const Scrollbar = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 0 0 ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(6)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const List = styled(MuiList)`
  background-color: ${(props) => props.theme.palette.primary.main};
`;

export const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

export const CrossIcon = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.icons.stroke};
  border-radius: ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(6)} 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(4)};
`;
