import { makeAutoObservable } from 'mobx';
import { userAuthStore } from 'stores';
import { PASS_TEMPLATE_ID } from 'shared/constants';
import { IFieldPasses, IPassListGetDto, IPassesPostDto } from 'shared/interfaces/api';

class PassListModel {
  constructor(dto?: IPassListGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.fromDate = dto.fromDate;
    this.toDate = dto.toDate;
    this.fullUserName = dto.fullUserName;
    this.isConfirmed = dto.isConfirmed;
    this.description = dto.description;
  }

  public id = '';

  public createdAt: Date | null = null;

  public requestDate: Date | null = null;

  public fromDate: Date | null = null;

  public toDate: Date | null = null;

  public fullUserName = '';

  public isConfirmed = false;

  public fieldsValues: string[] = [];

  public description = '';

  public fields: IFieldPasses[] = [];
}

export default PassListModel;
