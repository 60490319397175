import styled from 'styled-components/macro';

import { ListItemButton as MuiListItemButton, ListItemIcon as MuiListItemIcon, ListItemText as MuiListItemText } from '@mui/material';

export const ListItemButton = styled(MuiListItemButton)`
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

export const PermissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
