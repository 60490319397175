import { IMattertagGetDto } from 'shared/interfaces/app';

class TourMattertagModel {
  public constructor(dto?: IMattertagGetDto) {
    if (!dto) return;

    this.label = dto?.label;
    this.id = dto?.id;
  }

  public label = '';
  public id = '';
}

export default TourMattertagModel;
