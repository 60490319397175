import styled from 'styled-components/macro';
export const MapActions = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    position: inherit;
    justify-content: center;

    & > *:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing(4)};
    }
  }
  display: flex;
  position: absolute;
  z-index: 9;
  pointer-events: none;
`;

export const Column = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-right: 0;
  }
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing(3)};
  max-width: 200px;
  min-width: 150px;
  max-height: 700px;
  overflow: auto;
`;

export const AddFloor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 36px;
  border-radius: 12px;
  cursor: pointer;
  pointer-events: all;
  margin-top: ${(props) => props.theme.spacing(2)};

  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};

    svg {
      color: ${(props) => props.theme.palette.common.white};
    }
  }
`;
