import React, { forwardRef, ReactFragment } from 'react';
import { observer } from 'mobx-react';
import { X } from 'react-feather';
import { LinkProps, NavLink as RouterNavLink, useHistory } from 'react-router-dom';
import { companiesStore, messagesStore, notificationsStore, rolesStore } from 'stores';
import routes, { ROUTE } from 'routes';
import { Permissions } from 'shared/enums';
import { RouteInfoType } from 'shared/interfaces/app';

import appStore from 'stores/appStore';
import { useBreakpoint } from 'utils/hooks';

import { SidebarCategory, SidebarFooter, SidebarUser } from './Components';
import * as S from './Sidebar.styles';

interface ISidebarProps {
  PaperProps?: {
    style: {
      width: number;
    };
  };
  open?: boolean;
  onClose?: () => void;
}

const Sidebar: React.FC<ISidebarProps> = ({ ...rest }) => {
  const history = useHistory();
  const NavLink = forwardRef<LinkProps, any>((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

  const goHome = () => {
    history.push('/');
  };

  const bp = useBreakpoint();
  const isTablet = bp === 'md';
  const isMobile = bp === 'sm';

  let defaultAdminRouting = ROUTE.NONE;
  if (rolesStore.isAllow(Permissions.UsersModuleView)) defaultAdminRouting = ROUTE.Users;
  else if (rolesStore.isAllow(Permissions.RolesModuleView)) defaultAdminRouting = ROUTE.Roles;
  else if (rolesStore.isAllow(Permissions.DepartmentModuleView)) defaultAdminRouting = ROUTE.Departments;
  else if (rolesStore.isAllow(Permissions.CompaniesModuleView)) defaultAdminRouting = ROUTE.Companies;
  else if (rolesStore.isAllow(Permissions.ZonesModuleView)) defaultAdminRouting = ROUTE.Zones;
  else if (rolesStore.isAllow(Permissions.PassesConstructorModuleView)) defaultAdminRouting = ROUTE.PassTemplates;
  else if (rolesStore.isAllow(Permissions.MapConstructorModuleView)) defaultAdminRouting = ROUTE.MapConstructor;

  const customLogo = companiesStore.selectedCompany.logoImageString;
  const logo = customLogo ? customLogo : '/Logo_Color.svg';

  return (
    <S.Drawer
      open={appStore.visibleSidebar}
      variant={isMobile || isTablet ? 'temporary' : 'permanent'}
      {...rest}
      onClose={() => appStore.setVisibleSideBar(false)}>
      {appStore.visibleSidebar && isTablet ? (
        <S.CrossIcon onClick={() => appStore.setVisibleSideBar(!appStore.visibleSidebar)}>
          <X></X>
        </S.CrossIcon>
      ) : null}

      <S.Brand onClick={goHome}>
        <S.Logo src={logo} alt={''} />
      </S.Brand>

      <SidebarUser />

      <S.Scrollbar>
        <S.List disablePadding>
          <S.Items>
            {routes.map((category: RouteInfoType) => {
              // Если нет вкладок админки, то не отображаем саму админку
              if (category.path.startsWith(ROUTE.Admin)) {
                if (!defaultAdminRouting) return;
                else category.path = defaultAdminRouting;
              }

              // Прямые разрешения на модули
              if (category.path === ROUTE.Passes && !rolesStore.isAllow(Permissions.PassesModuleView)) return;
              if (category.path === ROUTE.Reservation && !rolesStore.isAllow(Permissions.ReservationsModuleView)) return;

              // Значки кол-ва
              let badge = null;
              if (category.path === ROUTE.Messages) badge = messagesStore.unreadMessagesCount;
              if (category.path === ROUTE.Notifications) badge = notificationsStore.unreadNotificationsCount;

              const categoryLink: ReactFragment = (
                <React.Fragment key={category.path}>
                  {category.icon ? (
                    <SidebarCategory
                      id={category.path}
                      name={category.name}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      badge={badge}
                      onClick={() => appStore.setVisibleSideBar(!appStore.visibleSidebar)}
                    />
                  ) : null}
                </React.Fragment>
              );

              return categoryLink;
            })}
          </S.Items>
        </S.List>

        <SidebarFooter />
      </S.Scrollbar>
    </S.Drawer>
  );
};

export default observer(Sidebar);
