import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { notificationsStore } from 'stores';
import { ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { NotificationsModel } from 'shared/models';
import { getFullDateTime } from 'utils/DateTimeUtils';
import { Button } from '@mui/material';

import * as S from './NotificationViewDialog.styles';

interface INotificationViewDialogProps {
  onClose: () => void;
}

const NotificationViewDialog: React.FC<INotificationViewDialogProps> = (props) => {
  useEffect(() => {
    return () => {
      notificationsStore.selectedNotification = new NotificationsModel();
    };
  }, []);

  const { onClose } = props;

  const date = new Date(notificationsStore.selectedNotification.createdAt!);

  return (
    <ModalDialog
      title={notificationsStore.selectedNotification.caption}
      titleFontSize={'20px'}
      subTitle={getFullDateTime(date)}
      onClose={onClose}
      onCancel={onClose}
      createBtnLabel={'Отправить'}
      isOpen={notificationsStore.dialogViewMode !== DialogStatus.Closed}>
      <S.ContentWrapper>
        <div style={{ marginBottom: 8, whiteSpace: 'break-spaces' }}>{notificationsStore.selectedNotification.content}</div>
        <Button variant="outlined" fullWidth size="large" onClick={onClose}>
          Закрыть
        </Button>
      </S.ContentWrapper>
    </ModalDialog>
  );
};

export default observer(NotificationViewDialog);
