import styled from 'styled-components/macro';

import { TextField as MuiTextField, Button as MuiButton, Box } from '@mui/material';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.spacing(4)};
  padding-right: ${(props) => props.theme.spacing(10)};
  margin-bottom: ${(props) => props.theme.spacing(6)};
`;

export const CustomContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing(6)};
`;

export const Button = styled(MuiButton)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

export const AddButton = styled(MuiButton)`
  margin-bottom: ${(props) => props.theme.spacing(4)};
  svg {
    margin: 0 !important;
  }
`;

export const TextField = styled(MuiTextField)`
  justify-content: flex-start;
  width: 100%;
  min-width: 240px;
`;

export const DropField = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing(6)};
  color: ${(props) => props.theme.palette.error.main};
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    stroke: ${(props) => props.theme.palette.error.main};
  }
`;
