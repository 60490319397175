import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { reservationStore as store, rolesStore, usersStore } from 'stores';
import { Accordion, Spinner, Table } from 'components';
import { Permissions } from 'shared/enums';
import { getEndOfDay, getFullBookingTimeInterval, getStartOfDay } from 'utils/DateTimeUtils';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { Box, Divider } from '@mui/material';
import { CommentDialog } from '../../Main/Components';
import { ReservationDialog, QrDialog, ReservationListFilters } from '../Components';
import * as S from './ReservationList.styles';
import { Columns, Rows } from './ReservationListService';

const ReservationList: React.FC = () => {
  const isAdmin = rolesStore.isAllow(Permissions.ReservationViewOtherUsers);
  const { filter } = store;

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';
  const isDesktop = bp === 'lg';
  const isLarge = bp === 'xl';

  useEffect(() => {
    if (isAdmin) usersStore.getUsersList().finally();
    filter.onOnlyMineChange(true);

    const bookingTimeInterval = getFullBookingTimeInterval();
    filter.setTimeInterval(bookingTimeInterval.fromTime, bookingTimeInterval.toTime);

    // Получем данные из url
    const url = new URL(document.location.href);
    const workplaceId = url.searchParams.get('workplaceId');
    const fromDateTime = url.searchParams.get('fromDateTime');
    const toDateTime = url.searchParams.get('toDateTime');
    url.searchParams.delete('workplaceId');
    url.searchParams.delete('fromDateTime');
    url.searchParams.delete('toDateTime');
    window.history.replaceState(null, 'key', url.href);

    if (workplaceId) {
      store.filter.updateWorkPlaceId(workplaceId);
    }

    if (fromDateTime && toDateTime) {
      store.filter.setDateInterval(getStartOfDay(new Date(fromDateTime)), getEndOfDay(new Date(fromDateTime)));
      const fromTime = new Date(fromDateTime);
      const toTime = new Date(toDateTime);
      store.filter.setTimeInterval(fromTime, toTime);
    }

    store.getRowItemList().finally();

    return () => {
      filter.deInit();
    };
  }, []);

  const onChangePage = (pageIndex: number) => {
    filter.pageIndex = pageIndex;
    store.getRowItemList().finally();
  };

  const Content = store.isLoadingTable
    ? () => <Spinner />
    : () => (
        <Table
          columns={Columns(isAdmin, isMobile, isTablet, isDesktop, isLarge)}
          rows={Rows(store.rawTable.rows, isAdmin, isMobile, isTablet, isDesktop, isLarge)}
          totalCount={store.rawTable.totalCount}
          pageIndex={filter.pageIndex}
          onChangePage={onChangePage}
        />
      );

  return (
    <Box sx={{ mt: 1 }}>
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
      {isMobile ? (
        <S.AccordionWrapper>
          <Accordion title="Фильтры">
            <ReservationListFilters />
          </Accordion>
          <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
        </S.AccordionWrapper>
      ) : (
        <ReservationListFilters />
      )}
      {store.isLoadingOverlay && <Spinner />}
      <Content />
      <ReservationDialog />
      <QrDialog />
      <CommentDialog />
    </Box>
  );
};

export default observer(ReservationList);
