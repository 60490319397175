import styled from 'styled-components/macro';
import { Menu as MuiMenu } from '@mui/material';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(4),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    minWidth: 180,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      color: theme.palette.text.secondary,
      padding: theme.spacing(2),
      height: '36px',
      borderRadius: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const MenuActions = styled.div`
  width: 100%;
  background-color: ${(p) => p.theme.palette.common.white};
`;
