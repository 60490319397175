import { addDays, format, startOfWeek } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { Marker } from 'leaflet';
import { makeAutoObservable, runInAction } from 'mobx';

import { appStore, mapStore, workPlacesStore, userAuthStore } from 'stores';
import { WorkPlacesApi } from 'api';
import { DialogStatus, WorkPlaceType } from 'shared/enums';
import { IAttributeDto, IWorkPlacesAvailableBookingGetDto, IWorkPlacesOutsideGetDto } from 'shared/interfaces/api';
import { IDropDownOption, IMattertagGetDto, IWorkPlaceTotalCount } from 'shared/interfaces/app';
import {
  MessagesModel,
  TourMattertagModel,
  WorkPlaceAvailableBookingModel,
  WorkPlaceFilterModel,
  WorkPlaceImageModel,
  WorkPlaceListModel,
  WorkPlaceModel,
} from 'shared/models';

class WorkPlacesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isTourEnable = false;

  public matterTags: IMattertagGetDto[] = [];

  public isFetching = false;

  public anchorEl: HTMLElement | null = null;

  public dialogViewMode = DialogStatus.Closed;

  public commentDialogViewMode = DialogStatus.Closed;

  public selectedWorkPlace = new WorkPlaceModel();

  public workPlaces: WorkPlaceListModel[] = [];

  public workPlaceSelectedId: string | null = null;

  public workPlacesPlainList: IDropDownOption[] = [];

  public workPlacesOutsideList: IWorkPlacesOutsideGetDto[] = [];

  public queryFilterParams = new WorkPlaceFilterModel();

  public workPlacesAvailableBooking: WorkPlaceAvailableBookingModel[] = [];

  public commentMessage = new MessagesModel();

  public userInfoDialogViewMode = DialogStatus.Closed;

  public workPlaceAttrList: IAttributeDto[] = [];

  public daysOfWeek = Array.from(Array(7)).map((e, i) => format(addDays(startOfWeek(new Date()), i + 1), 'EEEEEE', { locale: ruLocale }));

  public workplaceIdByTagId(id: string): string | undefined {
    if (this.matterTags.length === 0) return;
    const item = this.matterTags.find((_: any) => _.sid === id);
    const model = new TourMattertagModel(item);
    const workplace = this.workPlacesPlainList.find((_: IDropDownOption) => _.name == model.label);
    return workplace?.id;
  }

  public setTourArray(tags: IMattertagGetDto[]) {
    this.matterTags = tags;
  }

  public get workPlacesTotalCount(): IWorkPlaceTotalCount {
    return {
      freeWorkplacesCount: this.workPlacesAvailableBooking.filter((item) => item.availableBooking).length,
      reservedWorkplacesCount: this.workPlacesAvailableBooking.filter((item) => !item.availableBooking).length,
      totalWorkplacesCount: this.workPlacesAvailableBooking.length,
    };
  }

  public get workPlaceStatus(): WorkPlaceAvailableBookingModel | undefined {
    if (this.workPlacesAvailableBooking.length === 0) return;
    return this.workPlacesAvailableBooking.find((item) => item.id === this.selectedWorkPlace.id);
  }

  public get selectedWorkPlaceAttrList(): IAttributeDto[] {
    return this.workPlaceAttrList.map((i) => {
      this.selectedWorkPlace.attributes.forEach((j) => {
        if (i.id === j.id) {
          i.count = j.count;
        }
      });
      return i;
    });
  }

  public get daysOfWeekTags() {
    return this.daysOfWeek.map((item, idx) => {
      return {
        id: idx.toString(),
        name: item,
      };
    });
  }

  public get selectedWorkPlaceZones(): string[] {
    const filteredArr = this.workPlaces.filter((_) => _.selected);

    const zonesConcat: string[] = [];
    filteredArr.forEach((_) => _.zoneIds.forEach((item) => zonesConcat.push(item)));

    return Array.from(new Set(zonesConcat));
  }

  public get selectedWorkPlaces(): WorkPlaceListModel[] {
    return this.workPlaces.filter((_) => _.selected);
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCommentDialogViewMode(newDialogViewMode: DialogStatus) {
    this.commentDialogViewMode = newDialogViewMode;
  }

  public setAnchorEl(anchorEl: HTMLElement | null) {
    this.anchorEl = anchorEl;
  }

  public setUnSelectedWorkPlaces() {
    this.workPlaces.forEach((_) => (_.selected = false));
  }

  public setCreatingMode(marker: Marker) {
    this.selectedWorkPlace = new WorkPlaceModel();
    if (marker) this.selectedWorkPlace.setMarker(marker);
    this.selectedWorkPlace.setFloorId(mapStore.selectedFloor.id);
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public async setPositionMode(marker: Marker) {
    this.selectedWorkPlace.setMarker(marker);
    await this.createWorkPlacePosition();
    await this.getWorkPlaceOutsideList(mapStore.selectedFloorImage.id);
    mapStore.setIsDraw(false);
    appStore.setAnchorEl(appStore.savePopupMenuAnchorEl);
  }

  public async setEditingMode() {
    if (!this.selectedWorkPlace.id) return;
    await this.getWorkPlaceById(this.selectedWorkPlace.id);
    await this.getWorkPlaceImageList();
    this.setDialogViewMode(DialogStatus.Editing);
  }

  public async setDuplicateMode(marker: Marker) {
    await this.getWorkPlaceById(this.selectedWorkPlace.id);
    if (marker) this.selectedWorkPlace.setMarker(marker);
    // TODO mikhail.broch: Если нужно дублировать фотографии рабочего места при DuplicateAction
    // await this.getWorkPlaceImageList();
    this.selectedWorkPlace.setId('');
    this.selectedWorkPlace.setNumber('');

    this.setDialogViewMode(DialogStatus.Creating);
  }

  public closeDialog() {
    this.selectedWorkPlace.clear();
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public async getWorkPlacesList() {
    try {
      if (!mapStore.selectedFloorImage.id) return;

      this.isFetching = true;
      const workPlaces = await WorkPlacesApi.getList(mapStore.selectedFloorImage.id);
      if (!workPlaces) return;
      this.workPlaces = workPlaces.map((item) => new WorkPlaceListModel(item));
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getWorkPlacesAvailableBookingList() {
    try {
      this.isFetching = true;
      const result = await WorkPlacesApi.getWorkPlacesAvailableBooking(this.queryFilterParams.queryParamsDto);
      if (!result) return;

      this.setWorkPlacesAvailableBooking(result);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public setWorkPlacesAvailableBooking(value: IWorkPlacesAvailableBookingGetDto[]) {
    this.workPlacesAvailableBooking = value.map((_) => new WorkPlaceAvailableBookingModel(_));
  }

  public async getAvailableWorkPlainPlaces(floorId?: string | null) {
    await this.getWorkPlainPlaces(floorId, userAuthStore.userId);
  }

  public async getAllWorkPlainPlaces(floorId?: string | null) {
    await this.getWorkPlainPlaces(floorId);
  }

  private async getWorkPlainPlaces(floorId?: string | null, userId?: string | null) {
    try {
      this.isFetching = true;
      const dtos = await WorkPlacesApi.getPlainList(floorId, userId);
      if (!dtos) return;

      runInAction(() => (this.workPlacesPlainList = dtos.map((_) => ({ id: _.id, name: _.name, type: _.type }))));
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getWorkPlaceOutsideList(mapId: string) {
    try {
      if (mapId) {
        this.isFetching = true;
        const result = await WorkPlacesApi.getOutsideList(mapId);
        if (!result) return;

        this.workPlacesOutsideList = result.map((dto) => ({ id: dto.id, floorId: dto.floorId, type: dto.type, name: dto.name }));
      }
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getWorkPlaceById(id: string) {
    try {
      this.isFetching = true;
      const workPlace = await WorkPlacesApi.getById(id);
      if (!workPlace) return;
      this.selectedWorkPlace = new WorkPlaceModel(workPlace);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async createWorkPlace(): Promise<void> {
    if (this.selectedWorkPlace.postDto === null) return;

    try {
      this.isFetching = true;
      const workPlaceId = await WorkPlacesApi.postWorkPlace(this.selectedWorkPlace.postDto);
      if (!workPlaceId) return;
      this.selectedWorkPlace.setId(workPlaceId);
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  public async createWorkPlacePosition(): Promise<void> {
    if (!this.selectedWorkPlace.id) return;
    try {
      this.isFetching = true;
      await WorkPlacesApi.postWorkPlacePosition(this.selectedWorkPlace.id, mapStore.selectedFloorImage.id, this.selectedWorkPlace.position);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updateWorkPlace(): Promise<void> {
    if (this.selectedWorkPlace.putDto === null) return;

    try {
      this.isFetching = true;
      await WorkPlacesApi.putWorkPlace(this.selectedWorkPlace.id, this.selectedWorkPlace.putDto);
    } catch (e) {
      throw e;
    } finally {
      this.isFetching = false;
    }
  }

  public async updateWorkPlacePosition(): Promise<void> {
    if (!this.selectedWorkPlace.id) return;

    try {
      this.isFetching = true;
      await WorkPlacesApi.putWorkPlacePosition(this.selectedWorkPlace.id, mapStore.selectedFloorImage.id, this.selectedWorkPlace.position);
      this.workPlaces.find((_) => _.id === this.selectedWorkPlace.id)?.setPosition(this.selectedWorkPlace.position);
      this.selectedWorkPlace = new WorkPlaceModel();
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async removeWorkPlace(id: string): Promise<void> {
    try {
      this.isFetching = true;
      await WorkPlacesApi.deleteWorkPlace(id);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async hideWorkPlaceFromMap(id: string): Promise<void> {
    try {
      this.isFetching = true;
      await WorkPlacesApi.hideWorkPlace(id, mapStore.selectedFloorImage.id);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getWorkPlaceAttributesList(): Promise<void> {
    try {
      this.isFetching = true;
      const workPlaceAttr = await WorkPlacesApi.getWorkPlaceAttributes(this.selectedWorkPlace.type);
      if (!workPlaceAttr) return;
      this.workPlaceAttrList = workPlaceAttr;
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public getCaption(): string {
    const type = workPlacesStore.selectedWorkPlace.type;
    if (type === null) return '';

    return type.toString() === WorkPlaceType.WorkPlace ? 'Рабочее место' : 'Переговорная комната';
  }

  public setTourEnable(enable: boolean) {
    this.isTourEnable = enable;
  }

  public async getWorkPlaceImageList(): Promise<void> {
    if (!this.selectedWorkPlace.id) return;
    try {
      this.isFetching = true;
      const result = await WorkPlacesApi.getWorkPlaceImages(this.selectedWorkPlace.id);
      if (!result) return;
      this.selectedWorkPlace.workPlaceImages = result.map((dto) => new WorkPlaceImageModel(dto));
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async createWorkPlaceImage(): Promise<void> {
    if (!this.selectedWorkPlace.postImages) return;
    try {
      this.isFetching = true;
      const promiseArray = this.selectedWorkPlace.postImages.map((item) => {
        return WorkPlacesApi.postWorkPlaceImage(this.selectedWorkPlace.id, item);
      });
      await Promise.all(promiseArray);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async removeWorkPlaceImage(): Promise<void> {
    try {
      this.isFetching = true;
      await WorkPlacesApi.deleteWorkPlaceImage(this.selectedWorkPlace.id, this.selectedWorkPlace.removeImageIds);
      this.getWorkPlaceImageList();
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public clear() {
    this.workPlaces = [];
    this.workPlacesAvailableBooking = [];
    this.workPlacesPlainList = [];
    this.workPlaceSelectedId = null;
  }
}

export default new WorkPlacesStore();
