import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

import { rolesStore, usersStore } from 'stores';
import { DropDownChip, Input, ModalDialog } from 'components';

import { DialogStatus } from 'shared/enums';
import { IRoleDialog } from 'shared/interfaces/app';

import Permissions from './Components';

import * as S from './RoleDialog.styles';

const RoleDialog: React.FunctionComponent = () => {
  useEffect(() => {
    usersStore.getUsersList();

    return () => {
      rolesStore.clearPermissionList();
    };
  }, []);

  const [validated, setValidated] = useState(false);

  const formRef = useRef<FormikProps<IRoleDialog>>(null);

  const dialogTitle = rolesStore.selectedRole.id ? 'Редактировать роль' : 'Создать роль';

  const onSubmit = async (values: IRoleDialog) => {
    rolesStore.selectedRole.updateFromDialogForm(values);

    if (rolesStore.dialogViewMode === DialogStatus.Creating) {
      await rolesStore.createRole();
      rolesStore.updatePermissions();
    } else if (rolesStore.dialogViewMode === DialogStatus.Editing) {
      await rolesStore.updateRole();
      rolesStore.updatePermissions();
    }

    rolesStore.closeRoleDialog();
    rolesStore.getRolesList();
  };

  const roleForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: rolesStore.selectedRole.name,
        userIds: rolesStore.selectedRole.userIds,
        usersOpt: usersStore.usersForOptions,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Обязательное поле'),
      })}>
      {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
        setValidated(isValid);

        const handleDeleteUsers = (e: React.ChangeEvent<string>, value: string) => {
          e.preventDefault();

          const idx = values.userIds.indexOf(value);
          if (idx !== -1) {
            values.userIds.splice(idx, 1);
          }
          setFieldValue('userIds', values.userIds);
        };

        return (
          <S.Wrapper>
            <Input
              name="name"
              inputLabel="Название роли"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <DropDownChip
              name="userIds"
              options={values.usersOpt}
              values={values.userIds}
              placeholder="Выберите пользователей"
              dropDownLabel="Пользователи"
              onDelete={handleDeleteUsers}
              onChange={(e: React.ChangeEvent<any>) => {
                setFieldValue('userIds', e.target.value);
              }}
            />

            <Permissions />
          </S.Wrapper>
        );
      }}
    </Formik>
  );

  const handleCloseDialog = () => {
    rolesStore.closeRoleDialog();
  };

  return (
    <ModalDialog
      isModelHasId={rolesStore.selectedRole.id}
      title={dialogTitle}
      isValid={validated}
      isOpen={rolesStore.dialogViewMode !== DialogStatus.Closed}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {roleForm}
    </ModalDialog>
  );
};

export default observer(RoleDialog);
