import React, { ReactElement, useState } from 'react';
import { Pagination } from 'components';
import {
  TABLE_ROWS_PER_PAGE_DESKTOP,
  TABLE_ROWS_PER_PAGE_DESKTOP_LARGE,
  TABLE_ROWS_PER_PAGE_MOBILE,
  TABLE_ROWS_PER_PAGE_TABLET,
} from 'shared/constants';
import { Column } from 'shared/interfaces/app';
import { useBreakpoint } from 'utils/hooks';

import { Box, Divider } from '@mui/material';
import * as S from './Table.styles';

interface ITableProps {
  columns: Array<Column>;
  rows: Array<Array<JSX.Element>>;
  totalCount?: number | null;
  pageIndex?: number | null;
  onChangePage?: (pageIndex: number) => void;
}

const Table = (props: ITableProps): ReactElement => {
  const { columns, rows, totalCount, pageIndex, onChangePage } = props;

  const [page, setPage] = useState(pageIndex ?? 0);

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';
  const isDesktop = bp === 'lg';
  const isLarge = bp === 'xl';

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);

    onChangePage && onChangePage(page);
  };

  let pageCount = totalCount;
  let rowsOnPage = rows;

  const pageSize = isMobile
    ? TABLE_ROWS_PER_PAGE_MOBILE
    : isTablet
    ? TABLE_ROWS_PER_PAGE_TABLET
    : isDesktop
    ? TABLE_ROWS_PER_PAGE_DESKTOP
    : TABLE_ROWS_PER_PAGE_DESKTOP_LARGE;

  // Если фронтовая паджинация
  if (!totalCount && !pageIndex && !onChangePage) {
    pageCount = rows.length;
    rowsOnPage = rows.slice(page * pageSize, page * pageSize + pageSize);
  }

  return (
    <S.TableWrapper>
      <S.TableContainer>
        <S.Table>
          <S.TableHead>
            <S.TableRow>
              {columns.map((column, index) => {
                if (index === columns.length - 1) {
                  return !column.disabled ? (
                    <S.TableHeadCell
                      key={column.id}
                      align={column.align || 'left'}
                      style={{ width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth }}>
                      <S.HeadCellTypography>{column.label}</S.HeadCellTypography>
                    </S.TableHeadCell>
                  ) : null;
                }

                return !column.disabled ? (
                  <S.TableHeadCell
                    key={column.id}
                    align={column.align || 'left'}
                    style={{ width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth }}>
                    <S.TableCellWrapper>
                      <S.HeadCellTypography>{column.label}</S.HeadCellTypography>
                      <Divider sx={{ ml: 3, mr: 3, height: 14 }} orientation="vertical" />
                    </S.TableCellWrapper>
                  </S.TableHeadCell>
                ) : null;
              })}
            </S.TableRow>
          </S.TableHead>

          <S.TableBody>
            {rowsOnPage.map((row: Array<JSX.Element>, index: number) => (
              <S.TableRow key={index}>
                {row.map((cell: JSX.Element, index: number) => {
                  if (index === row.length - 1) {
                    return (
                      <S.TableBodyCell key={`${index}${cell.toString()}`} align="left">
                        {cell}
                      </S.TableBodyCell>
                    );
                  }

                  return (
                    <S.TableBodyCell key={`${index}${cell.toString()}`} align="left">
                      <S.TableCellWrapper>
                        {cell}
                        <Divider sx={{ ml: 3, mr: 3, height: 14 }} orientation="vertical" />
                      </S.TableCellWrapper>
                    </S.TableBodyCell>
                  );
                })}
              </S.TableRow>
            ))}
          </S.TableBody>
        </S.Table>
      </S.TableContainer>

      <Box sx={{ marginTop: '8px', position: 'fixed', bottom: '16px', backgroundColor: '#ffffffe8', padding: '8px', borderRadius: '18px' }}>
        <Pagination rows={pageCount!} pageSize={pageSize} pageIndex={page} onChangePage={handleChangePage} />
      </Box>
    </S.TableWrapper>
  );
};

export default Table;
