import styled from 'styled-components/macro';
import {
  List as MUIList,
  ListSubheader as MUIListSubheader,
  ListItem as MUIListItem,
  ListItemText as MUIListItemText,
  Typography as MuiTypography,
} from '@mui/material';

export const ListWrapper = styled.div`
  width: 100%;
`;

export const ListItemTextContainer = styled.div`
  position: relative;
`;

export const List = styled(MUIList)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: unset;
  }
  width: 100%;
  min-width: 344px;
  overflow-y: auto;
  margin-bottom: ${(props) => props.theme.spacing(13)};

  & ul {
    padding: 0;
  }
`;

export const ListSubheader = styled(MUIListSubheader)`
  border-radius: ${(props) => props.theme.spacing(4)};
  height: auto;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const ListItem = styled(MUIListItem)`
  height: 72px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing(3)};
  border-radius: ${(props) => props.theme.spacing(3)};
  cursor: pointer;

  h3 {
    line-height: 32px;
  }

  .MuiButton-root {
    display: none;
  }

  &:hover {
    background-color: #809daf33;

    .MuiButton-root {
      display: block;
      margin-right: ${(props) => props.theme.spacing(4)};
    }

    .list-item-time {
      display: none;
    }

    .MuiListItemSecondaryAction-root {
      display: flex;
      align-items: center;
    }
  }

  .MuiListItemSecondaryAction-root {
    position: static;
    transform: none;
    height: 100%;
  }
`;

export const ListItemContent = styled(MUIListItemText)`
  width: auto;
  height: auto;
  margin: 0;
`;

export const ListItemTime = styled(MuiTypography)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: end;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const Typography = styled(MuiTypography)<{ isRead?: boolean }>`
  max-width: 60vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${(props) => (!props.paragraph && !props.isRead ? 600 : 400)};
  color: ${(props) => (props.paragraph ? props.theme.palette.text.secondary : props.theme.palette.text.primary)};
  margin: 0;
  line-height: ${(props) => props.theme.spacing(7)};
`;

export const TimeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing(7)};
`;

export const IsReadIndicator = styled.div`
  width: ${(props) => props.theme.spacing(3)};
  height: ${(props) => props.theme.spacing(3)};
  background-color: ${(props) => props.theme.palette.error.main};
  border-radius: 50%;
  top: 0;
  right: 0;
  position: absolute;
`;
