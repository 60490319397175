import React, { useEffect, useRef, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { userAuthStore } from 'stores';
import { Input, PasswordField } from 'components';

import { IAuthForm } from 'shared/interfaces/app';

import { Button, Typography } from '@mui/material';

import * as S from './SignIn.styles';

const SignIn: React.FC = () => {
  useEffect(() => {
    userAuthStore.clearAuthForm();

    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (formRef.current) formRef.current.handleSubmit();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const history = useHistory();
  const [validated, setValidated] = useState(false);

  const formRef = useRef<FormikProps<IAuthForm>>(null);

  const onSubmit = async (values: IAuthForm) => {
    userAuthStore.updateFromAuthForm(values);

    await userAuthStore.signIn();

    if (userAuthStore.isUnConfirmedUser) history.push('/auth/authentication');
    else if (userAuthStore.isAuthUser) history.push('/');
  };

  const handleResetPass = () => {
    history.push('/auth/reset-password');
  };

  return (
    <>
      <Typography sx={{ mb: 5 }} variant="h2" align="center">
        Войдите в аккаунт
      </Typography>

      {userAuthStore.isAuthError && <S.AlertWrongSignin severity="error">Неверный логин или пароль</S.AlertWrongSignin>}

      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          email: userAuthStore.email,
          password: userAuthStore.password,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Неверный формат email').max(255).required('Обязательное поле'),
          password: Yup.string().required('Обязательное поле'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
          setValidated(isValid);
          return (
            <S.Content>
              <Input
                name="email"
                inputLabel="Введите логин"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <PasswordField
                name="password"
                label="Введите пароль"
                password={values.password}
                helperText={touched.password && errors.password}
                error={Boolean(touched.password && errors.password)}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </S.Content>
          );
        }}
      </Formik>

      <Button
        fullWidth
        disabled={!validated}
        sx={{ mb: 4 }}
        size="large"
        variant="contained"
        color="secondary"
        onClick={() => {
          if (formRef.current) formRef.current.handleSubmit();
        }}>
        Войти
      </Button>
      <Button fullWidth variant="outlined" size="large" color="primary" onClick={handleResetPass}>
        Восстановить пароль
      </Button>
    </>
  );
};

export default observer(SignIn);
