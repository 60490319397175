import { makeAutoObservable } from 'mobx';
import { IDepartmentPostPutDto, IDepartmentGetDto } from 'shared/interfaces/api';
import { IDepartmentsDialog } from 'shared/interfaces/app';

class DepartmentModel {
  constructor(dto?: IDepartmentGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.companyId = dto.companyId;
    this.isDeleted = dto.isDeleted;
    this.zoneIds = dto.zoneIds;
    this.userIds = dto.userIds;
  }

  public id = '';

  public name = '';

  public companyId = '';

  public isDeleted = false;

  public zoneIds: string[] = [];

  public userIds: string[] = [];

  public get postPutDto(): IDepartmentPostPutDto | null {
    return {
      name: this.name,
      zoneIds: this.zoneIds,
      userIds: this.userIds,
    };
  }

  public updateFromDialogForm(model: IDepartmentsDialog) {
    this.name = model.name;
    this.zoneIds = model.zoneIds;
    this.userIds = model.userIds;
  }
}

export default DepartmentModel;
