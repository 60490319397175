import styled from 'styled-components/macro';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div:not(:last-child) {
    margin-bottom: ${(_) => _.theme.spacing(2)};
  }
`;
