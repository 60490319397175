import React, { ChangeEvent, useEffect } from 'react';
import { DateRange } from '@mui/lab';
import { observer } from 'mobx-react';
import { messagesStore } from 'stores';
import { DateRangePicker, Input } from 'components';
import Loupe from 'components/Icons/Loupe';
import * as S from './MessagesFilters.styles';

const MessagesFilters = () => {
  useEffect(() => {
    onSearch();
  }, []);

  const onChangeDateRange = (dateRange: DateRange<Date>): void => {
    messagesStore.setDateRange(dateRange);
  };

  const onChangeSearchFilter = (event: ChangeEvent<{ value: string }>) => {
    messagesStore.queryParams.setSearchPattern(event.target.value);
  };

  const onSearch = (): void => {
    messagesStore.getMessagesList();
  };

  return (
    <S.FiltersWrapper>
      <Input
        mb={0}
        name="search"
        placeholder="Поиск..."
        value={messagesStore.queryParams.searchPattern}
        endIcon={<Loupe />}
        onChange={onChangeSearchFilter}
      />
      <DateRangePicker onChange={onChangeDateRange} range={messagesStore.queryDateRange} />
      <S.SearchButton sx={{ mr: 2, ml: 2 }} onClick={onSearch} variant="outlined" size={'large'}>
        Поиск
      </S.SearchButton>
    </S.FiltersWrapper>
  );
};

export default observer(MessagesFilters);
