import { Upload } from 'react-feather';
import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const DragArea = styled.label<{ areaHighlighted: boolean }>`
  ${(props) => props.theme.breakpoints.down('sm')} {
    height: unset;
    min-width: unset;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 2px dashed;
  border-radius: 12px;
  border-color: ${(props) => (props.areaHighlighted ? `${props.theme.palette.secondary.main}` : 'rgba(0, 58, 93, 0.1)')};
  min-height: 100px;
  height: 100%;
  cursor: pointer;
`;

export const DragAreaTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${(props) => props.theme.spacing(4)};
  box-shadow: 0 12px 13px -6px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
`;

export const DragAreaText = styled(Typography)`
  flex-direction: row;
  margin-left: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.primary.main};
`;

export const FileType = styled(Typography)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: ${(props) => props.theme.spacing(2)};
  }
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const UploadIcon = styled(Upload)`
  color: ${(props) => props.theme.palette.primary.main};
`;

export const ImageWrapper = styled.img`
  padding: ${(props) => props.theme.spacing(1)};
  max-height: 200px;
  object-fit: contain;
`;

export const Actions = styled.div`
  display: flex;
  right: 0;
  bottom: 0;
  position: absolute;
  padding: 0 5px 5px 5px;
  svg {
    width: 18px;
    height: 18px;
    stroke: ${(props) => props.theme.palette.error.light};
    &:hover {
      width: 20px;
      height: 20px;
    }
  }
`;
