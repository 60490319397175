import React from 'react';

const SuccessCircle: React.FC = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M88 47.999C88 70.095 70.092 87.999 48 87.999C25.908 87.999 8 70.095 8 47.999C8 25.911 25.908 7.99902 48 7.99902C70.092 7.99902 88 25.911 88 47.999Z"
        fill="#00B8B0"
      />
      <g filter="url(#filter0_dii_1273_47706)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.2203 32.781C72.2617 33.8224 72.2617 35.5109 71.2203 36.5523L48.3248 59.4477C45.2006 62.5719 40.1353 62.5719 37.0111 59.4477L27.449 49.8856C26.4076 48.8442 26.4076 47.1558 27.449 46.1144C28.4904 45.073 30.1789 45.073 31.2203 46.1144L40.7824 55.6765C41.8237 56.7179 43.5122 56.7179 44.5536 55.6765L67.449 32.781C68.4904 31.7397 70.1789 31.7397 71.2203 32.781Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dii_1273_47706"
          x="22.668"
          y="31"
          width="53.332"
          height="38.791"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1273_47706" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.227451 0 0 0 0 0.364706 0 0 0 0.1 0" />
          <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1273_47706" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1273_47706" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1273_47706" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="multiply" in2="effect2_innerShadow_1273_47706" result="effect3_innerShadow_1273_47706" />
        </filter>
      </defs>
    </svg>
  );
};

export default SuccessCircle;
