import { IMessagesRow, INotificationsGetDto, INotificationsRow } from 'shared/interfaces/api';
import { NotificationsQueryModel } from 'shared/models';

import HTTPService, { IHttpRequest } from './HTTPService';
import { utcToLocal } from '../utils/DateTimeUtils';

class NotificationsApi extends HTTPService {
  public constructor() {
    super('Notifications');
  }

  public async getNotifications(props: NotificationsQueryModel, unreadOnly?: boolean): Promise<INotificationsGetDto> {
    const query = new URLSearchParams({
      pageIndex: props.pageIndex.toString(),
      pageSize: props.pageSize.toString(),
      userId: props.userId.toString(),
    });

    if (unreadOnly) {
      query.append('unreadOnly', unreadOnly.toString());
    }

    const result = await this.GET<INotificationsGetDto>(`?${query.toString()}`, {});
    if (!result) return new Promise(() => null);

    result.rows.forEach((row: INotificationsRow) => (row.createdAt = utcToLocal(row.createdAt!)));
    return result;
  }

  public getUnreadCount(userId: string): Promise<number> {
    return this.GET(`${userId}/unread-count`, {});
  }

  public putNotificationStatus(userId: string, id: string): Promise<number> {
    const request: IHttpRequest<null> = {
      path: `${id}/users/${userId}`,
      showAccessAlert: false,
    };
    return this.PUT<null, number>(request);
  }

  public async getLastUnreadNotifications(userId: string, count: number): Promise<INotificationsRow[]> {
    const result = await this.GET<INotificationsRow[]>(`${userId}/last-unread/${count}`, {});
    if (!result) return new Promise(() => null);

    result.forEach((row: INotificationsRow) => (row.createdAt = utcToLocal(row.createdAt!)));
    return result;
  }
}

export default new NotificationsApi();
