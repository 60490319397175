import React, { useEffect, useRef, useState } from 'react';
import Trash from 'components/Icons/Trash';
import * as S from './Upload.styles';

interface IUploadProps {
  title: string;
  subTitle?: string;
  image?: string;
  accept?: string;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (e: any) => void;
  onDelete?: (e: any) => void;
}

const Upload: React.FC<IUploadProps> = (props) => {
  const { image, title, subTitle, accept, onChangeFile, handleDrop, onDelete } = props;
  const [isDragAreaHighlighted, setDragAreaHighlighted] = useState(false);

  const dragAreaRef = useRef<HTMLLabelElement>(null);

  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (image) {
      setWidth(dragAreaRef.current?.clientWidth);
      setHeight(dragAreaRef.current?.clientHeight);
    }
  }, [image]);

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    highlightDragArea();
    e.stopPropagation();
    e.preventDefault();
  };

  const highlightDragArea = () => {
    setDragAreaHighlighted(true);
  };

  const unHighlightDragArea = () => {
    setDragAreaHighlighted(false);
  };

  return (
    <S.DragArea
      ref={dragAreaRef}
      areaHighlighted={isDragAreaHighlighted}
      onMouseOver={highlightDragArea}
      onMouseLeave={unHighlightDragArea}
      onDrop={(e) => handleDrop!(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={highlightDragArea}
      onDragLeave={unHighlightDragArea}>
      {image && !!width && !!height ? (
        <S.ImageWrapper width={width} height={height} src={image} alt="" />
      ) : (
        <S.DragAreaTextWrapper>
          <S.UploadIcon />
          <S.DragAreaText variant="body2">{title}</S.DragAreaText>
        </S.DragAreaTextWrapper>
      )}
      {subTitle && <S.FileType variant="subtitle2">{subTitle}</S.FileType>}
      <input id="files" type="file" name="file" accept={accept ?? '.jpg, .jpeg, .png, .svg'} hidden onChange={onChangeFile} />
      {image && onDelete && (
        <S.Actions onClick={onDelete}>
          <Trash />
        </S.Actions>
      )}
    </S.DragArea>
  );
};

export default Upload;
