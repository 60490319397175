import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { calendarStore, reservationStore } from 'stores';
import { GlobalStyle, Sidebar, ContentHeader, MainContent, Toggler } from 'components';

import CalendarIcon from 'components/Icons/CalendarIcon';
import DocumentIcon from 'components/Icons/DocumentIcon';
import { reservation as reservationRoute } from 'routes/index';

import { getNearHour } from 'utils/DateTimeUtils';
import { Box, useTheme } from '@mui/material';
import * as S from './ReservationLayout.styles';

const ReservationLayout: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();

  const goToPage = (tabId: string) => {
    reservationStore.setTab(tabId);

    const tabRoute = reservationRoute.children?.find((i) => i.path === tabId);
    if (tabRoute?.path) history.push(tabRoute?.path);
  };

  useEffect(() => {
    const tabId = history.location.pathname;
    reservationStore.setTab(tabId);
  }, []);

  const handleChangeTab = (event: React.MouseEvent<HTMLElement>, tabId: string): void => {
    goToPage(tabId);
  };

  const theme = useTheme();
  reservationStore.reservationTabs[0].icon = <DocumentIcon color={theme.palette.primary.main} />;
  reservationStore.reservationTabs[1].icon = <CalendarIcon color={theme.palette.primary.main} />;

  return (
    <S.Root>
      <GlobalStyle />

      <Sidebar />

      <S.AppContent>
        <ContentHeader
          title="Бронирование"
          btnLabel="Создать"
          btnDisabled={!(reservationStore.filter.workplaceId || calendarStore.calendarFilter.workplaceId)}
          onClick={() => {
            if (reservationStore.filter.workplaceId) reservationStore.createDialog(reservationStore.filter.workplaceId!, getNearHour());
            if (calendarStore.calendarFilter.workplaceId) reservationStore.createDialog(calendarStore.calendarFilter.workplaceId!, getNearHour());
          }}
        />

        <MainContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: 'fit-content' }}>
            <Toggler tabs={reservationStore.reservationTabs} value={reservationStore.tab} onChange={handleChangeTab} />
          </Box>

          {children}
        </MainContent>
      </S.AppContent>
    </S.Root>
  );
};

export default observer(ReservationLayout);
