import axios, { AxiosRequestConfig, Method } from 'axios';
import { userAuthStore } from 'stores';
import { toastStore } from 'components/UIKit/Toast';
import { HOST } from 'shared/constants';

export interface IHttpRequest<T> {
  path: string;
  data?: T;
  options?: AxiosRequestConfig;
  showAccessAlert?: boolean;
}

export default class HTTPService {
  private readonly basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  /**
   * GET request
   *
   * @param {string} path
   * @param {object} options
   */
  public GET<T>(path = '', options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'get', null, options);
  }

  /**
   * POST request
   *
   * @param {string} path
   * @param data {object}
   * @param {object} options
   */
  public POST<O>(path = '', data?: any, options?: AxiosRequestConfig): Promise<O> {
    return this.safeFetch<O>(`${this.basePath}/${path}`, 'post', data, options);
  }

  /**
   * PUT request
   *
   * @param {IHttpRequest} request
   */
  public PUT<I, O>(request: IHttpRequest<I>): Promise<O> {
    const { path, data, options, showAccessAlert } = request;

    return this.safeFetch<O>(`${this.basePath}/${path}`, 'put', data, options, showAccessAlert);
  }

  /**
   * DELETE request
   *
   * @param {string} path
   * @param options
   */
  public DELETE<T>(path = '', data?: any, options?: AxiosRequestConfig): Promise<T> {
    return this.safeFetch<T>(`${this.basePath}/${path}`, 'delete', data, options);
  }

  /**
   * Request
   *
   * @param {string} path
   * @param {string} method
   * @param {string} data
   * @param {Object} options
   * @param showAccessAlert
   * @returns {Object|string}
   */
  public async safeFetch<T>(path: string, method: Method, data?: any, options: AxiosRequestConfig = {}, showAccessAlert = true): Promise<T> {
    if (!options.headers) {
      options.headers = { 'Content-Type': 'application/json' };
    }
    if (options.headers && !options.headers['Content-Type']) {
      options.headers['Content-Type'] = 'application/json';
    }

    if (!options.headers?.Authorization) {
      const token = userAuthStore.token || window.localStorage.getItem('token');
      if (token) options.headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await axios.request<T>({ baseURL: HOST + '/api', url: path, method, data, ...options });

      // Показываем для всех операций изменения состояния, кроме авторизации
      let isShowSuccess = false;
      if (method === 'put' || method === 'delete' || method === 'post') isShowSuccess = true;
      //if (method === 'post' && response.data.token) isShowSuccess = false;
      if (method === 'post' && response.config.url === 'Users/auth') isShowSuccess = false;

      if (showAccessAlert && isShowSuccess) toastStore.showSuccess();

      return response.data;
    } catch (errorData: any) {
      if (method === 'post' && errorData.config.url === 'Users/auth') {
        throw errorData.response;
      }

      if (axios.isCancel(errorData)) {
        console.log(errorData);

        return new Promise((_) => _);
      }

      // Unauthorised
      if (errorData.response.status === 401) {
        window.localStorage.removeItem('token');
        userAuthStore.token = null;
        userAuthStore.isAuthUser = false;

        return new Promise((_) => _);
      }

      if (errorData.response.data.error) {
        toastStore.showError(errorData.response.data.error);
      } else {
        toastStore.showError();
      }

      throw errorData.response;
    }
  }
}
