import styled from 'styled-components/macro';

export const Root = styled.div`
  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    width: 100%;
    padding: 0;
  }
  display: flex;
  min-height: 100%;
  padding: 12px;
`;

export const AppContent = styled.div`
  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    margin: 0;
  }
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-left: 40px;
  margin-right: 28px;
`;
