import React from 'react';
import { Divider } from '@mui/material';
import * as S from './SidebarFooter.styles';

const SidebarFooter: React.FC = () => {
  return (
    <div>
      <div style={{ color: 'white', marginBottom: '20px' }}>
        <Divider style={{ opacity: '0.1', margin: '0 20px' }} sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
        <div style={{ margin: '16px 0 0 24px' }}>
          <div style={{ opacity: '0.6', marginBottom: '4px' }}>Телефон</div>
          <div>
            <strong>+7 (495) 198 09 48</strong>
          </div>
        </div>

        <Divider style={{ opacity: '0.1', margin: '16px 20px' }} sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
        <div style={{ margin: '16px 0 0 24px', fontWeight: 'bold' }}>Q:OS by Manufaqtury</div>
      </div>

      <S.FooterImg />
    </div>
  );
};

export default SidebarFooter;
