import { Link as MuiLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export const PlaceSelectorRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: fit-content;

  & > *:not(:last-child) {
    margin-right: ${(_) => _.theme.spacing(2)};
    margin-bottom: ${(_) => _.theme.spacing(2)};
  }

  & > label {
    margin-bottom: ${(_) => _.theme.spacing(-2)};
  }

  ${(_) => _.theme.breakpoints.down('sm')} {
    flex-direction: column;
    flex: 1;
    width: 100%;
    margin-bottom: 0;
  }

  ${(_) => _.theme.breakpoints.up('sm')} {
    & > div,
    span,
    a {
      margin-bottom: ${(_) => _.theme.spacing(4)};
    }
  }
`;

export const Link = styled(MuiLink)<{ disabled?: boolean }>`
  ${(_) => _.theme.breakpoints.down('sm')} {
    margin-bottom: ${(_) => _.theme.spacing(4)};
  }

  pointer-events: ${(_) => (_.disabled ? 'none' : 'pointer')};
  text-decoration: none;
`;
