import { DateRange } from '@mui/lab';
import { makeAutoObservable } from 'mobx';
import { IMessagesQuery } from 'shared/interfaces/app';
import { getDateLastWeek, getEndOfDay } from 'utils/DateTimeUtils';

class MessagesQueryModel {
  constructor(dto?: IMessagesQuery) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.pageIndex = dto.pageIndex;
    this.pageSize = dto.pageSize;
    this.from = dto.from;
    this.to = dto.to;
    this.userId = dto.userId;
    this.searchPattern = dto.searchPattern;
  }

  public pageIndex = 0;
  public pageSize = 9;
  public to: Date | null = this.queryDateRange[1];
  public from: Date | null = this.queryDateRange[0];
  public userId = '';
  public searchPattern = '';

  public setSearchPattern(pattern: string) {
    this.searchPattern = pattern;
  }

  private get queryDateRange(): DateRange<Date> {
    return [getDateLastWeek(new Date()), getEndOfDay()];
  }
}

export default MessagesQueryModel;
