import styled from 'styled-components/macro';
import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButton as MuiToggleButton } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme, orientation }) => ({
  pointerEvents: 'all',
  '& .MuiToggleButtonGroup-grouped': {
    marginRight: orientation !== 'vertical' ? theme.spacing(2) : 0,
    marginBottom: orientation === 'horizontal' ? 0 : theme.spacing(2),
    border: 0,

    '&:last-of-type': {
      marginBottom: 0,
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: '12px',
    },
    '&:first-of-type': {
      borderRadius: '12px',
    },
  },
}));

export const ToggleButton = muiStyled(MuiToggleButton)(({ theme, has_actions }) => ({
  width: '100%',
  whiteSpace: 'nowrap',
  justifyContent: !has_actions ? 'center' : 'space-between',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1, 3),
  '&.Mui-selected, &.Mui-selected:hover': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },

  [theme.breakpoints.up('md')]: {
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      opacity: 0.6,
    },
  },
}));

export const TagLabel = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: center;
  }
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TagActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.theme.spacing(2)};
  svg:first-child {
    margin-right: ${(props) => props.theme.spacing(1)};

    &:hover {
      filter: drop-shadow(0 0 5px #fff);
    }
  }

  svg:last-child {
    margin-left: ${(props) => props.theme.spacing(1)};

    &:hover {
      filter: drop-shadow(0 0 5px #fff);
    }
  }
`;
