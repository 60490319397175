import React from 'react';
import { IThemeExtendedColors } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const PassIcon: React.FC = () => {
  const theme = useTheme();
  const iconColor = (theme.palette as IThemeExtendedColors).icons;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" stroke={iconColor?.stroke} xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M13.4925 1.66666H6.50833C3.975 1.66666 2.5 3.15 2.5 5.69166V14.3C2.5 16.8833 3.975 18.3333 6.50833 18.3333H13.4925C16.0667 18.3333 17.5 16.8833 17.5 14.3V5.69166C17.5 3.15 16.0667 1.66666 13.4925 1.66666Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7333 5.54166V5.55C6.37413 5.55 6.0833 5.84166 6.0833 6.2C6.0833 6.55833 6.37413 6.85 6.7333 6.85H9.22413C9.5833 6.85 9.87497 6.55833 9.87497 6.19083C9.87497 5.83333 9.5833 5.54166 9.22413 5.54166H6.7333ZM13.2666 10.6167H6.7333C6.37413 10.6167 6.0833 10.325 6.0833 9.96666C6.0833 9.60833 6.37413 9.31583 6.7333 9.31583H13.2666C13.625 9.31583 13.9166 9.60833 13.9166 9.96666C13.9166 10.325 13.625 10.6167 13.2666 10.6167ZM13.2666 14.425H6.7333C6.4833 14.4583 6.24163 14.3333 6.1083 14.125C5.97497 13.9083 5.97497 13.6333 6.1083 13.425C6.24163 13.2083 6.4833 13.0917 6.7333 13.1167H13.2666C13.5991 13.15 13.85 13.4333 13.85 13.775C13.85 14.1075 13.5991 14.3917 13.2666 14.425Z"
        fill="white"
      />
    </svg>
  );
};

export default PassIcon;
