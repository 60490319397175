import React, { useState } from 'react';

import { IThemeExtendedColors } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const QrIcon: React.FC = () => {
  const theme = useTheme();

  const mainColor = 'rgba(0, 0, 0, 0.54)';
  const [color, setColor] = useState(mainColor);

  const onMouseOver = () => {
    setColor(theme.palette.primary.main);
  };

  const onMouseLeave = () => {
    setColor(mainColor);
  };

  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3H3V9H5V5H9V3ZM3 21V15H5V19H9V21H3ZM15 3V5H19V9H21V3H15ZM19 15H21V21H15V19H19V15ZM7 7H11V11H7V7ZM7 13H11V17H7V13ZM17 7H13V11H17V7ZM13 13H17V17H13V13Z"
        fill={color}
      />
    </svg>
  );
};

export default QrIcon;
