import styled from 'styled-components/macro';

export const MainContentWrapper = styled.main`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.common.white};
  height: 100%;
  border-radius: 32px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
    margin: 0 ${(props) => props.theme.spacing(2)};
    margin-bottom: ${(props) => props.theme.spacing(3)};
  }

  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)};
    margin: 0 ${(props) => props.theme.spacing(2)};
    margin-left: ${(props) => props.theme.spacing(10)};
    margin-right: ${(props) => props.theme.spacing(10)};
    margin-bottom: ${(props) => props.theme.spacing(2)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(5)} ${(props) => props.theme.spacing(6)};
  }
`;
