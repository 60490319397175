import { makeAutoObservable } from 'mobx';
import { userAuthStore, usersStore, zonesStore } from 'stores';
import { CompaniesApi } from 'api';
import { DEFAULT_COMPANY_ID } from 'shared/constants';
import { DialogStatus } from 'shared/enums';
import { IDropDownOption, IDepartmentTableRow } from 'shared/interfaces/app';
import { DepartmentModel, DepartmentListModel } from 'shared/models';

class DepartmentsStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public departments: DepartmentListModel[] = [];

  public selectedDepartment = new DepartmentModel();

  public selectedCompanyId = '';

  public filterState = '';

  public dialogViewMode = DialogStatus.Closed;

  public isFetching = false;

  public get departmentsForTable(): IDepartmentTableRow[] {
    const departmentsData = this.departments.map(({ id, departmentName, companyName }) => {
      return { id, departmentName, companyName };
    });

    if (this.filterState !== '') {
      return departmentsData.filter(({ departmentName, companyName }) => {
        const nameFilter = departmentName.toLocaleLowerCase().includes(this.filterState.toLocaleLowerCase());
        const companyNameFilter = companyName?.toLocaleLowerCase().includes(this.filterState.toLocaleLowerCase());
        return nameFilter || companyNameFilter;
      });
    }

    return departmentsData;
  }

  public get departmentsForOptions(): IDropDownOption[] {
    return this.departments.map((item) => item.getDropDownOption());
  }

  public get companyId() {
    if (!this.selectedCompanyId && userAuthStore.companyId) {
      return userAuthStore.companyId;
    }
    return this.selectedCompanyId;
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setFilterState(pattern: string) {
    this.filterState = pattern;
  }

  public async setCreatingMode() {
    await zonesStore.getZonesList();
    usersStore.getUsersList();
    this.selectedDepartment = new DepartmentModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public async setEditingMode(departmentId: string) {
    await this.getDepartmentById(departmentId);
    await zonesStore.getZonesList();
    usersStore.getUsersList();
    this.setDialogViewMode(DialogStatus.Editing);
  }

  public async setDeletingMode(departmentId: string) {
    await this.getDepartmentById(departmentId);
    this.setDialogViewMode(DialogStatus.Deleting);
  }

  public closeDepartmentsDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public async getDepartmentsList() {
    try {
      this.isFetching = true;
      const departments = await CompaniesApi.getDepartments(DEFAULT_COMPANY_ID);
      if (!departments) return;
      this.departments = departments.map((item) => new DepartmentListModel(item));
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getDepartmentById(departmentId: string) {
    try {
      this.isFetching = true;
      const department = await CompaniesApi.getDepartmentById(DEFAULT_COMPANY_ID, departmentId);
      if (!department) return;
      this.selectedDepartment = new DepartmentModel(department);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async createDepartment(): Promise<void> {
    // if (this.selectedDepartment.id === null || this.companyId === null) return;
    if (this.selectedDepartment.postPutDto === null) return;
    try {
      this.isFetching = true;
      const newDepartment = await CompaniesApi.postDepartment(DEFAULT_COMPANY_ID, this.selectedDepartment.postPutDto);
      if (!newDepartment) return;
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updateDepartment(): Promise<void> {
    if (this.selectedDepartment.id === null || this.companyId === null) return;
    if (this.selectedDepartment.postPutDto === null) return;
    try {
      this.isFetching = true;
      await CompaniesApi.putDepartment(DEFAULT_COMPANY_ID, this.selectedDepartment.id, this.selectedDepartment.postPutDto);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async removeDepartment(): Promise<void> {
    if (this.selectedDepartment.id === null || this.companyId === null) return;
    try {
      this.isFetching = true;
      await CompaniesApi.deleteDepartment(DEFAULT_COMPANY_ID, this.selectedDepartment.id);
      this.getDepartmentsList();
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public clear() {
    this.departments = [];
  }
}

export default new DepartmentsStore();
