import styled from 'styled-components/macro';

import { Button as MuiButton, Typography as MuiTypography } from '@mui/material';

export const Button = styled(MuiButton)`
  justify-content: flex-start;
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(4)};
  width: 100%;
`;

export const Content = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  width: 528px;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.spacing(4)};
`;

export const SaveButton = styled(MuiButton)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing(4)};
  width: 528px;
`;

export const CheckEmailButton = styled(MuiButton)<{ validatedEmail: boolean }>`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    margin-top: ${(props) => props.theme.spacing(4)};
    margin-left: 0;
  }
  margin-top: ${(props) => (props.validatedEmail ? props.theme.spacing(1) : props.theme.spacing(8))};
  margin-left: ${(props) => props.theme.spacing(4)};
`;

export const EmailWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    flex-direction: column;
    margin-bottom: ${(_) => _.theme.spacing(9)};
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 528px;
  margin-bottom: ${(_) => _.theme.spacing(4)};
`;

export const Typography = styled(MuiTypography)`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
`;
