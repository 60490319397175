import React from 'react';
import { observer } from 'mobx-react';
import { workPlacesStore, rolesStore } from 'stores';
import { Pagination } from 'components';
import Trash from 'components/Icons/Trash';
import { WORKPLACE_IMAGES_PER_PAGE } from 'shared/constants';
import { Permissions } from 'shared/enums';
import { WorkPlaceImageModel } from 'shared/models';
import { Box } from '@mui/material';

import * as S from './WorkPlaceImages.styles';

const WorkPlaceImages: React.FC = () => {
  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    workPlacesStore.selectedWorkPlace.setImagesPage(page);
  };

  const onDeleteImage = async (image: WorkPlaceImageModel) => {
    const idx = workPlacesStore.selectedWorkPlace.workPlaceImages.findIndex((_) => _.id === image.id);
    if (idx !== -1) {
      workPlacesStore.selectedWorkPlace.workPlaceImages.splice(idx, 1);
      if (image.name) workPlacesStore.selectedWorkPlace.setRemoveImageIds(image.id);
    }
  };

  const workplaceGalaryUpdatingAllow = rolesStore.isAllow(Permissions.WorkplaceGalaryUpdating);

  return (
    <>
      <S.Wrapper>
        {workPlacesStore.selectedWorkPlace.workPlaceImages.length > 0 &&
          workPlacesStore.selectedWorkPlace.workPlaceImagesOnPage.map((image) => (
            <S.Images key={image.id}>
              <S.Image src={image.name ? image.imageURL : image.image} alt="photo" />

              {workplaceGalaryUpdatingAllow && (
                <S.Actions onClick={() => onDeleteImage(image)}>
                  <Trash />
                </S.Actions>
              )}
            </S.Images>
          ))}
      </S.Wrapper>

      <Box sx={{ display: 'flex', justifyContent: 'center', bottom: 0 }}>
        <Pagination
          rows={workPlacesStore.selectedWorkPlace.workPlaceImages.length}
          pageSize={WORKPLACE_IMAGES_PER_PAGE}
          pageIndex={workPlacesStore.selectedWorkPlace.imagesPage}
          onChangePage={onChangePage}
        />
      </Box>
    </>
  );
};

export default observer(WorkPlaceImages);
