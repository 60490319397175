import React, { useEffect, useRef, useState } from 'react';
import { CRS } from 'leaflet';
import { observer } from 'mobx-react';
import { appStore, mapStore, workPlacesStore } from 'stores';
import { Accordion, ContentHeader, DateTimePicker, MainContent, MapContext, ReservationMapLegend, TagImages } from 'components';
import { IPosition } from 'shared/interfaces/app';

import { getBookingTimeInterval, replaceHourMinute, utcToLocal } from 'utils/DateTimeUtils';
import { useBreakpoint } from 'utils/hooks';
import { Divider, SwipeableDrawer } from '@mui/material';
import { FloorType } from '../../shared/enums';
import { ReservationDialog } from '../Reservations/Components';
import { CommentDialog, Panel, MainFilters, WorkPlaceInfo, WorkPlacesAvailableBooking } from './Components';
import Tour from './Components/Tour';

import * as S from './Main.styles';

const Main: React.FC = () => {
  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';
  const isDesktop = bp === 'lg';
  const isLarge = bp === 'xl';
  const drawerBleeding = 25;

  const isShowSwipeLegend = isMobile || isTablet;

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [heightMap, setHeightMap] = useState<string>('100%');

  /**
   * Изменение высоты контейнера
   */
  useEffect(() => {
    if (!mapContainerRef.current) return;
    setHeightMap(mapContainerRef.current.clientHeight + 'px');
  }, [mapContainerRef.current]);

  useEffect(() => {
    const { fromTime } = workPlacesStore.queryFilterParams;

    const bookingTimeInterval = getBookingTimeInterval(fromTime);
    workPlacesStore.queryFilterParams.setTimeInterval(bookingTimeInterval.fromTime, bookingTimeInterval.toTime);

    // Для dev период 10 сек. Для prod - 1 мин
    const timeIntervalSec = process.env.REACT_APP_IS_DEVELOPMENT === 'true' ? 10 : 60;
    const interval = setInterval(() => {
      if (!workPlacesStore.isTourEnable) workPlacesStore.getWorkPlacesAvailableBookingList().finally();
    }, 1000 * timeIntervalSec);

    return () => {
      workPlacesStore.setTourEnable(false);
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    workPlacesStore.workPlaceSelectedId = null;
    setFlyPosition(null);

    // Получем данные из url
    const url = new URL(document.location.href);
    const typeId = parseInt(url.searchParams.get('typeId') || '1') || 1;
    const workplaceId = url.searchParams.get('workplaceId');
    const fromTimeInDay = utcToLocal(url.searchParams.get('fromTimeInDay') ?? '');
    const toTimeInDay = utcToLocal(url.searchParams.get('toTimeInDay') ?? '');
    url.searchParams.delete('workplaceId');
    url.searchParams.delete('typeId');
    url.searchParams.delete('fromTimeInDay');
    url.searchParams.delete('toTimeInDay');
    window.history.replaceState(null, 'key', url.href);

    const load = async () => {
      // Если нет объекта, то грузим первый этаж
      if (!workplaceId) {
        await mapStore.initMap(0).finally();
        return;
      }

      // Установка времени внутри дня
      if (fromTimeInDay && toTimeInDay) {
        const baseDate = workPlacesStore.queryFilterParams.fromTime;
        const fromTime = replaceHourMinute(new Date(fromTimeInDay), baseDate);
        const toTime = replaceHourMinute(new Date(toTimeInDay), baseDate);
        workPlacesStore.queryFilterParams.setTimeInterval(fromTime, toTime);
      }

      // Получаем этажи
      mapStore.selectedFloorType = typeId === 2 ? FloorType.Parking : FloorType.Floor;
      mapStore.floorTypes[0].selected = false;
      mapStore.floorTypes[1].selected = false;
      const floorType = mapStore.floorTypes.find((_) => _.value === mapStore.selectedFloorType.toString());
      if (floorType) floorType.selected = true;

      await mapStore.getFloorsList();

      // Получаем Workplace
      await workPlacesStore.getWorkPlaceById(workplaceId);
      if (!workPlacesStore.selectedWorkPlace) return;

      // Координаты куда лететь, летим к объекту
      setFlyPosition(workPlacesStore.selectedWorkPlace.position);

      // Устанавливаем нужный этаж
      mapStore.selectedFloor.setFloorImageId(workPlacesStore.selectedWorkPlace.floorId);
      const floorIndex = mapStore.floors.findIndex((_) => _.id === mapStore.selectedFloor.id);
      mapStore.setActiveFloor(floorIndex);

      // Получаем карты и выставляем 1 карту
      await mapStore.getFloorImagesList();
      mapStore.setActiveMap(0);

      // Получаем параметры карты и размещенные объекты
      await Promise.all([mapStore.getFloorImageById(), workPlacesStore.getWorkPlacesAvailableBookingList()]);

      // Устанавливаем выделение объекта
      const workplace = workPlacesStore.workPlacesAvailableBooking.find((_) => _.id === workplaceId);
      if (!workplace) return;
      workPlacesStore.workPlaceSelectedId = workplaceId;
      workplace.isSelected = true;

      setFlyPosition(null);
    };

    load().finally();

    return () => {
      mapStore.selectedFloorImage.clearMapId();
      mapStore.selectedFloorImage.clear();
      workPlacesStore.clear();
    };
  }, []);

  const [flyPosition, setFlyPosition] = useState<IPosition | null>();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const onDeselectMarker = () => {
    if (!workPlacesStore.workPlaceSelectedId) return;

    const workplace = workPlacesStore.workPlacesAvailableBooking.find((_) => _.id === workPlacesStore.workPlaceSelectedId);
    if (!workplace) return;

    workplace.isSelected = false;
    workPlacesStore.workPlaceSelectedId = null;
    setFlyPosition(null);
  };

  const onChangeFloorType = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value !== null) {
      mapStore.selectedFloorType = parseInt(value);

      mapStore.floorTypes.forEach((item) => {
        if (item.selected) {
          item.selected = false;
        }
      });

      const index = mapStore.floorTypes.findIndex((item) => item.value === value);
      if (index > -1) {
        mapStore.floorTypes[index].selected = true;
      }

      await mapStore.initMap(0).finally();
    }
  };

  const onChangeDate = (fromTime?: Date | null, toTime?: Date | null) => {
    if (!fromTime || !toTime) return;
    workPlacesStore.queryFilterParams.setTimeInterval(fromTime, toTime);
    workPlacesStore.getWorkPlacesAvailableBookingList().finally();
  };

  return (
    <>
      <ContentHeader title="Главная" />
      <MainContent>
        <>
          {isMobile ? (
            <>
              <Accordion title="Выбрать этаж и время">
                <MainFilters />
              </Accordion>
              <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
            </>
          ) : (
            <S.FilterWrapper>
              <TagImages
                tags={mapStore.floorTypes}
                onChange={onChangeFloorType}
                onTagChange={() => {
                  // ignore
                }}
              />

              <S.DateTimePickerWrapper>
                <DateTimePicker
                  timeFrom={workPlacesStore.queryFilterParams.fromTime}
                  timeTo={workPlacesStore.queryFilterParams.toTime}
                  onChange={onChangeDate}
                />
              </S.DateTimePickerWrapper>
            </S.FilterWrapper>
          )}

          {/*{!isMobile ? <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem /> : null}*/}

          <S.MapContentWrapper ref={mapContainerRef}>
            {/* Переключатель этажей */}
            <Panel />
            {workPlacesStore.selectedWorkPlace.id && <WorkPlaceInfo />}

            {mapStore.selectedFloorImage.imageName && (
              <S.MapContainer
                height={heightMap}
                crs={CRS.Simple}
                maxBoundsViscosity={0.9}
                center={mapStore.mapState.startedCoordinate}
                zoomControl={false}
                fadeAnimation={false}
                scrollWheelZoom={false}
                zoomSnap={0.1}
                maxZoom={0}
                minZoom={-2}>
                {workPlacesStore.isTourEnable && <Tour />}
                {!workPlacesStore.isTourEnable && !!mapStore.selectedFloorImage.imageBase64 && (
                  <MapContext floorImageModel={mapStore.selectedFloorImage} selectedPoint={flyPosition} onMapClick={onDeselectMarker} />
                )}

                {!workPlacesStore.isTourEnable && <WorkPlacesAvailableBooking />}
              </S.MapContainer>
            )}
          </S.MapContentWrapper>

          {isShowSwipeLegend && (
            <SwipeableDrawer
              anchor="bottom"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              swipeAreaWidth={drawerBleeding}
              disableSwipeToOpen={false}
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: '#fff',
                  zIndex: 15,
                },
              }}
              ModalProps={{
                keepMounted: true,
              }}>
              <S.StyledBox
                sx={{
                  position: 'absolute',
                  top: -drawerBleeding,
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                  visibility: !appStore.visibleSidebar ? 'visible' : 'unset',
                  right: 0,
                  left: 0,
                }}>
                <S.Puller />
                <S.MapLegendTitle></S.MapLegendTitle>
              </S.StyledBox>
              <S.StyledBox
                sx={{
                  mt: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  px: 2,
                  height: '100%',
                  overflow: 'auto',
                }}>
                {!workPlacesStore.isTourEnable && (
                  <ReservationMapLegend
                    freeCount={workPlacesStore.workPlacesTotalCount.freeWorkplacesCount}
                    reservedCount={workPlacesStore.workPlacesTotalCount.reservedWorkplacesCount}
                    totalCount={workPlacesStore.workPlacesTotalCount.totalWorkplacesCount}
                  />
                )}
              </S.StyledBox>
            </SwipeableDrawer>
          )}
        </>
      </MainContent>
      <ReservationDialog />
      <CommentDialog />
    </>
  );
};

export default observer(Main);
