import styled from 'styled-components/macro';
import { MenuItem as MuiMenuItem } from '@mui/material';

export const MenuItem = styled(MuiMenuItem)``;

export const Wrapper = styled.div`
  & > div:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
