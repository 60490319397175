import { makeAutoObservable } from 'mobx';
import { RolesApi } from 'api';
import {
  CompanyActions,
  DepartmentActions,
  DialogStatus,
  MapConstructorActions,
  MapActions,
  PassActions,
  Permissions,
  ReservationActions,
  RoleActions,
  UserActions,
  ViewModulesName,
  ZoneActions,
  MessageActions,
} from 'shared/enums';
import { IDropDownOption, IPermissionsForSwitchList, IRolesTableRow } from 'shared/interfaces/app';
import { RoleListModel, RoleModel } from 'shared/models';
import { userAuthStore } from './index';

class RolesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public roles: RoleListModel[] = [];

  public selectedRole = new RoleModel();

  public permissions: string[] = [];

  public dialogViewMode = DialogStatus.Closed;

  public isFetching = false;

  public get rolesForTable(): IRolesTableRow[] {
    return this.roles.map(({ id, name }) => {
      return { id, name };
    });
  }

  public get permissionsForSwitchList(): IPermissionsForSwitchList[] {
    const listOfPermissions: IPermissionsForSwitchList[] = [];
    ViewModulesName.forEach((permissionName: string, permissionId: Permissions) => {
      listOfPermissions.push({
        permissionId,
        permissionName,
        checked: permissionId.startsWith('_') ? null : false,
      });
    });

    listOfPermissions.map((item: IPermissionsForSwitchList) => {
      this.permissions.forEach((element: string) => {
        if (item.permissionId === element) item.checked = true;
      });
    });

    return listOfPermissions;
  }

  public isAllow(permission?: Permissions): boolean {
    if (userAuthStore.isAdmin) return true;
    if (!permission) return false;
    if (!userAuthStore.permissionsIds) return false;

    return userAuthStore.permissionsIds.includes(permission);
  }

  public get companyActions(): string[] {
    return this.permissions.filter((per) => CompanyActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get departmentActions(): string[] {
    return this.permissions.filter((per) => DepartmentActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get roleActions(): string[] {
    return this.permissions.filter((per) => RoleActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get zoneActions(): string[] {
    return this.permissions.filter((per) => ZoneActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get userActions(): string[] {
    return this.permissions.filter((per) => UserActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get passActions(): string[] {
    return this.permissions.filter((per) => PassActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get mapConstructorActions(): string[] {
    return this.permissions.filter((per) => MapConstructorActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get mapActions(): string[] {
    return this.permissions.filter((per) => MapActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get reservationActions(): string[] {
    return this.permissions.filter((per) => ReservationActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get messageActions(): string[] {
    return this.permissions.filter((per) => MessageActions.map((_) => _.id.toString()).indexOf(per) >= 0);
  }

  public get rolesForOptions(): IDropDownOption[] {
    return this.roles.map((item) => item.getDropDownOption());
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setActionPermission(event: React.MouseEvent<HTMLElement>, value: string) {
    const idx = this.permissions.indexOf(value);
    if (idx !== -1) {
      this.permissions.splice(idx, 1);
    } else {
      this.permissions.push(value);
    }
  }

  public setModulePermission(checked: boolean, permissionId: string) {
    if (checked) {
      this.permissions.push(permissionId);
    } else {
      const idx = this.permissions.indexOf(permissionId);
      if (idx !== -1) {
        this.permissions.splice(idx, 1);
      }
    }
  }

  public async setCreatingMode() {
    this.selectedRole = new RoleModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public async setEditingMode(roleId: string) {
    await this.getRoleById(roleId);
    await this.getPermissions(roleId);
    this.setDialogViewMode(DialogStatus.Editing);
  }

  public closeRoleDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public async getRolesList(companyId?: string) {
    try {
      this.isFetching = true;
      const roles = await RolesApi.getRoles(companyId);
      if (!roles) return;
      this.roles = roles.map((item) => new RoleListModel(item));
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getRoleById(roleId: string) {
    try {
      this.isFetching = true;
      const role = await RolesApi.getRole(roleId);
      if (!role) return;
      this.selectedRole = new RoleModel(role);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getPermissions(roleId: string) {
    try {
      this.isFetching = true;
      const result = await RolesApi.getPermissions(roleId);
      if (!result) return;
      this.permissions = result;
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async createRole(): Promise<void> {
    if (this.selectedRole.postPutDto === null) return;
    try {
      this.isFetching = true;
      const newRoleId = await RolesApi.postRole(this.selectedRole.postPutDto);
      if (!newRoleId) return;
      this.selectedRole.id = newRoleId;
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updateRole(): Promise<void> {
    if (this.selectedRole.postPutDto === null || this.selectedRole.id === null) return;
    try {
      this.isFetching = true;
      await RolesApi.putRole(this.selectedRole.id, this.selectedRole.postPutDto);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updatePermissions(): Promise<void> {
    if (this.selectedRole.id === null) return;
    try {
      this.isFetching = true;
      await RolesApi.putPermission(this.selectedRole.id, this.permissions);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async removeRole(roleId?: string): Promise<void> {
    const id = roleId ? roleId : this.selectedRole.id;
    try {
      this.isFetching = true;
      await RolesApi.deleteRole(id);
      await this.getRolesList();
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public clearRoleList() {
    this.roles = [];
  }

  public clearPermissionList() {
    this.permissions = [];
  }
}

export default new RolesStore();
