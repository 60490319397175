import { Typography } from '@mui/material';
import { Badge } from 'components';
import React from 'react';

import * as S from './ReservationMapLegend.styles';

interface IReservationMapLegendProps {
  freeCount: number;
  reservedCount: number;
  totalCount: number;
}

const ReservationMapLegend: React.FC<IReservationMapLegendProps> = (props) => {
  const { freeCount, reservedCount, totalCount } = props;
  return (
    <S.WorkPlaceCount>
      <Typography sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', m: 1 }}>
        Свободно
        <Badge bgColor="#00B8B0" count={freeCount} />
      </Typography>
      <Typography sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', m: 1 }}>
        Забронировано
        <Badge bgColor="#003A5D" count={reservedCount} />
      </Typography>
      <Typography sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', m: 1 }}>
        Всего мест
        <Badge bgColor="#B3C4CE" count={totalCount} />
      </Typography>
    </S.WorkPlaceCount>
  );
};

export default ReservationMapLegend;
