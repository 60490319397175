import { makeAutoObservable } from 'mobx';
import { companiesStore } from 'stores';
import { ColorPickStatus } from 'shared/enums';
import { ISendEmailDto, THEME_TYPE } from 'shared/interfaces/api';
import { ICompanies, ICompanyDialog, IDropDownOption } from 'shared/interfaces/app';
import ThemeOptionsModel from 'shared/models/ThemeOptionsModel';
import { getImgSize, isValidLogo, toBase64 } from 'utils/FileUtils';

class CompaniesModel {
  constructor(companiesData?: ICompanies) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (companiesData) {
      this.id = companiesData?.id;
      this.name = companiesData.name;
      this.isCustom = companiesData.themeType === THEME_TYPE.CUSTOMIZED;
      this.themeData = companiesData?.themeData;
      this.themeType = companiesData.themeType;
      this.departmentsCount = companiesData?.departmentsCount;
      this.logoImage = companiesData?.logoImage;
      this.logoImageString = companiesData?.logoImageString ?? '';
      this.isDeleted = companiesData?.isDeleted;
      this.smtpParamsHost = companiesData?.smtpParams?.host ?? '';
      this.smtpParamsPort = companiesData?.smtpParams?.port ?? 0;
      this.smtpParamsUserName = companiesData?.smtpParams?.userName ?? '';
      this.smtpParamsPassword = companiesData?.smtpParams?.password ?? '';
      this.smtpParamsUseSsl = companiesData?.smtpParams?.useSsl ?? false;
    }
  }

  public id: string | undefined = undefined;

  public name = '';

  public isCustom = false;

  public themeData: ThemeOptionsModel | undefined = new ThemeOptionsModel();

  public selectedColorKey = '';

  public colorPickState = ColorPickStatus.Background;

  public themeType: THEME_TYPE = 0;

  public smtpParamsHost = '';

  public smtpParamsPort = 0;

  public smtpParamsUserName = '';

  public smtpParamsPassword = '';

  public smtpParamsUseSsl = true;

  public smtpParamsEmail = '';

  public departmentsCount: number | undefined = 0;

  public logoImage: File | undefined = undefined;

  public logoImageString = '';

  public isValidLogo = true;

  public isDeleted: boolean | undefined = false;

  public selectedFormData: FormData = new FormData();

  public get postPutDto(): FormData {
    this.selectedFormData.append('name', this.name);
    this.selectedFormData.append('themeType', this.themeType.toString());
    if (this.themeData) {
      this.selectedFormData.append(
        'themeData',
        JSON.stringify({
          mainColor: this.themeData!.mainColor,
          statusColor: this.themeData!.statusColor,
          backgroundColor: this.themeData!.backgroundColor,
          fontColor: this.themeData!.fontColor,
        })
      );
    }
    this.selectedFormData.append('logoImage', this.logoImage ?? '');
    this.selectedFormData.append('smtpParams.Host', this.smtpParamsHost);
    this.selectedFormData.append('smtpParams.Port', this.smtpParamsPort!.toString());
    this.selectedFormData.append('smtpParams.UserName', this.smtpParamsUserName);
    this.selectedFormData.append('smtpParams.Password', this.smtpParamsPassword);
    this.selectedFormData.append('smtpParams.UseSsl', this.smtpParamsUseSsl.toString());

    return this.selectedFormData;
  }

  public get isValidSendEmail(): boolean {
    return Boolean(this.smtpParamsHost && this.smtpParamsPort && this.smtpParamsUserName && this.smtpParamsPassword && this.smtpParamsEmail);
  }

  public setValidLogo(isValid: boolean) {
    this.isValidLogo = isValid;
  }

  public setSelectedColor(color: string) {
    if (!this.themeData) this.themeData = new ThemeOptionsModel();

    const key = this.selectedColorKey!;

    switch (this.colorPickState) {
      case ColorPickStatus.Main:
        this.themeData.mainColor[key] = color;
        break;

      case ColorPickStatus.Status:
        this.themeData.statusColor[key] = color;
        break;

      case ColorPickStatus.Font:
        this.themeData.fontColor[key] = color;
        break;

      case ColorPickStatus.Background:
        this.themeData.backgroundColor[key] = color;
    }
  }

  public async uploadLogo(event: any): Promise<void> {
    if (event.target.files) {
      this.logoImage = isValidLogo(event.target.files[0]) ? event.target.files[0] : this.setValidLogo(false);
    } else if (event.dataTransfer.files) {
      this.logoImage = isValidLogo(event.dataTransfer.files[0]) ? event.dataTransfer.files[0] : this.setValidLogo(false);
    } else this.setValidLogo(false);

    this.logoImageString = await toBase64(this.logoImage!);
    this.setValidLogo(true);
  }

  public getSendEmailDto(model: ICompanyDialog): ISendEmailDto {
    return {
      smtpParams: {
        host: model.smtpParamsHost,
        userName: model.smtpParamsUserName,
        port: model.smtpParamsPort,
        password: model.smtpParamsPassword,
        useSsl: model.smtpParamsUseSsl,
      },
      email: companiesStore.selectedCompany.smtpParamsEmail,
    };
  }

  public updateFromDialogForm(model: ICompanyDialog) {
    this.name = model.name;
    this.themeType = model.isCustom ? THEME_TYPE.CUSTOMIZED : THEME_TYPE.DEFAULT;
    this.logoImage = model.logoImageString !== this.logoImageString ? model.logoImage : this.logoImage;
    this.smtpParamsHost = model.smtpParamsHost;
    this.smtpParamsPort = model.smtpParamsPort;
    this.smtpParamsUserName = model.smtpParamsUserName;
    this.smtpParamsPassword = model.smtpParamsPassword;
    this.smtpParamsUseSsl = model.smtpParamsUseSsl;
  }

  public getDropDownOption(): IDropDownOption {
    return { id: this.id!, name: this.name };
  }

  public clear() {
    this.id = undefined;
    this.name = '';
    this.smtpParamsHost = '';
    this.smtpParamsPort = 0;
    this.smtpParamsUserName = '';
    this.smtpParamsPassword = '';
    this.smtpParamsUseSsl = true;
  }
}

export default CompaniesModel;
