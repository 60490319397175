import styled from 'styled-components/macro';
import { IconButton as MuiIconButton, Tooltip as MuiTooltip } from '@mui/material';

export const IconButton = styled(MuiIconButton)`
  opacity: 0.5;
  &:hover {
    background-color: #aaaaaa44;
    opacity: 1;
  }
`;

export const Tooltip = styled(MuiTooltip)``;
