import { FloorType } from 'shared/enums';
import { IFloorGetDto, IFloorImageGetDto, IFloorImagesGetDto, IFloorPostPutDto, IFloorsGetDto } from 'shared/interfaces/api';
import HTTPService, { IHttpRequest } from './HTTPService';

class FloorsApi extends HTTPService {
  public constructor() {
    super('floors');
  }

  /*  Floors */
  public getFloors(type: FloorType | null): Promise<IFloorsGetDto[]> {
    if (type === null) return this.GET(``);
    return this.GET(`?Type=${type}`);
  }

  public getFloor(id: string): Promise<IFloorGetDto> {
    if (!id) return new Promise((_) => _);

    return this.GET(`${id}/`);
  }

  public postFloor(newFloor: IFloorPostPutDto): Promise<string> {
    return this.POST('', newFloor);
  }

  public putFloor(id: string, data: IFloorPostPutDto): Promise<void> {
    const request: IHttpRequest<IFloorPostPutDto> = {
      path: `${id}/`,
      data,
    };
    return this.PUT<IFloorPostPutDto, void>(request);
  }

  public deleteFloor(id: string): Promise<void> {
    return this.DELETE(`${id}/`);
  }

  /*  FloorImages */
  public getFloorImages(id: string): Promise<IFloorImagesGetDto[]> {
    if (!id) return new Promise((_) => _([]));

    return this.GET(`${id}/maps`);
  }

  public getFloorImage(floorId: string, mapId: string): Promise<IFloorImageGetDto> {
    if (!floorId || !mapId) return new Promise((_) => _);

    return this.GET(`${floorId}/maps/${mapId}/`);
  }

  public postFloorImage(floorId: string, formData: FormData): Promise<string> {
    return this.POST(`${floorId}/maps`, formData);
  }

  public putFloorImage(floorId: string, id: string, data: FormData): Promise<null> {
    const request: IHttpRequest<FormData> = {
      path: `${floorId}/maps/${id}`,
      data,
    };
    return this.PUT<FormData, null>(request);
  }

  public deleteFloorImage(floorId: string, id: string): Promise<void> {
    return this.DELETE(`${floorId}/maps/${id}`);
  }
}

export default new FloorsApi();
