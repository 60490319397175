import styled from 'styled-components/macro';
import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButton as MuiToggleButton } from '@mui/material';

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)`
  .MuiToggleButtonGroup-grouped {
    border: 0;
    .Mui-disabled {
      border: 0;
    }
    &:not(:first-of-type) {
      border-radius: 16px;
    }
    &:first-of-type {
      border-radius: 16px;
    }
  }

  button {
    margin-left: ${(_) => _.theme.spacing(2)};
  }
`;
