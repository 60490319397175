import React from 'react';
import { addMinutes } from 'date-fns';
import { observer } from 'mobx-react';
import { reservationStore as store, rolesStore, userAuthStore, workPlacesStore } from 'stores';
import { ModalDialog, Title, WorkPlaceAttr } from 'components';
import DateTimePicker from 'components/UIKit/DateTimePicker';
import UserPanel from 'pages/Main/Components/UserPanel';
import { Permissions, WorkPlaceType } from 'shared/enums';
import { getTimeOnly } from 'utils/DateTimeUtils';
import { useBreakpoint } from 'utils/hooks';
import { reservationStatus } from 'utils/ReservationStatus';
import { Button, Divider } from '@mui/material';
import * as S from './ReservationDialog.styles';

const ReservationDialog: React.FC = () => {
  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const data = store.reservation;
  if (!data) return null;

  const closeDialog = () => {
    store.isShowDialog = false;
  };

  const confirm = async () => {
    closeDialog();
    data.id && (await store.confirm(data.id));
  };

  const create = async () => {
    try {
      data.workplaceId && (await store.create());
      // Обновление маркеров на карте, если бронирование создалось с карты
      if (workPlacesStore.workPlacesAvailableBooking.length > 0) {
        workPlacesStore.getWorkPlacesAvailableBookingList();
      }

      if (store.isScheduleOpened) {
        // Автоматическое подтверждение бронирования, если бронирование создалось с календаря рабочего места(переговорки)
        await store.confirm(store.reservationId);
      }

      closeDialog();
    } catch (e) {
      //ignore
    }
  };

  const cancel = async () => {
    closeDialog();
    data.id && (await store.cancel(data.id));
  };

  const shift = async () => {
    closeDialog();
    data.id && (await store.shift());
  };

  const update = async () => {
    closeDialog();
    data.id && (await store.update());
  };

  const onChangeDate = (fromTime?: Date | null, toTime?: Date | null) => {
    data.setTimeInterval(fromTime, toTime);
  };

  let title = '';
  if (data.workplaceType != null) {
    title = (data.workplaceType.toString() === WorkPlaceType.WorkPlace ? 'Рабочее место ' : 'Переговорная ') + data.workplaceNumber;
  }

  const isMyBooking = userAuthStore.userId === data.userId || rolesStore.isAllow();
  const isCreateBooking = data.isCreateBooking;
  const isFilledTime = data.fromTime && data.toTime;
  const isAbilityUpdate = isMyBooking && !isCreateBooking && isFilledTime;
  const isDisableFromTime = isMyBooking && !isCreateBooking && isFilledTime && data.status === reservationStatus.accepted;

  const showAcceptQrButton = isAbilityUpdate && [reservationStatus.pending, reservationStatus.extended].includes(data.status);
  const showAcceptManualButton = showAcceptQrButton && rolesStore.isAllow(Permissions.ReservationConfirmationWithoutQrCode);
  const showExtendedButton = isAbilityUpdate && data.status === reservationStatus.pending;
  const showUpdateButton = isAbilityUpdate;
  const showCancelButton = isAbilityUpdate;

  const Line = () => <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />;

  return (
    <ModalDialog
      width={isMobile ? '300px' : '550px'}
      title={title}
      isValid={true}
      isOpen={store.isShowDialog}
      onClose={closeDialog}
      onCancel={closeDialog}>
      {/* Заголовок */}
      <div>{data.reservationText}</div>
      {data.statusText && <>{data.statusText}</>}

      {/* Кем забронировано */}
      {data.userId && (
        <>
          <Line />
          <UserPanel userId={data.userId}></UserPanel>
        </>
      )}

      {/* Описание места */}
      {!!store.reservation?.attributes && (
        <>
          <Line />
          <S.WorkPlaceWrapper>
            <WorkPlaceAttr attributes={store.reservation.attributes} />
          </S.WorkPlaceWrapper>
        </>
      )}

      {/* Комментарий админа */}
      {!!store.reservation?.comment ? (
        <>
          <Line />
          <div style={{ maxHeight: '1000px', overflowY: 'auto' }}>{store.reservation.comment}</div>
          <Line />
        </>
      ) : (
        <Line />
      )}

      {/* Время */}
      <Title label="Дата и время" />
      <S.DatePickerWrapper>
        <DateTimePicker isDisableFromTime={isDisableFromTime} timeFrom={data.fromTime} timeTo={data.toTime} onChange={onChangeDate} />
      </S.DatePickerWrapper>

      {/* Кнопки */}
      {isCreateBooking && (
        <Button disabled={!isFilledTime} color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" onClick={create}>
          Забронировать
        </Button>
      )}
      {showExtendedButton && (
        <Button color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" onClick={shift}>
          Продлить подтверждение до {getTimeOnly(addMinutes(data.fromTime, 30))}
        </Button>
      )}
      {showUpdateButton && (
        <Button color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" onClick={update}>
          Сохранить изменения времени бронирования
        </Button>
      )}
      {showAcceptManualButton && (
        <Button color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" onClick={confirm}>
          Подтвердить бронирование вручную
        </Button>
      )}
      {showAcceptQrButton && (
        <Button color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" onClick={() => store.confirmQrDialog()}>
          Подтвердить бронирование QR кодом
        </Button>
      )}
      {showCancelButton && (
        <Button color="error" sx={{ mb: 5 }} fullWidth variant="contained" size="large" onClick={cancel}>
          Отменить бронирование
        </Button>
      )}
      <Button variant="outlined" fullWidth size="large" onClick={closeDialog}>
        Закрыть
      </Button>
    </ModalDialog>
  );
};

export default observer(ReservationDialog);
