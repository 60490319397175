import React, { ReactElement } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useBreakpoint } from 'utils/hooks';
import * as S from './Pagination.styles';

interface ITableProps {
  pageIndex: number;
  pageSize: number;
  rows: number;
  onChangePage: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const Pagination = (props: ITableProps): ReactElement => {
  const { onChangePage, pageIndex, pageSize, rows } = props;

  const handleChangePage = (event: React.ChangeEvent<unknown>, page: number): void => {
    onChangePage(event, page === 0 ? page : page - 1);
  };

  const pageCalc = pageIndex + 1;
  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  return rows > 0 ? (
    <S.Pagination
      count={Math.ceil(rows / pageSize)}
      page={pageCalc}
      color="secondary"
      size={isMobile ? 'small' : 'medium'}
      onChange={handleChangePage}
      renderItem={(item) => <S.PaginationItem components={{ previous: ArrowLeft, next: ArrowRight }} {...item} />}
    />
  ) : (
    <></>
  );
};

export default Pagination;
