import { makeAutoObservable } from 'mobx';
import { PassesApi } from 'api';
import { DialogStatus, Permissions } from 'shared/enums';
import { IPassesFotTable } from 'shared/interfaces/app';
import { PassFilterModel, PassModel, PassListModel } from 'shared/models';
import { getDateOnly, getTimeSpan } from 'utils/DateTimeUtils';
import { passesStore, userAuthStore } from './index';
import { rolesStore } from 'stores';

class PassesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    // Для dev период 10 сек. Для prod - 1 мин
    const timeIntervalSec = process.env.REACT_APP_IS_DEVELOPMENT === 'true' ? 10 : 60;

    setInterval(this.iteration, 1000 * timeIntervalSec);
    setTimeout(this.iteration, 0);
  }

  /**
   * Опрос новых пропусков
   * @private
   */
  // todo kvv: в дальнейшем заменить на сокеты
  private async iteration(): Promise<void> {
    if (this.isActivePage) this.getPassesList();
  }

  public isActivePage = false;

  public passes: PassListModel[] = [];

  public selectedPass = new PassModel();

  public queryFilterParams = new PassFilterModel();

  public dialogViewMode = DialogStatus.Closed;

  public isLoaded = false;

  public setActivePage(value: boolean) {
    this.isActivePage = value;
  }

  public get isViewAll(): boolean {
    return rolesStore.isAllow(Permissions.UsersPassesView);
  }

  public get passesForTable(): IPassesFotTable[] {
    const passesData = this.passes.map((pass: PassListModel) => {
      const { id, createdAt, fromDate, toDate, fullUserName, description, isConfirmed } = pass;

      const createdAtView = getDateOnly(new Date(createdAt!));
      const plannedAtView = getDateOnly(new Date(toDate!)) + ' ' + getTimeSpan(new Date(fromDate!), new Date(toDate!));
      let row: IPassesFotTable;

      if (this.isViewAll) {
        row = {
          id,
          createdAt: createdAtView,
          plannedAt: plannedAtView,
          fullUserName: fullUserName,
          description: description,
          isConfirmed: isConfirmed,
        };
      } else {
        this.queryFilterParams.setUserId(userAuthStore.userId);

        row = {
          id,
          createdAt: createdAtView,
          plannedAt: plannedAtView,
          description: description,
          isConfirmed: isConfirmed,
        };
      }

      return row;
    });

    if (this.queryFilterParams.status === 'confirmed') {
      return passesData.filter(({ isConfirmed }) => isConfirmed);
    }

    if (this.queryFilterParams.status === 'unConfirmed') {
      return passesData.filter(({ isConfirmed }) => isConfirmed === false);
    }

    return passesData;
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCreatingMode() {
    this.selectedPass = new PassModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public closePassesDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public async getPassesList() {
    if (!this.isActivePage) passesStore.isLoaded = true;
    if (passesStore.queryFilterParams.isMyPass) passesStore.queryFilterParams.setUserId(userAuthStore.userId);

    try {
      const passes = await PassesApi.getPasses(this.queryFilterParams.queryParamsDto);
      if (!passes) return;
      this.passes = passes.map((item) => new PassListModel(item));
    } catch (e) {
      //ignore
    } finally {
      passesStore.isLoaded = false;
    }
  }

  public async getPassById(passId: string) {
    try {
      const pass = await PassesApi.getPass(passId);
      if (!pass) return;
      this.selectedPass = new PassModel(pass);
    } catch (e) {
      //ignore
    }
  }

  public async createPass(): Promise<void> {
    if (!this.selectedPass.passPostDto) return;

    try {
      const newPass = await PassesApi.postPass(this.selectedPass.passPostDto);
      if (!newPass) return;
    } catch (e) {
      //ignore
    }
  }

  public async confirmPass(passId: string, secretKey: string): Promise<void> {
    try {
      const pass = await PassesApi.putPassConfirm(passId, secretKey);
      this.selectedPass = new PassModel(pass);
    } catch (e) {
      //ignore
    }
  }

  public async rejectPass(passId: string, secretKey: string): Promise<void> {
    try {
      const pass = await PassesApi.putPassReject(passId, secretKey);
      this.selectedPass = new PassModel(pass);
    } catch (e) {
      //ignore
    }
  }
}

export default new PassesStore();
