import React from 'react';

const Settings: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3392 5.36282L15.8205 4.46274C15.3817 3.70114 14.4092 3.43841 13.6466 3.8754V3.8754C13.2835 4.08926 12.8504 4.14993 12.4425 4.04405C12.0347 3.93816 11.6858 3.6744 11.4727 3.31095C11.3355 3.07993 11.2619 2.8168 11.2591 2.54817V2.54817C11.2714 2.11749 11.109 1.70014 10.8086 1.3912C10.5083 1.08225 10.0957 0.908026 9.66487 0.908203H8.61987C8.19776 0.908198 7.79305 1.0764 7.49528 1.37559C7.19752 1.67479 7.03126 2.0803 7.03329 2.50241V2.50241C7.02078 3.37391 6.31068 4.07382 5.43909 4.07373C5.17046 4.07094 4.90733 3.99726 4.67631 3.86015V3.86015C3.91367 3.42315 2.94121 3.68589 2.5024 4.44749L1.94557 5.36282C1.50729 6.12347 1.76645 7.09531 2.52528 7.53673V7.53673C3.01854 7.82151 3.32239 8.3478 3.32239 8.91736C3.32239 9.48691 3.01854 10.0132 2.52528 10.298V10.298C1.76742 10.7364 1.50797 11.7059 1.94557 12.4643V12.4643L2.47189 13.372C2.67749 13.743 3.02245 14.0167 3.43045 14.1327C3.83844 14.2486 4.27582 14.1972 4.6458 13.9898V13.9898C5.00952 13.7776 5.44294 13.7194 5.84973 13.8283C6.25653 13.9372 6.60298 14.204 6.81209 14.5695C6.9492 14.8005 7.02288 15.0637 7.02567 15.3323V15.3323C7.02567 16.2128 7.73941 16.9265 8.61987 16.9265H9.66487C10.5424 16.9265 11.2549 16.2174 11.2591 15.3399V15.3399C11.257 14.9165 11.4243 14.5098 11.7238 14.2104C12.0232 13.911 12.4298 13.7437 12.8533 13.7457C13.1213 13.7529 13.3833 13.8263 13.6161 13.9593V13.9593C14.3767 14.3976 15.3485 14.1384 15.79 13.3796V13.3796L16.3392 12.4643C16.5517 12.0994 16.6101 11.6648 16.5013 11.2568C16.3925 10.8488 16.1255 10.5009 15.7595 10.2904V10.2904C15.3934 10.0798 15.1264 9.73195 15.0176 9.32392C14.9088 8.91589 14.9672 8.48132 15.1797 8.11644C15.318 7.87509 15.5181 7.67497 15.7595 7.53673V7.53673C16.5137 7.09555 16.7723 6.12938 16.3392 5.37045V5.37045V5.36282Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="9.14602" cy="8.9175" rx="2.1968" ry="2.1968" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Settings;
