import { makeAutoObservable } from 'mobx';
import { MessagesTypes } from 'shared/enums';
import { INotificationsRow } from 'shared/interfaces/api';

class NotificationsModel {
  constructor(dto?: INotificationsRow) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.content = dto.content;
    this.caption = dto.caption;
    this.isRead = dto.isRead;
    this.userSenderId = dto.userSenderId;
    this.type = MessagesTypes.Notification;
    this.userReceiverName = dto.userReceiverName;
  }

  public id = '';
  public createdAt: Date | null = null;
  public content = '';
  public caption = '';
  public isRead = false;
  public userSenderId = '';
  public type = MessagesTypes.Notification;
  public userReceiverName = '';
}

export default NotificationsModel;
