import React, { useState } from 'react';
import { IIconProps, IThemeExtendedColors } from 'shared/interfaces/app';

import { useTheme } from '@mui/material';

const DangerCircle: React.FC<IIconProps> = ({ color }) => {
  const theme = useTheme();

  const mainColor = (theme.palette as IThemeExtendedColors).icons?.stroke;
  const [strokeColor, setStrokeColor] = useState(mainColor);

  const onMouseOver = () => {
    setStrokeColor(theme.palette.primary.main);
  };

  const onMouseLeave = () => {
    setStrokeColor(mainColor);
  };

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00033 1.29175C13.257 1.29175 16.7087 4.74258 16.7087 9.00008C16.7087 13.2567 13.257 16.7084 9.00033 16.7084C4.74283 16.7084 1.29199 13.2567 1.29199 9.00008C1.29199 4.74258 4.74283 1.29175 9.00033 1.29175Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.99626 5.83683V9.51933" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.996 12.1634H9.00433" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DangerCircle;
