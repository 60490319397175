import React from 'react';
import { FormControlLabel, FormGroup, SwitchProps } from '@mui/material';

import * as S from './Switch.styles';

interface ISwitchProps extends SwitchProps {
  id?: string;
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch: React.FC<ISwitchProps> = (props) => {
  const { id, label, checked, onChange, disabled, ...rest } = props;

  return (
    <FormGroup>
      {label ? (
        <FormControlLabel
          sx={{ m: 2 }}
          control={
            <S.Switch
              id={id}
              focus_visible_class_name=".Mui-focusVisible"
              disabled={disabled}
              disableRipple
              {...rest}
              sx={{ m: 2 }}
              checked={checked}
              onChange={onChange}
            />
          }
          label={label}
        />
      ) : (
        <S.Switch
          disabled={disabled}
          id={id}
          focus_visible_class_name=".Mui-focusVisible"
          disableRipple
          {...rest}
          sx={{ m: 2 }}
          checked={checked}
          onChange={onChange}
        />
      )}
    </FormGroup>
  );
};

export default Switch;
