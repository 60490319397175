import { Calendar } from 'react-big-calendar';
import styled from 'styled-components/macro';

export const FloorAndPlaceSelectWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    border-bottom: 0;
    padding: 0;
    margin: 0;
  }
  border-bottom: 1px solid ${(_) => _.theme.palette.divider};
  padding: 10px 0 10px 0;
  margin: 5px 0 5px 0;
`;

export const CalendarWrapper = styled(Calendar)`
  height: -moz-calc(100vh - 320px);
  height: -webkit-calc(100vh - 320px);
  height: calc(100vh - 320px);

  & .rbc-time-header-content {
    border-color: white;
  }

  & .rbc-timeslot-group {
    min-height: 80px;
  }

  & .rbc-time-header.rbc-overflowing {
    border-color: white;
  }
`;
