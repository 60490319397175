export enum THEME_TYPE {
  DEFAULT,
  CUSTOMIZED,
}

export interface ISmtpParamsDto {
  host?: string;
  port?: number;
  userName?: string;
  password?: string;
  useSsl?: boolean;
}

export interface ICompanyGetDto {
  id: string;
  name: string;
  themeType: THEME_TYPE;
  themeData: string;
  logoImageBase64: File | string;
  isDeleted: boolean;
  smtpParams: ISmtpParamsDto;
}

export interface ICompaniesGetDto {
  id: string;
  name: string;
  themeType: THEME_TYPE;
  departmentsCount: number;
  isDeleted: boolean;
}

export interface IDepartmentListGetDto {
  id: string;
  departmentName: string;
  isDeleted: boolean;
  companyName: string;
}

export interface IDepartmentGetDto {
  id: string;
  name: string;
  isDeleted: boolean;
  companyId: string;
  zoneIds: string[];
  userIds: string[];
}

export interface IDepartmentPostPutDto {
  name: string;
  id?: string;
  zoneIds?: string[];
  userIds?: string[];
}

export interface ISendEmailDto {
  smtpParams: ISmtpParams;
  email: string;
}

export interface ISmtpParams {
  host: string;
  userName: string;
  port: number;
  password: string;
  useSsl: boolean;
}
