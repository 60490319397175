import React, { MouseEvent, useEffect } from 'react';
import { Edit3, Trash2 } from 'react-feather';

import ReactTooltip from 'react-tooltip';
import { ITags } from 'shared/interfaces/app';
import { ToggleButtonGroupProps, ToggleButtonProps } from '@mui/material';
import * as S from './Tag.styles';

type MuiProps = ToggleButtonGroupProps & ToggleButtonProps;

interface ITagProps extends MuiProps {
  tags: ITags[];
  disabledGroup?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  onCustomChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  onEditClick?: (e: MouseEvent, id: string) => void;
  onDeleteClick?: (e: MouseEvent, id: string) => void;
}

const Tag: React.FC<ITagProps> = (props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const { tags, value, size, disabledGroup, orientation, onCustomChange, onEditClick, onDeleteClick, ...rest } = props;
  let { allowDelete, allowEdit } = props;
  const hasActions = !!onEditClick || !!onDeleteClick;

  allowDelete = allowDelete ?? true;
  allowEdit = allowEdit ?? true;

  return (
    <S.ToggleButtonGroup disabled={disabledGroup} size={size} orientation={orientation} value={value} exclusive onChange={onCustomChange} {...rest}>
      {tags.map((item) => {
        return (
          <S.ToggleButton key={item.id} disableRipple value={item.id} has_actions={hasActions.toString()} {...rest}>
            <S.TagLabel>{item.name}</S.TagLabel>
            {hasActions && (
              <S.TagActions>
                {onEditClick && allowEdit && (
                  <Edit3 data-for="tooltip" data-tip="Редактировать" size={18} onClick={(e: MouseEvent) => onEditClick(e, item.id)} />
                )}
                {onDeleteClick && allowDelete && (
                  <Trash2 data-for="tooltip" data-tip="Удалить" size={18} onClick={(e: MouseEvent) => onDeleteClick(e, item.id)} />
                )}
              </S.TagActions>
            )}
          </S.ToggleButton>
        );
      })}
    </S.ToggleButtonGroup>
  );
};

export default Tag;
