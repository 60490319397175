import React, { useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { messagesStore, workPlacesStore } from 'stores';
import { Input, ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { IMessagesDialog } from 'shared/interfaces/app';

const CommentDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);

  const formRef = useRef<FormikProps<IMessagesDialog>>(null);

  const handleCloseMessageDialog = () => {
    workPlacesStore.setCommentDialogViewMode(DialogStatus.Closed);
  };

  const onSubmit = async (values: IMessagesDialog) => {
    workPlacesStore.commentMessage.updateFromDialogForm(values);

    const dto = workPlacesStore.commentMessage.messagePostDto;
    dto.caption = `Комментарий к '${dto.caption}'`;

    await messagesStore.createMessage(dto);
    workPlacesStore.setCommentDialogViewMode(DialogStatus.Closed);
    workPlacesStore.commentMessage.clear();
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const title = workPlacesStore.getCaption();
  const isOpen = workPlacesStore.commentDialogViewMode !== DialogStatus.Closed && workPlacesStore.commentDialogViewMode !== DialogStatus.Viewing;

  return (
    <ModalDialog
      isValid={validated}
      title={`${title} ${workPlacesStore.selectedWorkPlace.number}`}
      onClose={handleCloseMessageDialog}
      onCancel={handleCloseMessageDialog}
      onSubmit={handleSubmit}
      createBtnLabel="Отправить"
      isOpen={isOpen}>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          caption: `${title} ${workPlacesStore.selectedWorkPlace.number}`,
          content: workPlacesStore.commentMessage.content,
        }}
        validationSchema={Yup.object().shape({
          caption: Yup.string().max(255).required('Введите тему комментария'),
          content: Yup.string().max(2000).required('Введите текст комментария'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
          setValidated(isValid);

          return (
            <>
              <Input
                multiline
                name="content"
                inputLabel="Текст комментария *"
                value={values.content}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
              />
            </>
          );
        }}
      </Formik>
    </ModalDialog>
  );
};

export default observer(CommentDialog);
