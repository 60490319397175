import { makeAutoObservable } from 'mobx';
import { IMessagesPopup } from 'shared/interfaces/app';

class MessagesPopupModel {
  constructor(dto?: IMessagesPopup) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.popupIsOpen = dto.popupIsOpen;
  }

  public popupIsOpen = false;

  public setPopupMode(value: boolean) {
    this.popupIsOpen = value;
  }
}

export default MessagesPopupModel;
