import React, { useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';
import { appStore, companiesStore, mapStore, userAuthStore, workPlacesStore } from 'stores';
import { Toast, ConfirmDialog, Gallery } from 'components';
import { CssBaseline } from '@mui/material';
import Routes from './routes/Routes';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';
import { DEFAULT_COMPANY_ID } from './shared/constants';

const App: React.FC = () => {
  const [isRun, setIsRun] = useState(false);

  useLayoutEffect(() => {
    const init = async () => {
      const isValid = await userAuthStore.getAuthUserInfo();
      isValid && (await companiesStore.getCompany(DEFAULT_COMPANY_ID));

      setIsRun(true);
    };

    init().finally();
  }, []);

  if (!isRun) return null;

  const workPlaceName = workPlacesStore.selectedWorkPlace.number;

  return (
    <MuiThemeProvider theme={appStore.currentTheme}>
      <ReactTooltip
        id="tooltip"
        effect={'solid'}
        type={'light'}
        place={'bottom'}
        borderColor={'#eeeeee'}
        border={true}
        className="react-tooltip-extra"
      />
      <CssBaseline />
      <Routes />
      <Toast />
      <ConfirmDialog />
      <Gallery
        title={`Фотографии объекта №${workPlaceName}`}
        images={workPlacesStore.selectedWorkPlace.workPlaceImages}
        open={mapStore.isOpenGallery}
        onClose={() => mapStore.setOpenGallery(false)}
      />
    </MuiThemeProvider>
  );
};

export default observer(App);
