import { THEMES } from 'shared/constants';
import { PaletteOptions } from '@mui/material/styles';

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    common: {
      white: '#FFF',
    },
    primary: {
      main: '#003A5D',
      disabled: '#003A5D33',
    },
    secondary: {
      main: '#00B8B0',
    },
    error: {
      light: '#FF6A55',
      main: '#CE5148',
    },
    warning: {
      main: '#FFB800',
    },
    success: {
      main: '#00B8B0',
    },
    info: {
      main: 'rgba(0, 58, 93, 0.3)',
    },
    grey: {
      50: '#B3C4CE',
    },
    background: {
      default: '#F7F9FC',
      paper: '#F2F5F7',
    },
    text: {
      primary: '#003A5D',
      secondary: '#809DAF',
      disabled: 'rgba(0, 58, 93, 0.4)',
    },
    divider: '#E4E9EC',
    icons: {
      stroke: '#B3C4CE',
    },
  },
  calendar: {
    background: '#E5E5E5',
  },
};

const variants = [defaultVariant];

export default variants;
