import React from 'react';
import { CheckSquare, Trash } from 'react-feather';

import { reservationStore as store } from 'stores';
import { PopupMenu } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import QrIcon from 'components/Icons/QrIcon';
import { IReservationRowItemDto } from 'shared/interfaces/api/reservationDto';
import { Column } from 'shared/interfaces/app';
import { getDateOnly, getTimeSpan } from 'utils/DateTimeUtils';
import { getReservationStatus } from 'utils/ReservationStatus';
import { Chip, Divider, MenuItem, Typography } from '@mui/material';

export function Columns(isAdmin: boolean, isMobile: boolean, isTablet: boolean, isDesktop: boolean, isLarge: boolean): Column[] {
  const columns: Column[] = [];

  if (isMobile || isTablet) {
    return columns;
  }

  if (isDesktop) {
    columns.push({ id: 'dateTime', label: 'Дата / Время', minWidth: '120px' });
  } else {
    columns.push({ id: 'date', label: 'Дата', minWidth: '120px' });
    columns.push({ id: 'time', label: 'Время', minWidth: '140px' });
  }

  if (isAdmin) {
    columns.push({ id: 'userName', label: 'ФИО', minWidth: '10%', disabled: !isAdmin });
  }

  if (isDesktop || isLarge) {
    columns.push({ id: 'description', label: 'Объект', width: '50%' });
  } else {
    columns.push({ id: 'description', label: 'Объект', width: '70px' });
  }

  if (isDesktop) {
    columns.push({ id: 'status', label: 'Статус', minWidth: '50px' });
  } else {
    columns.push({ id: 'status', label: 'Статус', minWidth: '50px' });
    columns.push({ id: 'actionButtons', minWidth: '20px' });
  }

  return columns;
}

export function Rows(
  rows: IReservationRowItemDto[],
  isAdmin: boolean,
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  isLarge: boolean
): Array<JSX.Element>[] {
  return rows.map((data: IReservationRowItemDto, index: number) => {
    const row = Array<JSX.Element>();

    const activeButtons = store.getActiveButtons(data.status, data.userId);

    const menuItems = [
      <MenuItem
        sx={{ '&:hover': { color: 'primary.main' } }}
        disableRipple
        disabled={!activeButtons.accept}
        key={data.id}
        onClick={() => store.confirmDialog(data)}>
        <CheckSquare />
        <Typography variant="caption" sx={{ ml: 3 }}>
          Подтвердить
        </Typography>
      </MenuItem>,
      <MenuItem
        sx={{ '&:hover': { color: 'primary.main' } }}
        disableRipple
        disabled={!activeButtons.acceptQr}
        key={data.id}
        onClick={() => store.confirmQrDialog()}>
        <QrIcon />
        <Typography variant="caption" sx={{ ml: 3 }}>
          Сканировать QR-код
        </Typography>
      </MenuItem>,
      <MenuItem
        sx={{ '&:hover': { color: 'primary.main' } }}
        disableRipple
        disabled={!activeButtons.edit}
        key={data.id}
        onClick={() => store.editDialog(data.id)}>
        <EditPencilIcon />
        <Typography variant="caption" sx={{ ml: 3 }}>
          Редактировать
        </Typography>
      </MenuItem>,
      <MenuItem
        sx={{ '&:hover': { color: 'primary.main' } }}
        disableRipple
        disabled={!activeButtons.cancel}
        key={data.id}
        onClick={() => store.cancelDialog(data)}>
        <Trash />
        <Typography variant="caption" sx={{ ml: 3 }}>
          Удалить
        </Typography>
      </MenuItem>,
    ];

    // status
    const status = getReservationStatus(data.status, data.isCompleted);

    if (isMobile || isTablet) {
      const content = (
        <div key={'list' + index} style={{ marginTop: index == 0 && !isMobile ? '16px' : '0' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {isAdmin && (
                <Typography variant="body2" style={{ marginBottom: '10px' }}>
                  {data.userName}
                </Typography>
              )}
              <Typography variant="body1" style={{ marginBottom: '8px' }}>
                {getDateOnly(data.timeFrom)} {getTimeSpan(data.timeFrom, data.timeTo, true)}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '8px' }}>
                {data.description}
              </Typography>
              <Chip key={'status' + index} style={{ color: status.color }} label={status.value} />
            </div>
            {!data.isCompleted ? <PopupMenu key={data.id} menuItems={menuItems} /> : null}
          </div>
          <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
        </div>
      );

      row.push(content);
      return row;
    }

    if (isDesktop) {
      // dateTime
      const content = (
        <Typography key={'dateTime' + index}>
          {getDateOnly(data.timeFrom)}
          <br />
          {getTimeSpan(data.timeFrom, data.timeTo)}
        </Typography>
      );
      row.push(content);
    } else {
      // date
      row.push(getSimpleRow('date', getDateOnly(data.timeFrom), index));

      // time
      row.push(getSimpleRow('time', getTimeSpan(data.timeFrom, data.timeTo), index));
    }

    // userName
    if (isAdmin) row.push(getSimpleRow('userName', data.userName, index));

    // description
    row.push(getSimpleRow('description', data.description, index));

    if (isDesktop) {
      row.push(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Chip key={'status' + index} style={{ color: status.color }} label={status.value} />
          {!data.isCompleted ? <PopupMenu key={data.id} menuItems={menuItems} /> : null}
        </div>
      );
    } else {
      row.push(<Chip key={'status' + index} style={{ color: status.color }} label={status.value} />);

      // actionButtons - Кнопки редактировать и отменить бронь, если не завершено
      if (data.isCompleted) {
        row.push(getSimpleRow('abs', '', index));
      } else {
        row.push(<PopupMenu key={data.id} menuItems={menuItems} />);
      }
    }

    return row;
  });
}

function getSimpleRow(prefix: string, value: string, index: number): JSX.Element {
  return <Typography key={prefix + value + index}>{value}</Typography>;
}
