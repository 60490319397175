import { addHours, addWeeks, format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { IDropDownOption } from '../shared/interfaces/app';

// todo kvv - хардкод по Москве. В дальнейшем, получат с бэка
const timeZone = 3;

/**
 * Возвращает дату на неделю раньше от пришедшей даты
 * @returns {Date} date
 */
export function getDateLastWeek(date: Date): Date {
  return addWeeks(date, -1);
}

/**
 * Приводит дату к началу дня
 * @returns {Date} date
 */
export function getStartOfDay(date: Date | null = null): Date {
  if (!date) date = new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

/**
 * Приводит дату к концу дня
 * @returns {Date} date
 */
export function getEndOfDay(date: Date | null = null): Date {
  if (!date) date = new Date();

  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(0);

  return date;
}

function toFormat(date: Date | null, formatPattern: string): string {
  if (!date) return '';
  return format(date, formatPattern, { locale: ruLocale });
}

/**
 * Перевод из UTC в локально время
 * @param date
 */
export function utcToLocal(date: Date): Date;
export function utcToLocal(date: string): Date;
export function utcToLocal(date: Date | string): Date {
  if (date instanceof Date) return addHours(date, timeZone);
  else return addHours(new Date(date), timeZone);
}

/**
 * Перевод из локального времени браузера в UTC
 * @param date
 */
export function localToUtc(date: Date | null = null): Date {
  if (!date) date = new Date();

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
}

/**
 * Возвращает строку только времени
 * @param date
 */
export function getTimeOnly(date: Date): string {
  return toFormat(date, 'HH:mm');
}

/**
 * Возвращет строку только даты
 * @param date
 */
export function getDateOnly(date: Date): string {
  return toFormat(date, 'dd MMMM yyyy');
}

/**
 * Возвращет строку только даты
 * @param date
 */
export function getShortDateOnly(date: Date): string {
  return toFormat(date, 'LLL yyyy');
}

/**
 * Возвращет строку даты и времени
 * @param date
 */
export function getFullDateTime(date: Date): string {
  return toFormat(date, 'dd MMMM yyyy, HH:mm');
}

/**
 * Возвращет строку интервала времени
 * @param fromTime
 * @param toTime
 * @param isSimple
 */
export function getTimeSpan(fromTime: Date, toTime: Date, isSimple = false): string {
  if (isSimple) return getTimeOnly(fromTime) + ' - ' + getTimeOnly(toTime);
  return 'с ' + getTimeOnly(fromTime) + ' по ' + getTimeOnly(toTime);
}

function getTimeList(minuteStep = 60): { list: IDropDownOption[]; maxMin: number; minuteStep: number } {
  const list = [];
  const maxMin = 24 * 60;
  for (let i = 0; i < maxMin; i += minuteStep) {
    const date = localToUtc(new Date(i * 1000 * 60));
    list.push({ id: i.toString(), name: format(date, 'HH:mm') });
  }
  const last = { id: (maxMin - 1).toString(), name: '24:00' };
  list.push(last);

  return {
    list,
    maxMin,
    minuteStep,
  };
}

const _hourTimeList = getTimeList(60);
const _30minTimeList = getTimeList(30);
const _minTimeList = getTimeList(2);

export function getHourTimeList() {
  return _hourTimeList;
}

export function getMinTimeList() {
  return _minTimeList;
}

export function get30minTimeList() {
  return _30minTimeList;
}

/**
 * Ближайшее локальное время относительно сейчас
 */
export function getNearHour(date: Date | null = null) {
  if (!date) date = new Date();

  // Если есть минуты, то берем следующий час
  if (date.getMinutes() !== 0) date = addHours(date, 1);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return nowToLocal(date);
}

export function toUtc(date: Date): Date {
  date.setSeconds(0);
  date.setMilliseconds(0);
  const offset = new Date().getTimezoneOffset() / 60;
  return addHours(date, -timeZone - offset);
}

export function nowToLocal(date: Date | null = null) {
  if (!date) date = new Date();
  return utcToLocal(localToUtc(date));
}

export function getReservationText(fromTime: Date, toTime: Date) {
  return `Забронировано ${getDateOnly(fromTime)} ${getTimeSpan(fromTime, toTime)}`;
}

/**
 * Перевод из минут от начала дня в Date
 */
export function minutesToDate(minutes: number, baseDate: Date | null = null): Date {
  if (!baseDate) baseDate = new Date();
  const h = Math.trunc(minutes / 60);
  const m = minutes - h * 60;

  return new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), h, m, 0, 0);
}

export function replaceHourMinute(targetDate: Date, baseDate: Date): Date {
  return new Date(
    baseDate.getUTCFullYear(),
    baseDate.getUTCMonth(),
    baseDate.getUTCDate(),
    targetDate.getUTCHours(),
    targetDate.getUTCMinutes(),
    0,
    0
  );
}

/**
 * Если From < 19:00, то To = 18:00
 * Если From >= 19:00, то To = From + 1ч 1080
 * Перевод времени в кол-во минут от начала дня
 * @param fromTime
 */
export function getBookingTimeInterval(fromTime: Date) {
  const valueFrom = fromTime.getHours() * 60 + fromTime.getMinutes();
  const to1900 = 18 * 60;
  let valueTo = to1900;
  if (valueFrom > to1900) {
    valueTo = valueFrom + 60;
  }

  return { fromTime: getNearHour(), toTime: minutesToDate(valueTo, fromTime) };
}

export function getFullBookingTimeInterval() {
  const from0800 = 8 * 60;
  const to1900 = 18 * 60;

  return { fromTime: minutesToDate(from0800), toTime: minutesToDate(to1900) };
}
