import { makeAutoObservable } from 'mobx';
import { UsersApi } from 'api';
import { AUTH_DATA_STR } from 'shared/constants';
import { appStore, userAuthStore } from './index';
import { ROUTE } from '../routes';
import { IAuthGetDto, IAuthPutDto } from '../shared/interfaces/api';
import { IAuthenticationForm, IAuthForm, IResetPassForm } from 'shared/interfaces/app';

class UserAuthStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public userId = '';

  public email = '';

  public mailForResetPassword = '';

  public password = '';

  public newPassword = '';

  public newPasswordConfirmation = '';

  public isUserAgreementChecked = false;

  public isConfirmed = false;

  public isPasswordChanged = false;

  public isAdmin = false;

  public shortName = '';

  public lastName = '';

  public firstName = '';

  public patronymic = '';

  public companyId = '';

  public roleName = '';

  public companyName = '';

  public departmentId = '';

  public departmentName = '';

  public permissionsIds: string[] = [];

  public copyMessagesToEmail = true;

  public copyNotificationsToEmail = true;

  public isAuthUser = false;

  public isAuthError = false;

  public isUnConfirmedUser = false;

  public isSuccessResetPass = false;

  public isInitialized = false;

  public isFetching = false;

  public token: string | null = null;

  public async signIn() {
    this.isAuthError = false;

    try {
      this.isFetching = true;

      // Токен
      const token = await UsersApi.getAuthData({ email: this.email, password: this.password });

      if (token !== null) {
        this.token = token;
        window.localStorage.setItem(AUTH_DATA_STR, this.token);

        // Полная инфа юзера
        await userAuthStore.getAuthUserInfo();

        this.isUnConfirmedUser = !this.isPasswordChanged;

        this.setAuthUser(this.isPasswordChanged);
      } else {
        this.isUnConfirmedUser = true;
        this.isUserAgreementChecked = this.isConfirmed;
      }
    } catch (e) {
      this.isAuthError = true;
    } finally {
      this.isFetching = false;
    }
  }

  public async initUser() {
    try {
      this.isFetching = true;
      await UsersApi.initializationUser(this.authPutDto);
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public signOut() {
    this.token = '';
    this.setAuthUser(false);

    appStore.setAdminTab(ROUTE.NONE);
    UserAuthStore.removeTokenFromLocalStorage();
  }

  public async getAuthUserInfo(): Promise<boolean> {
    this.token = UserAuthStore.getTokenFromLocalStorage() || '';
    if (!this.token) return Promise.resolve(false);

    try {
      this.userId = this.getDataFromToken().nameid || '';
      this.isPasswordChanged =
        (this.getDataFromToken()['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision'] || '') === 'True';
    } catch (e) {
      return Promise.resolve(false);
    }
    if (!this.userId) return Promise.resolve(false);

    const result = await UsersApi.getAuthUserData(this.userId);
    this.updateFields(result);

    return Promise.resolve(true);
  }

  private updateFields(value: IAuthGetDto) {
    this.email = value.email;
    this.shortName = value.shortName;
    this.lastName = value.lastName;
    this.firstName = value.firstName;
    this.patronymic = value.patronymic;
    this.isConfirmed = value.isConfirmed;
    this.isPasswordChanged = value.isPasswordChanged;
    this.companyId = value.companyId;
    this.companyName = value.companyName;
    this.departmentId = value.departmentId;
    this.departmentName = value.departmentName;
    this.permissionsIds = value.permissionsIds;
    this.roleName = value.roleName;
    this.isAdmin = value.isAdmin!;
    this.copyMessagesToEmail = value.copyMessagesToEmail;
    this.copyNotificationsToEmail = value.copyNotificationsToEmail;
  }

  public async setCopyMessagesSetting(enabled: boolean) {
    try {
      this.isFetching = true;
      await UsersApi.putCopyMessagesSetting(this.userId, enabled);
      this.copyMessagesToEmail = enabled;
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async setCopyNotificationsSetting(enabled: boolean) {
    try {
      this.isFetching = true;
      await UsersApi.putCopyNotificationsSetting(this.userId, enabled);
      this.copyNotificationsToEmail = enabled;
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async resetPassword() {
    try {
      this.isFetching = true;
      await UsersApi.putResetPass(this.mailForResetPassword);
      this.isSuccessResetPass = true;
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public initializeMe() {
    try {
      this.token = UserAuthStore.getTokenFromLocalStorage() || '';

      try {
        this.userId = this.getDataFromToken()?.nameid || '';
        this.isPasswordChanged =
          (this.getDataFromToken()['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/authorizationdecision'] || '') === 'True';
        if (!this.isPasswordChanged) this.userId = '';
      } catch (e) {
        this.userId = '';
      }

      if (!this.token || !this.userId) {
        this.signOut();
        return;
      }

      this.setAuthUser(true);
    } catch (e) {
      // ignore
    } finally {
      this.isInitialized = true;
    }
  }

  public get authPutDto(): IAuthPutDto {
    return {
      email: this.email,
      oldPassword: this.password,
      newPassword: this.newPassword,
      isUserAgreementChecked: this.isUserAgreementChecked,
    };
  }

  public updateFromAuthenticationForm(model: IAuthenticationForm) {
    this.newPassword = model.newPassword;
    this.newPasswordConfirmation = model.newPasswordConfirmation;
    this.isUserAgreementChecked = model.isUserAgreementChecked;
  }

  public updateFromAuthForm(model: IAuthForm) {
    this.email = model.email;
    this.password = model.password;
  }

  public clearAuthForm(): void {
    this.email = '';
    this.password = '';
  }

  public updateFromResetPasswordForm(model: IResetPassForm) {
    this.mailForResetPassword = model.email;
  }

  public setAuthUser(value: boolean) {
    this.isAuthUser = value;
  }

  private static getTokenFromLocalStorage(): string | null {
    return window.localStorage.getItem(AUTH_DATA_STR);
  }

  private static removeTokenFromLocalStorage() {
    window.localStorage.removeItem(AUTH_DATA_STR);
  }

  private getDataFromToken() {
    if (!this.token) return;

    const base64Url = this.token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

export default new UserAuthStore();
