import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { messagesStore, userAuthStore } from 'stores';

import { ContentHeader, MainContent, Toggler, Pagination, Spinner, Checkbox, Accordion } from 'components';

import { DialogStatus } from 'shared/enums';
import { MessagesModel } from 'shared/models';
import { MessagesTabActions } from 'stores/messagesStore';
import { useBreakpoint } from 'utils/hooks';
import { Box, Divider, MenuItem } from '@mui/material';

import { MessageList, MessageViewDialog, MessagesDialog, MessagesFilters } from './Components';
import * as S from './Messages.styles';

const Messages: React.FC = () => {
  useEffect(() => {
    messagesStore.setActivePage(true);
    onSearch();

    return () => messagesStore.setActivePage(false);
  }, []);

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const onCreateMessage = () => {
    messagesStore.setCreatingMode();
  };

  const onCloseDialog = () => {
    messagesStore.closeMessagesDialog();
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number): void => {
    messagesStore.queryParams.pageIndex = page;
    onSearch();
  };

  const onSearch = (): void => {
    messagesStore.getMessagesList().finally();
  };

  const onChangeTab = (event: React.MouseEvent<HTMLElement>, tabKey: string): void => {
    messagesStore.setTab(tabKey);
    onSearch();
  };

  const onOpenListItem = async (item: MessagesModel): Promise<void> => {
    messagesStore.selectedMessage = item as MessagesModel;

    if (!item.isRead) {
      await messagesStore.readMessage();
    }

    messagesStore.setDialogViewMode(DialogStatus.Viewing);
  };

  const onCreateMessageFromItem = async (item: MessagesModel): Promise<void> => {
    messagesStore.selectedMessage = new MessagesModel(item);
    messagesStore.selectedMessage.updateContent('');
    if (!messagesStore.selectedMessage.isRead) {
      await messagesStore.readMessage();
    }

    messagesStore.setDialogViewMode(DialogStatus.Creating);
  };

  const Content = messagesStore.isFetching
    ? () => <Spinner />
    : () => (
        <>
          <MessageList
            isShowReplyButton={messagesStore.tab === MessagesTabActions.In}
            isShowRead={messagesStore.tab === MessagesTabActions.In}
            listItems={messagesStore.messagesForList}
            onItemClick={onOpenListItem}
            onItemHoverBtnClick={onCreateMessageFromItem}
          />

          <Box sx={{ marginTop: '8px', position: 'fixed', bottom: '16px', backgroundColor: '#ffffffe8', padding: '8px', borderRadius: '18px' }}>
            <Pagination
              rows={messagesStore.totalCount}
              pageSize={messagesStore.queryParams.pageSize}
              pageIndex={messagesStore.queryParams.pageIndex}
              onChangePage={onChangePage}
            />
          </Box>
        </>
      );

  const setUserMessagesSettings = async (e: React.SyntheticEvent, checked: boolean) => {
    await userAuthStore.setCopyMessagesSetting(checked);
  };

  const menuItemsSettings = [
    <MenuItem sx={{ '&:hover': { color: 'primary.main' } }} disableRipple key="copy-messages-to-email">
      <Checkbox label={'Получать сообщения на почту'} checked={userAuthStore.copyMessagesToEmail} onChange={setUserMessagesSettings} />
    </MenuItem>,
  ];

  return (
    <>
      <ContentHeader title="Сообщения" btnLabel="Написать" onClick={onCreateMessage} menuItemsSettings={menuItemsSettings} />
      <MainContent>
        <S.BoxWrapper sx={{ display: 'flex', justifyContent: 'flex-start', width: 'fit-content' }}>
          <Toggler tabs={messagesStore.messagesTabs} value={messagesStore.tab} onChange={onChangeTab} />
        </S.BoxWrapper>
        <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
        {isMobile ? (
          <>
            <Accordion title="Фильтры">
              <MessagesFilters />
            </Accordion>
          </>
        ) : (
          <MessagesFilters />
        )}
        <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
        <Content />
      </MainContent>

      {messagesStore.dialogViewMode === DialogStatus.Viewing && <MessageViewDialog onClose={onCloseDialog} />}
      {messagesStore.dialogViewMode !== DialogStatus.Closed && <MessagesDialog />}
    </>
  );
};

export default observer(Messages);
