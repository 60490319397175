import { makeAutoObservable } from 'mobx';
import { IZoneListGetDto } from 'shared/interfaces/api';
import { IDropDownOption } from 'shared/interfaces/app';

class ZoneListModel {
  constructor(dto?: IZoneListGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });
    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
    this.workplacesCount = dto.workplacesCount;
    this.roles = dto.roles;
    this.departments = dto.departments;
  }

  public id = '';

  public name = '';

  public workplacesCount = 0;

  public roles = '';

  public departments = '';

  public getDropDownOption(): IDropDownOption {
    return { id: this.id, name: this.name };
  }
}

export default ZoneListModel;
