import React from 'react';
import { observer } from 'mobx-react';
import { Info } from 'react-feather';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router-dom';
import { mapStore, reservationStore, workPlacesStore } from 'stores';
import { ContextMenu, WorkPlaceAttr } from 'components';
import CalendarIcon from 'components/Icons/CalendarIcon';
import DangerCircle from 'components/Icons/DangerCircle';
import { ROUTE } from 'routes';
import { DialogStatus, WorkPlaceType, Permissions } from 'shared/enums';
import rolesStore from 'stores/rolesStore';
import { getReservationText } from 'utils/DateTimeUtils';
import { Button, Divider, Typography, IconButton, useTheme } from '@mui/material';
import UserInfoViewDialog from './UserInfoViewDialog';

import * as S from './WorkPlaceInfo.styles';

const WorkPlaceInfo: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();

  if (!workPlacesStore.workPlaceStatus) return null;

  const handleClose = () => {
    workPlacesStore.setAnchorEl(null);
  };

  const onOpenReservationDialog = async () => {
    await reservationStore.createDialog(
      workPlacesStore.selectedWorkPlace.id,
      workPlacesStore.queryFilterParams.fromTime,
      workPlacesStore.queryFilterParams.toTime
    );
    workPlacesStore.getWorkPlacesAvailableBookingList();
    workPlacesStore.setAnchorEl(null);
  };

  const onOpenCommentDialog = () => {
    workPlacesStore.setCommentDialogViewMode(DialogStatus.Creating);
    workPlacesStore.setAnchorEl(null);
  };

  const onImageClick = (index: number) => {
    workPlacesStore.selectedWorkPlace.setSelectedImage(index);
  };

  const menuItems = rolesStore.isAllow(Permissions.ReservationsModuleView)
    ? () => {
        const actionArr = [
          <S.ButtonWrapper key="reservationAction">
            <Button size="medium" color="secondary" fullWidth variant="contained" onClick={onOpenReservationDialog}>
              Забронировать
            </Button>
          </S.ButtonWrapper>,
        ];

        if (reservationStore.isCommentBtn) {
          actionArr.push(
            <S.ButtonWrapper key="reservationAction">
              <Button size="medium" sx={{ mt: 2 }} color="secondary" fullWidth variant="contained" onClick={onOpenCommentDialog}>
                Комментировать
              </Button>
            </S.ButtonWrapper>
          );
        }

        return actionArr;
      }
    : () => [];

  const label = workPlacesStore.workPlaceStatus.type.toString() === WorkPlaceType.WorkPlace ? 'Рабочее место ' : 'Переговорная комната ';

  const filter = workPlacesStore.queryFilterParams;

  const canViewUserDetails = rolesStore.isAllow(Permissions.ReservationUserInfoView);

  const onCalendarRedirect = () => {
    const calendarLink = `${ROUTE.ReservationCalendar}?workplaceId=${
      workPlacesStore.workPlaceStatus?.id
    }&fromTimeInDay=${filter.fromTime.toISOString()}&toTimeInDay=${filter.toTime.toISOString()}`;
    history.push(calendarLink);
    handleClose();
  };

  return (
    <ContextMenu anchorEl={workPlacesStore.anchorEl} open={Boolean(workPlacesStore.anchorEl)} onClose={handleClose} menuItems={menuItems()}>
      <S.Wrapper>
        <Typography sx={{ mb: 2 }} variant="body2">
          {label} {workPlacesStore.workPlaceStatus.number}
        </Typography>

        {rolesStore.isAllow(Permissions.ReservationsModuleView) && (
          <Button
            sx={{ width: '100%', mb: 2 }}
            color="primary"
            variant="outlined"
            size="medium"
            startIcon={<CalendarIcon color={theme.palette.primary.main} />}
            onClick={onCalendarRedirect}>
            <Typography sx={{ mt: 1 }} variant="body2">
              Посмотреть в календаре
            </Typography>
          </Button>
        )}

        {reservationStore.reservationsList.map((el) => (
          <>
            <S.NamesWrapper key={el.id}>
              <S.NameRow>
                <Typography variant="subtitle1">{el.userName}</Typography>
                {canViewUserDetails && (
                  <IconButton onClick={() => (workPlacesStore.userInfoDialogViewMode = DialogStatus.Viewing)}>
                    <DangerCircle />
                  </IconButton>
                )}
              </S.NameRow>
              <Typography sx={{ color: 'text.secondary', mt: 1 }} variant="subtitle1">
                {getReservationText(el.timeFrom, el.timeTo)}
              </Typography>
            </S.NamesWrapper>
            <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
            <UserInfoViewDialog userId={el.userId}></UserInfoViewDialog>
          </>
        ))}

        {!!workPlacesStore.workPlaceStatus.attributes.length && (
          <>
            <S.AttrWrapper>
              <WorkPlaceAttr size="small" attributes={workPlacesStore.workPlaceStatus.attributes} />
            </S.AttrWrapper>
            <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
          </>
        )}

        {!!workPlacesStore.selectedWorkPlace.comment && (
          <>
            <div style={{ maxHeight: '1000px', overflowY: 'auto' }}>{workPlacesStore.selectedWorkPlace.comment}</div>
            <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
          </>
        )}

        {!!workPlacesStore.selectedWorkPlace.workPlaceImages.length && (
          <>
            <Carousel
              swipeable={true}
              emulateTouch={true}
              selectedItem={workPlacesStore.selectedWorkPlace.selectedImage}
              centerMode
              centerSlidePercentage={
                workPlacesStore.selectedWorkPlace.workPlaceImages.length < 5 ? 100 / workPlacesStore.selectedWorkPlace.workPlaceImages.length : 20
              }
              showStatus={false}
              infiniteLoop={false}
              dynamicHeight={false}
              showArrows={true}
              showIndicators={false}
              showThumbs={false}
              onClickItem={onImageClick}>
              {workPlacesStore.selectedWorkPlace.workPlaceImages.map((image) => (
                <S.ImageWrapper
                  key={image.id}
                  onClick={() => {
                    mapStore.setOpenGallery(true);
                  }}>
                  <S.Image src={image.imageURL} alt="photo" />
                </S.ImageWrapper>
              ))}
            </Carousel>

            <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
          </>
        )}
      </S.Wrapper>
    </ContextMenu>
  );
};

export default observer(WorkPlaceInfo);
