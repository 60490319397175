import React from 'react';
import { passesStore } from 'stores';
import { Table } from 'components';
import { IPassesFotTable } from 'shared/interfaces/app';
import { Chip } from '@mui/material';
import * as S from './PassesMobile.styles';
import { observer } from 'mobx-react';
import ChipStatus from '../ChipStatus';

const PassesMobile = () => {
  const createRowData = () => {
    return passesStore.passesForTable.map((data: IPassesFotTable) => {
      const newRow: JSX.Element = (
        <S.Cell>
          <S.Name variant="subtitle1">{data?.fullUserName}</S.Name>
          <S.Dates variant="caption">
            Дата создания: {data?.createdAt} <br />
            Дата пропуска: {data?.plannedAt}
          </S.Dates>
          <S.Description variant="subtitle1">{data?.description}</S.Description>
          <ChipStatus value={data?.isConfirmed} />
        </S.Cell>
      );
      return [newRow];
    });
  };
  return <Table columns={[]} rows={createRowData()} />;
};

export default observer(PassesMobile);
