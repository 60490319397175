import { IPosition } from 'shared/interfaces/app';

// Themes
export const THEMES = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

export const AUTH_DATA_STR = 'token';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const PASS_TEMPLATE_ID = '00000000-0000-0000-0000-000000000001';
export const DEFAULT_COMPANY_ID = '00000000-0000-0000-0000-000000000001';
export const MESSAGES_WP_RECEIVERS_ID = '10000000-0000-0000-0000-000000000001';

export const MESSAGES_TYPES = {
  MESSAGES: 'Messages',
  NOTIFICATIONS: 'Notifications',
};

export const MAP_IMAGES_STORAGE_PATH = '/files/maps/';
export const WORKPLACE_IMAGES_STORAGE_PATH = '/files/workplaces/';

export const TABLE_ROWS_PER_PAGE_MOBILE = 5;
export const TABLE_ROWS_PER_PAGE_TABLET = 6;
export const TABLE_ROWS_PER_PAGE_DESKTOP = 8;
export const TABLE_ROWS_PER_PAGE_DESKTOP_LARGE = 18;

export const WORKPLACE_IMAGES_PER_PAGE = 6;

export const MATTERPORT_MODEL_HOST = 'https://my.matterport.com';
export const MATTERPORT_SDK_KEY = localStorage.getItem('mbr_matterport_sdk_key') || process.env.REACT_APP_MATTERPORT_SDK_KEY;
export const MATTERPORT_TOOGLE_NAME = '3D-тур';
export const MATTERPORT_TOOGLE_ID = 'f0b1db63-ad55-41d3-97e8-f8fc68414ab7';

export const COMPANY_OPTION_ID = 'company-message-option';
export const DEPARTMENT_OPTION_ID = 'department-message-option';

export const HOST = localStorage.getItem('mbr_host') || process.env.REACT_APP_API_ENDPOINT || window.location.origin;

export const ZERO_POSITION: IPosition = {
  x: 0,
  y: 0,
};

export type TBtnColor = 'inherit' | 'secondary' | 'primary' | 'success' | 'error' | 'info' | 'warning';
