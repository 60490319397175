import React, { ReactElement } from 'react';
import { NotificationsModel } from 'shared/models';
import { getDateOnly, getStartOfDay, getTimeOnly } from 'utils/DateTimeUtils';
import { messagesStore, rolesStore } from 'stores';
import { Permissions } from 'shared/enums';

import * as S from './NotificationList.styles';

interface IListProps {
  listItems: NotificationsModel[];
  onItemClick: (item: NotificationsModel) => void;
}

const NotificationList = (props: IListProps): ReactElement => {
  const { listItems, onItemClick } = props;

  const usersMessagesOrNotificationsReading = rolesStore.isAllow(Permissions.UsersMessagesOrNotificationsReading);

  return (
    <S.ListWrapper>
      <S.List subheader={<li />}>
        {listItems.map((item, index) => {
          if (!item.createdAt) return;

          const currentDate = new Date(item.createdAt);
          const itemDate = new Date(listItems[index ? index - 1 : index].createdAt!);

          return (
            <div key={`section-${item.id}`}>
              <ul>
                {+getStartOfDay(currentDate) !== +getStartOfDay(itemDate) || index === 0 ? (
                  <S.ListSubheader>{getDateOnly(currentDate)}</S.ListSubheader>
                ) : null}

                <S.ListItem
                  onClick={() => {
                    onItemClick(item);
                  }}
                  secondaryAction={
                    <S.ListItemTextContainer>
                      <S.TimeWrapper className={'list-item-time'}>
                        <S.ListItemTime variant="caption">{getTimeOnly(new Date(item.createdAt))}</S.ListItemTime>
                        {usersMessagesOrNotificationsReading && (
                          <S.ListItemTime variant="caption">Для пользователя: {item.userReceiverName}</S.ListItemTime>
                        )}

                        {!item.isRead && <S.IsReadIndicator />}
                      </S.TimeWrapper>
                    </S.ListItemTextContainer>
                  }>
                  <S.ListItemContent
                    primary={
                      <S.Typography style={{ fontSize: '16px' }} isRead={item.isRead}>
                        {item.caption}
                      </S.Typography>
                    }
                    secondary={
                      <S.Typography style={{ fontSize: '14px' }} paragraph={true}>
                        {item.content}
                      </S.Typography>
                    }
                  />
                </S.ListItem>
              </ul>
            </div>
          );
        })}
      </S.List>
    </S.ListWrapper>
  );
};

export default NotificationList;
