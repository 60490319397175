import { MobileDateRangePicker as MuiMobileDateRangePicker } from '@mui/lab';
import styled from 'styled-components/macro';
import { TextField as MuiTextField } from '@mui/material';

export const TextField = styled(MuiTextField)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  width: 250px;
  min-width: 200px;

  .MuiInputLabel-outlined {
    display: none;
  }

  .MuiInputBase-input {
    padding: 0 0 0 12px;
  }

  legend span {
    display: none;
  }

  & input {
    cursor: pointer;
  },
  
`;

export const DatePickerWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const MobileDateRangePicker = styled(MuiMobileDateRangePicker as any)`
  .PrivateDateRangePickerToolbar-penIcon {
    display: none;
  }
`;
