import { MenuItem as MuiMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuItem = styled(MuiMenuItem)(({ theme, iconcolor }) => ({
  svg: {
    width: 20,
    height: 20,
    stroke: theme.palette.icons.stroke,
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1.5),
  },
  '&:active': {
    backgroundColor: theme.palette.background.paper,
  },
  '&:hover': {
    svg: {
      stroke: theme.palette.primary.main,
    },
  },
}));
