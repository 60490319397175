import React, { useEffect } from 'react';

import { observer } from 'mobx-react';

import { passTemplatesStore } from 'stores';

import { PASS_TEMPLATE_ID } from 'shared/constants';
import { Divider } from '@mui/material';
import PassTemplatesForm from './Components/PassTemplatesForm/PassTemplatesForm';
import * as S from './PassTemplates.styles';

const PassTemplates: React.FC = () => {
  useEffect(() => {
    passTemplatesStore.getPassTemplate(PASS_TEMPLATE_ID);
  }, []);

  return (
    <>
      <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
      <S.Box>
        <PassTemplatesForm />
      </S.Box>
    </>
  );
};

export default observer(PassTemplates);
