import React from 'react';

const DuplicateIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7104 21.2504H8.48044C7.08044 21.2504 5.94043 20.1104 5.94043 18.7104V8.48043C5.94043 7.08043 7.08044 5.94042 8.48044 5.94042H18.7104C20.1104 5.94042 21.2504 7.08043 21.2504 8.48043V18.7104C21.2504 20.1104 20.1104 21.2504 18.7104 21.2504Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.48999 17.32C3.02999 16.86 2.75 16.22 2.75 15.52V5.29C2.75 3.89 3.89001 2.74999 5.29001 2.74999H15.52C16.31 2.74999 17.02 3.10999 17.49 3.67999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DuplicateIcon;
