import React, { useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { Input, Title } from 'components';

import CheckIcon from 'components/Icons/CheckIcon';
import Loupe from 'components/Icons/Loupe';
import SelectExpand from 'components/Icons/SelectExpand';
import { IDropDownCustomOption, IDropDownOption } from 'shared/interfaces/app';
import { Typography, useTheme, FormHelperText } from '@mui/material';
import { COMPANY_OPTION_ID, DEPARTMENT_OPTION_ID } from 'shared/constants';

import * as S from './DropDown.styles';

interface IDropDownProps {
  customOptions?: IDropDownCustomOption[];
  name: string;
  value: string;
  hideNotSelectItem?: boolean;
  placeholder?: string;
  disabled?: boolean;
  withoutSearch?: boolean;
  options: IDropDownOption[];
  dropDownLabel?: string;
  error?: any;
  helperText?: any;
  mb?: number;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
}

const DropDown: React.FC<IDropDownProps> = (props) => {
  const { name, dropDownLabel, disabled, withoutSearch, value, error, helperText, placeholder, options, hideNotSelectItem, mb, onChange, onBlur } =
    props;
  const theme = useTheme();

  const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const [searchText, setSearchText] = useState('');

  const displayedBoldOptions = useMemo(
    () => options.filter((option) => option.id === COMPANY_OPTION_ID || option.id === DEPARTMENT_OPTION_ID),
    [options, searchText]
  );

  const displayedOptions = useMemo(() => options.filter((option) => containsText(option.name, searchText)), [options, searchText]);

  const iconRenderer = (props: any) => {
    return <div {...props}>{<SelectExpand />} </div>;
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: 'common.white',
      },
    },
    autoFocus: false,
    sx: {
      maxHeight: '432px',
      '&& .Mui-selected': {
        backgroundColor: '#FFF',
        ':hover': {
          backgroundColor: '#FFF',
        },
      },
    },
  };

  const placeHolder = (
    <Typography variant="body2" sx={{ opacity: 0.4 }}>
      {placeholder}
    </Typography>
  );

  return (
    <S.DropDownWrapper>
      {dropDownLabel && <Title label={dropDownLabel} />}
      <S.FormControl mb={mb ?? 0}>
        <S.Select
          focus_visible_class_name=".Mui-focusVisible"
          displayEmpty
          disabled={disabled ?? false}
          fullWidth
          name={name}
          MenuProps={MenuProps}
          value={value}
          IconComponent={iconRenderer}
          error={error}
          onChange={onChange}
          onBlur={onBlur}
          onClose={() => setSearchText('')}
          renderValue={() => (value !== '' ? (options.find((_) => _.id === value) ?? { name: ' ' }).name : placeHolder)}>
          {!withoutSearch && (
            <S.ListSubheader>
              <Input
                autoFocus={false}
                name="search"
                value={searchText}
                placeholder="Поиск..."
                startIcon={<Loupe />}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </S.ListSubheader>
          )}

          {!hideNotSelectItem && (
            <S.MenuItemNotSelect disableRipple value="">
              <Typography>--- Не выбрано ---</Typography>
            </S.MenuItemNotSelect>
          )}

          {displayedOptions.map((option) => (
            <S.MenuItem disableRipple key={option.id} value={option.id}>
              <S.MenuItemRow>
                {displayedBoldOptions.includes(option) ? (
                  <Typography variant="body2">{option.name}</Typography>
                ) : (
                  <Typography>{option.name}</Typography>
                )}
                {option.id === value && <CheckIcon color={theme.palette.primary.main} />}
              </S.MenuItemRow>
            </S.MenuItem>
          ))}
        </S.Select>
        {error ? <FormHelperText error>{helperText}</FormHelperText> : null}
      </S.FormControl>
    </S.DropDownWrapper>
  );
};

export default observer(DropDown);
