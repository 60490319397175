import React from 'react';
import { observer } from 'mobx-react';
import { appStore, mapStore, workPlacesStore, rolesStore } from 'stores';
import NavigationIcon from 'components/Icons/NavigationIcon';
import { WorkPlaceType } from 'shared/enums';
import { IWorkPlacesOutsideGetDto } from 'shared/interfaces/api';
import { MenuItem, Typography } from '@mui/material';
import { Permissions } from 'shared/enums';

import * as S from './UnplacedWorkPlaces.styles';

const UnplacedWorkPlaces: React.FC = () => {
  const onBtnClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (appStore.popupMenuAnchorEl !== null) {
      appStore.setAnchorEl(null);
    } else {
      appStore.setAnchorEl(event.currentTarget);
      appStore.setSavePopupMenuAnchorEl(event.currentTarget);
      workPlacesStore.getWorkPlaceOutsideList(mapStore.selectedFloorImage.id);
    }
  };

  const handleClose = () => {
    appStore.setAnchorEl(null);
  };

  const workplaceUpdatingAllow = rolesStore.isAllow(Permissions.WorkplaceUpdating);

  return (
    <div style={{ right: '24px', marginTop: '10px', position: 'absolute', zIndex: 10, backgroundColor: '#ffffffaa', borderRadius: '16px' }}>
      {workplaceUpdatingAllow && (
        <S.Button sx={{ cursor: 'pointer' }} color="primary" variant="outlined" size="large" startIcon={<NavigationIcon />} onClick={onBtnClick}>
          <Typography sx={{ mt: 0.5 }} variant="body2">
            Разместить
          </Typography>
        </S.Button>
      )}

      <S.Menu anchorEl={appStore.popupMenuAnchorEl} open={Boolean(appStore.popupMenuAnchorEl)} onClose={handleClose} onClick={handleClose}>
        {workPlacesStore.workPlacesOutsideList.length !== 0 ? (
          <div style={{ maxHeight: 500, marginTop: '10px', overflow: 'auto' }}>
            {workPlacesStore.workPlacesOutsideList.map((item: IWorkPlacesOutsideGetDto) => {
              const onUnplacedWorkPlaceClick = () => {
                appStore.setAnchorEl(null);
                mapStore.setIsDraw(true);
                mapStore.setIsPosition(true);
                workPlacesStore.selectedWorkPlace.setType(item.type);
                workPlacesStore.selectedWorkPlace.setId(item.id);
              };

              return (
                <S.MenuItem key={item.id} disableRipple onClick={onUnplacedWorkPlaceClick}>
                  {item.type.toString() === WorkPlaceType.WorkPlace ? (
                    <S.WPIcon src="/images/work-place-free-icon.svg" alt="WP" />
                  ) : (
                    <S.MRIcon src="/images/meeting-room-free.svg" alt="MR" />
                  )}
                  <Typography variant="caption" sx={{ ml: 3 }}>
                    {item.name}
                  </Typography>
                </S.MenuItem>
              );
            })}
          </div>
        ) : (
          <Typography sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} variant="caption">
            Нет неразмещенных <br /> объектов
          </Typography>
        )}
      </S.Menu>
    </div>
  );
};

export default observer(UnplacedWorkPlaces);
