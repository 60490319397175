import React from 'react';
import { Tag, Toggler } from 'components';
import { ITags } from 'shared/interfaces/app';
import { useBreakpoint } from 'utils/hooks';
import { Box } from '@mui/material';

interface IFloorsPanelProps {
  hideFloors?: boolean;
  floors: ITags[];
  selectedFloor: string;
  floorImages: ITags[];
  selectedFloorImage: string;
  onChangeFloorImage: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  onChangeFloor: (event: React.MouseEvent<HTMLElement>, value: string) => void;
}

const FloorsPanel: React.FC<IFloorsPanelProps> = (props) => {
  const { hideFloors, floors, selectedFloor, floorImages, selectedFloorImage, onChangeFloorImage, onChangeFloor } = props;

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  return (
    <>
      {isMobile ? (
        <>
          {!hideFloors && <Tag size="small" orientation="vertical" tags={floors} value={selectedFloor} onCustomChange={onChangeFloor} />}

          {!isMobile ? (
            <Box sx={{ width: '100%' }}>
              <Toggler size="small" tabs={floorImages} value={selectedFloorImage} onChange={onChangeFloorImage} />
            </Box>
          ) : (
            <Tag size="small" orientation="vertical" tags={floorImages} value={selectedFloorImage} onCustomChange={onChangeFloorImage} />
          )}
        </>
      ) : (
        <>
          {!isMobile ? (
            <Box sx={{ width: 'max-content' }}>
              <Toggler size="small" tabs={floorImages} value={selectedFloorImage} onChange={onChangeFloorImage} />
            </Box>
          ) : (
            <Tag size="small" orientation="vertical" tags={floorImages} value={selectedFloorImage} onCustomChange={onChangeFloorImage} />
          )}

          {!hideFloors && (
            <Box sx={{ width: 'max-content' }}>
              <Tag size="small" orientation="vertical" tags={floors} value={selectedFloor} onCustomChange={onChangeFloor} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default FloorsPanel;
