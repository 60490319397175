import { observer } from 'mobx-react';
import { usersStore, rolesStore } from 'stores';
import React, { useEffect } from 'react';
import { Permissions } from 'shared/enums';
import { Typography } from '@mui/material';

import * as S from './UserPanel.styles';

interface IUserPanelProps {
  userId: string;
}

const UserPanel: React.FC<IUserPanelProps> = (props) => {
  const { userId } = props;

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    await usersStore.getUserById(userId);
  };

  const user = usersStore.selectedUser;
  const canViewUserDetails = rolesStore.isAllow(Permissions.ReservationUserInfoView);

  return (
    <>
      {user && (
        <S.UserInfoWrapper>
          <Typography variant="body2">{user.fullName}</Typography>
          {canViewUserDetails && (
            <>
              <S.UserInfoRow>
                <S.RowLabel>E-Mail:</S.RowLabel>
                <Typography>{user.email}</Typography>
              </S.UserInfoRow>
              {user.phone && (
                <S.UserInfoRow>
                  <S.RowLabel>Телефон:</S.RowLabel>
                  <Typography>{user.phone}</Typography>
                </S.UserInfoRow>
              )}
              {user.position && (
                <S.UserInfoRow>
                  <S.RowLabel>Должность:</S.RowLabel>
                  <Typography>{user.position}</Typography>
                </S.UserInfoRow>
              )}
              {user.subCompanyName && (
                <S.UserInfoRow>
                  <S.RowLabel>Подразделение:</S.RowLabel>
                  <Typography>{user.subCompanyName}</Typography>
                </S.UserInfoRow>
              )}
            </>
          )}
        </S.UserInfoWrapper>
      )}
    </>
  );
};

export default observer(UserPanel);
