import styled from 'styled-components/macro';
import { Accordion as MuiAccordion } from '@mui/material';

export const Accordion = styled(MuiAccordion)`
  .MuiAccordionSummary-content {
    ${(props) => props.theme.breakpoints.down('sm')} {
      margin: ${(props) => props.theme.spacing(2)};
    }
  }

  .MuiAccordionSummary-root {
    ${(props) => props.theme.breakpoints.down('sm')} {
      min-height: 20px;
    }
  }

  margin: 0;
  background-color: white;
  box-shadow: none;

  & :before,
  .Mui-focusVisible {
    background-color: white;
  }
`;
