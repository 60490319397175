import React from 'react';

import { observer } from 'mobx-react';

import { Snackbar, Alert } from '@mui/material';
import toastStore from './toastStore';

const Toast: React.FC = () => {
  return (
    <Snackbar
      style={{ zIndex: 10001 }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={toastStore.isOpen}
      autoHideDuration={toastStore.duration}
      onClose={toastStore.onClose}>
      <Alert onClose={toastStore.onClose} severity={toastStore.messageType}>
        {toastStore.message}
      </Alert>
    </Snackbar>
  );
};

export default observer(Toast);
