import { createGlobalStyle } from 'styled-components/macro';
import { GlobalStyleProps } from 'shared/interfaces/app';

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    margin: 0;
  }
`;

export default GlobalStyle;
