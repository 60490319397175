import styled from 'styled-components/macro';
import { Menu as MuiMenu, MenuItem as MuiMenuItem, Button as MuiButton } from '@mui/material';

export const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2),
    minWidth: 180,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      flex: '1 auto',
      fontSize: '0.8125rem',
      fontWeight: 500,
      lineHeight: 1,
      color: theme.palette.text.secondary,
      padding: theme.spacing(2),
      height: '36px',
      borderRadius: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      svg: {
        stroke: theme.palette.grey[50],
      },
      '& .MuiSvgIcon-root': {
        fontSize: 24,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: theme.palette.background.paper,
      },
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        svg: {
          stroke: theme.palette.primary.main,
        },
      },
    },
  },
}));

export const MenuItem = styled(MuiMenuItem)`
  display: flex;
  flex: 1 auto;
`;

export const WPIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const MRIcon = styled.img`
  width: 45px;
  height: 25px;
`;

export const Button = styled(MuiButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
