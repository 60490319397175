import React from 'react';

import { IIconProps } from 'shared/interfaces/app';

import { IThemeExtendedColors } from 'shared/interfaces/app/IThemeExtendedColors';
import { useTheme } from '@mui/material';

const CheckIcon: React.FC<IIconProps> = ({ color }) => {
  const theme = useTheme();
  const iconColor = color ? color : theme.palette.common.white;
  const strokeColor = (theme.palette as IThemeExtendedColors).icons;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={strokeColor?.stroke} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_131_8945)">
        <g filter="url(#filter0_dii_131_8945)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7071 6.29289C21.0976 6.68342 21.0976 7.31658 20.7071 7.70711L12.1213 16.2929C10.9497 17.4645 9.05026 17.4645 7.87868 16.2929L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929C4.68342 10.9024 5.31658 10.9024 5.70711 11.2929L9.29289 14.8787C9.68342 15.2692 10.3166 15.2692 10.7071 14.8787L19.2929 6.29289C19.6834 5.90237 20.3166 5.90237 20.7071 6.29289Z"
            fill={iconColor}
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_dii_131_8945" x="0" y="5" width="25" height="20.1716" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_131_8945" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.227451 0 0 0 0 0.364706 0 0 0 0.1 0" />
          <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_131_8945" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_131_8945" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_131_8945" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
          <feBlend mode="multiply" in2="effect2_innerShadow_131_8945" result="effect3_innerShadow_131_8945" />
        </filter>
        <clipPath id="clip0_131_8945">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
