import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import QrScanner from 'qr-scanner';

import { reservationStore as store } from 'stores';
import { Spinner } from 'components';
import { Button, Typography } from '@mui/material';

import * as S from './QrDialog.styles';
import ScanResult = QrScanner.ScanResult;

const QrDialog: React.FC = () => {
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [refVisible, setRefVisible] = useState(false);
  const [isWaitingCamera, setWaitingCamera] = useState(true);

  let scanner: QrScanner | null = null;

  useEffect(() => {
    if (!refVisible || !refVideo || !refVideo.current) return;

    const options = {
      maxScansPerSecond: 100,
      highlightScanRegion: true,
      highlightCodeOutline: true,
    };
    scanner = new QrScanner(refVideo.current, scanResult, options);

    setWaitingCamera(true);
    scanner.start().then(() => setWaitingCamera(false));

    return () => {
      if (scanner) scanner.stop();
    };
  }, [refVisible]);

  const closeDialog = () => {
    store.isShowQrDialog = false;
  };

  const scanResult = (result: ScanResult): void => {
    if (!scanner || !result || !result.data) return;

    scanner.stop();
    closeDialog();

    store.confirmQr(result.data);
  };

  return (
    <S.QrDialogWrapper fullScreen onClose={closeDialog} open={store.isShowQrDialog}>
      <Typography variant="h2" align="left">
        Сканирование QR кода
      </Typography>

      {isWaitingCamera && (
        <div>
          <Spinner />
        </div>
      )}

      <video
        style={{ width: '100%', height: '90%', padding: '10px' }}
        ref={(el: HTMLVideoElement | null) => {
          refVideo.current = el;
          setRefVisible(!!el);
        }}>
        <track kind="captions" />
      </video>

      <Button variant="outlined" fullWidth size="large" onClick={closeDialog}>
        Закрыть
      </Button>
    </S.QrDialogWrapper>
  );
};

export default observer(QrDialog);
