import styled from 'styled-components/macro';

export const WrapperHeader = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  img:first-child {
    margin-right: 20px;
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  margin: 20px;
`;
