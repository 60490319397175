import styled from 'styled-components/macro';
import { Alert } from '@mui/material';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(_) => _.theme.spacing(8)};

  & > div:not(:last-child) {
    margin-bottom: ${(_) => _.theme.spacing(2)};
  }
`;

export const AlertWrongSignin = styled(Alert)`
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 16px;
  color: #ff6a55;
  background-color: rgba(255, 106, 85, 0.1);

  .MuiSvgIcon-root {
    width: 27px;
    height: 27px;
  }
`;
