import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { mapStore, workPlacesStore, rolesStore } from 'stores';
import { ContextMenu } from 'components';
import DatePickerIcon from 'components/Icons/DatePickerIcon';
import DuplicateIcon from 'components/Icons/DuplicateIcon';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import EyeClose from 'components/Icons/EyeClose';
import ImageIcon from 'components/Icons/ImageIcon';
import MainIcon from 'components/Icons/MainIcon';
import Trash from 'components/Icons/Trash';
import confirmDialog from 'components/UIKit/ConfirmDialog';
import { IConfirmDialog } from 'components/UIKit/ConfirmDialog/confirmDialogStore';
import { ROUTE } from 'routes';
import { DialogStatus, WorkPlaceActions, WorkPlaceActionsName, Permissions } from 'shared/enums';
import { Typography } from '@mui/material';

import * as S from './ContextMenuActions.styles';

const ContextMenuActions: React.FC = () => {
  const [selectedMode, setSelectedMode] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (workPlacesStore.anchorEl) {
      setSelectedMode(Boolean(workPlacesStore.workPlaces.find((_) => _.selected)));
    }
  }, [workPlacesStore.anchorEl]);

  const handleCloseContextMenu = () => {
    workPlacesStore.setAnchorEl(null);
  };

  const onHideWorkPlace = async () => {
    if (selectedMode) {
      const promiseArray = workPlacesStore.selectedWorkPlaces.map((item) => {
        return workPlacesStore.hideWorkPlaceFromMap(item.id);
      });

      await Promise.all(promiseArray);
      workPlacesStore.getWorkPlacesList();
    } else {
      if (!workPlacesStore.selectedWorkPlace.id) return;
      await workPlacesStore.hideWorkPlaceFromMap(workPlacesStore.selectedWorkPlace.id);
      workPlacesStore.getWorkPlacesList();
    }

    workPlacesStore.setAnchorEl(null);
  };

  const onEditWorkPlace = async () => {
    await workPlacesStore.setEditingMode();
    handleCloseContextMenu();
  };

  const onDuplicateWorkPlace = async () => {
    mapStore.setIsPosition(false);
    mapStore.setIsDraw(true);
    mapStore.setIsDuplicate(true);
    handleCloseContextMenu();
  };

  const onOpenGallery = async () => {
    await workPlacesStore.getWorkPlaceById(workPlacesStore.selectedWorkPlace.id);
    mapStore.setOpenGallery(true);
    workPlacesStore.getWorkPlaceImageList();
    handleCloseContextMenu();
  };

  const onScheduleOpen = async () => {
    await workPlacesStore.getWorkPlacesAvailableBookingList();
    const singleCalendarLink = `${ROUTE.ReservationSingle}?workplaceId=${workPlacesStore.workPlaceStatus?.id}`;
    history.push(singleCalendarLink);
    handleCloseContextMenu();
  };

  const onAddZoneWorkPlace = async () => {
    if (selectedMode) {
      mapStore.setDialogZonesViewMode(DialogStatus.Editing);
    } else {
      if (!workPlacesStore.selectedWorkPlace.id) return;
      await workPlacesStore.getWorkPlaceById(workPlacesStore.selectedWorkPlace.id);
      mapStore.setDialogZonesViewMode(DialogStatus.Editing);
    }
  };

  const onDelete = async (data?: string | string[]) => {
    if (selectedMode) {
      const deleteDialogParams: IConfirmDialog = {
        title: 'Удалить выбранные рабочие места?',
      };
      const isConfirm = await confirmDialog.open(deleteDialogParams);
      if (!isConfirm) return;

      const promiseArray = workPlacesStore.selectedWorkPlaces.map((item) => {
        return workPlacesStore.removeWorkPlace(item.id);
      });

      await Promise.all(promiseArray);
      workPlacesStore.getWorkPlacesList();
    } else {
      const deleteDialogParams: IConfirmDialog = {
        title: 'Удалить объект?',
      };
      const isConfirm = await confirmDialog.open(deleteDialogParams);
      if (!isConfirm) return;

      await workPlacesStore.removeWorkPlace(workPlacesStore.selectedWorkPlace.id);
      workPlacesStore.getWorkPlacesList();
    }
    handleCloseContextMenu();
  };

  const workplaceCreatingAllow = rolesStore.isAllow(Permissions.WorkplaceCreating);
  const workplaceRemovingAllow = rolesStore.isAllow(Permissions.WorkplaceRemoving);
  const workplaceUpdatingAllow = rolesStore.isAllow(Permissions.WorkplaceUpdating);
  const workplaceGalaryUpdatingAllow = rolesStore.isAllow(Permissions.WorkplaceGalaryUpdating);

  const menuItems = () => {
    const actionArr: Array<JSX.Element> = [];

    if (workplaceRemovingAllow) {
      actionArr.unshift(
        <S.MenuItem onClick={onDelete} disableRipple key={WorkPlaceActions.Delete}>
          <Trash />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.Delete)}
          </Typography>
        </S.MenuItem>
      );
    }

    if (workplaceUpdatingAllow) {
      actionArr.unshift(
        <S.MenuItem onClick={onHideWorkPlace} disableRipple key={WorkPlaceActions.Hide}>
          <EyeClose />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.Hide)}
          </Typography>
        </S.MenuItem>
      );
    }

    if (workplaceUpdatingAllow) {
      actionArr.unshift(
        <S.MenuItem onClick={onAddZoneWorkPlace} disableRipple key={WorkPlaceActions.AddToZone}>
          <MainIcon />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.AddToZone)}
          </Typography>
        </S.MenuItem>
      );
    }

    if (!selectedMode && workplaceGalaryUpdatingAllow) {
      actionArr.unshift(
        <S.MenuItem onClick={onOpenGallery} disableRipple key={WorkPlaceActions.Photo}>
          <ImageIcon />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.Photo)}
          </Typography>
        </S.MenuItem>
      );
    }

    if (!selectedMode && rolesStore.isAllow()) {
      actionArr.unshift(
        <S.MenuItem onClick={onScheduleOpen} disableRipple key={WorkPlaceActions.Schedule}>
          <DatePickerIcon />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.Schedule)}
          </Typography>
        </S.MenuItem>
      );
    }

    if (!selectedMode && workplaceCreatingAllow) {
      actionArr.unshift(
        <S.MenuItem onClick={onDuplicateWorkPlace} disableRipple key={WorkPlaceActions.Duplicate}>
          <DuplicateIcon />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.Duplicate)}
          </Typography>
        </S.MenuItem>
      );
    }

    if (!selectedMode && workplaceUpdatingAllow) {
      actionArr.unshift(
        <S.MenuItem onClick={onEditWorkPlace} disableRipple key={WorkPlaceActions.Edit}>
          <EditPencilIcon />
          <Typography sx={{ ml: 2 }} variant="caption">
            {WorkPlaceActionsName.get(WorkPlaceActions.Edit)}
          </Typography>
        </S.MenuItem>
      );
    }

    return actionArr;
  };

  const anyMenuItems = menuItems() && menuItems().length > 0;

  return (
    <>
      {anyMenuItems && (
        <ContextMenu
          anchorEl={workPlacesStore.anchorEl}
          open={Boolean(workPlacesStore.anchorEl)}
          onClose={handleCloseContextMenu}
          menuItems={menuItems()}></ContextMenu>
      )}
    </>
  );
};

export default observer(ContextMenuActions);
