import { makeAutoObservable } from 'mobx';

const DURATION = 4000;
const SUCCESS_MESSAGE = 'Операция выполена успешно';
const ERROR_MESSAGE = 'Во время выполнения произошла ошибка';

export enum ToastMessageType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

class ToastStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public duration = DURATION;

  public isOpen = false;

  public message = '';

  public messageType: ToastMessageType = ToastMessageType.Success;

  public showSuccess(message?: string) {
    this.isOpen = true;
    this.message = message || SUCCESS_MESSAGE;
    this.messageType = ToastMessageType.Success;
  }

  public showError(message?: string) {
    this.isOpen = true;
    this.message = message || ERROR_MESSAGE;
    this.messageType = ToastMessageType.Error;
  }

  public showWarning(message?: string) {
    this.isOpen = true;
    this.message = message || ERROR_MESSAGE;
    this.messageType = ToastMessageType.Warning;
  }

  public onClose() {
    this.isOpen = false;
    this.message = '';
  }
}

export default new ToastStore();
