import { KeyboardEvent } from 'react';
import L from 'leaflet';
import { makeAutoObservable, runInAction } from 'mobx';

import { rolesStore, workPlacesStore } from 'stores';
import { FloorsApi } from 'api';
import { MATTERPORT_TOOGLE_ID, MATTERPORT_TOOGLE_NAME } from 'shared/constants';
import { DialogStatus, FloorType, FloorTypeNames, ImageType, Permissions, WorkPlaceType } from 'shared/enums';
import { IFloorGetDto, IFloorImageGetDto, IFloorImagesGetDto, IFloorsGetDto } from 'shared/interfaces/api';
import { ITags, ITag, IDropDownOption } from 'shared/interfaces/app';
import { WorkPlaceAvailableBookingModel, WorkPlaceListModel } from 'shared/models';
import { FloorImageModel, FloorModel, MapStateModel } from 'shared/models/MapModel';
import { getBodyFromUrl, getImgSize } from 'utils/FileUtils';

class mapStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isLoadMap = false;

  public zoom: number | null = null;

  public isShowObjects = false;

  public pixelsPerMeter = 20;

  public fitZoom = 1;

  public mapState = new MapStateModel();

  public floors: FloorModel[] = [];

  public floorsDropDownList: IDropDownOption[] = [];

  public dialogFloorViewMode = DialogStatus.Closed;

  public dialogFloorImageViewMode = DialogStatus.Closed;

  public selectedFloor = new FloorModel();

  public floorImages: IFloorImagesGetDto[] = [];

  public isDraw = false;

  public isPosition = false;

  public isDuplicate = false;

  public isSelected = false;

  public isOpenGallery = false;

  public dialogZonesViewMode = DialogStatus.Closed;

  public keyDownEvent: KeyboardEvent<HTMLImageElement> | null = null;

  public selectedFloorImage = new FloorImageModel();

  public setMapParams(pixelsPerMeter: number, fitZoom: number) {
    this.pixelsPerMeter = pixelsPerMeter;
    this.fitZoom = fitZoom;
  }

  public floorTypes: ITag[] = [
    {
      label: FloorTypeNames.get(FloorType.Floor)!,
      value: FloorType.Floor.toString(),
      selected: true,
    },

    {
      label: FloorTypeNames.get(FloorType.Parking)!,
      value: FloorType.Parking.toString(),
      selected: false,
    },
  ];

  public selectedFloorType: FloorType = FloorType.Floor;

  public dialogFloorMode = DialogStatus.Closed;

  public dialogFloorImageMode = DialogStatus.Closed;

  public get floorsTags(): ITags[] {
    return this.floors.map(({ id, name }) => {
      return {
        id: id,
        name: name,
      };
    });
  }

  public get floorTagsMain(): ITags[] {
    const floorTags = this.floors.filter((item) => item.mapCount > 0);

    return floorTags.map(({ id, name }) => {
      return {
        id: id,
        name: name,
      };
    });
  }

  public get floorImagesTags(): ITags[] {
    return this.floorImages.map(({ id, name }) => {
      return {
        id: id,
        name: name,
      };
    });
  }

  public get floorImagesWith3DTour(): ITags[] {
    const list = [...this.floorImagesTags];

    if (this.floorImages.length > 0 && this.selectedFloor.tourModelName && rolesStore.isAllow(Permissions.Tour3D)) {
      const tour = new FloorImageModel({
        id: MATTERPORT_TOOGLE_ID,
        name: MATTERPORT_TOOGLE_NAME,
        pixelsPerMeter: 0,
      });

      list.push(tour);
    }

    return list;
  }

  public async initMap(floorIndex: number | null, mapIndex = 0): Promise<void> {
    // Получаем этажи и выставляем 1 этаж
    if (floorIndex !== null) {
      await this.getFloorsList();
      this.setActiveFloor(floorIndex);
    }

    // Получаем карты и выставляем 1 карту
    await this.getFloorImagesList();
    this.setActiveMap(mapIndex);

    // Получаем параметры карты и размещеные объекты
    await Promise.all([this.getFloorImageById(), workPlacesStore.getWorkPlacesAvailableBookingList()]);
  }

  public setActiveFloor(): void;
  public setActiveFloor(floor: IFloorGetDto): void;
  public setActiveFloor(floor: number): void;
  public setActiveFloor(floor?: number | IFloorGetDto): void {
    // Индекс
    if (typeof floor === 'number') {
      const floorIndex = floor as number;
      this.selectedFloor = new FloorModel(this.floors[floorIndex]);
      return;
    }

    // Dto
    const instanceOfIFloorGetDto = (object: any): object is IFloorGetDto => !!object?.id;
    if (instanceOfIFloorGetDto(floor)) {
      const dto = floor as IFloorGetDto;
      this.selectedFloor = new FloorModel(dto);
      return;
    }

    // Void
    if (!floor) {
      this.selectedFloor = new FloorModel();
    }
  }

  public setActiveMap(): void;
  public setActiveMap(map: IFloorImageGetDto): void;
  public setActiveMap(map: number): void;
  public setActiveMap(map: string): void;
  public setActiveMap(map?: number | string | IFloorImageGetDto): void {
    // Индекс
    if (typeof map === 'number') {
      const mapIndex = map as number;
      this.selectedFloorImage = new FloorImageModel(this.floorImages[mapIndex]);
      return;
    }

    // Id
    if (typeof map === 'string') {
      const mapId = map as string;
      this.selectedFloorImage = new FloorImageModel(this.floorImages.find((_) => _.id === mapId));
      return;
    }

    // Dto

    const instanceOfIFloorImageGetDto = (object: any): object is IFloorImageGetDto => !!object?.id;
    if (instanceOfIFloorImageGetDto(map)) {
      const dto = map as IFloorImageGetDto;
      this.selectedFloorImage = new FloorImageModel(dto);
      return;
    }

    // Void
    if (!map) {
      this.selectedFloorImage = new FloorImageModel();
    }
  }

  public onChangeZoom(zoom: number): void {
    this.zoom = zoom;
    const size = this.getSize();
    const fontSize = Math.round(size / 3);
    this.isShowObjects = fontSize > 6;
  }

  private getSize(): number {
    if (!this.pixelsPerMeter) return 1;

    const maxSize = this.pixelsPerMeter;
    const zoom = Math.abs(this.zoom!);

    const k = this.fitZoom > 0 ? this.fitZoom : 1;
    return (maxSize + 1) / (1 + zoom * k) + 1;
  }

  public getIcon(
    mapObject: WorkPlaceAvailableBookingModel | WorkPlaceListModel,
    availableBooking: boolean,
    isSelected = false,
    customIcon: string | null = null
  ) {
    const { type, iconSize, fontSize, url } = this.getWorkPlaceIcon(mapObject.type, availableBooking);

    let imgStyle = '';
    let labelStyle = '';
    if (isSelected) {
      imgStyle = 'style="filter: drop-shadow(0px 0px 4px red)"';
      labelStyle = 'background-color: #ff000057';
    }
    let html = `<img src="${customIcon ? customIcon : url}" alt="" ${imgStyle} />`;
    if (this.isShowObjects) html += `<div style="font-size: ${fontSize}px; ${labelStyle}">${mapObject.number}</div>`;

    return new L.DivIcon({ attribution: type, iconSize, className: 'div-icon', html });
  }

  public getIconSize() {
    let size = this.getSize();
    if (size <= 10) size = 10;
    if (size > 30) size = 30;

    const fontSize = Math.round(size / 3);
    const meetingK = 1.7;

    const workplace: L.PointTuple = [size, size];
    const meeting: L.PointTuple = [size * meetingK, size * meetingK * 0.6];

    return { workplace, meeting, fontSize };
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogFloorMode = newDialogViewMode;
  }

  public setDialogZonesViewMode(newDialogViewMode: DialogStatus) {
    this.dialogZonesViewMode = newDialogViewMode;
  }

  public setIsDraw(draw: boolean) {
    this.isDraw = draw;
  }

  public setIsPosition(isPosition: boolean) {
    this.isPosition = isPosition;
  }

  public setOpenGallery(isOpen: boolean) {
    this.isOpenGallery = isOpen;
  }

  public setIsDuplicate(isDuplicate: boolean) {
    this.isDuplicate = isDuplicate;
  }

  public setKeyDownEvent(event: KeyboardEvent<HTMLImageElement>) {
    if (event.type === 'keydown' && event.key === 'Shift') {
      this.isSelected = true;
    }
    if (event.type === 'keyup' && event.key === 'Shift') {
      this.isSelected = false;
    }

    this.keyDownEvent = event;
  }

  public setDialogFloorImageMode(newDialogViewMode: DialogStatus) {
    this.dialogFloorImageMode = newDialogViewMode;
  }

  public closeFloorDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public async closeFloorImageDialog() {
    this.setDialogFloorImageMode(DialogStatus.Closed);
    workPlacesStore.clear();
    await this.getFloorImageById();
    workPlacesStore.getWorkPlacesList().finally();
  }

  public setCreatingMode() {
    this.setActiveFloor();
    this.selectedFloor.updateFromToolbarFilter(this.selectedFloorType);
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public openFloorImageDialog() {
    this.setActiveMap();
    this.setDialogFloorImageMode(DialogStatus.Creating);
  }

  public async getFloorsList() {
    try {
      const floors = await FloorsApi.getFloors(this.selectedFloorType);
      if (!floors) return;

      this.setFloors(floors);
    } catch (e) {
      //ignore
    }
  }

  public setFloors(floors: IFloorsGetDto[]) {
    this.floors = floors.map((_) => new FloorModel(_));
  }

  public async getFloorsDropDownList() {
    try {
      const floors = await FloorsApi.getFloors(null);
      if (!floors) return;

      runInAction(() => (this.floorsDropDownList = floors.map((_) => ({ id: _.id, name: _.name }))));
    } catch (e) {
      //ignore
    }
  }

  public async getFloorImageById(floorImageId?: string) {
    try {
      const mapId = floorImageId ? floorImageId : this.selectedFloorImage.id;

      if (!mapId) return;

      this.isLoadMap = true;
      const image = await FloorsApi.getFloorImage(this.selectedFloor.id, mapId);
      if (!image) throw Error('Wrong image');

      this.setActiveMap(image);

      const url = this.selectedFloorImage.imageURL();
      const { base64, type } = await getBodyFromUrl(url);

      if (!Object.values(ImageType).find((_: string) => _ === <ImageType>type)) throw 'Wrong image type';

      const size = await getImgSize(url);

      this.selectedFloorImage.type = <ImageType>type;
      this.selectedFloorImage.width = size.width;
      this.selectedFloorImage.height = size.height;

      this.setImageBase64(base64);
    } catch (e) {
      //ignore
      console.log(e);
    } finally {
      this.isLoadMap = false;
    }
  }

  public setImageBase64(base64: string) {
    this.selectedFloorImage.imageBase64 = base64;
  }

  public async createFloorImage(): Promise<void> {
    if (this.selectedFloorImage.postPutDto === null) return;
    try {
      const newFloorImage = await FloorsApi.postFloorImage(this.selectedFloor.id, this.selectedFloorImage.postPutDto);
      if (!newFloorImage) return;
      this.selectedFloorImage.setFloorImageId(newFloorImage);
    } catch (e) {
      //ignore
    }
  }

  public async updateFloorImage(): Promise<void> {
    if (this.selectedFloor.postPutDto === null || this.selectedFloor.id === null) return;
    try {
      await FloorsApi.putFloorImage(this.selectedFloor.id, this.selectedFloorImage.id, this.selectedFloorImage.postPutDto);
    } catch (e) {
      //ignore
    }
  }

  public async getFloorImagesList(floorId?: string) {
    try {
      const floorID = this.selectedFloor.id;
      const floorImages = await FloorsApi.getFloorImages(floorId ? floorId : floorID);
      if (!floorImages) return;
      this.setMaps(floorImages);
    } catch (e) {
      //ignore
    }
  }

  public setMaps(floorImages: IFloorImagesGetDto[]) {
    this.floorImages = floorImages.map((_) => ({ id: _.id, name: _.name, pixelsPerMeter: 0 }));
  }

  public async getFloorById(floorId: string) {
    try {
      const floor = await FloorsApi.getFloor(floorId);
      if (!floor) return;

      this.setActiveFloor(floor);
    } catch (e) {
      //ignore
    }
  }

  public async createFloor(): Promise<string> {
    if (this.selectedFloor.postPutDto === null) return '';
    try {
      const newFloor = await FloorsApi.postFloor(this.selectedFloor.postPutDto);
      if (!newFloor) return '';

      return newFloor;
    } catch (e) {
      return '';
    }
  }

  public async updateFloor(): Promise<void> {
    if (this.selectedFloor.postPutDto === null || this.selectedFloor.id === null) return;
    try {
      await FloorsApi.putFloor(this.selectedFloor.id, this.selectedFloor.postPutDto);
    } catch (e) {
      //ignore
    }
  }

  public async removeFloor(): Promise<void> {
    try {
      await FloorsApi.deleteFloor(this.selectedFloor.id);
      await this.getFloorsList();
    } catch (e) {
      //ignore
    }
  }

  public async removeFloorImage(): Promise<void> {
    try {
      await FloorsApi.deleteFloorImage(this.selectedFloor.id, this.selectedFloorImage.id);
      await this.getFloorImagesList();
    } catch (e) {
      //ignore
    }
  }

  public clear() {
    this.floors = [];
    this.floorImages = [];
    this.floorsDropDownList = [];
  }

  private getWorkPlaceIcon(type: WorkPlaceType, availableBooking: boolean): any {
    let url: string;
    let iconSize: number[];

    const { workplace, meeting, fontSize } = this.getIconSize();

    if (type == WorkPlaceType.Meeting) {
      url = availableBooking ? '/images/meeting-room-free.svg' : '/images/meeting-room-booked.svg';
      iconSize = meeting;
    } else {
      url = availableBooking ? '/images/work-place-free-icon.svg' : '/images/work-place-booked-icon.svg';
      iconSize = workplace;
    }

    return { url, iconSize, type, fontSize };
  }
}

export default new mapStore();
