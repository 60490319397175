import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { mapStore, workPlacesStore, zonesStore, rolesStore } from 'stores';
import { Checkbox, DropDownChip, Input, ModalDialog, NumberInput, Tag, Title, Toggler, Upload } from 'components';
import { ITabToggler } from 'components/UIKit/Toggler/Toggler';
import { DialogStatus, WorkPlaceType } from 'shared/enums';
import { IAttributeDto } from 'shared/interfaces/api';
import { IWorkPlaceDialog } from 'shared/interfaces/app';
import { Box, Divider, Typography } from '@mui/material';
import WorkPlaceImages from './Components';
import { Permissions } from 'shared/enums';

const WorkPlaceDialog: React.FC = () => {
  const isOffice = mapStore.floorTypes[0].selected;
  const isParking = mapStore.floorTypes[1].selected;

  const onCloseDialog = () => {
    workPlacesStore.closeDialog();
  };

  useEffect(() => {
    zonesStore.getZonesList();

    return () => {
      mapStore.setIsDraw(false);
      mapStore.setIsDuplicate(false);
    };
  }, []);

  useEffect(() => {
    workPlacesStore.getWorkPlaceAttributesList();
  }, [workPlacesStore.selectedWorkPlace.type]);

  const [validated, setValidated] = useState(false);

  const formRef = useRef<FormikProps<IWorkPlaceDialog>>(null);

  const dialogTitle = workPlacesStore.selectedWorkPlace.id ? 'Редактировать объект' : 'Создать объект';

  const workPlaceFormTabs: Array<ITabToggler> = [
    {
      id: WorkPlaceType.WorkPlace,
      name: 'Рабочее место',
    },
    {
      id: WorkPlaceType.Meeting,
      name: 'Переговорная комната',
    },
  ];

  const onSubmit = async (values: IWorkPlaceDialog) => {
    workPlacesStore.selectedWorkPlace.updateFromDialogForm(values);
    if (isParking) workPlacesStore.selectedWorkPlace.setType(WorkPlaceType.Parking);

    try {
      if (workPlacesStore.dialogViewMode === DialogStatus.Creating) {
        await workPlacesStore.createWorkPlace();
        await workPlacesStore.createWorkPlacePosition();
        await workPlacesStore.createWorkPlaceImage();
      } else if (workPlacesStore.dialogViewMode === DialogStatus.Editing) {
        await workPlacesStore.updateWorkPlace();
        await workPlacesStore.createWorkPlaceImage();
        await workPlacesStore.getWorkPlacesList();
      }

      workPlacesStore.selectedWorkPlace.clear();
      workPlacesStore.setDialogViewMode(DialogStatus.Closed);
    } catch (e) {
      // ignore
    }
  };

  const onChangeAvailableDays = (event: React.MouseEvent<HTMLElement>, value: string) => {
    workPlacesStore.selectedWorkPlace.allowWeekDays[parseInt(value)] = !workPlacesStore.selectedWorkPlace.allowWeekDays[parseInt(value)];
  };

  const onChangeTab = (event: React.MouseEvent<HTMLElement>, tabId: string) => {
    if (tabId === WorkPlaceType.WorkPlace) {
      workPlacesStore.selectedWorkPlace.setType(WorkPlaceType.WorkPlace);
    } else {
      workPlacesStore.selectedWorkPlace.setType(WorkPlaceType.Meeting);
    }
  };

  const prepareDataForTags = () => {
    const filterData = workPlacesStore.selectedWorkPlace.allowWeekDaysTag.filter((_) => _.isActive);
    return filterData.map((_) => _.id);
  };

  const onIsActiveChange = (event: any, checked: boolean) => {
    workPlacesStore.selectedWorkPlace.setIsActive(checked);
  };

  const onChangeComment = (e: React.ChangeEvent<any>) => {
    workPlacesStore.selectedWorkPlace.setComment(e.target.value);
  };

  const onChangeAttr = (value: number, id: string) => {
    const addAttr: IAttributeDto = {
      id: id,
      count: value,
    };

    if (workPlacesStore.selectedWorkPlace.attributes.every((attr) => attr.id !== id) || workPlacesStore.selectedWorkPlace.attributes.length === 0) {
      workPlacesStore.selectedWorkPlace.attributes.push(addAttr);
    } else {
      const index = workPlacesStore.selectedWorkPlace.attributes.findIndex((attr) => attr.id === id);
      if (index > -1) {
        workPlacesStore.selectedWorkPlace.attributes[index].count = value;
      }
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    workPlacesStore.selectedWorkPlace.uploadImage(e);
    e.target.value = '';
  };

  const draggableUpload = async (e: React.DragEvent<HTMLLabelElement>): Promise<void> => {
    e.preventDefault();
    workPlacesStore.selectedWorkPlace.uploadImage(e);
  };

  const workplaceGalaryUpdatingAllow = rolesStore.isAllow(Permissions.WorkplaceGalaryUpdating);

  return (
    <ModalDialog
      isModelHasId={workPlacesStore.selectedWorkPlace.id}
      title={dialogTitle}
      isValid={validated}
      isOpen={workPlacesStore.dialogViewMode !== DialogStatus.Closed && workPlacesStore.dialogViewMode !== DialogStatus.Deleting}
      onClose={onCloseDialog}
      onCancel={onCloseDialog}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {isOffice && (
        <Box sx={{ justifyContent: 'center', mb: 5, width: 'fit-content' }}>
          <Toggler tabs={workPlaceFormTabs} value={workPlacesStore.selectedWorkPlace.type.toString()} onChange={onChangeTab} />
        </Box>
      )}

      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          number: workPlacesStore.selectedWorkPlace.number,
          zoneIds: workPlacesStore.selectedWorkPlace.zoneIds,
          zonesOpt: zonesStore.zonesForOptions,
        }}
        validationSchema={Yup.object().shape({
          number: Yup.string().max(255).required('Обязательное поле'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
          setValidated(isValid);

          const onDelete = (e: any, value: string) => {
            e.preventDefault();

            const idx = values.zoneIds.indexOf(value);
            if (idx !== -1) {
              values.zoneIds.splice(idx, 1);
            }
            setFieldValue('zoneIds', values.zoneIds);
          };

          return (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input
                  name="number"
                  inputLabel="Номер объекта"
                  value={values.number}
                  autoFocus={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.number && errors.number)}
                  helperText={touched.number && errors.number}
                />

                <div style={{ width: '250px', marginLeft: '10px' }}>
                  <DropDownChip
                    name="zoneIds"
                    options={values.zonesOpt}
                    values={values.zoneIds}
                    placeholder="Выберите зоны"
                    dropDownLabel="Зоны"
                    onDelete={onDelete}
                    onChange={(event: any) => {
                      setFieldValue('zoneIds', event.target.value);
                    }}
                  />
                </div>
              </Box>
            </>
          );
        }}
      </Formik>

      <Box sx={{ mb: 4, mt: 4 }}>
        <Checkbox label="Доступен" checked={workPlacesStore.selectedWorkPlace.isActive} onChange={onIsActiveChange} />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 7 }}>
        <Tag
          disabledGroup={!workPlacesStore.selectedWorkPlace.isActive}
          size="small"
          orientation="horizontal"
          value={prepareDataForTags()}
          onCustomChange={onChangeAvailableDays}
          tags={workPlacesStore.daysOfWeekTags}
        />
      </Box>

      {isOffice && (
        <>
          <Title label="Параметры объекта" />
          {workPlacesStore.selectedWorkPlaceAttrList.map((item) => {
            return (
              <NumberInput
                key={item.id}
                id={item.id}
                label={item.name}
                value={item.count}
                onChange={(value, id) => {
                  onChangeAttr(value, id);
                }}
              />
            );
          })}
        </>
      )}

      <Input mb={7} multiline name="comments" inputLabel="Комментарий" value={workPlacesStore.selectedWorkPlace.comment} onChange={onChangeComment} />

      <Title label="Фотографии места" sx={{ mt: 4 }} />
      {workplaceGalaryUpdatingAllow && (
        <Upload
          title="Выберите или перетащите фото объекта"
          accept=".jpg, .jpeg, .png"
          subTitle="Файл должен быть формата JPG или PNG и не более 10 МБ"
          onChangeFile={onUpload}
          handleDrop={draggableUpload}
        />
      )}
      {!workPlacesStore.selectedWorkPlace.isValidImage && workplaceGalaryUpdatingAllow && (
        <Typography sx={{ mt: 1, ml: 3 }} variant="caption" color="error">
          Неверный формат файла
        </Typography>
      )}
      <WorkPlaceImages />
    </ModalDialog>
  );
};

export default observer(WorkPlaceDialog);
