import React, { ReactElement } from 'react';
import { messagesStore, rolesStore } from 'stores';
import { Permissions } from 'shared/enums';
import { MessagesModel } from 'shared/models';
import { getFullDateTime } from 'utils/DateTimeUtils';
import { useBreakpoint } from 'utils/hooks';
import { Button } from '@mui/material';

import * as S from './MessageList.styles';

interface IListProps {
  isShowReplyButton: boolean;
  isShowRead: boolean;
  listItems: MessagesModel[];
  onItemClick: (item: MessagesModel) => void;
  onItemHoverBtnClick: (item: MessagesModel) => void;
}

const MessageList = (props: IListProps): ReactElement => {
  const { isShowReplyButton, isShowRead, listItems, onItemClick, onItemHoverBtnClick } = props;

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const onReplyButtonClick = (e: React.MouseEvent<HTMLButtonElement>, item: MessagesModel) => {
    e.stopPropagation();
    onItemHoverBtnClick(item as MessagesModel);
  };

  const usersMessagesOrNotificationsReading = rolesStore.isAllow(Permissions.UsersMessagesOrNotificationsReading);

  return (
    <S.ListWrapper>
      <S.List subheader={<li />}>
        {listItems.map((item) => {
          if (!item.createdAt) return;

          const currentDate = new Date(item.createdAt);

          return (
            <div key={`section-${item.id}`}>
              <ul>
                <S.ListItem
                  isShowReplyButton={isShowReplyButton && isShowRead}
                  onClick={() => {
                    onItemClick(item);
                  }}
                  secondaryAction={
                    <S.ListItemTextContainer>
                      {isMobile ? null : (
                        <S.TimeWrapper className={'list-item-time'}>
                          <S.ListItemTime variant="caption">{getFullDateTime(currentDate)}</S.ListItemTime>
                          <S.ListItemTime variant="caption">{messagesStore.getMessageSubtitleName(item)}</S.ListItemTime>
                          {usersMessagesOrNotificationsReading && (
                            <S.ListItemTime variant="caption">{messagesStore.getOnlyFromMessageSubtitle(item)}</S.ListItemTime>
                          )}
                          {!item.isRead && isShowRead && <S.IsReadIndicator />}
                        </S.TimeWrapper>
                      )}

                      {isShowReplyButton && isShowRead && (
                        <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => onReplyButtonClick(e, item)} variant="outlined" size={'large'}>
                          Ответить
                        </Button>
                      )}
                    </S.ListItemTextContainer>
                  }>
                  <S.ListItemContent
                    primary={
                      <S.Typography variant={isMobile ? 'h6' : 'body2'} isRead={item.isRead}>
                        {item.caption}
                      </S.Typography>
                    }
                    secondary={
                      isMobile ? (
                        <>
                          <S.TimeWrapper className={'list-item-time'}>
                            <S.ListItemTime variant="caption">{getFullDateTime(currentDate)}</S.ListItemTime>
                            <S.ListItemTime variant="caption">{messagesStore.getMessageSubtitleName(item)}</S.ListItemTime>
                            {!item.isRead && isShowRead && <S.IsReadIndicator />}
                          </S.TimeWrapper>
                          <S.Typography variant="body2" paragraph={true}>
                            {item.content}
                          </S.Typography>
                        </>
                      ) : null
                    }
                  />
                </S.ListItem>
              </ul>
            </div>
          );
        })}
      </S.List>
    </S.ListWrapper>
  );
};

export default MessageList;
