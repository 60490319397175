import { makeAutoObservable } from 'mobx';
import { DEFAULT_COMPANY_ID } from 'shared/constants';

import { IUserGetDto, IUserPostPutDto, IUsersGetDto } from 'shared/interfaces/api';
import { IDropDownOption, IUserDialog } from 'shared/interfaces/app';

class UserModel {
  constructor(dto?: IUserGetDto & IUsersGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.roleId = dto.roleId ?? '';
    this.departmentId = dto.departmentId ?? '';
    this.isDeleted = dto.isDeleted;
    this.isConfirmed = dto.isConfirmed;
    this.isPasswordChanged = dto.isPasswordChanged!;
    this.isEnabledEmail = dto.isEnabledEmail;
    this.roleName = dto.roleName;
    this.departmentName = dto.departmentName;
    this.email = dto.email;
    this.fullName = dto.fullName;
    this.firstName = dto.firstName!;
    this.lastName = dto.lastName!;
    this.patronymic = dto.patronymic!;
    this.phone = dto.phone;
    this.subCompanyName = dto.subCompanyName;
    this.position = dto.position;
    this.isAdmin = dto.isAdmin!;
  }

  public id = '';

  public roleId = '';

  public departmentId = '';

  public companyId = '';

  public subCompanyName = '';

  public isDeleted = false;

  public isConfirmed = false;

  public isPasswordChanged = false;

  public isEnabledEmail = false;

  public roleName = '';

  public departmentName = '';

  public email = '';

  public fullName = '';

  public firstName = '';

  public lastName = '';

  public patronymic = '';

  public phone = '';

  public position = '';

  public isAdmin = false;

  public get postPutDto(): IUserPostPutDto | null {
    return {
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      patronymic: this.patronymic,
      companyId: !this.isAdmin ? DEFAULT_COMPANY_ID : null,
      roleId: this.roleId ? this.roleId : null,
      departmentId: !!this.departmentId ? this.departmentId : null,
      phone: this.phone,
      position: this.position,
      subCompanyName: this.subCompanyName,
      isAdmin: this.isAdmin,
    };
  }

  public updateUserDialogForm(model: IUserDialog) {
    this.firstName = model.firstName;
    this.email = model.email;
    this.lastName = model.lastName;
    this.patronymic = model.patronymic;
    this.subCompanyName = model.subCompanyName;
    this.roleId = model.roleId;
    this.departmentId = model.departmentId;
    this.phone = model.phone;
    this.position = model.position;
    this.isAdmin = model.isAdmin;
  }

  public getDropDownOption(): IDropDownOption {
    return { id: this.id, name: this.fullName };
  }
}

export default UserModel;
