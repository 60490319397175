import React, { useState } from 'react';
import { Title } from 'components';
import { InputAdornment } from '@mui/material';

import * as S from './Input.styles';

interface InputProps {
  name: string;
  type?: string;
  inputLabel?: string;
  readonly?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value: string | number;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  error?: any;
  autoFocus?: boolean;
  helperText?: any;
  mb?: number;
  multiline?: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onKeyDown?: (e: React.KeyboardEvent<any>) => void;
  onIconClick?: () => void;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    name,
    value,
    disabled,
    type,
    inputLabel,
    placeholder,
    startIcon,
    endIcon,
    helperText,
    multiline,
    autoFocus,
    readonly,
    mb,
    error,
    onChange,
    onBlur,
    onKeyDown,
    onIconClick,
  } = props;

  const [isFocus, setFocus] = useState(false);

  const sxIcon = onIconClick ? { cursor: 'pointer' } : {};

  const startAdornment = startIcon ? (
    <InputAdornment sx={sxIcon} onClick={onIconClick} position="start">
      {startIcon}
    </InputAdornment>
  ) : null;
  const endAdornment = endIcon ? (
    <InputAdornment sx={sxIcon} onClick={onIconClick} position="end">
      {endIcon}
    </InputAdornment>
  ) : null;

  const inputProps = {
    startAdornment: startAdornment,
    endAdornment: endAdornment,
  };

  const onblur = (e: React.FocusEvent<any>) => {
    if (onBlur) onBlur(e);
    setFocus(false);
  };

  return (
    <S.InputWrapper>
      {inputLabel && <Title label={inputLabel} />}
      <S.TextField
        inputProps={{
          readOnly: readonly ?? false,
        }}
        mb={mb ?? 2}
        focus={isFocus}
        autoFocus={autoFocus}
        name={name}
        type={type}
        fullWidth
        multiline={multiline ?? false}
        rows={6}
        disabled={disabled ?? false}
        autoComplete="off"
        hiddenLabel
        placeholder={placeholder}
        color="secondary"
        value={value}
        onChange={onChange}
        onBlur={onblur}
        onFocus={() => setFocus(true)}
        InputProps={inputProps}
        error={error}
        helperText={helperText}
        onKeyDown={onKeyDown}
      />
    </S.InputWrapper>
  );
};

export default Input;
