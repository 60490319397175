import { userAuthStore } from 'stores';
import { IReservationFilterDto } from 'shared/interfaces/api';
import { IReservationRowItemDto, ReservationCreateOrUpdateDto, IReservationDto } from 'shared/interfaces/api/reservationDto';

import { ITableDto } from 'shared/interfaces/app';

import { toUtc, utcToLocal } from 'utils/DateTimeUtils';

import HTTPService, { IHttpRequest } from './HTTPService';

class ReservationApi extends HTTPService {
  public constructor() {
    super('Reservations');
  }

  public async getRowItemList(filter: IReservationFilterDto): Promise<ITableDto<IReservationRowItemDto>> {
    if (!filter.fromTime || !filter.toTime) return new Promise(() => null);

    filter.fromTime = toUtc(filter.fromTime);
    filter.toTime = toUtc(filter.toTime);

    filter.fromTimeInDay = filter.fromTimeInDay ? toUtc(filter.fromTimeInDay) : null;
    filter.toTimeInDay = filter.toTimeInDay ? toUtc(filter.toTimeInDay) : null;

    const result = await this.GET<ITableDto<IReservationRowItemDto>>('', { params: filter });
    result.rows.forEach((row: IReservationRowItemDto) => {
      row.createdAt = utcToLocal(row.createdAt);
      row.timeFrom = utcToLocal(row.timeFrom);
      row.timeTo = utcToLocal(row.timeTo);
    });
    return result;
  }

  public async get(id: string, userId: string): Promise<IReservationDto | null> {
    const result = await this.GET<IReservationDto>(`${id}/`, { params: { userId } });
    if (!result) return null;

    ReservationApi.toLocalTimeReservationDto(result);
    return result;
  }

  public async getByWorkplaceId(workplaceId: string): Promise<IReservationDto | null> {
    const result = await this.GET<IReservationDto>(`workplace/${workplaceId}/`);
    if (!result) return null;

    ReservationApi.toLocalTimeReservationDto(result);
    return result;
  }

  public async confirm(id: string): Promise<void> {
    return await this.PUT({ path: `${id}/confirm` });
  }

  public async confirmQr(workplaceName: string): Promise<void> {
    const data = {
      userId: userAuthStore.userId,
      workplaceName: workplaceName.trim(),
    };

    return await this.PUT({ path: 'confirm-by-qr', data });
  }

  public async shift(id: string): Promise<void> {
    return await this.PUT({ path: `${id}/shift` });
  }

  public async cancel(id: string): Promise<void> {
    return await this.PUT({ path: `${id}/cancel` });
  }

  public async update(id: string, data: ReservationCreateOrUpdateDto | null): Promise<void> {
    if (!data) return;

    data.fromTime = toUtc(data.fromTime);
    data.toTime = toUtc(data.toTime);

    const request: IHttpRequest<ReservationCreateOrUpdateDto> = { path: `${id}/`, data };
    return await this.PUT<ReservationCreateOrUpdateDto, void>(request);
  }

  public async create(data: ReservationCreateOrUpdateDto | null): Promise<string | undefined> {
    if (!data) return;

    data.fromTime = toUtc(data.fromTime);
    data.toTime = toUtc(data.toTime);

    return await this.POST('', data);
  }

  private static toLocalTimeReservationDto(result: IReservationDto): void {
    result.fromTime && (result.fromTime = utcToLocal(result.fromTime));
    result.toTime && (result.toTime = utcToLocal(result.toTime));
    result.cancelationAtTime && (result.cancelationAtTime = utcToLocal(result.cancelationAtTime));
  }
}

export default new ReservationApi();
