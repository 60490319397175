import React, { useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

import DatePickerIcon from 'components/Icons/DatePickerIcon';
import { IDropDownOption } from 'shared/interfaces/app';
import { get30minTimeList, getHourTimeList, getMinTimeList, minutesToDate } from 'utils/DateTimeUtils';
import { useBreakpoint } from 'utils/hooks';
import { ClickAwayListener, SelectChangeEvent, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';
import CheckIcon from '../../Icons/CheckIcon';
import SelectExpand from '../../Icons/SelectExpand';
import * as S from './DateTimePicker.styles';

interface IDateRangePickerProps {
  timeFrom: Date;
  timeTo?: Date | null;
  isDisableFromTime?: boolean | null;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onChange: (fromTime?: Date | null, toTime?: Date | null) => void;
}

const DateTimePicker = (props: IDateRangePickerProps) => {
  const theme = useTheme();

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const [open, setOpen] = useState(false);

  const { onChange, timeFrom, timeTo, onBlur, isDisableFromTime } = props;

  // Для dev отображаем минуты
  let options = get30minTimeList();
  if (process.env.REACT_APP_IS_DEVELOPMENT === 'true') {
    options = getMinTimeList();
  }

  // Перевод времени в кол-во минут от начала дня
  let valueFrom = timeFrom ? (timeFrom.getHours() * 60 + timeFrom.getMinutes()).toString() : '';
  let valueTo = timeTo ? (timeTo.getHours() * 60 + timeTo.getMinutes()).toString() : '';

  // Поправка, если не совпадает дата и пункты меню
  const minuteStep = options.minuteStep;
  const valueFromInt = parseInt(valueFrom);
  const modValueFrom = valueFromInt % minuteStep;
  if (modValueFrom !== 0) {
    valueFrom = (valueFromInt + modValueFrom).toString();
  }

  let valueToInt = parseInt(valueTo);
  const modValueTo = valueToInt % minuteStep;
  if (modValueTo !== 0) valueToInt = valueToInt + modValueTo;

  if (valueToInt > options.maxMin) valueTo = options.maxMin.toString();

  // Пункты времени для от/до
  const fromOptions = options.list.slice(0, -1);
  const toOptions = options.list.filter((_) => parseInt(_.id) > parseInt(valueFrom));

  const onChangeDate = (newDate: Date | null, keyboardInputValue?: string | undefined) => {
    if (newDate) {
      if (isNaN(newDate.getDate())) return;
      if (newDate.getFullYear() < new Date().getFullYear()) return;

      let newTimeFrom: Date | null = null;
      let newTimeTo: Date | null = null;

      if (timeFrom)
        newTimeFrom = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), timeFrom.getHours(), timeFrom.getMinutes(), 0, 0);
      if (timeTo) newTimeTo = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), timeTo.getHours(), timeTo.getMinutes(), 0, 0);

      onChange(newTimeFrom, newTimeTo);
      setOpen(false);
    }

    if (!keyboardInputValue) return;
    setOpen(false);

    if (keyboardInputValue.length !== 10) return;

    const newDateInput = new Date(Date.parse(keyboardInputValue));
    if (!newDateInput || newDateInput.getDate() === 1 || isNaN(newDateInput.getDate())) return;
    if (newDateInput.getFullYear() < new Date().getFullYear()) return;

    // Новую дату мержим со временами
    const newTimeFrom = new Date(
      newDateInput.getFullYear(),
      newDateInput.getMonth(),
      newDateInput.getDate(),
      timeFrom.getHours(),
      timeFrom.getMinutes(),
      0,
      0
    );
    let newTimeTo = timeTo;
    if (timeTo)
      newTimeTo = new Date(newDateInput.getFullYear(), newDateInput.getMonth(), newDateInput.getDate(), timeTo.getHours(), timeTo.getMinutes(), 0, 0);

    onChange(newTimeFrom, newTimeTo);
    setOpen(false);
  };

  const onTimeFromChange = (e: SelectChangeEvent) => {
    const value = parseInt(e.target.value);
    const newTimeFrom = minutesToDate(value, timeFrom);
    let newTimeTo = timeTo;

    // Если начало больше конца, то прибавляем 1 час
    if (timeTo && newTimeFrom >= timeTo) newTimeTo = minutesToDate(value + 60, timeFrom);

    onChange(newTimeFrom, newTimeTo);
  };

  const onTimeToChange = (e: SelectChangeEvent) => {
    const value = parseInt(e.target.value);
    const newTimeTo = minutesToDate(value, timeFrom);

    onChange(timeFrom, newTimeTo);
  };

  const onClickAway = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onBlurDate = (e: React.FocusEvent<any>) => {
    if (onBlur) onBlur(e);
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: 'common.white',
      },
    },
    autoFocus: true,
    sx: {
      maxHeight: '432px',
      '&& .Mui-selected': {
        backgroundColor: '#FFF',
        ':hover': {
          backgroundColor: '#FFF',
        },
      },
    },
  };

  const iconRenderer = (props: any) => {
    return <div {...props}>{<SelectExpand />} </div>;
  };

  const optionItems = (options: IDropDownOption[], value: string) =>
    options.map((_) => (
      <S.MenuItem disableRipple key={_.id} value={_.id}>
        <S.MenuItemRow>
          <Typography>{_.name}</Typography>
          {_.id === value && <CheckIcon color={theme.palette.primary.main} />}
        </S.MenuItemRow>
      </S.MenuItem>
    ));

  const getValue = (value: string) => {
    return value !== '' ? (options.list.find((_: IDropDownOption) => _.id === value) ?? { name: ' ' }).name : '';
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <ClickAwayListener onClickAway={onClickAway}>
        <S.Box>
          {!isMobile ? (
            <S.DateWrapper>
              <DatePicker
                mask={''}
                disabled={isDisableFromTime ?? false}
                open={open}
                value={timeFrom}
                onChange={onChangeDate}
                components={{ OpenPickerIcon: DatePickerIcon }}
                renderInput={(params: TextFieldProps) => {
                  if (params.inputProps) params.inputProps.disabled = true;

                  return (
                    <S.DateTextField
                      {...params}
                      helperText={null}
                      onClick={() => {
                        if (!isDisableFromTime) setOpen(true);
                      }}
                    />
                  );
                }}
              />
            </S.DateWrapper>
          ) : (
            <S.MobileDatePicker
              mask={''}
              disabled={isDisableFromTime ?? false}
              value={timeFrom}
              open={open}
              onChange={onChangeDate}
              onClose={onClickAway}
              renderInput={(params: TextFieldProps) => {
                if (params.inputProps) params.inputProps.disabled = true;

                return (
                  <>
                    <S.DateTextField
                      {...params}
                      helperText={null}
                      onBlur={onBlurDate}
                      onClick={() => {
                        if (!isDisableFromTime) setOpen(true);
                      }}
                    />
                    <div role="none" onClick={onOpen} style={{ position: 'absolute', right: '10px', margin: '12px', cursor: 'pointer' }}>
                      <DatePickerIcon />
                    </div>
                  </>
                );
              }}
            />
          )}

          <S.TimeWrapper>
            <S.TimeSelect
              disabled={isDisableFromTime ?? false}
              MenuProps={MenuProps}
              value={valueFrom}
              IconComponent={iconRenderer}
              onChange={onTimeFromChange}
              renderValue={() => getValue(valueFrom)}>
              {optionItems(fromOptions, valueFrom)}
            </S.TimeSelect>

            <S.TimeSelect
              displayEmpty
              MenuProps={MenuProps}
              value={valueTo}
              IconComponent={iconRenderer}
              onChange={onTimeToChange}
              renderValue={() => getValue(valueTo)}>
              {optionItems(toOptions, valueTo)}
            </S.TimeSelect>
          </S.TimeWrapper>
        </S.Box>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
