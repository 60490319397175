import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { companiesStore, rolesStore } from 'stores';
import { Checkbox, Input, Upload, Switch } from 'components';
import PasswordField from 'components/UIKit/PasswordField';
import { DEFAULT_COMPANY_ID } from 'shared/constants';

import { Permissions } from 'shared/enums';
import { ICompanyDialog } from 'shared/interfaces/app';

import { Typography, Divider, Box } from '@mui/material';
import * as S from './Companies.styles';
import ColorPickerList from './Components/CompanyDialog/ColorPickerList';

const Companies: React.FC = () => {
  const [validated, setValidated] = useState(false);
  const [validatedEmail, setValidatedEmail] = useState(true);
  const formRef = useRef<FormikProps<ICompanyDialog>>(null);

  useEffect(() => {
    companiesStore.getCompany(DEFAULT_COMPANY_ID);
    return () => {
      companiesStore.selectedCompany.clear();
    };
  }, []);

  const onSubmit = async (values: ICompanyDialog) => {
    companiesStore.selectedCompany.updateFromDialogForm(values);
    await companiesStore.updateCompany();
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const handleSendEmail = async () => {
    if (formRef.current) {
      const values = formRef.current.values;
      await companiesStore.sendTestEmail(values);
    }

    companiesStore.selectedCompany.smtpParamsEmail = '';
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    companiesStore.selectedCompany.smtpParamsEmail = event.currentTarget.value;
  };

  const handleBlurEmail = async () => {
    const isValidEmail = await Yup.string().email().required().isValid(companiesStore.selectedCompany.smtpParamsEmail);

    setValidatedEmail(isValidEmail);
  };

  const companiesUpdatingAllow = rolesStore.isAllow(Permissions.CompaniesUpdating);

  return (
    <>
      <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          onSubmit={onSubmit}
          validateOnMount
          initialValues={{
            name: companiesStore.selectedCompany.name,
            isCustom: companiesStore.selectedCompany.isCustom,
            themeData: companiesStore.selectedCompany.themeData,
            logoImage: companiesStore.selectedCompany.logoImage,
            logoImageString: companiesStore.selectedCompany.logoImageString,
            smtpParamsHost: companiesStore.selectedCompany.smtpParamsHost,
            smtpParamsPort: companiesStore.selectedCompany.smtpParamsPort,
            smtpParamsUserName: companiesStore.selectedCompany.smtpParamsUserName,
            smtpParamsPassword: companiesStore.selectedCompany.smtpParamsPassword,
            smtpParamsUseSsl: companiesStore.selectedCompany.smtpParamsUseSsl,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Обязательное поле'),
            smtpParamsHost: Yup.string().max(255).required('Обязательное поле'),
            smtpParamsPort: Yup.number().required('Обязательное поле'),
            smtpParamsUserName: Yup.string().max(255).required('Обязательное поле'),
            smtpParamsPassword: Yup.string().max(255).required('Обязательное поле'),
          })}>
          {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
            setValidated(isValid);

            const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
              await companiesStore.selectedCompany.uploadLogo(e);
            };

            const draggableUpload = async (e: React.DragEvent<HTMLLabelElement>): Promise<void> => {
              e.preventDefault();
              await companiesStore.selectedCompany.uploadLogo(e);
            };

            const onDeleteLogo = (e: any) => {
              e.preventDefault();
              companiesStore.selectedCompany.logoImageString = '';
              companiesStore.selectedCompany.logoImage = undefined;
            };

            return (
              <S.Content>
                <Input
                  name="name"
                  inputLabel="Название компании *"
                  readonly={!companiesUpdatingAllow}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <Box
                  sx={{
                    mt: 4,
                    mb: 4,
                  }}>
                  <Upload
                    title="Выберите или перетащите логотип"
                    accept=".svg"
                    subTitle="Файл должен быть формата SVG и не более 200 КБ"
                    image={companiesStore.selectedCompany.logoImageString}
                    onChangeFile={handleUpload}
                    handleDrop={draggableUpload}
                    onDelete={onDeleteLogo}
                  />
                  {!companiesStore.selectedCompany.isValidLogo && (
                    <Typography sx={{ mt: 1, ml: 3 }} variant="caption" color="error">
                      Неверный формат файла
                    </Typography>
                  )}
                </Box>

                <Typography sx={{ mb: 5 }} variant="h3" align="left">
                  Параметры цветовой схемы
                </Typography>

                <Box sx={{ mb: 5 }}>
                  <Checkbox
                    disabled={!companiesUpdatingAllow}
                    label="Использовать настраиваемую цветовую схему"
                    checked={values.isCustom}
                    onChange={() => setFieldValue('isCustom', !values.isCustom)}
                  />
                </Box>

                {<ColorPickerList isDefault={values.isCustom} />}

                <Typography sx={{ mb: 8 }} variant="h3" align="left">
                  Параметры SMTP сервера
                </Typography>

                <Input
                  name="smtpParamsHost"
                  inputLabel="Хост *"
                  value={values.smtpParamsHost}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readonly={!companiesUpdatingAllow}
                  error={Boolean(touched.smtpParamsHost && errors.smtpParamsHost)}
                  helperText={touched.smtpParamsHost && errors.smtpParamsHost}
                />

                <Input
                  name="smtpParamsPort"
                  inputLabel="Порт *"
                  type="number"
                  value={values.smtpParamsPort}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readonly={!companiesUpdatingAllow}
                  error={Boolean(touched.smtpParamsPort && errors.smtpParamsPort)}
                  helperText={touched.smtpParamsPort && errors.smtpParamsPort}
                />

                <Input
                  name="smtpParamsUserName"
                  inputLabel="Имя пользователя *"
                  value={values.smtpParamsUserName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readonly={!companiesUpdatingAllow}
                  error={Boolean(touched.smtpParamsUserName && errors.smtpParamsUserName)}
                  helperText={touched.smtpParamsUserName && errors.smtpParamsUserName}
                />

                <PasswordField
                  name={'smtpParamsPassword'}
                  label="Пароль *"
                  password={values.smtpParamsPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  readonly={!companiesUpdatingAllow}
                  error={Boolean(touched.smtpParamsPassword && errors.smtpParamsPassword)}
                  helperText={touched.smtpParamsPassword && errors.smtpParamsPassword}
                />

                <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography>Использовать SSL</Typography>
                  <Switch
                    disabled={!companiesUpdatingAllow}
                    checked={values.smtpParamsUseSsl}
                    onChange={() => setFieldValue('smtpParamsUseSsl', !values.smtpParamsUseSsl)}
                  />
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
              </S.Content>
            );
          }}
        </Formik>

        <S.EmailWrapper>
          <Input
            name="smtpParamsEmail"
            inputLabel="Проверить почту"
            value={companiesStore.selectedCompany.smtpParamsEmail}
            error={!validatedEmail}
            disabled={!companiesUpdatingAllow}
            onChange={handleChangeEmail}
            onBlur={handleBlurEmail}
          />
          <S.CheckEmailButton
            validatedEmail={!validatedEmail}
            sx={{ mt: !validatedEmail ? 4 : 8, ml: 4 }}
            disabled={!companiesUpdatingAllow || (!companiesStore.selectedCompany.isValidSendEmail && !validatedEmail)}
            variant="contained"
            size="large"
            onClick={handleSendEmail}>
            Проверить
          </S.CheckEmailButton>
        </S.EmailWrapper>

        {companiesUpdatingAllow && (
          <S.SaveButton color="secondary" variant="contained" size="large" autoFocus disabled={!validated} onClick={handleSubmit}>
            Сохранить
          </S.SaveButton>
        )}
      </Box>
    </>
  );
};

export default observer(Companies);
