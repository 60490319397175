import styled from 'styled-components/macro';

export const ContactField = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  margin-bottom: ${(props) => props.theme.spacing(5)};
  display: flex;
  justify-content: space-between;
`;

export const UserDialogWrapper = styled.div`
  & > div:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
