import React, { useEffect, useRef } from 'react';
import L, { DomEvent, Handler, LatLng, LatLngBounds, Map as MapLeaflet } from 'leaflet';
import { useMap } from 'react-leaflet';
import { mapStore } from 'stores';
import { FloorImageModel } from 'shared/models';
import { IPosition } from '../../../shared/interfaces/app';

interface IMapContext {
  floorImageModel: FloorImageModel;
  selectedPoint?: IPosition | null;
  onMapClick?: () => void;
}

const MapContext: React.FC<IMapContext> = (props) => {
  const { floorImageModel, onMapClick, selectedPoint } = props;

  const map = useMap();
  const zoomInRef = useRef<HTMLButtonElement>(null);
  const zoomOutRef = useRef<HTMLButtonElement>(null);

  const getSmoothWheelZoom = (minZoom: number, onStartWheel?: () => void) => {
    return Handler.extend({
      // Скорость зумма
      smoothSensitivity: 1,

      _goalZoom: null,
      _prevCenter: null,
      _prevZoom: null,
      _isWheeling: null,
      _wheelMousePosition: null,
      _centerPoint: null,
      _startLatLng: null,
      _wheelStartLatLng: null,
      _startZoom: null,
      _moved: null,
      _zooming: null,
      _timeoutId: null,
      _zoom: null,
      _center: null,
      _zoomAnimationId: null,

      addHooks() {
        DomEvent.on(this._map._container, 'mousewheel', this._onWheelScroll, this);
      },

      removeHooks() {
        DomEvent.off(this._map._container, 'mousewheel', this._onWheelScroll, this);
      },

      _onWheelScroll(e: any) {
        if (!this._isWheeling) {
          this._onWheelStart(e);
        }
        this._onWheeling(e);
      },

      _onWheelStart(e: MouseEvent) {
        onStartWheel && onStartWheel();

        const map = this._map;
        this._isWheeling = true;
        this._wheelMousePosition = map.mouseEventToContainerPoint(e);
        this._centerPoint = map.getSize()._divideBy(2);
        this._startLatLng = map.containerPointToLatLng(this._centerPoint);
        this._wheelStartLatLng = map.containerPointToLatLng(this._wheelMousePosition);
        this._startZoom = map.getZoom();
        this._moved = false;
        this._zooming = true;

        map._stop();
        if (map._panAnim) map._panAnim.stop();

        this._goalZoom = map.getZoom();
        this._prevCenter = map.getCenter();
        this._prevZoom = map.getZoom();

        this._zoomAnimationId = requestAnimationFrame(this._updateWheelZoom.bind(this));
      },

      _onWheeling(e: MouseEvent) {
        const map = this._map;

        this._goalZoom = this._goalZoom + DomEvent.getWheelDelta(e) * 0.003 * this.smoothSensitivity;
        if (this._goalZoom < map.getMinZoom() || this._goalZoom > map.getMaxZoom()) {
          this._goalZoom = map._limitZoom(this._goalZoom);
        }
        this._wheelMousePosition = this._map.mouseEventToContainerPoint(e);

        clearTimeout(this._timeoutId);
        this._timeoutId = setTimeout(this._onWheelEnd.bind(this), 200);

        DomEvent.preventDefault(e);
        DomEvent.stopPropagation(e);
      },

      _onWheelEnd() {
        this._isWheeling = false;
        cancelAnimationFrame(this._zoomAnimationId);
        this._map._moveEnd(true);
      },

      _updateWheelZoom() {
        const map = this._map;

        if (!map.getCenter().equals(this._prevCenter) || map.getZoom() != this._prevZoom) return;

        this._zoom = map.getZoom() + (this._goalZoom - map.getZoom()) * 0.3;
        this._zoom = Math.floor(this._zoom * 100) / 100;

        const delta = this._wheelMousePosition.subtract(this._centerPoint);
        if (delta.x === 0 && delta.y === 0) return;

        this._center = map.unproject(map.project(this._wheelStartLatLng, this._zoom).subtract(delta), this._zoom);

        if (!this._moved) {
          map._moveStart(true, false);
          this._moved = true;
        }

        map._move(this._center, this._zoom);
        this._prevCenter = map.getCenter();
        this._prevZoom = map.getZoom();

        this._zoomAnimationId = requestAnimationFrame(this._updateWheelZoom.bind(this));
      },
    });
  };

  // Класс плавного зумма
  MapLeaflet.mergeOptions({ smoothWheelZoom: true });

  useEffect(() => {
    if (!floorImageModel.imageBase64) return;

    const { pixelsPerMeter, height, width, imageBase64 } = floorImageModel;

    const bounds = new LatLngBounds(new LatLng(0, 0), new LatLng(height, width));
    map.fitBounds(bounds, { animate: false, padding: [10, 10] });
    const fitZoom = map.getZoom();
    map.setZoom(fitZoom, { animate: false });
    map.setMaxBounds(bounds);

    mapStore.setMapParams(pixelsPerMeter ?? 33, Math.abs(fitZoom));

    L.imageOverlay(imageBase64, bounds).addTo(map);

    map.doubleClickZoom.disable();
    map.boxZoom.disable();

    // Добавалние плавного зумма
    map.addHandler('smoothWheelZoom', getSmoothWheelZoom(fitZoom));

    if (onMapClick) map.on('click', onMapClick);

    const changeZoom = () => {
      const currentZoom = map.getZoom();
      if (mapStore.zoom === currentZoom) return;

      mapStore.onChangeZoom(currentZoom);
    };

    changeZoom();

    map.on('moveend', () => {
      changeZoom();
    });

    if (selectedPoint && selectedPoint.x > 0 && selectedPoint.y > 0) {
      map.flyTo(new LatLng(selectedPoint.y, selectedPoint.x), 0);
    }

    return () => {
      floorImageModel.clear();
      map.eachLayer((layer) => map.removeLayer(layer));
    };
  }, []);

  const onZoomIn = () => {
    map.zoomIn(1);
  };

  const onZoomOut = () => {
    map.zoomOut(1);
  };

  return (
    <>
      <div className="leaflet-control-container-custom">
        <img
          role={'none'}
          src="/images/buttonPlus.svg"
          onClick={onZoomIn}
          onKeyPress={() => {
            return;
          }}
          alt=""
        />
        <img
          role={'none'}
          src="/images/buttonMinus.svg"
          onClick={onZoomOut}
          onKeyPress={() => {
            return;
          }}
          alt=""
        />
      </div>
    </>
  );
};

export default MapContext;
