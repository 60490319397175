import React from 'react';
import { observer } from 'mobx-react';
import { mapStore, workPlacesStore } from 'stores';
import { Badge, FloorsPanel, ReservationMapLegend } from 'components';
import { MATTERPORT_TOOGLE_ID } from 'shared/constants';
import { FloorImageModel } from 'shared/models';
import { useBreakpoint } from 'utils/hooks';
import { Box, Typography } from '@mui/material';

import * as S from './Panel.styles';

const Panel: React.FC = () => {
  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';
  const isDesktop = bp === 'lg';

  const onChangeFloor = async (event: React.MouseEvent<HTMLElement>, value: string): Promise<void> => {
    if (value === null) return;

    await mapStore.getFloorById(value);
    await mapStore.getFloorImagesList(mapStore.selectedFloor.id);

    mapStore.setActiveMap(0);
    await Promise.all([mapStore.getFloorImageById(), workPlacesStore.getWorkPlacesAvailableBookingList()]);
  };

  const onChangeMap = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value === null) return;

    if (value === MATTERPORT_TOOGLE_ID) {
      mapStore.selectedFloorImage.setFloorImageId(value);
      workPlacesStore.setTourEnable(true);
      return;
    } else {
      workPlacesStore.setTourEnable(false);
    }

    mapStore.setActiveMap(value);
    // Получаем параметры карты и размещеные объекты
    await Promise.all([mapStore.getFloorImageById(), workPlacesStore.getWorkPlacesAvailableBookingList()]);
  };

  return (
    <S.PanelWrapper>
      {!isMobile && (
        <FloorsPanel
          hideFloors={workPlacesStore.isTourEnable}
          floors={mapStore.floorTagsMain}
          floorImages={mapStore.floorImagesWith3DTour}
          selectedFloor={mapStore.selectedFloor.id}
          selectedFloorImage={mapStore.selectedFloorImage.id}
          onChangeFloorImage={onChangeMap}
          onChangeFloor={onChangeFloor}
        />
      )}

      {!workPlacesStore.isTourEnable && !isTablet && !isMobile && (
        <ReservationMapLegend
          freeCount={workPlacesStore.workPlacesTotalCount.freeWorkplacesCount}
          reservedCount={workPlacesStore.workPlacesTotalCount.reservedWorkplacesCount}
          totalCount={workPlacesStore.workPlacesTotalCount.totalWorkplacesCount}
        />
      )}
    </S.PanelWrapper>
  );
};

export default observer(Panel);
