import { IPassListGetDto, IPassesPostDto, IPassGetDto } from 'shared/interfaces/api';
import { IPassFilter } from 'shared/interfaces/app';

import HTTPService, { IHttpRequest } from './HTTPService';
import { toUtc, utcToLocal } from 'utils/DateTimeUtils';

class PassesApi extends HTTPService {
  public constructor() {
    super('Passes');
  }

  public async getPasses(filter: Omit<IPassFilter, 'myPass' | 'status'>): Promise<IPassListGetDto[]> {
    filter.from = toUtc(filter.from);
    filter.to = toUtc(filter.to);

    const result = await this.GET<IPassListGetDto[]>('', { params: { ...filter } });
    if (!result) return new Promise(() => null);

    result.forEach((item: IPassListGetDto) => {
      item.createdAt = utcToLocal(item.createdAt!);
      item.fromDate = utcToLocal(item.fromDate!);
      item.toDate = utcToLocal(item.toDate!);
    });

    return result;
  }

  public async getPass(passId: string): Promise<IPassGetDto> {
    const result = await this.GET<IPassGetDto>(`${passId}`, {});
    if (!result) return new Promise(() => null);

    result.fromDate = utcToLocal(result.fromDate!);
    result.toDate = utcToLocal(result.toDate!);

    return result;
  }

  public async postPass(data: IPassesPostDto): Promise<string> {
    data.fromDate = toUtc(data.fromDate);
    data.toDate = toUtc(data.toDate);

    return await this.POST('', data, {});
  }

  public async putPassConfirm(passId: string, secretKey: string): Promise<IPassGetDto> {
    const request: IHttpRequest<null> = { path: `${passId}/confirm/${secretKey}` };
    const result = await this.PUT<null, IPassGetDto>(request);
    if (!result) return new Promise(() => null);

    result.fromDate = utcToLocal(result.fromDate!);
    result.toDate = utcToLocal(result.toDate!);

    return result;
  }

  public async putPassReject(passId: string, secretKey: string): Promise<IPassGetDto> {
    const request: IHttpRequest<null> = { path: `${passId}/reject/${secretKey}` };
    const result = await this.PUT<null, IPassGetDto>(request);
    if (!result) return new Promise(() => null);

    result.fromDate = utcToLocal(result.fromDate!);
    result.toDate = utcToLocal(result.toDate!);

    return result;
  }
}

export default new PassesApi();
