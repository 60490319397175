import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { notificationsStore, userAuthStore } from 'stores';

import { ContentHeader, MainContent, Pagination, Spinner, Toggler, Checkbox } from 'components';
import { DialogStatus } from 'shared/enums';
import { NotificationsModel } from 'shared/models';
import { Box, Divider, MenuItem } from '@mui/material';
import { NotificationViewDialog, NotificationList } from './Components';

const Notifications: React.FC = () => {
  useEffect(() => {
    notificationsStore.setActivePage(true);
    notificationsStore.getNotificationsList().finally();

    return () => notificationsStore.setActivePage(false);
  }, []);

  const onCloseDialog = () => {
    notificationsStore.closeNotificationsDialog();
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number): void => {
    notificationsStore.queryParams.pageIndex = page;
    notificationsStore.getNotificationsList().finally();
  };

  const onOpenListItem = async (item: NotificationsModel): Promise<void> => {
    notificationsStore.selectedNotification = item;
    if (!item.isRead) await notificationsStore.readNotification();

    notificationsStore.setDialogViewMode(DialogStatus.Viewing);
  };

  const onChangeTab = async (event: React.MouseEvent<HTMLElement>, tabKey: string): Promise<void> => {
    notificationsStore.setTab(tabKey);
    await notificationsStore.getNotificationsList();
  };

  const Content = notificationsStore.isFetching
    ? () => <Spinner />
    : () => (
        <>
          <NotificationList listItems={notificationsStore.notificationsForList} onItemClick={onOpenListItem} />

          <Box sx={{ marginTop: '8px', position: 'fixed', bottom: '16px', backgroundColor: '#ffffffe8', padding: '8px', borderRadius: '18px' }}>
            <Pagination
              pageSize={notificationsStore.queryParams.pageSize}
              pageIndex={notificationsStore.queryParams.pageIndex}
              onChangePage={onChangePage}
              rows={notificationsStore.totalCount}
            />
          </Box>
        </>
      );

  const setUserNotificationsSettings = async (e: React.SyntheticEvent, checked: boolean) => {
    await userAuthStore.setCopyNotificationsSetting(checked);
  };

  const menuItemsSettings = [
    <MenuItem sx={{ '&:hover': { color: 'primary.main' } }} disableRipple key="copy-notifications-to-email">
      <Checkbox label={'Получать уведомления на почту'} checked={userAuthStore.copyNotificationsToEmail} onChange={setUserNotificationsSettings} />
    </MenuItem>,
  ];

  return (
    <>
      <ContentHeader title="Уведомления" menuItemsSettings={menuItemsSettings} />
      <MainContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: 'fit-content' }}>
          <Toggler tabs={notificationsStore.notificationsTabs} value={notificationsStore.tab} onChange={onChangeTab} />
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

        <Content />
      </MainContent>

      {notificationsStore.dialogViewMode !== DialogStatus.Closed && <NotificationViewDialog onClose={onCloseDialog} />}
    </>
  );
};

export default observer(Notifications);
