import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  margin-left: ${(props) => props.theme.spacing(3)};
  margin-top: ${(props) => props.theme.spacing(4)};

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

export const Name = styled(Typography)`
  color: ${(props) => props.theme.palette.text.main};
  padding-bottom: ${(props) => props.theme.spacing(2)};
`;

export const Dates = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  padding-bottom: ${(props) => props.theme.spacing(2)};
`;

export const Description = styled(Typography)`
  color: ${(props) => props.theme.palette.text.main};
  padding-bottom: ${(props) => props.theme.spacing(2)};
`;
