import styled from 'styled-components/macro';

export const FilterWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme.breakpoints.up('sm')} {
    flex-wrap: wrap;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }

  & > div:not(:last-child) {
    margin-bottom: ${(props) => props.theme.spacing(2)};
  }
`;
