import React from 'react';

const LogoIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M8 10L16.1248 18H19V17.546L11.3364 10H8Z" fill="white" />
      <path
        d="M9.49988 2C4.79728 2 1 5.57396 1 10C1 14.426 4.79728 18 9.49988 18C10.4913 18 11.4418 17.8406 12.3258 17.5482L10.5247 15.8537C10.1947 15.8989 9.85236 15.922 9.49988 15.922C5.85707 15.922 3.31807 13.4288 3.31807 10.0207C3.31807 6.57121 5.85707 4.07798 9.49988 4.07798C13.1429 4.07798 15.6597 6.57121 15.6597 10.0207C15.6597 10.4014 15.6282 10.7706 15.5674 11.1259L17.4332 12.8822C17.7992 11.9883 18 11.0166 18 10C18 5.57396 14.2025 2 9.49988 2Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoIcon;
