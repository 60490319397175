import { makeAutoObservable } from 'mobx';
import { userAuthStore } from 'stores';
import { PASS_TEMPLATE_ID } from 'shared/constants';
import { IFieldPasses, IPassesPostDto, IPassGetDto } from 'shared/interfaces/api';
import { getNearHour } from 'utils/DateTimeUtils';

class PassModel {
  constructor(dto?: IPassGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.fromDate = dto.fromDate;
    this.toDate = dto.toDate;
    this.isConfirmed = dto.isConfirmed;
    this.fieldsValues = dto.fieldsValues;
    this.fields = dto.fields;
    this.comments = dto.comments;
  }

  public id = '';

  public createdAt: Date | null = null;

  public fromDate: Date = getNearHour();

  public toDate?: Date | null = null;

  public fullUserName = '';

  public isConfirmed: boolean | null = null;

  public fieldsValues: string[] = [];

  public description = '';

  public comments = '';

  public fields: IFieldPasses[] = [];

  public get passPostDto(): IPassesPostDto | undefined {
    if (!userAuthStore.userId || !this.toDate) return;

    return {
      userId: userAuthStore.userId,
      fromDate: this.fromDate,
      toDate: this.toDate,
      passTemplateId: PASS_TEMPLATE_ID,
      fields: this.fields,
      comments: this.comments,
    };
  }

  public get currentFields() {
    return this.fields;
  }

  public setFields(fields: IFieldPasses[]) {
    this.fields = fields;
  }

  public setTimeInterval(fromTime: Date, toTime?: Date | null) {
    this.fromDate = fromTime;
    this.toDate = toTime;
  }

  public setComments(comment: string) {
    this.comments = comment;
  }
}

export default PassModel;
