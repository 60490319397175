import React from 'react';

const MessagesIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path
          opacity="0.4"
          d="M10.0165 1.66666C5.17484 1.66666 1.6665 5.61666 1.6665 10C1.6665 11.4 2.07484 12.8417 2.7915 14.1583C2.92484 14.375 2.9415 14.65 2.84984 14.9083L2.2915 16.775C2.1665 17.225 2.54984 17.5583 2.97484 17.425L4.65817 16.925C5.1165 16.775 5.47484 16.9667 5.90067 17.225C7.11734 17.9417 8.63317 18.3083 9.99984 18.3083C14.1332 18.3083 18.3332 15.1167 18.3332 9.975C18.3332 5.54166 14.7498 1.66666 10.0165 1.66666Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.98337 11.0751C9.39171 11.0667 8.91671 10.5917 8.91671 10.0001C8.91671 9.41675 9.40004 8.93342 9.98337 8.94175C10.575 8.94175 11.05 9.41675 11.05 10.0084C11.05 10.5917 10.575 11.0751 9.98337 11.0751ZM6.14137 11.0751C5.55804 11.0751 5.07471 10.5918 5.07471 10.0084C5.07471 9.41677 5.54971 8.94177 6.14137 8.94177C6.73304 8.94177 7.20804 9.41677 7.20804 10.0084C7.20804 10.5918 6.73304 11.0668 6.14137 11.0751ZM12.7582 10.0084C12.7582 10.5917 13.2332 11.0751 13.8249 11.0751C14.4165 11.0751 14.8915 10.5917 14.8915 10.0084C14.8915 9.41675 14.4165 8.94175 13.8249 8.94175C13.2332 8.94175 12.7582 9.41675 12.7582 10.0084Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default MessagesIcon;
