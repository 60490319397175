import React, { useMemo, useRef } from 'react';
import L, { LatLngLiteral, LeafletEventHandlerFnMap, LeafletMouseEvent } from 'leaflet';
import { Marker } from 'react-leaflet';

interface IProps {
  draggable?: boolean;
  position: LatLngLiteral;
  icon?: L.DivIcon;
  onDragEnd?: (position: LatLngLiteral) => void;
  onClick?: (e: LeafletMouseEvent) => void;
  onDragStart?: () => void;
}

const DraggableMarker: React.FC<IProps> = (props) => {
  const { draggable, position, icon, onDragStart, onDragEnd, onClick } = props;

  const markerRef = useRef<L.Marker>(null);

  const eventHandlers: LeafletEventHandlerFnMap = useMemo(
    () => ({
      dragstart() {
        if (onDragStart) onDragStart();
      },
      dragend() {
        const marker = markerRef.current;
        if (marker != null && onDragEnd) {
          onDragEnd(marker.getLatLng());
        }
      },
      click(e: LeafletMouseEvent) {
        if (onClick) onClick(e);
      },
    }),
    [draggable]
  );

  return <Marker icon={icon} draggable={draggable} eventHandlers={eventHandlers} position={position} ref={markerRef} />;
};

export default DraggableMarker;
