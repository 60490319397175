import React, { useState } from 'react';
import { Typography } from '@mui/material';

import * as S from './TagImage.styles';
interface IElementProps {
  value: string;
  label: string;
  selected: boolean;
  onChange: (e: any, value: any) => void;
}

const TagImage: React.FC<IElementProps> = (props) => {
  const { value, label, selected, onChange } = props;
  const [isActionMode, setIsActionMode] = useState(false);

  const imageAction = isActionMode || selected;
  return (
    <S.ToggleButton
      key={value}
      selected={selected}
      disableRipple
      value={value}
      onMouseEnter={() => setIsActionMode(true)}
      onMouseLeave={() => setIsActionMode(false)}
      onChange={(e) => onChange(e, value)}>
      <S.Img src={'/images/background-tag.png'} alt={''} />
      <Typography variant="button" sx={{ color: 'common.white', zIndex: 9 }}>
        {label}
      </Typography>
      {imageAction && <S.ActionLayer />}
    </S.ToggleButton>
  );
};

export default TagImage;
