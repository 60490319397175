import React from 'react';
import CloseIcon from 'components/Icons/CloseIcon';
import { TBtnColor } from 'shared/constants';
import { useBreakpoint } from 'utils/hooks';
import { Box, Button, Typography } from '@mui/material';

import * as S from './ModalDialog.styles';

interface IModalDialogProps {
  isModelHasId?: string;
  isValid?: boolean;
  title: string;
  titleFontSize?: string;
  subTitle?: string;
  createBtnLabel?: string;
  isOpen: boolean;
  width?: string;
  children?: React.ReactNode;
  submitBtnLabel?: string;
  submitBtnColor?: TBtnColor;
  cancelBtnLabel?: string;
  closeIcon?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  date?: string;
}

const ModalDialog: React.FC<IModalDialogProps> = (props) => {
  const {
    isModelHasId,
    createBtnLabel,
    submitBtnLabel,
    cancelBtnLabel,
    submitBtnColor,
    isValid,
    title,
    titleFontSize,
    subTitle,
    isOpen,
    children,
    closeIcon,
    onClose,
    onSubmit,
    onCancel,
    width,
    date,
  } = props;

  const bp = useBreakpoint();

  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';

  let firstTitleLine = title;
  let secondTitleLine = '';

  const isBreakPos = title.startsWith("Бронирование '");
  if (isBreakPos) {
    firstTitleLine = title.substring(0, 13);
    secondTitleLine = title.substring(13);
  }

  const MobileTitle = () => (
    <>
      <S.TopMobileTitle>
        <S.MobileData variant="subtitle1" align="left">
          {date}
        </S.MobileData>
        {subTitle && (
          <S.MobileSubtitle variant="subtitle1" align="left">
            {subTitle}
          </S.MobileSubtitle>
        )}
      </S.TopMobileTitle>
      <Typography variant="h5" align="left">
        <S.Title titleFontSize={titleFontSize}>
          {firstTitleLine}
          {secondTitleLine && (
            <>
              <br />
              {secondTitleLine}
            </>
          )}
        </S.Title>
      </Typography>
    </>
  );

  const CommonTitle = () => (
    <>
      <Typography variant="h2" align="left" style={{ fontSize: '25px' }}>
        <S.Title titleFontSize={titleFontSize}>
          {firstTitleLine}
          {secondTitleLine && (
            <>
              <br />
              {secondTitleLine}
            </>
          )}
        </S.Title>
      </Typography>
      {subTitle && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }} align="left">
          {subTitle} {date}
        </Typography>
      )}
    </>
  );

  return (
    <S.DialogWrapper scroll="body" fullWidth={true} PaperProps={{ sx: { minWidth: width } }} onClose={onClose} open={isOpen}>
      <S.DialogTitle>
        {isMobile || isTablet ? <MobileTitle /> : <CommonTitle />}
        {closeIcon ? (
          <Box
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              cursor: 'pointer',
              right: 20,
              top: 8,
              svg: {
                fill: (theme: any) => theme.palette.icons.stroke,
                '&:hover': {
                  fill: (theme: any) => theme.palette.primary.main,
                },
              },
            }}>
            <CloseIcon />
          </Box>
        ) : null}
      </S.DialogTitle>

      <S.DialogContent>{children}</S.DialogContent>

      {onSubmit && (
        <S.DialogActions disableSpacing={true}>
          {submitBtnLabel ? (
            <Button
              color={submitBtnColor ?? 'secondary'}
              sx={{ mb: 5 }}
              fullWidth
              variant="contained"
              size="large"
              autoFocus
              disabled={isValid === undefined ? false : !isValid}
              onClick={onSubmit}>
              {submitBtnLabel}
            </Button>
          ) : isModelHasId ? (
            <Button color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" autoFocus disabled={!isValid} onClick={onSubmit}>
              Сохранить
            </Button>
          ) : (
            <Button color="secondary" sx={{ mb: 5 }} fullWidth variant="contained" size="large" autoFocus disabled={!isValid} onClick={onSubmit}>
              {createBtnLabel ? createBtnLabel : 'Создать'}
            </Button>
          )}

          {cancelBtnLabel ? (
            <Button variant="outlined" fullWidth size="large" onClick={onCancel}>
              {cancelBtnLabel}
            </Button>
          ) : (
            <Button variant="outlined" fullWidth size="large" onClick={onCancel}>
              Закрыть
            </Button>
          )}
        </S.DialogActions>
      )}
    </S.DialogWrapper>
  );
};

export default ModalDialog;
