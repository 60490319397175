import styled from 'styled-components/macro';
import { Button as MuiButton, TextField as MuiTextField } from '@mui/material';

export const Button = styled(MuiButton)`
  margin: ${(prop) => prop.theme.spacing(6)};
  color: grey;
  border-color: grey;
`;

export const TextField = styled(MuiTextField)`
  margin-left: ${(prop) => prop.theme.spacing(4)};
`;

export const AccordionWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;
