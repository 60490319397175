import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: ${(p) => p.theme.spacing(4)};
  margin-top: ${(p) => p.theme.spacing(4)};
`;

export const Images = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80px;
  height: 80px;
  margin: 2px;
  cursor: pointer;
  border-radius: 12px;
  transition: box-shadow 0.1s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
`;

export const Image = styled.img`
  width: 100% !important;
  height: 100% !important;
  border-radius: 12px;
  object-fit: cover;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 0 5px 5px 5px;
  svg {
    width: 18px;
    height: 18px;
    stroke: ${(props) => props.theme.palette.common.white};
    &:hover {
      width: 20px;
      height: 20px;
      stroke: ${(props) => props.theme.palette.error.light};
    }
  }
`;
