import styled from 'styled-components/macro';

export const FooterImg = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    border-radius: 0;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  height: 50px;
  border-radius: 0 0 ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(6)};
  background-image: url('/images/foother.svg');
`;
