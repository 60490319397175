export enum reservationStatus {
  none = -2,
  archive = -1,
  pending = 0,
  accepted = 1,
  extended = 2,
  canceled = 3,
}

export const ReservationStatusData = new Map<reservationStatus, IReservationStatus>([
  [reservationStatus.archive, { value: 'В архиве', color: 'silver' }],
  [reservationStatus.pending, { value: 'Требует подтверждения', color: 'red' }],
  [reservationStatus.accepted, { value: 'Подтверждено', color: 'green' }],
  [reservationStatus.extended, { value: 'Продлено', color: 'orange' }],
  [reservationStatus.canceled, { value: 'Отменено', color: 'silver' }],
]);

export const ReservationStatusDialog = new Map<reservationStatus, IReservationStatus>([
  [reservationStatus.pending, { value: 'Забронировано но не подтверждено', color: 'orange' }],
  [reservationStatus.accepted, { value: 'Забронировано мной', color: 'blue' }],
  [reservationStatus.extended, { value: 'Забронировано но не подтверждено', color: 'orange' }],
]);

export interface IReservationStatus {
  value: string;
  color: string;
}

export function getReservationStatus(status: reservationStatus, isCompleted: boolean): IReservationStatus {
  if (isCompleted) return ReservationStatusData.get(reservationStatus.archive)!;

  return ReservationStatusData.get(status)!;
}

export function getReservationDialogStatus(status: reservationStatus): IReservationStatus {
  return ReservationStatusDialog.get(status)!;
}
