import React from 'react';
import { observer } from 'mobx-react';
import { workPlacesStore } from 'stores';
import WorkPlace from '../WorkPlaces/WorkPlace';

const MeetingRooms = () => {
  return (
    <>
      {/* {workPlacesStore.mapMeetRoom.map((item) => (
        <WorkPlace key={item.id} mapObject={item} />
      ))} */}
    </>
  );
};

export default observer(MeetingRooms);
