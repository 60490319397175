import NumericInput from 'react-numeric-input';
import styled from 'styled-components';

export const NumberInput = styled(NumericInput)`
  width: 72px;
  height: 24px;
  background-color: #f2f5f7;
  border-radius: 70px !important;
  border: none !important;
  color: transparent;
  text-shadow: 0 0 0 ${(props) => props.theme.palette.primary.main};
  font-weight: 600;
`;

export const NumberInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NumberInputLabel = styled.label``;
