import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    white-space: normal;
  }
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  white-space: pre;

  button {
    margin-top: 10px;
  }
`;
