import React, { useEffect } from 'react';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import ru from 'date-fns/locale/ru';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';

import { observer } from 'mobx-react';

import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';

import { calendarStore, reservationStore, workPlacesStore } from 'stores';
import { getDateOnly, getEndOfDay, getStartOfDay, nowToLocal } from 'utils/DateTimeUtils';
import { Box } from '@mui/material';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ROUTE } from '../../../routes';
import { ReservationDialog } from '../Components';
import CustomDateHeaderCell from '../ReservationCalendar/Components/CustomDateHeaderCell';
import CustomToolBar from '../ReservationCalendar/Components/CustomToolBar/CustomToolBar';
import EventCard from '../ReservationCalendar/Components/EventCard/EventCard';

import * as S from './ReservationSingle.styles';

const locales = { 'ru-RU': ru };

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const ReservationSingle: React.FC = () => {
  useEffect(() => {
    reservationStore.setSchedulePage(true);
    calendarStore.setShowActionButton(false);
    const url = new URL(document.location.href);
    const workplaceId = url.searchParams.get('workplaceId');
    if (!workplaceId) return;

    calendarStore.calendarFilter.workplaceId = workplaceId;
    calendarStore.calendarFilter.fromTime = getStartOfDay();
    calendarStore.calendarFilter.toTime = getEndOfDay();
    workPlacesStore.getWorkPlaceById(workplaceId).then(() => {
      reservationStore.setTab(ROUTE.ReservationSingle);
      calendarStore.calendarFilter.onOnlyMineChange(false);
      calendarStore.calendarFilter.updateWorkPlaceId(workplaceId);
      calendarStore.getCalendarViewData().finally();
    });

    // Для dev период 10 сек. Для prod - 1 мин
    const timeIntervalSec = process.env.REACT_APP_IS_DEVELOPMENT === 'true' ? 10 : 60;
    const interval = setInterval(() => calendarStore.getCalendarViewData(), 1000 * timeIntervalSec);

    return () => {
      clearInterval(interval);
      calendarStore.deInit();
      reservationStore.setSchedulePage(false);
    };
  }, []);

  useEffect(() => {
    const daysHeaderElems = document.getElementsByClassName('rbc-button-link');
    const matchedElems: HTMLElement[] = [];

    try {
      for (const key in daysHeaderElems) {
        if (daysHeaderElems[key].outerHTML) {
          const res = daysHeaderElems[key].outerHTML.toString().match(/\d+ пнд|втр|срд|чтв|птн|суб|вск /) || 'not';

          if (res) {
            matchedElems.push(daysHeaderElems[key] as HTMLElement);
          }
        }
      }

      calendarStore.setHTMLElements(matchedElems);
    } catch (e) {
      console.log(e);
    }
  }, [calendarStore.viewPeriodMode, calendarStore.currentDate]);

  const caption = `${
    calendarStore.currentDate ? getDateOnly(calendarStore.currentDate) : getDateOnly(nowToLocal())
  }, ${workPlacesStore.getCaption()} ${workPlacesStore.selectedWorkPlace.number}`;

  const Toolbar = (props: any) => <CustomToolBar {...props} caption={caption} />;

  const scrollToTime = new Date();
  scrollToTime.setHours(7, 0, 0);

  return (
    <Box sx={{ m: 5 }}>
      {calendarStore.headerDateCellElems.map((cell, index) => (
        <CustomDateHeaderCell key={index} parentNode={cell} />
      ))}

      <S.CalendarWrapper
        culture={'ru-RU'}
        defaultView={Views.DAY}
        components={{ eventWrapper: EventCard, toolbar: Toolbar }}
        view={calendarStore.viewPeriodMode}
        localizer={localizer}
        events={calendarStore.reservations}
        scrollToTime={scrollToTime}
      />
      <ReservationDialog />
    </Box>
  );
};

export default observer(ReservationSingle);
