import React from 'react';
import { IAttributeDto } from 'shared/interfaces/api';

import * as S from './WorkPlaceAttr.styles';

interface IWorkPlaceAttrProps {
  size?: 'small' | 'large';
  attributes: IAttributeDto[];
}

const WorkPlaceAttr: React.FC<IWorkPlaceAttrProps> = (props) => {
  const { attributes, size } = props;

  return (
    <>
      {attributes && (
        <>
          {attributes.map((item: IAttributeDto) => {
            if (!item.count || (item.count && item.count < 1)) return null;

            const iconUrl = `/images/attributeIcons/${item.icon}.svg`;
            const label = item.count == 1 ? `${item.name}` : `${item.name} - ${item.count} шт`;

            return (
              <S.Wrapper key={item.id}>
                <S.Icon size={size ?? 'large'} src={iconUrl} alt={label} />
                <S.Label variant={size === 'small' ? 'caption' : 'body1'}>{label}</S.Label>
              </S.Wrapper>
            );
          })}
        </>
      )}
    </>
  );
};

export default WorkPlaceAttr;
