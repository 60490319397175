import L, { LatLngLiteral, LeafletEvent } from 'leaflet';
import { makeAutoObservable } from 'mobx';
import { IImageSize } from 'shared/interfaces/app';

const DEFAULT_ZOOM = 14;

class MapStateModel {
  public constructor(defaultZoom?: number) {
    // if (defaultZoom) this.currentZoom = defaultZoom;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isShowSvg = true;

  public bounds: L.LatLngBoundsExpression | null = null;

  public center: L.LatLngTuple | null = null;

  //public currentZoom = DEFAULT_ZOOM;

  public startedCoordinate: LatLngLiteral = { lat: 0, lng: 0 };

  public setBounds(bounds: L.LatLngBoundsExpression) {
    this.bounds = bounds;
  }

  public setCenter(center: L.LatLngTuple) {
    this.center = center;
  }

  public zoomIn() {
    // this.currentZoom += 1;
  }

  public zoomOut() {
    // this.currentZoom -= 1;
  }

  public setZoom(zoom: number) {
    // this.currentZoom = zoom;
  }

  public onZoomChange(e: LeafletEvent): void {
    // this.currentZoom = e.target.getZoom();
  }
}

export default MapStateModel;
