import styled from 'styled-components/macro';
import {
  Table as MUITable,
  TableBody as MUITableBody,
  TableCell as MUITableCell,
  TableHead as MUITableHead,
  TableRow as MUITableRow,
  TableContainer as MUITableContainer,
  Typography as MUITypography,
} from '@mui/material';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const TableContainer = styled(MUITableContainer)``;

export const Table = styled(MUITable)`
  min-width: 300px;
`;

export const TableBody = styled(MUITableBody)`
  height: auto;
`;

export const TableCellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableHeadCell = styled(MUITableCell)`
  height: ${(props) => props.theme.spacing(13)};
  padding: 0;
  border-bottom: none;
`;

export const TableBodyCell = styled(MUITableCell)`
  height: ${(props) => props.theme.spacing(13)};
  padding: 0;
  border-bottom: none;

  p {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
  }
`;

export const TableHead = styled(MUITableHead)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    border-top: 0;
    border-bottom: 0;
  }
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

export const TableRow = styled(MUITableRow)``;

export const HeadCellTypography = styled(MUITypography)`
  color: ${(props) => props.theme.palette.primary.main};
  opacity: 0.6;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
`;
