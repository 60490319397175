import styled from 'styled-components/macro';

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RolesWrapper = styled.div`
  height: 100%;
  margin-top: ${(props) => props.theme.spacing(4)};
`;
