import React from 'react';

import { IDropDownOption } from 'shared/interfaces/app';
import { get30minTimeList, getHourTimeList, getMinTimeList, minutesToDate } from 'utils/DateTimeUtils';
import { SelectChangeEvent, Typography, useTheme } from '@mui/material';
import CheckIcon from '../../Icons/CheckIcon';
import SelectExpand from '../../Icons/SelectExpand';
import * as S from './TimeIntervalPicker.styles';

interface ITimeIntervalPickerProps {
  timeFrom: Date | null;
  timeTo: Date | null;
  isDisableFromTime?: boolean | null;
  onBlur?: (e: React.FocusEvent<any>) => void;
  onChange: (fromTime?: Date | null, toTime?: Date | null) => void;
}

const TimeIntervalPicker = (props: ITimeIntervalPickerProps) => {
  const theme = useTheme();

  const { onChange, timeFrom, timeTo, isDisableFromTime } = props;

  // Для dev отображаем минуты
  let options = get30minTimeList();
  if (process.env.REACT_APP_IS_DEVELOPMENT === 'true') {
    options = getMinTimeList();
  }

  // Перевод времени в кол-во минут от начала дня
  let valueFrom = timeFrom ? (timeFrom.getHours() * 60 + timeFrom.getMinutes()).toString() : '';
  let valueTo = timeTo ? (timeTo.getHours() * 60 + timeTo.getMinutes()).toString() : '';

  // Поправка, если не совпадает дата и пункты меню
  const minuteStep = options.minuteStep;
  const valueFromInt = parseInt(valueFrom);
  const modValueFrom = valueFromInt % minuteStep;
  if (modValueFrom !== 0) {
    valueFrom = (valueFromInt + modValueFrom).toString();
  }

  let valueToInt = parseInt(valueTo);
  const modValueTo = valueToInt % minuteStep;
  if (modValueTo !== 0) valueToInt = valueToInt + modValueTo;

  if (valueToInt > options.maxMin) valueTo = options.maxMin.toString();

  // Пункты времени для от/до
  const fromOptions = options.list.slice(0, -1);
  const toOptions = options.list.filter((_) => parseInt(_.id) > parseInt(valueFrom));

  const onTimeFromChange = (e: SelectChangeEvent) => {
    const value = parseInt(e.target.value);
    const newTimeFrom = minutesToDate(value);
    let newTimeTo = timeTo;

    // Если начало больше конца, то прибавляем 1 час
    if (timeTo && newTimeFrom >= timeTo) newTimeTo = minutesToDate(value + 60);

    onChange(newTimeFrom, newTimeTo);
  };

  const onTimeToChange = (e: SelectChangeEvent) => {
    const value = parseInt(e.target.value);
    const newTimeTo = minutesToDate(value);

    onChange(timeFrom, newTimeTo);
  };

  const MenuProps = {
    PaperProps: {
      sx: {
        bgcolor: 'common.white',
      },
    },
    autoFocus: true,
    sx: {
      maxHeight: '432px',
      '&& .Mui-selected': {
        backgroundColor: '#FFF',
        ':hover': {
          backgroundColor: '#FFF',
        },
      },
    },
  };

  const iconRenderer = (props: any) => {
    return <div {...props}>{<SelectExpand />} </div>;
  };

  const optionItems = (options: IDropDownOption[], value: string) =>
    options.map((_) => {
      return (
        <S.MenuItem disableRipple key={_.id} value={_.id}>
          <S.MenuItemRow>
            <Typography>{_.name}</Typography>
            {_.id === value && <CheckIcon color={theme.palette.primary.main} />}
          </S.MenuItemRow>
        </S.MenuItem>
      );
    });

  const getValue = (value: string) => {
    return value !== '' ? (options.list.find((_: IDropDownOption) => _.id === value) ?? { name: ' ' }).name : '';
  };

  if (!valueFrom || !valueTo) return null;

  return (
    <S.TimeWrapper>
      <S.TimeSelect
        disabled={isDisableFromTime ?? false}
        MenuProps={MenuProps}
        value={valueFrom}
        IconComponent={iconRenderer}
        onChange={onTimeFromChange}
        renderValue={() => getValue(valueFrom)}>
        {optionItems(fromOptions, valueFrom)}
      </S.TimeSelect>

      <S.TimeSelect
        displayEmpty
        MenuProps={MenuProps}
        value={valueTo}
        IconComponent={iconRenderer}
        onChange={onTimeToChange}
        renderValue={() => getValue(valueTo)}>
        {optionItems(toOptions, valueTo)}
      </S.TimeSelect>
    </S.TimeWrapper>
  );
};

export default TimeIntervalPicker;
