import styled from 'styled-components/macro';
import { MenuItem as MuiMenuItem, ListSubheader as MuiListSubheader, Chip as MuiChip, Select as MuiSelect } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  & .MuiOutlinedInput-root {
    margin-bottom: 0;
  },
`;

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#F2F5F7',
  },
}));

export const Select = muiStyled(MuiSelect)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  '& .MuiSelect-select': {
    height: '26px',
  },
}));

export const MenuItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Chip = styled(MuiChip)`
  background-color: ${(p) => p.theme.palette.secondary.main};
  height: 26px;
  margin-right: ${(p) => p.theme.spacing(1)};
`;

export const Chips = styled.div`
  ${(props) => props.theme.breakpoints.between('xs', 'md')};
  display: flex;
  position: absolute;
`;

export const ListSubheader = styled(MuiListSubheader)`
  margin-top: ${(props) => props.theme.spacing(1)};
  padding-right: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(3)};
  height: 100%;
  background-color: ${(props) => props.theme.palette.common.white};
`;
