import styled from 'styled-components/macro';

export const DateNavigator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

export const CalendarViewWrapper = styled.div`
  height: 100%;
  flex-grow: 1.2;
  display: flex;
  align-items: flex-end;
`;

export const DateScroller = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(_) => _.theme.palette.background.paper};
  border-radius: 12px;
  height: 32px;
`;

export const ScrollBtnWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  &:active {
    & svg {
      color: ${(_) => _.theme.palette.secondary.main};
    }
  }
`;

export const TodayBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin: 0 4px;
  padding: 0 8px;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    border-radius: 12px;
    background-color: ${(_) => _.theme.palette.primary.main};
    color: ${(_) => _.theme.palette.common.white};
  }
  &:active {
    box-shadow: 0 12px 13px -6px rgba(0, 0, 0, 0.09);
    color: ${(_) => _.theme.palette.secondary.main};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    flex: unset;
    align-items: flex-end;
    margin-right: ${(props) => props.theme.spacing(4)};
    & .MuiTypography-root {
      margin-bottom: ${(props) => props.theme.spacing(3)};
    }
  }
`;

export const ActionWrapper = styled.div`
  display: flex;

  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-direction: column;

    Button {
      width: 100%;
      margin-bottom: ${(props) => props.theme.spacing(2)};
      height: 32px;
    }
  }
`;
