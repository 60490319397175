import { DateRange } from '@mui/lab';
import { makeAutoObservable } from 'mobx';
import { userAuthStore, usersStore } from 'stores';
import { MessagesApi } from 'api';
import { DialogStatus, MessagesTypes } from 'shared/enums';
import { IMessagesPostDto } from 'shared/interfaces/api';
import { IDropDownOption } from 'shared/interfaces/app';
import { MessagesModel, MessagesQueryModel, MessagesPopupModel } from 'shared/models';
import { getDateLastWeek, getEndOfDay } from 'utils/DateTimeUtils';
import { ITabToggler } from '../components/UIKit/Toggler/Toggler';
import { COMPANY_OPTION_ID, DEPARTMENT_OPTION_ID } from 'shared/constants';

export enum MessagesTabActions {
  In = 'in',
  Out = 'out',
}

class MessagesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    // Для dev период 10 сек. Для prod - 1 мин
    const timeIntervalSec = process.env.REACT_APP_IS_DEVELOPMENT === 'true' ? 10 : 60;

    setInterval(this.iteration, 1000 * timeIntervalSec);
    setTimeout(this.iteration, 0);
  }

  /**
   * Опрос новых сообщений
   * @private
   */
  // todo kvv: в дальнейшем заменить на сокеты
  private async iteration(): Promise<void> {
    const count = this.unreadMessagesCount;
    await this.getUnreadCount();

    // Если увеличилось кол-во, то запрашиваем не прочитанные для попапа
    if (this.unreadMessagesCount > count) await this.getUnreadMessages();

    // Если увеличилось кол-во и активна вкладка, то запрашиваем все
    if (this.isActivePage && this.unreadMessagesCount > count) await this.getMessagesList();
  }

  public isActivePage = false;

  public messages: MessagesModel[] = [];

  public unreadMessages: MessagesModel[] = [];

  public selectedMessage = new MessagesModel();

  public queryParams = new MessagesQueryModel();

  public popup = new MessagesPopupModel();

  public totalCount = 0;

  public unreadMessagesCount = 0;

  public dialogViewMode = DialogStatus.Closed;

  public isFetching = false;

  public selectedTab: string = MessagesTabActions.In;

  public get messagesTabs(): Array<ITabToggler> {
    return [
      {
        id: MessagesTabActions.In,
        name: 'Входящие',
        count: this.unreadMessagesCount,
      },
      {
        id: MessagesTabActions.Out,
        name: 'Исходящие',
      },
    ];
  }

  public getSelectedMessageSubtitleName(): string {
    return this.getMessageSubtitleName(this.selectedMessage);
  }

  public getMessageSubtitleName(message: MessagesModel): string {
    const isTabInput = this.selectedTab === MessagesTabActions.In;
    const prefix = isTabInput ? 'От кого:' : 'Кому:';
    //Если на странице входящих то отображаем, от кого (отправитель) сообщение
    //Если на странице исходящих то кому(получатель) было отправлено сообщение
    let name = '';
    if (message.type == MessagesTypes.Private) name = isTabInput ? message.userSenderName : message.userReceiverName;
    if (message.type == MessagesTypes.Company) name = isTabInput ? `${message.userSenderName} (рассылка компании)` : 'рассылка компании';
    if (message.type == MessagesTypes.Department) name = isTabInput ? `${message.userSenderName} (рассылка отдела)` : 'рассылка отдела';
    if (message.type == MessagesTypes.RB) name = isTabInput ? message.userSenderName : 'Администраторам рабочих мест';
    return `${prefix} ${name}`;
  }

  public getOnlyFromMessageSubtitle(message: MessagesModel): string {
    let name = '';
    const isTabInput = this.selectedTab === MessagesTabActions.In;

    if (message.type == MessagesTypes.Private) name = message.userReceiverName;
    if (message.type == MessagesTypes.Company) name = 'рассылка компании';
    if (message.type == MessagesTypes.Department) name = 'рассылка отдела';
    if (message.type == MessagesTypes.RB) name = 'Администраторам рабочих мест';

    return isTabInput ? `Кому: ${name}` : '';
  }

  public get messageUserForOptions(): IDropDownOption[] {
    const customOptions = usersStore.users.map((item) => item.getDropDownOption());

    if (userAuthStore.departmentId) {
      customOptions.unshift({
        id: DEPARTMENT_OPTION_ID,
        name: 'Своему отделу',
      });
    }
    customOptions.unshift({
      id: COMPANY_OPTION_ID,
      name: 'Всем пользователям',
    });

    return customOptions;
  }

  public get messagesForList(): MessagesModel[] {
    return this.messages;
  }

  public get messagesForPopup(): MessagesModel[] {
    return this.unreadMessages;
  }

  public get messagesQueryParams() {
    this.queryParams.userId = userAuthStore.userId;
    return this.queryParams;
  }

  public get tab() {
    return this.selectedTab;
  }

  public get queryDateRange(): DateRange<Date> {
    if (!this.queryParams.from && !this.queryParams.to) {
      return [getDateLastWeek(new Date()), getEndOfDay()];
    }
    return [this.queryParams.from, this.queryParams.to];
  }

  public setDateRange(dateRange: DateRange<Date>) {
    this.queryParams.from = dateRange[0];
    this.queryParams.to = dateRange[1];
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCreatingMode() {
    this.selectedMessage = new MessagesModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public setTab(value: string) {
    this.queryParams.pageIndex = 0;
    this.selectedTab = value;
  }

  public closeMessagesDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  /**
   * Список сообщений
   */
  public async getMessagesList() {
    if (!this.messagesQueryParams.from || !this.messagesQueryParams.to) return;
    try {
      this.isFetching = true;
      let messages;

      switch (this.tab) {
        case MessagesTabActions.In:
          messages = await MessagesApi.getMessagesInput(this.messagesQueryParams);
          break;
        case MessagesTabActions.Out:
          messages = await MessagesApi.getMessagesOutput(this.messagesQueryParams);
          break;
      }

      if (!messages) return;

      this.totalCount = messages.totalCount;
      this.messages = messages.rows.map((item) => new MessagesModel(item));
    } catch (e) {
      this.messages = [];
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  /**
   * Создать сообщение
   */
  public async createMessage(messagePostDto: IMessagesPostDto): Promise<void> {
    try {
      const newMessage = await MessagesApi.postMessage(messagePostDto);
      if (!newMessage) return;
    } catch (e) {
      //ignore
    }
  }

  /**
   * Получить непрочитанные сообщения
   */
  public async getUnreadCount() {
    try {
      if (!userAuthStore.userId) return;

      const count = await MessagesApi.getUnreadCount(userAuthStore.userId);
      if (!count) return;
      this.unreadMessagesCount = count;
    } catch (e) {
      //ignore
    }
  }

  /***
   * Прочитали сообщение
   */
  public async readMessage() {
    try {
      await MessagesApi.putMessageStatus(userAuthStore.userId, this.selectedMessage.id);
      await this.getMessagesList();

      // Уменьшаяем непрочитанные на 1
      if (this.unreadMessagesCount > 0) this.unreadMessagesCount -= 1;
    } catch (e) {
      //ignore
    }
  }

  public async getUnreadMessages() {
    try {
      this.isFetching = true;
      const messages = await MessagesApi.getLastUnreadMessages(userAuthStore.userId, 4);
      if (!messages) return;

      this.unreadMessages = messages.map((item) => new MessagesModel(item));
    } catch (e) {
      this.unreadMessages = [];
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public setActivePage(value: boolean) {
    this.isActivePage = value;
  }
}

export default new MessagesStore();
