import React, { ReactElement, useState } from 'react';

import MoreActions from 'components/Icons/MoreActions';

import * as S from './PopupMenu.styles';

interface IPopupMenuProps {
  button?: JSX.Element;
  menuItems: ReactElement[];
}

const PopupMenu = (props: IPopupMenuProps) => {
  const { menuItems, button } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const btn = button ?? <MoreActions />;

  return (
    <>
      <S.ActionsWrapper
        id="popup-button"
        aria-controls={open ? 'popup-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {btn}
      </S.ActionsWrapper>
      <S.Menu
        id="popup-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}>
        <div style={{ maxHeight: 500, marginTop: '10px', overflow: 'auto' }}>
          {menuItems.map((element) => {
            return element;
          })}
        </div>
      </S.Menu>
    </>
  );
};

export default PopupMenu;
