import React, { useState } from 'react';
import { IThemeExtendedColors } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const Trash: React.FC = () => {
  const theme = useTheme();

  const mainColor = (theme.palette as IThemeExtendedColors).icons?.stroke;
  const [strokeColor, setStrokeColor] = useState(mainColor);

  const onMouseOver = () => {
    setStrokeColor(theme.palette.primary.main);
  };

  const onMouseLeave = () => {
    setStrokeColor(mainColor);
  };

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <path
        d="M16.1042 7.89017C16.1042 7.89017 15.6517 13.5027 15.3892 15.8668C15.2642 16.996 14.5667 17.6577 13.4242 17.6785C11.25 17.7177 9.07332 17.7202 6.89999 17.6743C5.80082 17.6518 5.11499 16.9818 4.99249 15.8727C4.72832 13.4877 4.27832 7.89017 4.27832 7.89017"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.2567 5.19975H3.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.5335 5.19975C13.8793 5.19975 13.316 4.73725 13.1877 4.09642L12.9852 3.08308C12.8602 2.61558 12.4368 2.29225 11.9543 2.29225H8.42682C7.94432 2.29225 7.52099 2.61558 7.39599 3.08308L7.19349 4.09642C7.06516 4.73725 6.50182 5.19975 5.84766 5.19975"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trash;
