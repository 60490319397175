import styled from 'styled-components/macro';

export const Spinner = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  width: 90%;
`;
