import React, { useRef, useState, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { mapStore, workPlacesStore } from 'stores';
import { Input, Checkbox, ModalDialog, Upload } from 'components';
import { DialogStatus, WorkplacePositionAction } from 'shared/enums';
import { IFloorImageDialog } from 'shared/interfaces/app';

import { Typography } from '@mui/material';
import * as S from './FloorImageDialog.styles';

const FloorImageDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    return () => {
      mapStore.selectedFloorImage.prevHeight = null;
      mapStore.selectedFloorImage.prevWidth = null;
    };
  }, []);

  const formRef = useRef<FormikProps<IFloorImageDialog>>(null);

  const dialogTitle = mapStore.selectedFloorImage.id ? 'Редактировать карту этажа' : 'Создать карту этажа';

  const onSubmit = async (values: IFloorImageDialog): Promise<void> => {
    mapStore.selectedFloorImage.updateFromDialogForm(values);

    if (mapStore.dialogFloorImageMode === DialogStatus.Creating) {
      await mapStore.createFloorImage();
    } else if (mapStore.dialogFloorImageMode === DialogStatus.Editing) {
      await mapStore.updateFloorImage();
    }

    mapStore.closeFloorImageDialog();
    await mapStore.getFloorImagesList();
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    await mapStore.selectedFloorImage.uploadImage(e);
  };

  const draggableUpload = async (e: React.DragEvent<HTMLLabelElement>): Promise<void> => {
    e.preventDefault();
    await mapStore.selectedFloorImage.uploadImage(e);
  };

  const setWorkplacePositionAction = async (e: React.SyntheticEvent, checked: boolean) => {
    if (checked) {
      mapStore.selectedFloorImage.workplacePositionAction = WorkplacePositionAction.Delete;
    }
  };

  const alertVisible =
    mapStore.selectedFloorImage.prevHeight &&
    mapStore.selectedFloorImage.prevWidth &&
    (mapStore.selectedFloorImage.prevWidth !== mapStore.selectedFloorImage.width ||
      mapStore.selectedFloorImage.prevHeight !== mapStore.selectedFloorImage.height);

  const floorForm: JSX.Element = (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          name: mapStore.selectedFloorImage.name,
          pixelsPerMeter: mapStore.selectedFloorImage.pixelsPerMeter,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(50, 'Название карты этажа должно содержать не более 50 символов').required('Обязательное поле'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
          setValidated(isValid);

          return (
            <>
              <Input
                name="name"
                inputLabel="Название карты"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              <Input
                name="pixelsPerMeter"
                inputLabel="Пикселей в одном метре"
                value={values.pixelsPerMeter}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </>
          );
        }}
      </Formik>
      <S.UploadField>
        <Upload
          title="Выберите или перетащите карту"
          accept=".jpg, .jpeg, .png, .svg"
          subTitle="Файл должен быть формата SVG, PNG или JPG"
          image={mapStore.selectedFloorImage.imageBase64}
          onChangeFile={handleUpload}
          handleDrop={draggableUpload}
        />

        {!mapStore.selectedFloorImage.isValidMap && (
          <Typography sx={{ mt: 1, ml: 3 }} variant="caption" color="error">
            Неверный формат файла
          </Typography>
        )}

        {alertVisible === true && (
          <S.AlertWrapper>
            <S.AlertMapSize severity="error">
              <div>Размеры текущей и загружаемой карт не совпадают.</div>
              <div>
                Текущая: {mapStore.selectedFloorImage.prevWidth}x{mapStore.selectedFloorImage.prevHeight}
              </div>
              <div>
                Загружаемая: {mapStore.selectedFloorImage.width}x{mapStore.selectedFloorImage.height}
              </div>
              <div>На карте есть объекты. Их координаты могут быть смещены.</div>
              <div>Для удаления объектов с карты поставьте галочку &quot;Удалить объекты с карты&quot;</div>
            </S.AlertMapSize>

            <Checkbox label={'Удалить объекты с карты'} onChange={setWorkplacePositionAction} />
          </S.AlertWrapper>
        )}
      </S.UploadField>
    </>
  );

  const handleCloseDialog = () => {
    mapStore.closeFloorImageDialog();
  };

  return (
    <ModalDialog
      isModelHasId={mapStore.selectedFloorImage.id}
      title={dialogTitle}
      isValid={validated && !!mapStore.selectedFloorImage.imageBase64}
      isOpen={mapStore.dialogFloorImageMode !== DialogStatus.Closed}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onSubmit={() => {
        formRef.current?.handleSubmit();
      }}>
      {floorForm}
    </ModalDialog>
  );
};

export default observer(FloorImageDialog);
