import React from 'react';
import { observer } from 'mobx-react';
import { rolesStore } from 'stores';
import { Switch, Tag, Title } from 'components';
import {
  CompanyActions,
  DepartmentActions,
  MapActions,
  MapConstructorActions,
  PassActions,
  ReservationActions,
  RoleActions,
  UserActions,
  ZoneActions,
  MessageActions,
} from 'shared/enums';
import { IPermissionsForSwitchList } from 'shared/interfaces/app';
import { useBreakpoint } from 'utils/hooks';
import { Box, Divider, Typography } from '@mui/material';

import * as S from './Permissions.styles';

const Permissions: React.FC = () => {
  const handleChangeModules = (event: React.ChangeEvent<HTMLInputElement>) => {
    rolesStore.setModulePermission(event.target.checked, event.target.id);
  };

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const modulePermissions: JSX.Element = (
    <>
      {rolesStore.permissionsForSwitchList.map((item: IPermissionsForSwitchList) => (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {item.checked !== null ? (
              <>
                <Typography variant="body1">{item.permissionName!}</Typography>
                <Switch id={item.permissionId} key={item.permissionId} checked={item.checked} onChange={handleChangeModules} />
              </>
            ) : (
              <>
                <Typography variant="body2">{item.permissionName!}</Typography>
              </>
            )}
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
        </>
      ))}
    </>
  );

  const actionPermissions: JSX.Element = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ mt: 2, mb: 2 }} variant="body2">
        Пользователи
      </Typography>
      <Tag size="small" orientation="horizontal" value={rolesStore.userActions} onCustomChange={rolesStore.setActionPermission} tags={UserActions} />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Роли
      </Typography>
      <Tag size="small" orientation="horizontal" value={rolesStore.roleActions} onCustomChange={rolesStore.setActionPermission} tags={RoleActions} />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Отдел
      </Typography>
      <Tag
        size="small"
        orientation="horizontal"
        value={rolesStore.departmentActions}
        onCustomChange={rolesStore.setActionPermission}
        tags={DepartmentActions}
      />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Компания
      </Typography>
      <Tag
        size="small"
        orientation="horizontal"
        value={rolesStore.companyActions}
        onCustomChange={rolesStore.setActionPermission}
        tags={CompanyActions}
      />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Зоны
      </Typography>
      <Tag size="small" orientation="horizontal" value={rolesStore.zoneActions} onCustomChange={rolesStore.setActionPermission} tags={ZoneActions} />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Пропуска
      </Typography>
      <Tag
        size="small"
        orientation={isMobile ? 'vertical' : 'horizontal'}
        value={rolesStore.passActions}
        onCustomChange={rolesStore.setActionPermission}
        tags={PassActions}
      />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Конструктор карты
      </Typography>
      <Tag
        size="small"
        orientation="vertical"
        value={rolesStore.mapConstructorActions}
        onCustomChange={rolesStore.setActionPermission}
        tags={MapConstructorActions}
      />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Карта
      </Typography>
      <Tag size="small" orientation="vertical" value={rolesStore.mapActions} onCustomChange={rolesStore.setActionPermission} tags={MapActions} />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Бронирования
      </Typography>
      <Tag
        size="small"
        orientation="vertical"
        value={rolesStore.reservationActions}
        onCustomChange={rolesStore.setActionPermission}
        tags={ReservationActions}
      />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />

      <Typography sx={{ mb: 3 }} variant="body2">
        Сообщения
      </Typography>
      <Tag
        size="small"
        orientation="vertical"
        value={rolesStore.messageActions}
        onCustomChange={rolesStore.setActionPermission}
        tags={MessageActions}
      />
      <Divider sx={{ mt: 2, mb: 2 }} orientation="horizontal" flexItem />
    </Box>
  );

  return (
    <S.PermissionsWrapper>
      <Typography sx={{ mt: 2 }} variant="h3">
        Отображать модули
      </Typography>
      {modulePermissions}

      <Typography sx={{ mt: 2 }} variant="h3">
        Действия
      </Typography>
      {actionPermissions}
    </S.PermissionsWrapper>
  );
};

export default observer(Permissions);
