import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';
export type IBreakpoint = 'xl' | 'lg' | 'md' | 'sm';

const useBreakpoint = (): IBreakpoint => {
  const [width, setWidth] = useState(0);
  const theme = useTheme();
  const brValues = theme.breakpoints.values;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (width > brValues.lg) {
    return 'xl';
  }

  if (width <= brValues.lg && width >= brValues.md) {
    return 'lg';
  }

  if (width <= brValues.md && width >= brValues.sm) {
    return 'md';
  }

  return 'sm';
};

export default useBreakpoint;
