import React, { useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { mapStore } from 'stores';
import { Input, ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { IFloorDialog } from 'shared/interfaces/app';
import { FloorImageModel } from '../../../../../shared/models';

const FloorDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);

  const formRef = useRef<FormikProps<IFloorDialog>>(null);

  const dialogTitle = mapStore.selectedFloor.id ? 'Редактировать этаж' : 'Создать этаж';

  const onSubmit = async (values: IFloorDialog) => {
    mapStore.selectedFloor.updateFromDialogForm(values);

    let newFloor = '';
    if (mapStore.dialogFloorMode === DialogStatus.Creating) {
      newFloor = await mapStore.createFloor();
    } else if (mapStore.dialogFloorMode === DialogStatus.Editing) {
      await mapStore.updateFloor();
    }

    mapStore.closeFloorDialog();

    mapStore.clear();
    await mapStore.getFloorsList();

    if (newFloor) {
      mapStore.selectedFloor.setFloorImageId(newFloor);
    }

    await mapStore.getFloorImagesList(mapStore.selectedFloor.id);
    mapStore.selectedFloorImage = new FloorImageModel(mapStore.floorImages[0]);
    await mapStore.getFloorImageById();
  };

  const floorForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        name: mapStore.selectedFloor.name,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Обязательное поле'),
      })}>
      {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
        setValidated(isValid);
        return (
          <>
            <Input
              name="name"
              inputLabel="Название этажа"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </>
        );
      }}
    </Formik>
  );

  const handleCloseDialog = () => {
    mapStore.closeFloorDialog();
  };

  return (
    <ModalDialog
      isModelHasId={mapStore.selectedFloor.id}
      title={dialogTitle}
      isValid={validated}
      isOpen={mapStore.dialogFloorMode !== DialogStatus.Closed}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {floorForm}
    </ModalDialog>
  );
};

export default observer(FloorDialog);
