import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';

import { departmentsStore, usersStore, zonesStore } from 'stores';
import { DropDownChip, Input, ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { IDepartmentsDialog } from 'shared/interfaces/app/IDepartmentsApp';
import { DepartmentModel } from 'shared/models';

import * as S from './DepartmentsDialog.styles';

const DepartmentsDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);
  const formRef = useRef<FormikProps<IDepartmentsDialog>>(null);

  useEffect(() => {
    return () => {
      departmentsStore.selectedDepartment = new DepartmentModel();
    };
  }, []);

  const onSubmit = async (values: IDepartmentsDialog) => {
    departmentsStore.selectedDepartment.updateFromDialogForm(values);

    if (departmentsStore.dialogViewMode === DialogStatus.Creating) {
      await departmentsStore.createDepartment();
    } else if (departmentsStore.dialogViewMode === DialogStatus.Editing) {
      await departmentsStore.updateDepartment();
    }

    departmentsStore.closeDepartmentsDialog();
    await departmentsStore.getDepartmentsList();
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const handleCloseDialog = () => {
    departmentsStore.setDialogViewMode(DialogStatus.Closed);
  };

  const dialogTitle = departmentsStore.selectedDepartment.id ? 'Редактировать отдел' : 'Создать отдел';

  return (
    <ModalDialog
      isModelHasId={departmentsStore.selectedDepartment.id}
      isValid={validated}
      title={dialogTitle}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onSubmit={handleSubmit}
      isOpen={departmentsStore.dialogViewMode === DialogStatus.Creating || departmentsStore.dialogViewMode === DialogStatus.Editing}>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          name: departmentsStore.selectedDepartment.name,
          zoneIds: departmentsStore.selectedDepartment.zoneIds,
          userIds: departmentsStore.selectedDepartment.userIds,
          zonesOpt: zonesStore.zonesForOptions,
          usersOpt: usersStore.usersForOptions,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Обязательное поле'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
          setValidated(isValid);

          const handleDeleteZone = (e: React.ChangeEvent<string>, value: string) => {
            e.preventDefault();

            const idx = values.zoneIds.indexOf(value);
            if (idx !== -1) {
              values.zoneIds.splice(idx, 1);
            }
            setFieldValue('zoneIds', values.zoneIds);
          };

          const handleDeleteUsers = (e: React.ChangeEvent<string>, value: string) => {
            e.preventDefault();

            const idx = values.userIds.indexOf(value);
            if (idx !== -1) {
              values.userIds.splice(idx, 1);
            }
            setFieldValue('userIds', values.userIds);
          };
          return (
            <S.DepartmentDialogWrapper>
              <Input
                name="name"
                inputLabel="Название отдела"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <DropDownChip
                name="userIds"
                options={values.usersOpt}
                values={values.userIds}
                placeholder="Выберите пользователей"
                dropDownLabel="Пользователи"
                onDelete={handleDeleteUsers}
                onChange={(e: React.ChangeEvent<any>) => {
                  setFieldValue('userIds', e.target.value);
                }}
              />

              <DropDownChip
                name="zoneIds"
                options={values.zonesOpt}
                placeholder="Выберите зоны"
                values={values.zoneIds}
                dropDownLabel="Зоны"
                onDelete={handleDeleteZone}
                onChange={(e: React.ChangeEvent<any>) => {
                  setFieldValue('zoneIds', e.target.value);
                }}
              />
            </S.DepartmentDialogWrapper>
          );
        }}
      </Formik>
    </ModalDialog>
  );
};

export default observer(DepartmentsDialog);
