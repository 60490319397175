import { observer } from 'mobx-react';
import { ModalDialog } from 'components';
import { Button } from '@mui/material';
import { DialogStatus } from 'shared/enums';
import { workPlacesStore, usersStore } from 'stores';
import UserPanel from 'pages/Main/Components/UserPanel';
import React from 'react';

import * as S from './UserInfoViewDialog.styles';

interface IMessageViewDialogProps {
  userId: string;
}

const UserInfoViewDialog: React.FC<IMessageViewDialogProps> = (props) => {
  const { userId } = props;

  const onClose = () => {
    workPlacesStore.userInfoDialogViewMode = DialogStatus.Closed;
  };

  return (
    <ModalDialog
      title={'Информация о пользователе'}
      onClose={onClose}
      onCancel={onClose}
      isOpen={workPlacesStore.userInfoDialogViewMode !== DialogStatus.Closed}>
      <S.ContentWrapper>
        <UserPanel userId={userId}></UserPanel>
        <Button variant="outlined" fullWidth size="large" onClick={onClose}>
          Закрыть
        </Button>
      </S.ContentWrapper>
    </ModalDialog>
  );
};
export default observer(UserInfoViewDialog);
