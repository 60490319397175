import React, { ChangeEvent } from 'react';
import { DateRange } from '@mui/lab';
import { observer } from 'mobx-react';

import { mapStore, reservationStore as store, rolesStore, usersStore, workPlacesStore } from 'stores';
import { DateRangePicker, DropDown, FloorAndPlaceControlPanel } from 'components';
import TimeIntervalPicker from 'components/UIKit/TimeIntervalPicker';
import { ROUTE } from 'routes';
import { Permissions } from 'shared/enums';

import * as S from './ReservationListFilters.styles';
import { localToUtc, toUtc } from '../../../../utils/DateTimeUtils';

const ReservationListFilters = () => {
  const isAdmin = rolesStore.isAllow(Permissions.ReservationViewOtherUsers);
  const { filter } = store;

  const onChangeFilterUser = (event: ChangeEvent<{ value: string }>) => {
    filter.userId = event.target.value;
    store.getRowItemList().finally();
  };

  const onChangeTime = (fromTime?: Date | null, toTime?: Date | null) => {
    if (!fromTime || !toTime) return;
    filter.setTimeInterval(fromTime, toTime);
    store.getRowItemList().finally();
  };

  const onChangeDate = (dateRange: DateRange<Date>) => {
    try {
      const fromDate = dateRange[0];
      const toDate = dateRange[1];

      if (!fromDate || !toDate) return;
      if (fromDate > toDate) return;

      filter.onPeriodUpdate(fromDate, toDate);
      store.getRowItemList().finally();
    } catch (e) {
      console.log(e);
    }
  };

  const onSelectOnlyMine = (isOnlyMine: boolean) => {
    filter.onOnlyMineChange(isOnlyMine);
    store.getRowItemList().finally();
  };

  const onChangeFloor = (id: string | null) => {
    filter.updateFloorId(id);
    store.getRowItemList().finally();
  };

  const onChangePlace = (id: string | null) => {
    filter.updateWorkPlaceId(id);
    store.getRowItemList().finally();
  };

  const typeId = workPlacesStore.workPlacesPlainList.find((_) => _.id === filter.workplaceId)?.type;
  const fromTimeInDay = toUtc(filter.fromTimeInDay ?? new Date()).toISOString();
  const toTimeInDay = toUtc(filter.toTimeInDay ?? new Date()).toISOString();
  const link = `${ROUTE.Main}?workplaceId=${filter.workplaceId}&typeId=${typeId}&fromTimeInDay=${fromTimeInDay}&toTimeInDay=${toTimeInDay}`;

  return (
    <S.FiltersWrapper>
      <S.DataPickerWrapper>
        <DateRangePicker range={[filter.fromTime, filter.toTime]} onChange={onChangeDate} />
        <TimeIntervalPicker timeFrom={filter.fromTimeInDay} timeTo={filter.toTimeInDay} onChange={onChangeTime} />
      </S.DataPickerWrapper>

      {isAdmin && usersStore.usersForOptions.length > 0 && (
        <S.DropDownWrapper>
          <DropDown
            mb={0}
            name="userId"
            disabled={filter.isOnlyMine}
            options={usersStore.usersForOptions}
            value={filter.userId}
            placeholder="ФИО"
            onChange={onChangeFilterUser}
          />
        </S.DropDownWrapper>
      )}

      <FloorAndPlaceControlPanel
        link={link}
        getFloorList={mapStore.getFloorsDropDownList}
        getWorkPlaces={workPlacesStore.getAvailableWorkPlainPlaces}
        floorsList={mapStore.floorsDropDownList}
        placesList={workPlacesStore.workPlacesPlainList}
        selectedFloorId={filter.floorId}
        selectedWorkPlaceId={filter.workplaceId}
        onlyMineSelected={filter.isOnlyMine}
        onChangeFloor={onChangeFloor}
        onChangePlace={onChangePlace}
        onSelectOnlyMine={onSelectOnlyMine}
      />
    </S.FiltersWrapper>
  );
};

export default observer(ReservationListFilters);
