import styled from 'styled-components/macro';
import { Button } from '@mui/material';

export const FiltersWrapper = styled.div`
  display: flex;

  ${(_) => _.theme.breakpoints.down('sm')} {
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }

  ${(_) => _.theme.breakpoints.up('sm')} {
    flex-wrap: nowrap;

    & > *:not(:last-child) {
      margin-right: ${(props) => props.theme.spacing(2)};
    }
  }

  &:before {
    opacity: 1;
    background-color: transparent;
  }
`;

export const SearchButton = styled(Button)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0;
    width: 100%;
  }
`;
