import styled from 'styled-components/macro';
import { TextField as MuiTextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const InputWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .MuiTextField-root {
    margin-bottom: 0;
  }
`;

export const TextField = muiStyled(MuiTextField)(({ theme, focus, mb }) => ({
  marginBottom: theme.spacing(mb),
  '& .MuiInputAdornment-root, .MuiInputAdornment-positionStart': {
    backgroundColor: focus ? theme.palette.common.white : theme.palette.background.paper,
    borderRadius: '12px 0 0 12px',
  },
  '& .MuiInputAdornment-positionEnd': {
    backgroundColor: focus ? theme.palette.common.white : theme.palette.background.paper,
    borderRadius: '0 12px 12px 0',
  },
  '& textarea': {
    fontWeight: 'normal',
  },
}));
