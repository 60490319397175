import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { passesStore, usersStore } from 'stores';
import { Accordion, ContentHeader, MainContent, Spinner, Table } from 'components';

import { Column, IPassesFotTable } from 'shared/interfaces/app';
import { Divider, Typography } from '@mui/material';
import { useBreakpoint } from '../../utils/hooks';
import { PassesDialog, PassesFilters, PassesMobile, PassesTablet } from './Components';
import ChipStatus from './Components/ChipStatus';

const Passes: React.FC = () => {
  useEffect(() => {
    if (passesStore.isViewAll) usersStore.getUsersList().finally();
    passesStore.getPassesList().finally();

    passesStore.setActivePage(true);
    return () => passesStore.setActivePage(false);
  }, []);

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';
  const isDesktop = bp === 'lg';

  const createRowData = () => {
    return passesStore.passesForTable.map((data: IPassesFotTable, index: number) => {
      const row: Array<JSX.Element> = Object.values(data)
        .map((value, idx) => {
          if (idx === Object.values(data).length - 1) return <ChipStatus value={value} />;

          return <Typography key={value + index}>{value}</Typography>;
        })
        .slice(1);

      return row;
    });
  };

  const handleCreatePass = () => {
    passesStore.setCreatingMode();
  };

  const handleCloseDialog = () => {
    passesStore.closePassesDialog();
  };

  const columns: Column[] = [
    {
      id: 'createdDate',
      label: 'Дата создания',
      minWidth: 160,
    },
    {
      id: 'passDate',
      label: 'Дата пропуска',
      minWidth: 160,
    },
    {
      id: 'user',
      label: 'ФИО',
      minWidth: 100,
      disabled: !passesStore.isViewAll,
    },
    {
      id: 'description',
      label: 'Описание',
      minWidth: 300,
    },
    {
      id: 'status',
      label: 'Статус',
      minWidth: 100,
      width: 100,
    },
  ];

  return (
    <>
      <ContentHeader title="Пропуска" btnLabel="Создать" onClick={handleCreatePass} />
      <MainContent>
        <>
          {isMobile ? (
            <>
              <Accordion title="Фильтры">
                <PassesFilters />
              </Accordion>
              <Divider sx={{ mt: 1, mb: 1 }} orientation="horizontal" flexItem />
            </>
          ) : (
            <PassesFilters />
          )}

          {passesStore.isLoaded ? (
            <Spinner />
          ) : isMobile ? (
            <PassesMobile />
          ) : isTablet || isDesktop ? (
            <PassesTablet />
          ) : (
            <Table columns={columns} rows={createRowData()} />
          )}

          <PassesDialog onClose={handleCloseDialog} />
        </>
      </MainContent>
    </>
  );
};

export default observer(Passes);
