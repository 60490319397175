import React, { ReactElement } from 'react';
import { appStore } from 'stores';
import { PopupMenu } from 'components';
import PlusCubeIcon from 'components/Icons/PlusCubeIcon';
import Settings from 'components/Icons/Settings';
import { useBreakpoint } from 'utils/hooks';
import { Box, Button, Typography } from '@mui/material';
import * as S from './ContentHeader.styles';

interface IContentHeaderProps {
  title: string;
  btnLabel?: string;
  btnDisabled?: boolean;
  menuItemsSettings?: ReactElement[];
  onClick?: () => void;
}

const ContentHeader: React.FC<IContentHeaderProps> = (props) => {
  const { title, btnLabel, btnDisabled, onClick, menuItemsSettings } = props;

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';

  const settingsButton = <Settings />;
  return (
    <S.HeaderWrapper>
      <S.Header isbutton={!!onClick}>
        {isMobile || isTablet ? (
          <S.MenuButton onClick={() => appStore.setVisibleSideBar(!appStore.visibleSidebar)}>
            <img src={'/images/hamburger.svg'} alt={''} />
          </S.MenuButton>
        ) : null}

        <S.HeaderTitle>
          <Typography variant={isMobile ? 'h3' : 'h2'}>{title}</Typography>
          {menuItemsSettings && <PopupMenu menuItems={menuItemsSettings} button={settingsButton} />}
        </S.HeaderTitle>

        {onClick && (
          <S.ButtonWrapper onClick={onClick} disabled={btnDisabled}>
            {btnLabel && !isMobile && (
              <Button color="secondary" variant="contained" size="small" disabled={btnDisabled} onClick={onClick}>
                {btnLabel}
              </Button>
            )}
            {isMobile && <PlusCubeIcon />}
          </S.ButtonWrapper>
        )}
      </S.Header>
    </S.HeaderWrapper>
  );
};

export default ContentHeader;
