import { makeAutoObservable } from 'mobx';
import { ReservationApi, WorkPlacesApi } from 'api';

import confirmDialog from 'components/UIKit/ConfirmDialog';
import { IConfirmDialog } from 'components/UIKit/ConfirmDialog/confirmDialogStore';
import { ITabToggler } from 'components/UIKit/Toggler/Toggler';
import { IReservationFilterDto } from 'shared/interfaces/api';
import { ReservationDto, IReservationRowItemDto, ReservationCreateOrUpdateDto, IReservationDto } from 'shared/interfaces/api/reservationDto';
import { ITableDto } from 'shared/interfaces/app';
import { ReservationMapPreview } from 'shared/models';
import ReservationFilter from 'shared/models/ReservationCalendar/ReservationFilter';
import ReservationQueryModel from 'shared/models/ReservationQueryModel';
import { getEndOfDay, getReservationText, getTimeOnly } from 'utils/DateTimeUtils';
import { reservationStatus } from 'utils/ReservationStatus';
import { ROUTE } from '../routes';
import { Permissions } from '../shared/enums';
import rolesStore from './rolesStore';
import { calendarStore, userAuthStore } from './index';

interface IActiveButtons {
  acceptQr: boolean;
  accept: boolean;
  edit: boolean;
  cancel: boolean;
  comment: boolean;
}

class ReservationStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public rawTable: ITableDto<IReservationRowItemDto> = { rows: [], totalCount: 0 };

  public queryParams = new ReservationQueryModel();

  public selectedTab: string = ROUTE.ReservationList;

  public reservationsList: ReservationMapPreview[] = [];

  public filter = new ReservationFilter();

  public reservationId = '';

  public isLoadingTable = false;
  public isLoadingOverlay = false;
  public isLoadingMapPreview = false;

  public isShowDialog = false;
  public isShowQrDialog = false;

  public isScheduleOpened = false;

  public reservation: ReservationDto | null = null;

  public get reservationTabs(): Array<ITabToggler> {
    return [
      {
        id: ROUTE.ReservationList,
        name: 'Список',
      },
      {
        id: ROUTE.ReservationCalendar,
        name: 'Календарь',
      },
    ];
  }

  public get tab() {
    return this.selectedTab;
  }

  public get isCommentBtn(): boolean {
    return !!this.reservationsList.find((_) => _.userId === userAuthStore.userId && _.status === reservationStatus.accepted);
  }

  public getActiveButtons(status: reservationStatus | null = null, userId: string | null = null): IActiveButtons {
    if (status == null || userId == null)
      return {
        acceptQr: false,
        accept: false,
        edit: false,
        cancel: false,
        comment: false,
      };

    const isNotConfirm = status === reservationStatus.pending || status === reservationStatus.extended;
    const isMyBooking = userAuthStore.userId === userId || rolesStore.isAllow();

    const acceptQr = isMyBooking && isNotConfirm;
    const accept = rolesStore.isAllow(Permissions.ReservationConfirmationWithoutQrCode) && isNotConfirm;
    const comment = status === reservationStatus.accepted;

    return {
      acceptQr,
      accept,
      edit: isMyBooking,
      cancel: isMyBooking,
      comment: comment,
    };
  }

  public setTab(value: string) {
    this.queryParams.pageIndex = 0;
    this.selectedTab = value;
  }

  public setSchedulePage(isOpened: boolean) {
    this.isScheduleOpened = isOpened;
  }

  public setReservationId(id: string) {
    this.reservationId = id;
  }

  public async getRowItemList() {
    if (this.selectedTab === ROUTE.ReservationCalendar || this.selectedTab === ROUTE.ReservationSingle) {
      await calendarStore.getCalendarViewData();
      return;
    }

    this.setLoadingOverlay(true);
    try {
      this.rawTable = await ReservationApi.getRowItemList(this.filter.getFilterDto());
    } catch (e) {
      //ignore
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  private setLoadingOverlay(value: boolean) {
    this.isLoadingOverlay = value;
  }

  public async getReservationListForMap(workplaceId: string, fromTime: Date, toTime: Date) {
    if (!workplaceId) return;

    const filter: IReservationFilterDto = {
      fromTime: fromTime,
      toTime: toTime,
      workplaceId: workplaceId,
      isDescendingOrder: false,
      isActiveOnly: true,
    };

    this.isLoadingMapPreview = true;
    try {
      const result = await ReservationApi.getRowItemList(filter);
      this.reservationsList = result.rows.map(({ id, userName, timeFrom, timeTo, userId, status }) => {
        return new ReservationMapPreview({
          id,
          userName,
          userId,
          status,
          timeFrom,
          timeTo,
        });
      });
    } catch (e) {
      //ignore
    } finally {
      this.isLoadingMapPreview = false;
    }
  }

  /**
   * Диалог подтверждение брони
   * @param data
   */
  public async confirmDialog(data: IReservationRowItemDto): Promise<void> {
    const dialogParams: IConfirmDialog = {
      title: 'Подтвердить бронирование?',
      content: data.description,
      buttonName: 'Подтвердить',
      buttonColor: 'success',
    };
    const isConfirm = await confirmDialog.open(dialogParams);
    if (!isConfirm) return;

    await this.confirm(data.id);
  }
  /**
   * Диалог подтверждение брони через QR
   */
  public confirmQrDialog(): void {
    this.isShowQrDialog = true;
  }

  /**
   * Подтверждение брони
   * @param id
   */
  public async confirm(id: string): Promise<void> {
    try {
      this.setLoadingOverlay(true);
      await ReservationApi.confirm(id);

      if (this.selectedTab === ROUTE.ReservationCalendar) calendarStore.hideInfo();
      await this.getRowItemList();
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  /**
   * Подтверждение брони по qr
   * @param workplaceName
   */
  public async confirmQr(workplaceName: string): Promise<void> {
    try {
      this.setLoadingOverlay(true);
      await ReservationApi.confirmQr(workplaceName);

      if (this.selectedTab === ROUTE.ReservationCalendar) calendarStore.hideInfo();
      await this.getRowItemList();
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  /**
   * Сдвиг начала брони
   */
  public async shift() {
    if (!this.reservation || !this.reservation.id) return;

    try {
      this.setLoadingOverlay(true);
      await ReservationApi.shift(this.reservation.id);

      if (this.selectedTab === ROUTE.ReservationCalendar) calendarStore.hideInfo();
      await this.getRowItemList();
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  /**
   * Диалог редактирвоание брони
   * @param id
   */
  public async editDialog(id: string) {
    const reservation = await ReservationStore.loadReservation({ reservationId: id });
    if (reservation) {
      this.reservation = new ReservationDto(reservation);
      this.reservation.reservationText = reservation.reservationText;
      this.reservation.statusText = reservation.statusText;
    }

    this.isShowDialog = true;
  }

  /**
   * Получить бронь
   * @param reservationId
   */
  public async get(reservationId: string): Promise<void> {
    const reservation = await ReservationStore.loadReservation({ reservationId });
    if (reservation) this.reservation = new ReservationDto(reservation);

    this.reservation = reservation;
  }

  /**
   * Редактирование времени брони
   */
  public async update() {
    if (
      !this.reservation ||
      !this.reservation.id ||
      !this.reservation.workplaceId ||
      !this.reservation ||
      !this.reservation.fromTime ||
      !this.reservation.toTime
    )
      return;

    try {
      this.setLoadingOverlay(true);
      await ReservationApi.update(this.reservation.id, this.ReservationUpdateModel());

      if (this.selectedTab === ROUTE.ReservationCalendar) calendarStore.hideInfo();
      await this.getRowItemList();
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  /**
   * Диалог создание брони
   * @param workplaceId
   * @param fromTime
   * @param toTime
   * @description [this.filter.workplaceId] должен быть задан всегда
   */
  public async createDialog(workplaceId: string, fromTime: Date, toTime: Date | null = null) {
    if (!workplaceId) return;

    // 6 вечера, либо конец дня
    if (!toTime) {
      const baseDate = new Date();
      toTime = new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate(), 18, 0, 0, 0);
      if (toTime <= fromTime) toTime = getEndOfDay();
    }

    const reservationDto: IReservationDto = {
      workplaceId,
      fromTime,
      toTime,
    };

    // Смотрим есть ли текущее бронирование на место
    let reservation = await ReservationStore.loadReservation({ workplaceId });
    if (!reservation) reservation = new ReservationDto(reservationDto);
    this.reservation = reservation;

    // Если нет, то получем dto места и делаем свой ReservationDto
    if (!this.reservation.id) {
      const workplace = await WorkPlacesApi.getById(workplaceId);
      reservationDto.workplaceNumber = workplace.number;
      reservationDto.workplaceType = workplace.type;
      reservationDto.attributes = workplace.attributes;
      reservationDto.comment = workplace.comment;
      this.reservation = new ReservationDto(reservationDto);
    }

    this.reservation.fromTime = fromTime;
    this.reservation.toTime = toTime;

    this.reservation.isCreateBooking = true;
    this.isShowDialog = true;
  }

  public async create() {
    try {
      this.setLoadingOverlay(true);
      const result = await ReservationApi.create(this.ReservationUpdateModel());
      if (!result) return;
      this.setReservationId(result);

      if (this.selectedTab === ROUTE.ReservationCalendar) calendarStore.hideInfo();

      await this.getRowItemList();
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  public static async loadReservation({
    workplaceId,
    reservationId,
  }: {
    workplaceId?: string;
    reservationId?: string;
  }): Promise<ReservationDto | null> {
    let reservationRaw: IReservationDto | null = null;
    if (workplaceId) reservationRaw = await ReservationApi.getByWorkplaceId(workplaceId);
    if (reservationId) reservationRaw = await ReservationApi.get(reservationId, userAuthStore.userId);
    if (!reservationRaw) return null;

    const reservation = new ReservationDto(reservationRaw);

    const isMyBooking = userAuthStore.userId === reservation.userId;
    const isCreateBooking = reservation.isCreateBooking;

    if (reservation.fromTime && reservation.toTime) {
      const reservationText = getReservationText(reservation.fromTime, reservation.toTime);

      // В ожидании, продлено
      if (reservation.status === reservationStatus.pending || reservation.status === reservationStatus.extended) {
        reservation.reservationText = reservationText;
        if (reservation.cancelationAtTime)
          reservation.statusText =
            isMyBooking && !isCreateBooking ? `Не подтверждено. Бронь отменится в ${getTimeOnly(reservation.cancelationAtTime)}` : '';
      }

      // Подтверждено
      if (reservation.status === reservationStatus.accepted) {
        reservation.reservationText = reservationText;
        reservation.statusText = isMyBooking && !isCreateBooking ? `Подтверждено` : '';
      }
    }

    return reservation;
  }

  /**
   * Диалог отмены брони
   * @param data
   */
  public async cancelDialog(data: IReservationRowItemDto) {
    const dialogParams: IConfirmDialog = {
      title: 'Отменить бронирование?',
      content: data.description,
      buttonName: 'Отменить',
    };
    const isConfirm = await confirmDialog.open(dialogParams);
    if (!isConfirm) return;

    await this.cancel(data.id);
  }

  /**
   * Отмена брони
   * @param id
   */
  public async cancel(id: string) {
    try {
      this.setLoadingOverlay(true);
      await ReservationApi.cancel(id);

      if (this.selectedTab === ROUTE.ReservationCalendar) calendarStore.hideInfo();
      await this.getRowItemList();
    } finally {
      this.setLoadingOverlay(false);
    }
  }

  private ReservationUpdateModel(): ReservationCreateOrUpdateDto | null {
    if (!this.reservation || !this.reservation.workplaceId || !this.reservation.fromTime || !this.reservation.toTime) return null;

    return new ReservationCreateOrUpdateDto(this.reservation.workplaceId, userAuthStore.userId, this.reservation.fromTime, this.reservation.toTime);
  }
}

export default new ReservationStore();
