import styled from 'styled-components/macro';
import { Button as MuiButton, Accordion, AccordionDetails as MuiAccordionDetails } from '@mui/material';

export const Wrapper = styled.div`
  height: 100%;
`;

export const CellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Button = styled(MuiButton)`
  justify-content: flex-start;
  padding: ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(4)};
  width: 100%;
`;

export const FilterAccordion = styled(Accordion)`
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: none;
  margin-bottom: ${(props) => props.theme.spacing(2)};

  &.Mui-expanded {
    margin: 0;
  }
  &:before {
    box-shadow: none;
    background-color: transparent;
  }

  & .MuiButtonBase-root {
    &.Mui-focusVisible {
      background-color: ${(props) => props.theme.palette.common.white};
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  & .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
  }
`;

export const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  & .MuiTypography-root {
    margin-bottom: ${(props) => props.theme.spacing(2)};
  }

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;
