import { makeAutoObservable } from 'mobx';

import { getDateOnly, getTimeSpan } from 'utils/DateTimeUtils';
import { IReservationRowItemDto } from 'shared/interfaces/api/reservationDto';

export default class CalendarViewData {
  constructor(dto: IReservationRowItemDto, isMy: boolean | null) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.id = dto.id;
    this.title = dto.userName;
    this.start = dto.timeFrom;
    this.end = dto.timeTo;
    this.createdAt = dto.createdAt;
    this.userName = dto.userName;
    this.workPlaceId = dto.workplaceId;
    this.status = dto.status;
    this.desc = dto.description;
    this.userId = dto.userId;
    this.workplaceType = dto.workplaceType;
    this.isMy = isMy;
  }

  public isMy: boolean | null = null;

  public overlaps = 1;

  public overlapsIds: string[] = [];

  public id = '';

  public title = '';

  public status = 0;

  public userId = '';

  public userName = '';

  public workPlaceId = '';

  public isCompleted = false;

  public start: Date | null = null;

  public end: Date | null = null;

  public createdAt: Date | null = null;

  public desc = '';

  public workplaceName = '';

  public widthInPercents = 100;

  public offsetInPercents = 0;

  public workplaceType: number | null = null;

  public get periodFormatted(): string {
    if (!this.start || !this.end) return '';

    return `${getDateOnly(this.start)} ${getTimeSpan(this.start, this.end)}`;
  }

  public getDto(): IReservationRowItemDto {
    return {
      id: this.id,
      userId: this.userId,
      workplaceId: this.workPlaceId,
      timeFrom: this.start || new Date(),
      timeTo: this.end || new Date(),
      createdAt: this.createdAt || new Date(),
      userName: this.userName,
      description: this.desc,
      workplaceName: this.workplaceName,
      status: this.status,
      isCompleted: this.isCompleted,
      workplaceType: this.workplaceType,
    };
  }

  public setWidth(width: number) {
    this.widthInPercents = width - 1;
    this.offsetInPercents = this.workplaceType === 0 ? 50 : 0;
  }
}
