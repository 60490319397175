import { TextField as MuiTextField } from '@mui/material';
import styled from 'styled-components/macro';

export const TextField = styled(MuiTextField)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const ImageLogo = styled.img`
  height: 300px;
  width: 300px;
  margin: 10px;
`;
