import styled from 'styled-components/macro';

export const HeaderWrapper = styled.header`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(2)};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: 0;
    margin-left: 0;
  }
  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    display: flex;
    align-items: center;
  }
`;

export const Header = styled.div<{ isbutton: boolean }>`
  display: flex;
  align-items: center;
  position: relative;

  ${(props) => props.theme.breakpoints.down('sm')} {
    justify-content: ${(props) => (props.isbutton ? 'space-between' : 'center')};
    z-index: 50;
    width: 100%;
    height: 44px;
    color: ${(props) => props.theme.palette.common.white};
    background-color: ${(props) => props.theme.palette.primary.main};

    img {
      position: ${(props) => (props.isbutton ? 'static' : 'absolute')};
      left: 0;
      margin-left: ${(props) => (props.isbutton ? 'unset' : props.theme.spacing(2.5))};
    }
  }

  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    flex: 1;
    justify-content: ${(props) => (props.isbutton ? 'space-between' : 'flex-start')};
    margin-left: ${(props) => props.theme.spacing(12)};
    margin-right: ${(props) => props.theme.spacing(10)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    justify-content: space-between;
  }
`;

export const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.breakpoints.between('sm', 'md')} {
    background-color: ${(props) => props.theme.palette.primary.main};
    border-radius: 50%;
    margin-top: ${(props) => props.theme.spacing(1)};
    margin-right: ${(props) => props.theme.spacing(3)};
    width: 44px;
    height: 44px;

    & img {
      width: 24px;
      height: 24px;
    }
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    margin-left: ${(props) => props.theme.spacing(2.5)};

    & img {
      width: 28px;
      height: 28px;
    }
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    svg {
      margin-left: ${(props) => props.theme.spacing(1.5)};
      margin-top: ${(props) => props.theme.spacing(2)};
      width: 20px;
      height: 20px;
      stroke: ${(p) => p.theme.palette.icons.stroke};

      :hover {
        stroke: ${(p) => p.theme.palette.common.white};
      }
    }
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    svg {
      margin-left: ${(props) => props.theme.spacing(1.5)};
      margin-top: ${(props) => props.theme.spacing(2)};
      width: 24px;
      height: 24px;
      stroke: ${(p) => p.theme.palette.icons.stroke};

      :hover {
        stroke: ${(p) => p.theme.palette.primary.main};
        opacity: 0.5;
      }
    }
  }
`;

export const ButtonWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  pointer-events: ${(p) => (p.disabled ? 'none' : 'unset')};

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-right: ${(props) => props.theme.spacing(2.5)};

    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
      stroke: ${(p) => (p.disabled ? p.theme.palette.icons.stroke : p.theme.palette.secondary.main)};
    }
  }
`;
