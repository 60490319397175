import React, { ReactElement } from 'react';
import { IMenuItem } from 'shared/interfaces/app';
import { MenuItem, MenuProps, Typography } from '@mui/material';

import * as S from './ContextMenu.styles';

interface IContextMenuProps extends MenuProps {
  maxWidth?: string;
  width?: string;
  items?: IMenuItem[];
  menuItems?: ReactElement[];
  children?: JSX.Element;
  onMouseLeave?: () => void;
}

const ContextMenu: React.FC<IContextMenuProps> = (props) => {
  const { maxWidth, width, items, children, menuItems, onMouseLeave, ...rest } = props;
  return (
    <S.Menu
      style={{ minWidth: '300px', width: width || '100%', maxWidth: maxWidth || '500px' }}
      elevation={0}
      MenuListProps={{ onMouseLeave: onMouseLeave }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...rest}>
      {children}

      {items && (
        <>
          {items.map((item) => (
            <MenuItem sx={{ '&:hover': { color: 'primary.main' } }} onClick={item.onClick} disableRipple key={item.id} value={item.label}>
              {item.icon}
              {item.label}
            </MenuItem>
          ))}
        </>
      )}

      {menuItems && (
        <S.MenuActions>
          {menuItems.map((element) => {
            return element;
          })}
        </S.MenuActions>
      )}
    </S.Menu>
  );
};

export default ContextMenu;
