import { makeAutoObservable } from 'mobx';
import { INotificationsQuery } from 'shared/interfaces/app';

class NotificationsQueryModel {
  constructor(dto?: INotificationsQuery) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.pageIndex = dto.pageIndex;
    this.pageSize = dto.pageSize;
    this.userId = dto.userId;
  }

  public pageIndex = 0;
  public pageSize = 8;
  public userId = '';
}

export default NotificationsQueryModel;
