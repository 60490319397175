import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import { mapStore, workPlacesStore, zonesStore } from 'stores';
import { DropDownChip, ModalDialog } from 'components';
import { DialogStatus } from 'shared/enums';
import { IAddZonesDialog } from 'shared/interfaces/app';

const ZonesDialog: React.FC = () => {
  const formRef = useRef<FormikProps<IAddZonesDialog>>(null);
  const [selectedMode, setSelectedMode] = useState(false);

  useEffect(() => {
    zonesStore.getZonesList();
  }, []);

  useEffect(() => {
    if (workPlacesStore.anchorEl) {
      setSelectedMode(Boolean(workPlacesStore.workPlaces.find((_) => _.selected)));
    }
  }, [workPlacesStore.anchorEl]);

  const onCloseDialog = () => {
    mapStore.setDialogZonesViewMode(DialogStatus.Closed);
  };

  const onSubmit = async (values: IAddZonesDialog) => {
    if (selectedMode) {
      const workplacesIds: string[] = [];
      workPlacesStore.selectedWorkPlaces.forEach(({ id }) => workplacesIds.push(id));
      await zonesStore.updateWorkPlaceZones(workplacesIds, values.zoneIds);
      workPlacesStore.getWorkPlacesList();
    } else {
      workPlacesStore.selectedWorkPlace.updateFromAddZoneDialog(values);
      await workPlacesStore.updateWorkPlace();
    }
    workPlacesStore.setAnchorEl(null);
    onCloseDialog();
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  return (
    <ModalDialog
      title="Управление зонами"
      isOpen={mapStore.dialogZonesViewMode !== DialogStatus.Closed}
      onClose={onCloseDialog}
      onCancel={onCloseDialog}
      onSubmit={handleSubmit}
      submitBtnLabel="Сохранить">
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        initialValues={{
          zoneIds: selectedMode ? workPlacesStore.selectedWorkPlaceZones : workPlacesStore.selectedWorkPlace.zoneIds,
          zonesOpt: zonesStore.zonesForOptions,
        }}>
        {({ values, setFieldValue }) => {
          const onDeleteZone = (e: React.ChangeEvent<string>, value: string) => {
            e.preventDefault();

            const idx = values.zoneIds.indexOf(value);
            if (idx !== -1) {
              values.zoneIds.splice(idx, 1);
            }
            setFieldValue('zoneIds', values.zoneIds);
          };

          const onChangeZones = (e: React.ChangeEvent<any>) => {
            setFieldValue('zoneIds', e.target.value);
          };
          return (
            <>
              <DropDownChip
                name="zoneIds"
                options={values.zonesOpt}
                values={values.zoneIds}
                placeholder="Выберите зоны"
                dropDownLabel="Зоны"
                onDelete={onDeleteZone}
                onChange={onChangeZones}
              />
            </>
          );
        }}
      </Formik>
    </ModalDialog>
  );
};

export default observer(ZonesDialog);
