import React, { useState } from 'react';
import { IThemeExtendedColors } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const NavigationIcon: React.FC = () => {
  const theme = useTheme();

  const mainColor = (theme.palette as IThemeExtendedColors).icons?.stroke;
  const [strokeColor, setStrokeColor] = useState(mainColor);

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={theme.palette.primary.main} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6299 8.59486V7.08186C20.6299 5.02086 18.9589 3.34986 16.8969 3.34986H15.6919"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.37012 8.59486V7.08186C3.37012 5.02086 5.04112 3.34986 7.10312 3.34986H8.33912"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6299 12.8046V16.8786C20.6299 18.9406 18.9589 20.6116 16.8969 20.6116H15.6919"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.37012 12.8046V16.8786C3.37012 18.9406 5.04112 20.6116 7.10312 20.6116H8.33912"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="2" fill={theme.palette.primary.main} />
    </svg>
  );
};

export default NavigationIcon;
