import { makeAutoObservable } from 'mobx';
import { ZERO_POSITION } from 'shared/constants';
import { WorkPlaceType } from 'shared/enums';
import { IAttributeDto, IWorkPlacesAvailableBookingGetDto } from 'shared/interfaces/api';
import { IPosition } from 'shared/interfaces/app';

class WorkPlaceAvailableBookingModel {
  public constructor(dto?: IWorkPlacesAvailableBookingGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.type = dto.type;
    this.isActive = dto.isActive;
    this.position = dto.position;
    this.number = dto.number;
    this.attributes = dto.attributes;
    this.availableBooking = dto.availableBooking;
  }

  public id: string | undefined = undefined;

  public type = WorkPlaceType.WorkPlace;

  public isActive = false;

  public isSelected = false;

  public number = '';

  public attributes: IAttributeDto[] = [];

  public position: IPosition = ZERO_POSITION;

  public availableBooking = false;
}

export default WorkPlaceAvailableBookingModel;
