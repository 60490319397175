import React, { useEffect } from 'react';
import { CRS } from 'leaflet';
import { observer } from 'mobx-react';
import { ChevronDown } from 'react-feather';
import { mapStore, workPlacesStore } from 'stores';

import { MapContext, ModalDialog, Spinner, TagImages } from 'components';

import { DialogStatus } from 'shared/enums';
import { FloorImageModel, FloorModel } from 'shared/models/MapModel';
import 'leaflet/dist/leaflet.css';
import { useBreakpoint } from 'utils/hooks';
import { AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import {
  WorkPlaces,
  FloorDialog,
  FloorImageDialog,
  WorkPlaceDialog,
  DrawControl,
  ContextMenuActions,
  ZonesDialog,
  UnplacedWorkPlaces,
  FloorsPanel,
} from './Components';

import MapSettings from './Components/MapSettings/MapSettings';
import * as S from './Map.styles';

const Map: React.FC = () => {
  useEffect(() => {
    initMap();

    return () => {
      mapStore.selectedFloorImage.clear();
    };
  }, []);

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  useEffect(() => {
    if (!mapStore.isDraw) {
      workPlacesStore.clear();
      workPlacesStore.getWorkPlacesList();
    }
  }, [mapStore.isDraw]);

  useEffect(() => {
    if (mapStore.selectedFloorImage.id) {
      workPlacesStore.getWorkPlacesList();
    }

    return () => {
      mapStore.selectedFloorImage.clear();
      workPlacesStore.clear();
    };
  }, [mapStore.selectedFloorImage.id]);

  const initMap = async () => {
    await mapStore.getFloorsList();
    mapStore.selectedFloor = new FloorModel(mapStore.floors[0]);
    await mapStore.getFloorImagesList();
    mapStore.selectedFloorImage = new FloorImageModel(mapStore.floorImages[0]);
    await mapStore.getFloorImageById();
  };

  const handleDeleteFloor = async () => {
    await mapStore.removeFloor();
    mapStore.dialogFloorViewMode = DialogStatus.Closed;
  };

  const handleDeleteFloorImage = async () => {
    await mapStore.removeFloorImage();
    mapStore.selectedFloorImage = new FloorImageModel();
    mapStore.dialogFloorImageViewMode = DialogStatus.Closed;
  };

  const handleClose = () => {
    mapStore.dialogFloorViewMode = DialogStatus.Closed;
  };

  const handleCloseFloorImage = () => {
    mapStore.dialogFloorImageViewMode = DialogStatus.Closed;
  };

  const onUnSelectObjects = () => {
    if (!mapStore.isSelected) {
      workPlacesStore.setUnSelectedWorkPlaces();
    }
  };

  return (
    <>
      <div>
        <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
        {isMobile ? (
          <S.FilterAccordion>
            <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="body2">Настройки помещений</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MapSettings />
            </AccordionDetails>
          </S.FilterAccordion>
        ) : (
          <MapSettings />
        )}
        <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />
      </div>

      <S.MapContentWrapper>
        {!isMobile ? <FloorsPanel /> : null}

        <UnplacedWorkPlaces />

        <ContextMenuActions />

        {mapStore.isLoadMap && (
          <S.Spiner>
            <Spinner />
          </S.Spiner>
        )}

        {!!mapStore.selectedFloorImage.imageBase64}

        {mapStore.selectedFloorImage.imageName && (
          <S.MapContainer
            style={{ height: '100%' }}
            crs={CRS.Simple}
            maxBoundsViscosity={0.9}
            center={mapStore.mapState.startedCoordinate}
            zoomControl={false}
            scrollWheelZoom={false}
            fadeAnimation={false}
            zoomSnap={0.1}
            maxZoom={0}
            minZoom={-2}>
            {!!mapStore.selectedFloorImage.imageBase64 && mapStore.selectedFloorImage.id && (
              <MapContext floorImageModel={mapStore.selectedFloorImage} onMapClick={onUnSelectObjects} />
            )}
            <DrawControl />
            <WorkPlaces />

            {/*<S.ShowLabel>*/}
            {/*  <Checkbox label={'Названия мест'} checked={mapStore.isShowObjects} onChange={onShowObjects} />*/}
            {/*</S.ShowLabel>*/}
          </S.MapContainer>
        )}
      </S.MapContentWrapper>

      <FloorDialog />
      <FloorImageDialog />
      {mapStore.dialogZonesViewMode !== DialogStatus.Closed && <ZonesDialog />}
      {workPlacesStore.dialogViewMode !== DialogStatus.Closed && <WorkPlaceDialog />}

      <ModalDialog
        isOpen={mapStore.dialogFloorViewMode !== DialogStatus.Closed && mapStore.dialogFloorViewMode === DialogStatus.Deleting}
        title="Вы уверены, что хотите удалить этаж?"
        submitBtnLabel="Удалить"
        submitBtnColor="error"
        onCancel={handleClose}
        onSubmit={handleDeleteFloor}
      />
      <ModalDialog
        isOpen={mapStore.dialogFloorImageViewMode !== DialogStatus.Closed && mapStore.dialogFloorImageViewMode === DialogStatus.Deleting}
        title="Вы уверены, что хотите удалить карту этажа?"
        submitBtnLabel="Удалить"
        submitBtnColor="error"
        onCancel={handleCloseFloorImage}
        onSubmit={handleDeleteFloorImage}
      />
    </>
  );
};

export default observer(Map);
