import { makeAutoObservable } from 'mobx';
import { INotificationsPopup } from 'shared/interfaces/app';

class NotificationsPopupModel {
  constructor(dto?: INotificationsPopup) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.popupIsOpen = dto.popupIsOpen;
  }

  public popupIsOpen = false;

  public setPopupMode(value: boolean) {
    this.popupIsOpen = value;
  }
}

export default NotificationsPopupModel;
