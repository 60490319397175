import React from 'react';

import { BoxProps, Typography } from '@mui/material';
import * as S from './Badge.styles';

interface IBadgeProps extends BoxProps {
  color?: string;
  bgColor?: string;
  count: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const Badge: React.FC<IBadgeProps> = (props) => {
  const { count, bgColor, color, onMouseEnter, onMouseLeave, ...rest } = props;

  return (
    <S.Badge
      sx={{ bgcolor: bgColor ?? 'secondary.main', color: color ?? 'common.white' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}>
      <Typography variant="caption">{count}</Typography>
    </S.Badge>
  );
};

export default Badge;
