import React from 'react';

const MainIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        strokeWidth="1.5"
        d="M13.3962 1.66666H16.2178C17.3864 1.66666 18.3333 2.62154 18.3333 3.79997V6.64543C18.3333 7.82386 17.3864 8.77874 16.2178 8.77874H13.3962C12.2276 8.77874 11.2808 7.82386 11.2808 6.64543V3.79997C11.2808 2.62154 12.2276 1.66666 13.3962 1.66666Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth="1.5"
        d="M6.60358 1.66666H3.78194C2.61338 1.66666 1.6665 2.62154 1.6665 3.79997V6.64543C1.6665 7.82386 2.61338 8.77874 3.78194 8.77874H6.60358C7.77214 8.77874 8.71902 7.82386 8.71902 6.64543V3.79997C8.71902 2.62154 7.77214 1.66666 6.60358 1.66666ZM16.2177 11.2213H13.3961C12.2275 11.2213 11.2807 12.1761 11.2807 13.3546V16.2C11.2807 17.3777 12.2275 18.3333 13.3961 18.3333H16.2177C17.3863 18.3333 18.3332 17.3777 18.3332 16.2V13.3546C18.3332 12.1761 17.3863 11.2213 16.2177 11.2213Z"
      />
      <path
        strokeWidth="1.5"
        d="M3.78194 11.2213H6.60358C7.77214 11.2213 8.71902 12.1762 8.71902 13.3546V16.2001C8.71902 17.3777 7.77214 18.3334 6.60358 18.3334H3.78194C2.61338 18.3334 1.6665 17.3777 1.6665 16.2001V13.3546C1.6665 12.1762 2.61338 11.2213 3.78194 11.2213Z"
      />
    </svg>
  );
};

export default MainIcon;
