import { Calendar } from 'react-big-calendar';
import styled from 'styled-components/macro';

export const CalendarWrapper = styled(Calendar)`
  width: 100%;
  height: -moz-calc(100vh - 50px);
  height: -webkit-calc(100vh - 50px);
  height: calc(100vh - 50px);

  & .rbc-timeslot-group {
    min-height: 80px;
  }
`;
