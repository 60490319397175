import React, { forwardRef } from 'react';

import { observer } from 'mobx-react';
import { LinkProps, NavLink as RouterNavLink } from 'react-router-dom';

import { appStore, messagesStore, notificationsStore } from 'stores';
import { Badge } from 'components';
import { MESSAGES_TYPES } from 'shared/constants';
import { Typography } from '@mui/material';
import * as S from './SidebarCategory.styles';

const NavLink = forwardRef<LinkProps, any>((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

interface ISidebarCategoryProps {
  id?: string;
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  badge: number | null;
  activeClassName?: string;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
}

const SidebarCategory: React.FC<ISidebarCategoryProps> = (props) => {
  const { name, icon, classes, isOpen, badge, id, ...rest } = props;

  const handleBadgeMouseEnter = () => {
    appStore.setCursorOnCategory(id ?? '');
    if (appStore.popupTimeoutID) window.clearTimeout(appStore.popupTimeoutID);

    switch (id) {
      case MESSAGES_TYPES.MESSAGES:
        notificationsStore.popup.setPopupMode(false);
        return messagesStore.popup.setPopupMode(true);

      case MESSAGES_TYPES.NOTIFICATIONS:
        messagesStore.popup.setPopupMode(false);
        return notificationsStore.popup.setPopupMode(true);
    }
  };

  const handleBadgeMouseLeave = () => {
    appStore.setPopupTimeoutID(
      window.setTimeout(() => {
        appStore.setCursorOnCategory('');

        switch (id) {
          case MESSAGES_TYPES.MESSAGES:
            messagesStore.popup.setPopupMode(false);
            break;
          case MESSAGES_TYPES.NOTIFICATIONS:
            notificationsStore.popup.setPopupMode(false);
            break;
        }
      }, 1000)
    );
  };

  return (
    <div>
      <S.Category {...rest}>
        {icon}

        <S.CategoryText>
          <Typography variant="body2" sx={{ color: 'common.white' }}>
            {name}
          </Typography>
        </S.CategoryText>
        {badge ? <Badge count={badge} onMouseEnter={handleBadgeMouseEnter} onMouseLeave={handleBadgeMouseLeave} /> : null}
      </S.Category>
    </div>
  );
};

export default observer(SidebarCategory);
