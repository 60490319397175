import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { workPlacesStore } from 'stores';
import { ModalDialog } from 'components';
import { WorkPlaceImageModel } from 'shared/models';

import * as S from './Gallery.styles';

interface IGalleryProps {
  images: WorkPlaceImageModel[];
  title: string;
  open: boolean;
  onClose: () => void;
}

const Gallery: React.FC<IGalleryProps> = (props) => {
  const { images, title, open, onClose } = props;

  const SliderArrow = (props: { onClick: () => void; children: JSX.Element; isNext: boolean; top?: string }) => (
    <S.IconWrapper onClick={props.onClick} isNext={props.isNext}>
      {props.children}
    </S.IconWrapper>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
      <SliderArrow
        isNext={true}
        onClick={() => {
          return;
        }}>
        <img src="/images/NextIcon.svg" alt="" />
      </SliderArrow>
    ),
    prevArrow: (
      <SliderArrow
        isNext={false}
        onClick={() => {
          return;
        }}>
        <img src="/images/PrevIcon.svg" alt="" />
      </SliderArrow>
    ),
  };

  const onImageClick = (index: number) => {
    workPlacesStore.selectedWorkPlace.setSelectedImage(index);
  };

  return (
    <ModalDialog closeIcon title={title} isOpen={open} onClose={onClose}>
      <S.Gallery>
        {/* <Slider {...settings}>
          {images.map((item) => (
            <S.GalleryPhoto key={item.id}>
              <img src={item.imageURL} alt="" />
            </S.GalleryPhoto>
          ))}
        </Slider> */}

        <Carousel showThumbs={false} showStatus={false} selectedItem={workPlacesStore.selectedWorkPlace.selectedImage} onClickItem={onImageClick}>
          {images.map((item) => (
            <S.GalleryPhoto key={item.id}>
              <img src={item.imageURL} alt="" />
            </S.GalleryPhoto>
          ))}
        </Carousel>
      </S.Gallery>
    </ModalDialog>
  );
};

export default Gallery;
