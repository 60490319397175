import React, { useEffect, useRef, useState } from 'react';

import { Formik, FormikProps } from 'formik';

import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { userAuthStore } from 'stores';
import { PasswordField } from 'components';
import { IAuthenticationForm } from 'shared/interfaces/app';
import { Button, Typography } from '@mui/material';
import { Checkbox } from 'components';

import * as S from './ConfirmUser.styles';

const MIN_PASSWORD_LENGTH = 6;

const ConfirmUser: React.FC = () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const formRef = useRef<FormikProps<IAuthenticationForm>>(null);

  useEffect(() => {
    if (!userAuthStore.email) {
      history.push('/auth/sign-in');
    } else if (userAuthStore.isAuthUser) {
      history.push('/');
    }
    return () => {
      userAuthStore.isUnConfirmedUser = false;
    };
  }, []);

  const onSubmit = async (values: IAuthenticationForm) => {
    userAuthStore.updateFromAuthenticationForm(values);
    await userAuthStore.initUser();
    userAuthStore.password = '';
    userAuthStore.newPassword = '';
    history.push('/auth/sign-in');
  };

  return (
    <>
      <Typography variant="h2" align="center">
        Придумайте пароль
      </Typography>
      <Typography sx={{ mt: 2, mb: 2, color: 'text.secondary' }} variant="body1" align="center">
        Для подтверждения аккаунта необходимо изменить пароль
      </Typography>

      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          newPassword: userAuthStore.newPassword,
          newPasswordConfirmation: userAuthStore.newPasswordConfirmation,
          isUserAgreementChecked: userAuthStore.isUserAgreementChecked,
        }}
        validationSchema={Yup.object().shape({
          newPassword: Yup.string().min(MIN_PASSWORD_LENGTH, 'Длина пароля должна быть не менее 6 символов').required('Обязательное поле'),
          newPasswordConfirmation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать')
            .required('Обязательное поле'),
          isUserAgreementChecked: Yup.boolean().oneOf([true], 'Вы должны быть согласны'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
          setValidated(isValid);
          return (
            <S.Content>
              <PasswordField
                name="newPassword"
                label="Новый пароль"
                password={values.newPassword}
                helperText={touched.newPassword && errors.newPassword}
                error={Boolean(touched.newPassword && errors.newPassword)}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />

              <PasswordField
                name="newPasswordConfirmation"
                label="Подтвердите пароль"
                password={values.newPasswordConfirmation}
                helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                error={Boolean(touched.newPasswordConfirmation && errors.newPasswordConfirmation)}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />

              {!userAuthStore.isUserAgreementChecked && (
                <Checkbox
                  label="Я согласен на обработку персональных данных"
                  checked={values.isUserAgreementChecked}
                  onChange={() => setFieldValue('isUserAgreementChecked', !values.isUserAgreementChecked)}
                />
              )}
            </S.Content>
          );
        }}
      </Formik>

      <Button
        sx={{ mt: 8 }}
        fullWidth
        variant="contained"
        color="secondary"
        size="large"
        disabled={!validated}
        onClick={() => {
          if (formRef.current) formRef.current.handleSubmit();
        }}>
        Далее
      </Button>

      {/*  <span>
        Пароль изменён, перейдите на страницу авторизации и войдите в систему чтобы продолжить
        <Button component={Link} to="/auth/sign-in" fullWidth color="primary">
          Войти
        </Button>
      </span> */}
    </>
  );
};

export default observer(ConfirmUser);
