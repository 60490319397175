import { makeAutoObservable } from 'mobx';
import { FloorType } from 'shared/enums';
import { IFloorGetDto, IFloorPostPutDto } from 'shared/interfaces/api';
import { IFloorDialog } from 'shared/interfaces/app';

class FloorModel {
  public constructor(dto?: IFloorGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.tourModelName = dto.tourModelName;
    this.name = dto.name;
    this.type = dto.type;
    this.mapCount = dto.mapCount;
  }

  public id = '';

  public name = '';

  public tourModelName? = '';

  public mapCount = 0;

  public type: FloorType = FloorType.Floor;

  public get postPutDto(): IFloorPostPutDto | null {
    return {
      name: this.name,
      type: this.type,
    };
  }

  public setFloorImageId(id: string) {
    this.id = id;
  }

  public updateFromDialogForm(model: IFloorDialog) {
    this.name = model.name;
  }

  public updateFromToolbarFilter(type: FloorType) {
    this.type = type;
  }
}

export default FloorModel;
