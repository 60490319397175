import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { rolesStore } from 'stores';
import { ModalDialog, PopupMenu, Table } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import Trash from 'components/Icons/Trash';
import { DialogStatus, Permissions } from 'shared/enums';
import { Column, IRolesTableRow } from 'shared/interfaces/app';
import { Typography, MenuItem } from '@mui/material';
import { RoleDialog } from './Components';

import * as S from './Roles.styles';

const columns: Column[] = [
  {
    id: 'roleName',
    label: 'Название роли',
    minWidth: 240,
  },
];

const Roles: React.FC = () => {
  useEffect(() => {
    rolesStore.getRolesList();
  }, []);

  const isEditDisabled = !rolesStore.isAllow(Permissions.RolesUpdating);
  const isDeleteDisabled = !rolesStore.isAllow(Permissions.RolesRemoving);

  const createRowData = () => {
    const rows = rolesStore.rolesForTable.map((data: IRolesTableRow) => {
      const menuItems = [
        <MenuItem
          sx={{ '&:hover': { color: 'primary.main' } }}
          disableRipple
          disabled={isEditDisabled}
          key={data.id}
          onClick={() => {
            rolesStore.setEditingMode(data.id);
          }}>
          <EditPencilIcon />
          <Typography variant="caption" sx={{ ml: 3 }}>
            Редактировать
          </Typography>
        </MenuItem>,
        <MenuItem
          sx={{ '&:hover': { color: 'primary.main' } }}
          disableRipple
          disabled={isDeleteDisabled}
          key={data.id}
          onClick={async () => {
            await rolesStore.getRoleById(data.id);
            rolesStore.setDialogViewMode(DialogStatus.Deleting);
          }}>
          <Trash />
          <Typography variant="caption" sx={{ ml: 3 }}>
            Удалить
          </Typography>
        </MenuItem>,
      ];

      const row: Array<JSX.Element> = Object.values(data)
        .map((value, index) => {
          if (index === Object.values(data).length - 1) {
            return (
              <S.CellWrapper key={value.id}>
                <Typography>{value}</Typography>
                <PopupMenu key={data.id} menuItems={menuItems} />
              </S.CellWrapper>
            );
          }

          return <Typography key={value.id}>{value}</Typography>;
        })
        .slice(1);

      return row;
    });

    return rows;
  };

  const handleCloseConfirmDialog = () => {
    rolesStore.setDialogViewMode(DialogStatus.Closed);
  };

  const handleDeleteRole = async () => {
    await rolesStore.removeRole();
    rolesStore.setDialogViewMode(DialogStatus.Closed);
  };

  return (
    <S.RolesWrapper>
      <Table columns={columns} rows={createRowData()} />
      {rolesStore.dialogViewMode !== DialogStatus.Closed && rolesStore.dialogViewMode !== DialogStatus.Deleting && <RoleDialog />}
      <ModalDialog
        isOpen={rolesStore.dialogViewMode !== DialogStatus.Closed && rolesStore.dialogViewMode === DialogStatus.Deleting}
        title="Вы уверены, что хотите удалить роль?"
        submitBtnLabel="Удалить"
        submitBtnColor="error"
        onCancel={handleCloseConfirmDialog}
        onSubmit={handleDeleteRole}
      />
    </S.RolesWrapper>
  );
};

export default observer(Roles);
