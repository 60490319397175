import styled from 'styled-components/macro';

export const Gallery = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .slick-slider {
    margin: auto;
    width: 100%;
    height: 100%;
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    bottom: unset;
    .slick-active {
      button:before {
        color: ${(p) => p.theme.palette.secondary.main};
      }
    }
    li {
      margin: unset;
      width: 15px;
      height: 15px;
      button:before {
        display: flex;
        justify-content: center;
        opacity: 1;
        color: #b3c4ce;
        width: 15px;
        height: 15px;
      }
      button {
        padding: 0;
        width: 15px;
        height: 15px;
      }
    }
  }

  .slick-list {
    border-radius: 24px !important;
    height: 100%;

    .slick-track {
      height: 100%;
    }
  }
`;

export const GalleryPhoto = styled.div`
  border-radius: 24px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 400px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const IconWrapper = styled.div<{ isNext: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  left: ${(props) => (props.isNext ? 'unset' : '10px')};
  right: ${(props) => (props.isNext ? '10px' : 'unset')};
  top: 180px;
  cursor: pointer;
  z-index: 100;

  svg {
    width: 100%;
    height: 100%;
  }
`;
