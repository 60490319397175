import { IZoneGetDto, IZoneListGetDto, IZonePostPutDto, IZoneUpdateWorkplacesDto } from 'shared/interfaces/api';

import HTTPService, { IHttpRequest } from './HTTPService';

class ZonesApi extends HTTPService {
  public constructor() {
    super('zones');
  }

  public getZones(): Promise<IZoneListGetDto[]> {
    return this.GET('');
  }

  public getZone(id: string): Promise<IZoneGetDto> {
    return this.GET(`/${id}`);
  }

  public postZone(newZone: IZonePostPutDto): Promise<string> {
    return this.POST('', newZone);
  }

  public putZone(id: string, updateZone: IZonePostPutDto): Promise<IZoneGetDto> {
    const request: IHttpRequest<IZonePostPutDto> = {
      path: `${id}/`,
      data: updateZone,
    };
    return this.PUT<IZonePostPutDto, IZoneGetDto>(request);
  }

  public postUpdateWorkPlaces(postDto: IZoneUpdateWorkplacesDto): Promise<void> {
    return this.POST(`update-workplaces`, postDto);
  }

  public deleteZone(id: string): Promise<void> {
    return this.DELETE(`${id}/`);
  }
}

export default new ZonesApi();
