import { makeAutoObservable } from 'mobx';
import { IReservationMapPreview } from 'shared/interfaces/app';
import { reservationStatus } from 'utils/ReservationStatus';

class ReservationMapPreview {
  constructor(dto?: IReservationMapPreview) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.userName = dto.userName;
    this.userId = dto.userId;
    this.status = dto.status;
    this.timeFrom = dto.timeFrom;
    this.timeTo = dto.timeTo;
  }

  public id = '';

  public userName = '';

  public userId = '';

  public status: reservationStatus = reservationStatus.none;

  public timeFrom: Date = new Date();

  public timeTo: Date = new Date();
}

export default ReservationMapPreview;
