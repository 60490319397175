import React, { ReactNode } from 'react';

import * as S from './MainContent.styles';
interface IMainContentProps {
  children: JSX.Element | ReactNode;
}

const MainContent: React.FC<IMainContentProps> = ({ children }) => {
  return <S.MainContentWrapper>{children}</S.MainContentWrapper>;
};

export default MainContent;
