import React from 'react';
import { observer } from 'mobx-react';

import { ChevronLeft, ChevronRight } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { calendarStore, reservationStore } from 'stores';
import { IconButton, Tag } from 'components';
import { CalendarAction, CalendarViewMode } from 'shared/enums';
import { ITags } from 'shared/interfaces/app';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useBreakpoint } from 'utils/hooks';
import { Button, Typography } from '@mui/material';
import { getNearHour, getShortDateOnly } from '../../../../../utils/DateTimeUtils';
import * as S from './customToolBar.styles';

const viewModes: ITags[] = [
  {
    id: CalendarViewMode.Day,
    name: 'День',
  },
  {
    id: CalendarViewMode.Week,
    name: 'Неделя',
  },
];

const CustomToolBar = (props: any) => {
  const { date, onNavigate } = props;
  let { caption } = props;
  const bp = useBreakpoint();
  const location = useLocation();
  const isMobile = bp === 'sm';

  const onPrevClick = () => {
    onNavigate(CalendarAction.Prev);
    calendarStore.updateCurrentDate(date, CalendarAction.Prev);
  };

  const onTodayClick = () => {
    onNavigate(CalendarAction.TODAY);
    calendarStore.updateCurrentDate(date, CalendarAction.TODAY);
  };

  const onNextClick = () => {
    onNavigate(CalendarAction.Next);
    calendarStore.updateCurrentDate(date, CalendarAction.Next);
  };

  if (!caption) caption = getShortDateOnly(date);

  return (
    <S.DateNavigator>
      <S.CalendarViewWrapper>
        <Tag
          size="small"
          orientation="horizontal"
          tags={viewModes}
          onCustomChange={calendarStore.selectViewPeriodMode}
          value={calendarStore.viewPeriodMode}
        />
      </S.CalendarViewWrapper>

      <S.TitleWrapper>
        {isMobile ? <Typography variant="body2">{caption}</Typography> : <Typography variant="h6">{caption}</Typography>}

        <S.ActionWrapper>
          {location.pathname === '/reservation/single' && (
            <Button
              sx={{ mr: 4 }}
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => {
                if (reservationStore.filter.workplaceId) reservationStore.createDialog(reservationStore.filter.workplaceId!, getNearHour());
                if (calendarStore.calendarFilter.workplaceId) reservationStore.createDialog(calendarStore.calendarFilter.workplaceId!, getNearHour());
              }}>
              Создать
            </Button>
          )}

          <S.DateScroller>
            <S.ScrollBtnWrapper>
              <IconButton icon={<ChevronLeft />} onClick={onPrevClick} />
            </S.ScrollBtnWrapper>
            <S.TodayBtnWrapper onClick={onTodayClick}>Сегодня</S.TodayBtnWrapper>
            <S.ScrollBtnWrapper>
              <IconButton icon={<ChevronRight />} onClick={onNextClick} />
            </S.ScrollBtnWrapper>
          </S.DateScroller>
        </S.ActionWrapper>
      </S.TitleWrapper>
    </S.DateNavigator>
  );
};

export default observer(CustomToolBar);
