import React from 'react';
import { IIconProps } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const ReservationIcon: React.FC<IIconProps> = ({ color }) => {
  const theme = useTheme();
  const iconColor = color ? color : theme.palette.common.white;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={iconColor} xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 14.0583V7.71416H17.5V14.1092C17.5 16.725 15.8535 18.3333 13.219 18.3333H6.77278C4.16301 18.3333 2.5 16.6917 2.5 14.0583ZM6.63282 12.0083C6.25412 12.0258 5.94127 11.725 5.92481 11.3425C5.92481 10.9592 6.22119 10.6425 6.59989 10.625C6.97036 10.625 7.27497 10.9175 7.28321 11.2917C7.29967 11.6758 7.00329 11.9925 6.63282 12.0083ZM10.0165 12.0083C9.63776 12.0258 9.32492 11.725 9.30845 11.3425C9.30845 10.9592 9.60483 10.6425 9.98354 10.625C10.354 10.625 10.6586 10.9175 10.6669 11.2917C10.6833 11.6758 10.3869 11.9925 10.0165 12.0083ZM13.3754 15.075C12.9967 15.0667 12.6921 14.75 12.6921 14.3667C12.6839 13.9833 12.9885 13.6675 13.3672 13.6592H13.3754C13.7623 13.6592 14.0752 13.9758 14.0752 14.3667C14.0752 14.7583 13.7623 15.075 13.3754 15.075ZM9.30845 14.3667C9.32492 14.75 9.63776 15.0508 10.0165 15.0333C10.3869 15.0175 10.6833 14.7008 10.6669 14.3175C10.6586 13.9425 10.354 13.65 9.98354 13.65C9.60483 13.6675 9.30845 13.9833 9.30845 14.3667ZM5.91658 14.3667C5.93304 14.75 6.24588 15.0508 6.62459 15.0333C6.99506 15.0175 7.29144 14.7008 7.27497 14.3175C7.26674 13.9425 6.96213 13.65 6.59166 13.65C6.21295 13.6675 5.91658 13.9833 5.91658 14.3667ZM12.7003 11.3342C12.7003 10.9508 12.9967 10.6425 13.3754 10.6342C13.7459 10.6342 14.0423 10.9333 14.0587 11.3008C14.067 11.6842 13.7706 12.0008 13.4001 12.0083C13.0214 12.0167 12.7086 11.725 12.7003 11.3425V11.3342Z"
      />
      <path d="M2.50293 7.71408C2.51363 7.22492 2.5548 6.25408 2.63218 5.94158C3.02735 4.18408 4.36929 3.06742 6.28751 2.90825H13.7134C15.6152 3.07575 16.9736 4.19992 17.3687 5.94158C17.4453 6.24575 17.4865 7.22408 17.4972 7.71408H2.50293Z" />
      <mask id="path-3-outside-1_123_13395" maskUnits="userSpaceOnUse" x="5.28662" y="0.666664" width="4" height="6" fill="black">
        <rect fill="white" x="5.28662" y="0.666664" width="4" height="6" />
        <path d="M6.92054 5.49166C7.28278 5.49166 7.55446 5.2175 7.55446 4.85V2.30916C7.55446 1.94166 7.28278 1.66666 6.92054 1.66666C6.5583 1.66666 6.28662 1.94166 6.28662 2.30916V4.85C6.28662 5.2175 6.5583 5.49166 6.92054 5.49166Z" />
      </mask>
      <path
        d="M6.92054 5.49166C7.28278 5.49166 7.55446 5.2175 7.55446 4.85V2.30916C7.55446 1.94166 7.28278 1.66666 6.92054 1.66666C6.5583 1.66666 6.28662 1.94166 6.28662 2.30916V4.85C6.28662 5.2175 6.5583 5.49166 6.92054 5.49166Z"
        fill="white"
      />
      <path
        d="M6.92054 6.49166C7.84563 6.49166 8.55446 5.75916 8.55446 4.85H6.55446C6.55446 4.78126 6.58291 4.68578 6.66227 4.60552C6.74207 4.52482 6.84191 4.49166 6.92054 4.49166V6.49166ZM8.55446 4.85V2.30916H6.55446V4.85H8.55446ZM8.55446 2.30916C8.55446 1.40173 7.84733 0.666664 6.92054 0.666664V2.66666C6.84098 2.66666 6.74105 2.63304 6.66163 2.55258C6.58278 2.47269 6.55446 2.37774 6.55446 2.30916H8.55446ZM6.92054 0.666664C5.99375 0.666664 5.28662 1.40173 5.28662 2.30916H7.28662C7.28662 2.37774 7.2583 2.47269 7.17945 2.55258C7.10003 2.63304 7.0001 2.66666 6.92054 2.66666V0.666664ZM5.28662 2.30916V4.85H7.28662V2.30916H5.28662ZM5.28662 4.85C5.28662 5.75916 5.99545 6.49166 6.92054 6.49166V4.49166C6.99917 4.49166 7.09901 4.52482 7.17881 4.60552C7.25817 4.68578 7.28662 4.78126 7.28662 4.85H5.28662Z"
        mask="url(#path-3-outside-1_123_13395)"
      />
      <mask id="path-5-outside-2_123_13395" maskUnits="userSpaceOnUse" x="11.4453" y="0.666664" width="4" height="6" fill="black">
        <rect fill="white" x="11.4453" y="0.666664" width="4" height="6" />
        <path d="M13.0792 5.49166C13.4332 5.49166 13.7132 5.2175 13.7132 4.85V2.30916C13.7132 1.94166 13.4332 1.66666 13.0792 1.66666C12.717 1.66666 12.4453 1.94166 12.4453 2.30916V4.85C12.4453 5.2175 12.717 5.49166 13.0792 5.49166Z" />
      </mask>
      <path d="M13.0792 5.49166C13.4332 5.49166 13.7132 5.2175 13.7132 4.85V2.30916C13.7132 1.94166 13.4332 1.66666 13.0792 1.66666C12.717 1.66666 12.4453 1.94166 12.4453 2.30916V4.85C12.4453 5.2175 12.717 5.49166 13.0792 5.49166Z" />
      <path
        d="M13.0792 6.49166C13.9876 6.49166 14.7132 5.76772 14.7132 4.85H12.7132C12.7132 4.77259 12.7456 4.67789 12.8211 4.60231C12.8955 4.52771 12.9922 4.49166 13.0792 4.49166V6.49166ZM14.7132 4.85V2.30916H12.7132V4.85H14.7132ZM14.7132 2.30916C14.7132 1.39316 13.9893 0.666664 13.0792 0.666664V2.66666C12.9912 2.66666 12.8945 2.63015 12.8204 2.55579C12.7455 2.48057 12.7132 2.3864 12.7132 2.30916H14.7132ZM13.0792 0.666664C12.1524 0.666664 11.4453 1.40173 11.4453 2.30916H13.4453C13.4453 2.37774 13.417 2.47269 13.3381 2.55258C13.2587 2.63304 13.1588 2.66666 13.0792 2.66666V0.666664ZM11.4453 2.30916V4.85H13.4453V2.30916H11.4453ZM11.4453 4.85C11.4453 5.75916 12.1541 6.49166 13.0792 6.49166V4.49166C13.1579 4.49166 13.2577 4.52482 13.3375 4.60552C13.4169 4.68578 13.4453 4.78126 13.4453 4.85H11.4453Z"
        mask="url(#path-5-outside-2_123_13395)"
      />
    </svg>
  );
};

export default ReservationIcon;
