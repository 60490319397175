import styled from 'styled-components/macro';
import { Pagination as MuiPagination, PaginationItem as MuiPaginationItem } from '@mui/material';

export const Pagination = styled(MuiPagination)`
  .Mui-selected {
    color: ${(props) => props.theme.palette.common.white};
  }

  .MuiButtonBase-root {
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
      background-color: ${(props) => props.theme.palette.background.paper};
    }
  }
`;

export const PaginationItem = styled(MuiPaginationItem)`
  svg {
    width: ${(props) => props.theme.spacing(4)};
  }
`;
