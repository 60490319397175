import { makeAutoObservable } from 'mobx';
import { mapStore, userAuthStore } from 'stores';
import { IWorkPlaceFilter } from 'shared/interfaces/app';
import { getEndOfDay, getNearHour } from 'utils/DateTimeUtils';

class WorkPlaceFilterModel {
  constructor(dto?: IWorkPlaceFilter) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.fromTime = dto.fromTime;
    this.toTime = dto.toTime;
    this.mapId = dto.mapId;
    this.userId = dto.userId;
  }

  public fromTime: Date = getNearHour();

  public toTime: Date = getEndOfDay();

  public mapId = '';

  public userId = '';

  public get queryParamsDto(): IWorkPlaceFilter {
    return {
      mapId: mapStore.selectedFloorImage.id,
      fromTime: this.fromTime,
      toTime: this.toTime,
      userId: userAuthStore.userId,
    };
  }

  public setMapId(mapId: string) {
    this.mapId = mapId;
  }

  public setTimeInterval(fromTime: Date, toTime: Date) {
    this.fromTime = fromTime;
    this.toTime = toTime;
  }
}

export default WorkPlaceFilterModel;
