import { makeAutoObservable } from 'mobx';
import { HOST, WORKPLACE_IMAGES_STORAGE_PATH } from 'shared/constants';
import { IWorkPlacesImageGetDto } from 'shared/interfaces/api';
import { toBase64 } from 'utils/FileUtils';

class WorkPlaceImageModel {
  constructor(dto?: IWorkPlacesImageGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.name = dto.name;
  }

  public id = '';

  public name = '';

  public image? = '';

  public imageBlob?: File;

  public get imageURL(): string {
    return HOST + WORKPLACE_IMAGES_STORAGE_PATH + this.name;
  }

  public setId(id: string) {
    this.id = id;
  }

  public seImageBlob(image: File) {
    this.imageBlob = image;
  }

  public async setImage(image: File) {
    this.image = await toBase64(image);
  }
}

export default WorkPlaceImageModel;
