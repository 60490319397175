import React from 'react';

const SelectExpand: React.FC = () => {
  return (
    <div>
      <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.70711 0.792893C1.31658 0.402369 0.683417 0.402369 0.292893 0.792893C-0.0976311 1.18342 -0.0976311 1.81658 0.292893 2.20711L1.70711 0.792893ZM6 6.5L5.29289 7.20711C5.68342 7.59763 6.31658 7.59763 6.70711 7.20711L6 6.5ZM11.7071 2.20711C12.0976 1.81658 12.0976 1.18342 11.7071 0.792893C11.3166 0.402369 10.6834 0.402369 10.2929 0.792893L11.7071 2.20711ZM0.292893 2.20711L5.29289 7.20711L6.70711 5.79289L1.70711 0.792893L0.292893 2.20711ZM6.70711 7.20711L11.7071 2.20711L10.2929 0.792893L5.29289 5.79289L6.70711 7.20711Z"
          fill="#003A5D"
        />
      </svg>
    </div>
  );
};

export default SelectExpand;
