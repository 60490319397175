import React, { useState } from 'react';

import { IThemeExtendedColors } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const TickSquare: React.FC = () => {
  const theme = useTheme();

  const mainColor = (theme.palette as IThemeExtendedColors).icons?.stroke;
  const [strokeColor, setStrokeColor] = useState(mainColor);

  const onMouseOver = () => {
    setStrokeColor(theme.palette.primary.main);
  };

  const onMouseLeave = () => {
    setStrokeColor(mainColor);
  };

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3345 0.7502H5.66549C2.64449 0.7502 0.750488 2.8892 0.750488 5.9162V14.0842C0.750488 17.1112 2.63549 19.2502 5.66549 19.2502H14.3335C17.3645 19.2502 19.2505 17.1112 19.2505 14.0842V5.9162C19.2505 2.8892 17.3645 0.7502 14.3345 0.7502Z"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.43994 10.0002L8.81394 12.3732L13.5599 7.6272" stroke={strokeColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TickSquare;
