import { makeAutoObservable, runInAction } from 'mobx';
import { userAuthStore } from 'stores';
import { UsersApi } from 'api';
import { DEFAULT_COMPANY_ID } from 'shared/constants';
import { DialogStatus, MessagesTypes } from 'shared/enums';
import { IDropDownOption, IUsersSelect, IUsersTableRow } from 'shared/interfaces/app';
import { UserFilterState, UserModel } from 'shared/models';
import departmentsStore from './departmentsStore';
import rolesStore from './rolesStore';

class UsersStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public users: UserModel[] = [];

  public anchorEl: HTMLElement | null = null;

  public selectedUser = new UserModel();

  public dialogViewMode = DialogStatus.Closed;

  public filterState = new UserFilterState(() => this.doFilter());

  public filteredUsers: UserModel[] = [];

  public isFetching = false;

  public get usersForTable(): IUsersTableRow[] {
    let tableData: UserModel[] = [];

    // Пользователи  с доступом к просмотру таблицы Пользователи не должны видеть superAdmins(isAdmin === true)
    if (!userAuthStore.isAdmin) {
      tableData = this.filteredUsers.filter((item) => !item.isAdmin);
    } else {
      tableData = this.filteredUsers;
    }

    return tableData.map(({ id, fullName, position, email, phone, subCompanyName, departmentName, roleName }) => {
      return { id, fullName, position, email, phone, subCompanyName, departmentName, roleName };
    });
  }

  public get usersForSelect(): IUsersSelect[] {
    return this.users.map(({ id, fullName }) => {
      return { id, fullName, type: MessagesTypes.Private };
    });
  }

  public get usersForOptions(): IDropDownOption[] {
    return this.users.map((_) => _.getDropDownOption());
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCreatingMode() {
    this.setDialogViewMode(DialogStatus.Creating);
    this.selectedUser = new UserModel();
  }

  public async setEditingMode(userId: string) {
    await this.getUserById(userId);
    this.setDialogViewMode(DialogStatus.Editing);
  }

  public closeUserDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public doFilter(): void {
    const filter = (item: UserModel) => item.fullName.toLocaleLowerCase().includes(this.filterState.pattern.toLocaleLowerCase());

    this.filteredUsers = this.users;

    if (this.filterState.pattern) {
      this.filteredUsers = this.filteredUsers.filter(filter);
    }

    if (this.filterState.roleId) {
      this.filteredUsers = this.filteredUsers.filter((item) => item.roleId === this.filterState.roleId);
    }
  }

  public async getUsersList() {
    try {
      this.isFetching = true;
      const users = await UsersApi.getUsers();
      if (!users) return;

      runInAction(() => {
        this.users = users.map((item) => new UserModel(item));
        this.filteredUsers = users.map((item) => new UserModel(item));
      });
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getUserById(userId: string) {
    try {
      const user = await UsersApi.getUser(userId);
      if (!user) return;
      this.selectedUser = new UserModel(user);
    } catch (e) {
      //ignore
    }
  }

  public async createUser(): Promise<void> {
    if (this.selectedUser.postPutDto === null) return;
    try {
      const newUser = await UsersApi.postUser(this.selectedUser.postPutDto);
      if (!newUser) return;
      this.selectedUser = new UserModel(newUser);
    } catch (e) {
      //ignore
    }
  }

  public async updateUser(): Promise<void> {
    if (this.selectedUser.postPutDto === null || this.selectedUser.id === null) return;
    try {
      const updateUser = await UsersApi.putUser(this.selectedUser.id, this.selectedUser.postPutDto);
      if (!updateUser) return;
      this.selectedUser = new UserModel(updateUser);
    } catch (e) {
      //ignore
    }
  }

  public async removeUser(id: string): Promise<void> {
    try {
      await UsersApi.deleteUser(id);
      await this.getUsersList();
    } catch (e) {
      //ignore
    }
  }
}

export default new UsersStore();
