import styled from 'styled-components/macro';

export const WorkPlaceWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  display: flex;
  flex-flow: wrap;
  width: 100%;
  margin-bottom: 6px;
`;

export const DatePickerWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  margin-bottom: 16px;
`;
