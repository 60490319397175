export enum DialogStatus {
  Closed,
  Editing,
  Creating,
  Deleting,
  Viewing,
}

export enum MessagesTypes {
  Company,
  Department,
  RB,
  Private,
  Notification,
}

export enum ColorPickStatus {
  Main,
  Status,
  Font,
  Background,
  Icons,
}

export enum Permissions {
  // Если наинается с подчеркивания, то это подзаголовк
  InfoLineAdmin = '_InfoLineAdmin',
  InfoLineGeneral = '_InfoLineGeneral',

  // Модули админки
  CompaniesModuleView = '1d183957-0015-449c-8807-d589809b34b4',
  DepartmentModuleView = 'd5b90488-d98b-4e8f-829c-fcb30d9afd06',
  RolesModuleView = 'b654310f-58a1-474a-97c4-c6e15e4a567a',
  UsersModuleView = '96393af2-98e9-4e79-89bd-bfc30e75d1a9',
  ZonesModuleView = 'ab920f3a-b8e0-4c04-801a-0871c62abee7',
  PassesConstructorModuleView = '8bb0946f-8c96-4a14-88df-5ce3c00c8422',
  MapConstructorModuleView = '4de1e800-8948-4b1a-ae52-007c1451bdc9',

  // Админка - Компании
  CompaniesUpdating = 'e1a37107-06f0-4b9c-9bab-7a2ce82ed81d',

  // Админка - Отделы
  DepartmentCreating = '998bc46b-96ea-4f26-a0cf-43670205de2c',
  DepartmentUpdating = 'c6d1a646-0bb7-48eb-b357-7b19995df4ac',
  DepartmentRemoving = '59daabc7-ff21-44e4-b3f0-bb8ae7df1d5f',

  // Админка - Пропуска
  PassesConstructorUpdating = '00db0c12-8577-45f9-8503-22296a4bc7e7',

  // Админка - Роли
  RolesCreating = 'a77f816f-036c-41a9-8163-7b777fde8569',
  RolesUpdating = '0e790f76-037d-4bdf-9f4f-90a1427e5efc',
  RolesRemoving = '02e192aa-d1e2-476d-a492-a279578a1565',

  // Админка - Юзеры
  UserCreating = '8fec1309-64c7-4c59-9c93-6fe045cc78a1',
  UserUpdating = '1055744f-c098-43cc-8350-d0277e4f970b',
  UserRemoving = 'aefa562a-1d51-45b7-8529-102005873a2f',

  // Админка - Зоны
  ZonesCreating = 'eb76a75e-6cad-4013-ae21-a84ea90d8087',
  ZonesUpdating = 'aa245aee-66ae-40e3-9aa3-f902fbd50377',
  ZoneRemoving = 'deb24d26-85b2-4798-9895-addda549d6a5',

  // Модули меню
  PassesModuleView = '8dd76c41-b62d-4852-a2cd-7fe9c0d1ecb9',
  ReservationsModuleView = 'f37cf1ff-63e9-40a5-ba0a-88901801fa26',
  ReservationUserInfoView = '0929b06e-ed5b-48a1-8d19-45ce83c0b33f',

  // Пропуска
  UsersPassesView = '1a7b66ac-b4e7-47b1-bcd7-3983a6746937',

  WorkplaceMessagesReading = '61dbc46d-6163-41ab-9889-b4cb75ea1e72',

  // Карта
  MapOrFloorCreating = 'b5d80c3e-1e7a-4a0e-ae86-f9e9d6619e35',
  MapOrFloorUpdating = '54d06173-42ac-488d-aa84-5f5f7d498235',
  MapOrFloorRemoving = 'c1be5652-7574-4e88-91c4-6a4fe96f218d',
  WorkplaceCreating = 'a5c42842-7bb1-438c-93ff-7cd5f3071ea0',
  WorkplaceUpdating = 'ee4e1f13-0638-479b-a70c-f8e4de2b0562',
  WorkplaceRemoving = '0089f2ea-b43b-4159-8b9b-e8b2c8300b2a',
  WorkplaceGalaryUpdating = 'f2225b79-0cdd-4eb6-8e99-4b7b9cd809c4',
  Tour3D = 'd6216ccf-1473-47df-a646-6b97227a6a22',

  UsersMessagesOrNotificationsReading = 'affa73df-dea0-4007-968c-2fcb6259084c',

  ReservationConfirmationWithoutQrCode = 'd6216ccf-1473-47df-a646-6b97227a6a26',
  ReservationViewOtherUsers = '0929b06e-ed5b-48a1-8d19-45ce83c0b331',
}

export const ViewModulesName = new Map([
  [Permissions.InfoLineAdmin, 'Администрирование'],
  [Permissions.UsersModuleView, 'Пользователи'],
  [Permissions.RolesModuleView, 'Роли'],
  [Permissions.DepartmentModuleView, 'Отделы'],
  [Permissions.CompaniesModuleView, 'Компания'],
  [Permissions.ZonesModuleView, 'Зоны'],
  [Permissions.PassesConstructorModuleView, 'Конструктор пропусков'],
  [Permissions.MapConstructorModuleView, 'Конструктор этажей и карт'],

  [Permissions.InfoLineGeneral, 'Общие'],
  [Permissions.PassesModuleView, 'Пропуска'],
  [Permissions.ReservationsModuleView, 'Бронирование рабочих мест'],
]);

export const CompanyActions = [
  {
    id: Permissions.CompaniesUpdating,
    name: 'Редактирование',
  },
];

export const DepartmentActions = [
  {
    id: Permissions.DepartmentCreating,
    name: 'Создание',
  },
  {
    id: Permissions.DepartmentUpdating,
    name: 'Редактирование',
  },
  {
    id: Permissions.DepartmentRemoving,
    name: 'Удаление',
  },
];

export const RoleActions = [
  {
    id: Permissions.RolesCreating,
    name: 'Создание',
  },
  {
    id: Permissions.RolesUpdating,
    name: 'Редактирование',
  },
  {
    id: Permissions.RolesRemoving,
    name: 'Удаление',
  },
];

export const ZoneActions = [
  {
    id: Permissions.ZonesCreating,
    name: 'Создание',
  },
  {
    id: Permissions.ZonesUpdating,
    name: 'Редактирование',
  },
  {
    id: Permissions.ZoneRemoving,
    name: 'Удаление',
  },
];

export const UserActions = [
  {
    id: Permissions.UserCreating,
    name: 'Создание',
  },
  {
    id: Permissions.UserUpdating,
    name: 'Редактирование',
  },
  {
    id: Permissions.UserRemoving,
    name: 'Удаление',
  },
];

export const PassActions = [
  {
    id: Permissions.PassesConstructorUpdating,
    name: 'Редактирование шаблона',
  },
  {
    id: Permissions.UsersPassesView,
    name: 'Просмотр чужих пропусков',
  },
];

export const MapConstructorActions = [
  {
    id: Permissions.MapOrFloorCreating,
    name: 'Создание этажей и планов',
  },
  {
    id: Permissions.MapOrFloorUpdating,
    name: 'Редактирование этажей и планов',
  },
  {
    id: Permissions.MapOrFloorRemoving,
    name: 'Удаление этажей и планов',
  },
  {
    id: Permissions.WorkplaceCreating,
    name: 'Создание мест',
  },
  {
    id: Permissions.WorkplaceUpdating,
    name: 'Редактирование мест',
  },
  {
    id: Permissions.WorkplaceRemoving,
    name: 'Удаление мест',
  },
  {
    id: Permissions.WorkplaceGalaryUpdating,
    name: 'Редактирование галереи места',
  },
];

export const MapActions = [
  {
    id: Permissions.Tour3D,
    name: 'Просмотр 3D тура',
  },
];

export const ReservationActions = [
  {
    id: Permissions.WorkplaceMessagesReading,
    name: 'Просмотр сообщений рабочих мест',
  },
  {
    id: Permissions.ReservationUserInfoView,
    name: 'Отображение информации о пользователе забронированого места',
  },
  {
    id: Permissions.ReservationConfirmationWithoutQrCode,
    name: 'Подтверждение бронирования без QR кода',
  },
  {
    id: Permissions.ReservationViewOtherUsers,
    name: 'Просмотр информации другого пользователя',
  },
];

export const MessageActions = [
  {
    id: Permissions.UsersMessagesOrNotificationsReading,
    name: 'Просмотр сообщений/уведомлений других пользователей',
  },
];

export enum ImageType {
  SVG = 'image/svg+xml',
  PNG = 'image/png',
  JPG = 'image/jpeg',
}

export enum WorkplacePositionAction {
  Save = 0, /// Сохранить без изменения координаты рабочего места на карте
  Recalculate = 1, /// Пересчитать координаты
  Delete = 2, /// Удалить с карты рабочее место
}

export enum FloorType {
  Parking = 0,
  Floor = 1,
}

export enum WorkPlaceType {
  WorkPlace = '0',
  Meeting = '1',
  Parking = '2',
}

export const FloorTypeNames = new Map([
  [FloorType.Parking, 'Парковка'],
  [FloorType.Floor, 'Помещение'],
]);

export enum WorkPlaceActions {
  Edit = 0,
  Duplicate = 1,
  AddToZone = 2,
  RemoveFromZone = 3,
  Schedule = 4,
  Photo = 5,
  Hide = 6,
  Delete = 7,
}

export enum CalendarViewMode {
  Day = 'day',
  Week = 'week',
}

export enum CalendarAction {
  Prev = 'PREV',
  Next = 'NEXT',
  TODAY = 'TODAY',
}

export const WorkPlaceActionsName = new Map([
  [WorkPlaceActions.Edit, 'Редактировать'],
  [WorkPlaceActions.Edit, 'Редактировать'],
  [WorkPlaceActions.Duplicate, 'Дублировать'],
  [WorkPlaceActions.AddToZone, 'Управление зонами'],
  [WorkPlaceActions.RemoveFromZone, 'Удалить из зоны'],
  [WorkPlaceActions.Schedule, 'Расписание'],
  [WorkPlaceActions.Photo, 'Фото объекта'],
  [WorkPlaceActions.Hide, 'Скрыть'],
  [WorkPlaceActions.Delete, 'Удалить'],
]);

export enum AdminTableActions {
  Edit = 0,
  Delete = 1,
}
