import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const Icon = styled.img<{ size: 'small' | 'large' }>`
  width: ${(p) => (p.size === 'small' ? '24px' : '32px')};
  height: ${(p) => (p.size === 'small' ? '24px' : '32px')};
  margin-top: ${(p) => p.theme.spacing(1)};
  margin-bottom: ${(p) => p.theme.spacing(1)};
  margin-right: ${(p) => p.theme.spacing(1)};
`;

export const Wrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1.5)};
  max-height: 32px;
  height: 100%;
  width: 50%;
`;

export const Label = styled(Typography)`
  display: flex;
  align-items: center;
  height: 100%;
`;
