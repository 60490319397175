import styled from 'styled-components/macro';
import { Paper } from '@mui/material';

export const Root = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.main};
  min-height: 100vh;
  flex-direction: column;
`;

export const Brand = styled.div`
  margin-top: ${(props) => props.theme.spacing(8)};
`;

export const AuthWrapper = styled(Paper)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-top: ${(p) => p.theme.spacing(4)};
    width: 95%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
  width: 511px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.palette.common.white};
  padding-top: ${(p) => p.theme.spacing(8)};
  padding-right: ${(p) => p.theme.spacing(8)};
  padding-bottom: ${(p) => p.theme.spacing(10)};
  padding-left: ${(p) => p.theme.spacing(8)};
`;
