import React from 'react';

import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { userAuthStore } from 'stores';

// For routes that can only be accessed by authenticated users
const AuthGuard: React.FC = ({ children }) => {
  if (!userAuthStore.isAuthUser && userAuthStore.isInitialized) {
    return <Redirect to="/auth/sign-in" />;
  }

  return <>{children}</>;
};

export default observer(AuthGuard);
