import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: ['Inter', 'sans-serif'].join(','),
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: '3rem',
    fontWeight: 600,
    lineHeight: 3,
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: 2,
  },
  body1: {
    fontSize: '0.9375rem',
    lineHeight: 1.5,
    fontWeight: 400,
  },
  body2: {
    fontSize: '0.9375rem', //15px
    fontWeight: 600,
    lineHeight: 1.5,
  },
  caption: {
    fontSize: '0.8125rem', //13px
    fontWeight: 500,
    lineHeight: 1,
  },
  subtitle1: {
    fontSize: '0.875rem', //14px
    fontWeight: 500,
    lineHeight: 1,
  },
  subtitle2: {
    fontSize: '0.75rem', //12px
    fontWeight: 500,
    lineHeight: 1,
  },
  button: {
    lineHeight: 1.5,
    textTransform: 'none',
  },
};

export default typography;
