import { IRoleListGetDto, IRolePostPutDto, IRoleGetDto } from 'shared/interfaces/api';

import HTTPService, { IHttpRequest } from './HTTPService';

class RolesApi extends HTTPService {
  public constructor() {
    super('Roles');
  }

  public getRoles(companyId?: string): Promise<IRoleListGetDto[]> {
    const url = companyId ? `?CompanyId=${companyId}` : '';
    return this.GET(url);
  }

  public getRole(id: string): Promise<IRoleGetDto> {
    return this.GET(`${id}/`);
  }

  public getPermissions(roleId: string): Promise<string[]> {
    return this.GET(`${roleId}/permissions`);
  }

  public postRole(newRole: IRolePostPutDto): Promise<string> {
    return this.POST('', newRole);
  }

  public putRole(id: string, data: IRolePostPutDto): Promise<IRoleGetDto> {
    const request: IHttpRequest<IRolePostPutDto> = {
      path: `${id}/`,
      data,
    };
    return this.PUT<IRolePostPutDto, IRoleGetDto>(request);
  }

  public putPermission(roleId: string, data: string[]): Promise<IRoleGetDto> {
    const request: IHttpRequest<string[]> = {
      path: `${roleId}/permissions`,
      data,
    };
    return this.PUT<string[], IRoleGetDto>(request);
  }

  public deleteRole(id: string): Promise<void> {
    return this.DELETE(`${id}/`);
  }
}

export default new RolesApi();
