import styled from 'styled-components/macro';
import { Button as MuiButton, FormControl as MuiFormControl } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Button = styled(MuiButton)`
  margin: ${(props) => props.theme.spacing(6)};
  color: grey;
  border-color: grey;
`;

export const FormControl = styled(MuiFormControl)`
  width: 100%;
`;
