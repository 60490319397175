import React, { useState } from 'react';

import { IThemeExtendedColors } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const EyeClose: React.FC = () => {
  const theme = useTheme();

  const mainColor = theme.palette.primary.main;
  const [strokeColor, setStrokeColor] = useState(mainColor);

  const onMouseOver = () => {
    setStrokeColor(theme.palette.primary.main);
  };

  const onMouseLeave = () => {
    setStrokeColor(theme.palette.primary.main);
  };
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={strokeColor}
      xmlns="http://www.w3.org/2000/svg"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}>
      <path
        d="M8.34249 11.7641C7.86332 11.2858 7.57166 10.6358 7.57166 9.90664C7.57166 8.44581 8.74832 7.26831 10.2083 7.26831C10.9308 7.26831 11.5958 7.56081 12.0667 8.03914"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.7961 10.3743C12.6027 11.4493 11.7561 12.2976 10.6819 12.4926" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.75417 14.3522C4.43167 13.3139 3.31167 11.7972 2.5 9.90639C3.32 8.00722 4.4475 6.48222 5.77833 5.43556C7.10083 4.38889 8.62667 3.82056 10.2083 3.82056C11.7992 3.82056 13.3242 4.39722 14.655 5.45139"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4151 7.28418C16.9884 8.04585 17.4926 8.92501 17.9167 9.90585C16.2776 13.7033 13.3809 15.9908 10.2084 15.9908C9.48922 15.9908 8.78006 15.8742 8.09839 15.6467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.7811 3.33325L3.63611 16.4783" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EyeClose;
