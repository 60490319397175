import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { passesStore, passTemplatesStore } from 'stores';
import { Input, ModalDialog, Title } from 'components';
import DateTimePicker from 'components/UIKit/DateTimePicker';
import { PASS_TEMPLATE_ID } from 'shared/constants';
import { DialogStatus } from 'shared/enums';
import { IFieldPasses } from 'shared/interfaces/api';
import { Box } from '@mui/material';
import { getNearHour } from '../../../../utils/DateTimeUtils';

interface IPassesDialogProps {
  onClose: () => void;
}

const PassesDialog: React.FC<IPassesDialogProps> = (props) => {
  useEffect(() => {
    (async () => {
      if (passesStore.dialogViewMode !== DialogStatus.Closed) {
        await passTemplatesStore.getPassTemplate(PASS_TEMPLATE_ID);

        passesStore.selectedPass.setFields(
          passTemplatesStore.selectedPassTemplate.currentFields.map(({ name }) => {
            return { name: name, value: '' } as IFieldPasses;
          })
        );
      }
    })();
  }, [passesStore.dialogViewMode]);
  const { onClose } = props;
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    (async () => {
      const isValidFrom = await Yup.date().required('Время обязательно').isValid(passesStore.selectedPass.fromDate);
      const isValidTo = await Yup.date().required('Время обязательно').isValid(passesStore.selectedPass.toDate);

      setValidated(isValidFrom && isValidTo);
    })();
  }, [passesStore.selectedPass.fromDate, passesStore.selectedPass.toDate]);

  const onSubmit = async () => {
    await passesStore.createPass();
    passesStore.closePassesDialog();
    await passesStore.getPassesList();
  };

  const onChangeDate = (fromTime?: Date | null, toTime?: Date | null) => {
    if (!fromTime) fromTime = getNearHour();
    passesStore.selectedPass.setTimeInterval(fromTime, toTime);
  };

  const onChangeComments = (e: React.ChangeEvent<any>) => {
    passesStore.selectedPass.setComments(e.target.value);
  };

  return (
    <ModalDialog
      isValid={validated}
      title="Создание пропуска"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      isOpen={passesStore.dialogViewMode !== DialogStatus.Closed}>
      <Title label="Дата и время" />
      <div style={{ marginBottom: '16px' }}>
        <DateTimePicker onChange={onChangeDate} timeFrom={passesStore.selectedPass.fromDate} timeTo={passesStore.selectedPass.toDate} />
      </div>

      {passesStore.selectedPass.currentFields.map((field, index) => {
        return (
          <Box key={`${field.name}key${index}`} sx={{ width: '100%' }}>
            <Input
              name={field.name}
              inputLabel={field.name}
              value={field.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => (field.value = event.currentTarget.value)}
            />
          </Box>
        );
      })}

      <Input mb={7} multiline name="comments" inputLabel="Комментарий" value={passesStore.selectedPass.comments} onChange={onChangeComments} />
    </ModalDialog>
  );
};

export default observer(PassesDialog);
