import React, { useCallback, useEffect } from 'react';

import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { passesStore } from 'stores';

import { Typography } from '@mui/material';
import { getDateOnly, getTimeSpan } from '../../utils/DateTimeUtils';
import * as S from './RejectPass.styles';

const RejectPass: React.FC = () => {
  const location = useLocation();

  const getQuery = useCallback(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    const query = getQuery();
    const id = query.get('passId');
    const key = query.get('secretKey');

    if (!id || !key) return;

    passesStore.rejectPass(id, key);
  }, []);

  if (passesStore.isLoaded) return <>Подождите</>;

  const date = new Date(passesStore.selectedPass.fromDate!);
  const timeFrom = new Date(passesStore.selectedPass.fromDate!);
  const timeTo = new Date(passesStore.selectedPass.toDate!);

  return (
    <>
      <S.WrapperHeader>
        <img src={'/Logo_Color.svg'} alt={''} />
        <Typography variant="h3" color="common.white">
          Отклонение пропуска
        </Typography>
      </S.WrapperHeader>

      <S.WrapperContent>
        <Typography variant="h1" color="theme.palette.primary.main">
          {passesStore.selectedPass.isConfirmed === false ? 'Пропуск отклонен' : 'Ошибка отклонения пропуска'}
        </Typography>

        <div>{passesStore.selectedPass.fullUserName}</div>
        <div>
          {getDateOnly(date)}&nbsp;
          {getTimeSpan(timeFrom, timeTo)}
        </div>
      </S.WrapperContent>
    </>
  );
};

export default observer(RejectPass);
