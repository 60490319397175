import React from 'react';
import { observer } from 'mobx-react';
import { ModalDialog } from 'components';
import confirmDialog from './index';

const ConfirmDialog: React.FC = () => {
  return (
    <ModalDialog
      isOpen={confirmDialog.isOpen}
      title={confirmDialog.title}
      submitBtnLabel={confirmDialog.buttonName}
      submitBtnColor={confirmDialog.buttonColor}
      onCancel={confirmDialog.onCancel}
      onSubmit={confirmDialog.onSubmit}>
      <>{confirmDialog.content && confirmDialog.content}</>
    </ModalDialog>
  );
};

export default observer(ConfirmDialog);
