import { IImageSize } from 'shared/interfaces/app';

/**
 * Принимает base64 и возвращает файл картинки
 * @param {File} base64
 * @return {File}
 */
export function base64ToImage(base64: string): File {
  return new File([base64], 'image.svg', { type: 'image/svg' });
}

/*** Преобразует base64 строку к файлу
 * @param base64 - строка
 * В случае ошибки возвращает пустой файл
 */

export function convertBase64ToFile(base64: string): File {
  try {
    const arr = base64.split(',');

    const bstr = atob(arr[1]);

    let n = bstr.length;

    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'icon');
  } catch (error) {
    console.log(error);

    return new File([], 'empty file');
  }
}

/**
 * Проверяет формат файла логотипа
 * @param {File} file - загружаемый файл
 * @returns {boolean} - true/false (valid/not valid)
 */

export function isValidLogo(file?: File): boolean {
  if (!file) return false;

  const regExp = /\.(svg)/i;
  const isValidFormat = file.name.search(regExp) !== -1;
  const isValidSize = file.size < 204800;

  return isValidFormat && isValidSize;
}

/**
 * Проверяет формат файла карты
 * @param {File} file - загружаемый файл
 * @returns {boolean} - true/false (valid/not valid)
 */

export function isValidMap(file?: File): boolean {
  if (!file) return false;

  const regExp = /\.(jpe?g|svg|png)/i;
  const isValidFormat = file.name.search(regExp) !== -1;

  return isValidFormat;
}

/**
 * Проверяет формат файла фотографии объекта
 * @param {File} file - загружаемый файл
 * @returns {boolean} - true/false (valid/not valid)
 */

export function isValidWPImage(file?: File): boolean {
  if (!file) return false;

  const regExp = /\.(jpe?g|png)/i;
  const isValidFormat = file.name.search(regExp) !== -1;
  const isValidSize = file.size < 1024000 * 10;

  return isValidFormat && isValidSize;
}

export const getBodyFromUrl = async (url: string): Promise<{ type: string; base64: string }> => {
  const data = await fetch(url);
  const blob = await data.blob();
  const type = blob.type;

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64 = <string>reader.result;
      resolve({ type, base64 });
    };
  });
};

export const toBase64 = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getSvgSize = (svg: string): IImageSize => {
  let height = 0;

  let width = 0;

  try {
    const parser: DOMParser = new DOMParser();

    const xmlDoc: Document = parser.parseFromString(svg, 'text/xml');
    const svgTag: SVGSVGElement = xmlDoc.getElementsByTagName('svg')[0];

    height = parseInt(svgTag.getAttribute('height') || '0', 10);
    width = parseInt(svgTag.getAttribute('width') || '0', 10);
  } catch (error) {
    // ignore
  }

  return { height, width };
};

export const getImgSize = async (url: string): Promise<IImageSize> => {
  return new Promise((resolve) => {
    try {
      const img = new Image();

      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };

      img.src = url;
    } catch (e) {
      resolve({ height: 0, width: 0 });
    }
  });
};
