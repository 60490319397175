import React, { useState } from 'react';
import { DateRangePicker as MuiDateRangePicker, DateRange, LocalizationProvider, MobileDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import ruLocale from 'date-fns/locale/ru';

import { Calendar } from 'react-feather';
import { getEndOfDay } from 'utils/DateTimeUtils';
import { useBreakpoint } from 'utils/hooks';
import { Box, ClickAwayListener, IconButton, InputAdornment, TextField } from '@mui/material';
import * as S from './DateRangePicker.styles';

interface IDateRangePickerProps {
  onChange: (date: DateRange<Date>) => void;
  range: DateRange<Date>;
}

const DateRangePicker = (props: IDateRangePickerProps) => {
  const { onChange, range } = props;

  const bp = useBreakpoint();
  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';
  const isDesktop = bp === 'lg';

  const [isOpen, setOpen] = useState<boolean>(false);

  const onChangeDateRange = (date: DateRange<Date | null>) => {
    // Приведение правой границы к концу дня
    if (date && date[1]) date[1] = getEndOfDay(date[1]);
    onChange(date);
  };

  const onOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <S.DatePickerWrapper>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
          {isMobile || isTablet ? (
            <S.MobileDateRangePicker
              mask={''}
              calendars={1}
              open={isOpen}
              value={range}
              onAccept={onOpen}
              onClose={onOpen}
              onChange={onChangeDateRange}
              renderInput={(startProps: MuiTextFieldProps, endProps: MuiTextFieldProps) => {
                const startValue = startProps.inputProps?.value;
                delete startProps.inputProps?.value;
                if (startProps.inputProps) startProps.inputProps.disabled = true;

                return (
                  <S.TextField
                    {...startProps}
                    onClick={onOpen}
                    inputProps={startProps.inputProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onOpen} style={{ margin: '0' }}>
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={`${startValue}-${endProps.inputProps?.value}`}
                  />
                );
              }}
            />
          ) : (
            <MuiDateRangePicker
              calendars={1}
              open={isOpen}
              value={range}
              mask={''}
              onChange={onChangeDateRange}
              renderInput={(startProps: MuiTextFieldProps, endProps: MuiTextFieldProps) => {
                const startValue = startProps.inputProps?.value;
                delete startProps.inputProps?.value;
                if (startProps.inputProps) startProps.inputProps.disabled = true;

                return (
                  <S.TextField
                    {...startProps}
                    onClick={onOpen}
                    inputProps={startProps.inputProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onOpen} style={{ margin: '0' }}>
                            <Calendar />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={`${startValue}-${endProps.inputProps?.value}`}
                  />
                );
              }}
            />
          )}
        </LocalizationProvider>
      </S.DatePickerWrapper>
    </ClickAwayListener>
  );
};

export default DateRangePicker;
