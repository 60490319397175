import React from 'react';
import { Chip } from '@mui/material';

interface IChipStatusProps {
  value: boolean | null;
}

const ChipStatus: React.FC<IChipStatusProps> = (props) => {
  const { value } = props;

  let caption: string;
  let color: any;
  if (value === null) {
    caption = 'В ожидании';
    color = 'info';
  } else if (value) {
    caption = 'Подтверждён';
    color = 'secondary';
  } else {
    caption = 'Отклонён';
    color = 'error';
  }

  return <Chip style={{ width: '120px' }} color={color} label={caption} />;
};

export default ChipStatus;
