import React, { useEffect, useState } from 'react';

import { Checkbox, DropDown } from 'components';

import { IDropDownOption } from 'shared/interfaces/app';

import { Button } from '@mui/material';
import { ROUTE } from '../../../routes';
import * as S from './floorAndPlaceControlPanel.styles';

interface IProps {
  getFloorList(): void;
  getWorkPlaces(floorId: string | null): void;
  selectedFloorId: string | null;
  selectedWorkPlaceId: string | null;
  link: string | null;

  floorsList: IDropDownOption[];
  placesList: IDropDownOption[];

  onChangeFloor(floorId: string | null): void;
  onChangePlace(placeId: string | null): void;

  onlyMineSelected?: boolean;
  onSelectOnlyMine?(onlyMine: boolean): void;
}

const FloorAndPlaceControlPanel: React.FC<IProps> = (props: IProps) => {
  const {
    getFloorList,
    getWorkPlaces,
    selectedFloorId,
    selectedWorkPlaceId,
    floorsList,
    placesList,
    onlyMineSelected,
    link,
    onChangeFloor,
    onChangePlace,
    onSelectOnlyMine,
  } = props;

  useEffect(() => {
    getFloorList();
  }, []);

  useEffect(() => {
    getWorkPlaces(selectedFloorId);
  }, [selectedFloorId]);

  const onSelectFloor = async (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeFloor(e.target.value);
  };

  const onSelectWorkPlace = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangePlace(e.target.value);
  };

  const onClickOnlyMine = (e: React.SyntheticEvent, checked: boolean) => {
    if (!onSelectOnlyMine) return;

    onSelectOnlyMine(checked);
  };

  return (
    <S.PlaceSelectorRow>
      <DropDown mb={0} name={''} placeholder="Выберите этаж" value={selectedFloorId || ''} options={floorsList} onChange={onSelectFloor} />
      <DropDown mb={0} name={''} placeholder="Выберите место" value={selectedWorkPlaceId || ''} options={placesList} onChange={onSelectWorkPlace} />

      {link && (
        <S.Link target={'_blank'} to={link} disabled={!selectedWorkPlaceId}>
          <Button color="secondary" variant="contained" size="large" disabled={!selectedWorkPlaceId}>
            Показать на карте
          </Button>
        </S.Link>
      )}

      {onSelectOnlyMine && <Checkbox label={'Мои бронирования'} checked={onlyMineSelected} onChange={onClickOnlyMine} />}
    </S.PlaceSelectorRow>
  );
};

export default FloorAndPlaceControlPanel;
