import styled from 'styled-components/macro';

export const Root = styled.div`
  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    width: 100%;
    padding: 0;
  }
  display: flex;
  min-height: 100%;
  padding: 12px;
`;

export const AppContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 28px;
  width: 100%;

  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    margin: 0;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: -moz-calc(100% - 292px);
    max-width: -webkit-calc(100% - 292px);
    max-width: calc(100% - 292px);
  }
`;

export const TogglerWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    width: unset;
  }
`;
