import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { messagesStore, usersStore } from 'stores';
import { DropDown, Input, ModalDialog } from 'components';

import { DialogStatus } from 'shared/enums';
import { IMessagesDialog } from 'shared/interfaces/app';
import { MessagesModel } from 'shared/models';

const MessagesDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);

  const formRef = useRef<FormikProps<IMessagesDialog>>(null);

  useEffect(() => {
    usersStore.getUsersList();

    return () => {
      messagesStore.selectedMessage = new MessagesModel();
    };
  }, []);

  const handleCloseMessageDialog = () => {
    messagesStore.setDialogViewMode(DialogStatus.Closed);
  };

  const onSubmit = async (values: IMessagesDialog) => {
    messagesStore.selectedMessage.updateFromDialogForm(values);

    await messagesStore.createMessage(messagesStore.selectedMessage.messagePostDto);

    messagesStore.closeMessagesDialog();
    await messagesStore.getMessagesList();
    await messagesStore.getUnreadCount();
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  return (
    <ModalDialog
      isValid={validated}
      title="Новое сообщение"
      onClose={handleCloseMessageDialog}
      onCancel={handleCloseMessageDialog}
      onSubmit={handleSubmit}
      createBtnLabel="Отправить"
      isOpen={messagesStore.dialogViewMode !== DialogStatus.Closed && messagesStore.dialogViewMode !== DialogStatus.Viewing}>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          caption: messagesStore.selectedMessage.caption,
          content: messagesStore.selectedMessage.content,
          userId: messagesStore.selectedMessage.userSenderId, // Используем в качестве начального значения Id отправителя, чтобы можно было ответить конкретному человеку из массовой рассылки
          userSenderId: messagesStore.selectedMessage.userSenderId,
          userOpt: messagesStore.messageUserForOptions,
        }}
        validationSchema={Yup.object().shape({
          caption: Yup.string().max(255).required('Введите тему письма'),
          content: Yup.string().max(2000).required('Введите текст сообщения'),
          userId: Yup.string().required('Поле кому обязательное'),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
          setValidated(isValid);
          return (
            <>
              {values.userOpt && values.userId !== undefined && (
                <DropDown
                  mb={5}
                  name="userId"
                  options={values.userOpt}
                  value={values.userId}
                  dropDownLabel="Кому *"
                  error={Boolean(touched.userId && errors.userId)}
                  helperText={touched.userId && errors.userId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}

              <Input
                mb={7}
                name="caption"
                inputLabel="Тема письма *"
                value={values.caption}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.caption && errors.caption)}
                helperText={touched.caption && errors.caption}
              />

              <Input
                mb={7}
                multiline
                name="content"
                inputLabel="Текст сообщения *"
                value={values.content}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
              />
            </>
          );
        }}
      </Formik>
    </ModalDialog>
  );
};

export default observer(MessagesDialog);
