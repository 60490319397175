import { makeAutoObservable } from 'mobx';
import { IThemeColors, IThemeOptions } from 'shared/interfaces/app';

class ThemeOptionsModel {
  constructor(themeOptions?: IThemeOptions) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (themeOptions) {
      this.mainColor = themeOptions.mainColor;
      this.statusColor = themeOptions.statusColor;
      this.fontColor = themeOptions.fontColor;
      this.backgroundColor = themeOptions.backgroundColor;
    }
  }

  public mainColor: IThemeColors = {
    primary: '',
    secondary: '',
  };

  public statusColor: IThemeColors = {
    success: '',
    error: '',
    warning: '',
  };

  public fontColor: IThemeColors = {
    primary: '',
    secondary: '',
    inverted: '',
  };

  public backgroundColor: IThemeColors = {
    primary: '',
    secondary: '',
  };
}

export default ThemeOptionsModel;
