import React from 'react';
import { IIconProps } from 'shared/interfaces/app';
import { useTheme } from '@mui/material';

const CalendarIcon: React.FC<IIconProps> = ({ color }) => {
  const theme = useTheme();
  const iconColor = color ? color : theme.palette.common.white;

  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" stroke={iconColor} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.57715 7.83688H16.4304" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.702 11.0914H12.7097" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00372 11.0914H9.01144" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.29816 11.0914H5.30588" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.702 14.3302H12.7097" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.00372 14.3302H9.01144" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.29816 14.3302H5.30588" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3699 1.66666V4.40898" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.63795 1.66666V4.40898" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5319 2.98266H5.4758C3.02856 2.98266 1.5 4.34594 1.5 6.85185V14.3932C1.5 16.9385 3.02856 18.3333 5.4758 18.3333H12.5242C14.9791 18.3333 16.5 16.9622 16.5 14.4563V6.85185C16.5077 4.34594 14.9868 2.98266 12.5319 2.98266Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
