import React, { ChangeEvent, useEffect } from 'react';
import { observer } from 'mobx-react';
import { departmentsStore, rolesStore } from 'stores';
import { Input, ModalDialog, PopupMenu, Table } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import Loupe from 'components/Icons/Loupe';
import Trash from 'components/Icons/Trash';
import { DialogStatus, Permissions } from 'shared/enums';
import { Column, IDepartmentTableRow } from 'shared/interfaces/app';
import { Divider, MenuItem, Typography } from '@mui/material';
import { DepartmentsDialog } from './Components';

import * as S from './Departments.styles';

const columns: Column[] = [
  {
    id: 'departmentName',
    label: 'Название отдела',
    minWidth: 240,
  },
  {
    id: 'companyName',
    label: 'Название компании',
    minWidth: 170,
  },
];

const Departments: React.FC = () => {
  useEffect(() => {
    departmentsStore.getDepartmentsList().finally();
  }, []);

  const isEditDisabled = !rolesStore.isAllow(Permissions.DepartmentUpdating);
  const isDeleteDisabled = !rolesStore.isAllow(Permissions.DepartmentRemoving);

  const createRowData = () => {
    return departmentsStore.departmentsForTable.map((data: IDepartmentTableRow) => {
      const menuItems = [
        <MenuItem
          sx={{ '&:hover': { color: 'primary.main' } }}
          disableRipple
          disabled={isEditDisabled}
          key={data.id}
          onClick={() => {
            departmentsStore.setEditingMode(data.id);
          }}>
          <EditPencilIcon />
          <Typography variant="caption" sx={{ ml: 3 }}>
            Редактировать
          </Typography>
        </MenuItem>,
        <MenuItem
          sx={{ '&:hover': { color: 'primary.main' } }}
          disableRipple
          disabled={isDeleteDisabled}
          key={data.id}
          onClick={async () => {
            await departmentsStore.getDepartmentById(data.id);
            departmentsStore.setDeletingMode(data.id);
          }}>
          <Trash />
          <Typography variant="caption" sx={{ ml: 3 }}>
            Удалить
          </Typography>
        </MenuItem>,
      ];

      const row: Array<JSX.Element> = Object.values(data)
        .map((value, index) => {
          if (index === Object.values(data).length - 1) {
            return (
              <S.CellWrapper key={value.id}>
                <Typography>{value}</Typography>
                <PopupMenu key={data.id} menuItems={menuItems} />
              </S.CellWrapper>
            );
          }

          return <Typography key={value.id}>{value}</Typography>;
        })
        .slice(1);

      return row;
    });
  };

  const handleCloseConfirmDialog = () => {
    departmentsStore.setDialogViewMode(DialogStatus.Closed);
  };

  const handleDeleteDepartment = async () => {
    await departmentsStore.removeDepartment();
    departmentsStore.setDialogViewMode(DialogStatus.Closed);
  };

  const changeFilter = (event: ChangeEvent<{ value: string }>) => {
    departmentsStore.setFilterState(event.target.value);
  };

  return (
    <>
      <Divider sx={{ mt: 4, mb: 4 }} orientation="horizontal" flexItem />

      <S.FormControl sx={{ mr: 2, mb: 4 }}>
        <Input
          mb={4}
          name="search-fullName"
          placeholder="Поиск..."
          value={departmentsStore.filterState}
          endIcon={<Loupe />}
          onChange={changeFilter}
        />
      </S.FormControl>

      <Table columns={columns} rows={createRowData()} />
      <DepartmentsDialog />
      <ModalDialog
        isOpen={departmentsStore.dialogViewMode === DialogStatus.Deleting}
        title="Вы уверены, что хотите удалить отдел?"
        submitBtnLabel="Удалить"
        submitBtnColor="error"
        onCancel={handleCloseConfirmDialog}
        onSubmit={handleDeleteDepartment}
      />
    </>
  );
};

export default observer(Departments);
