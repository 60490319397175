import { LatLngLiteral, Marker } from 'leaflet';
import { makeAutoObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { WORKPLACE_IMAGES_PER_PAGE, ZERO_POSITION } from 'shared/constants';
import { WorkPlaceType } from 'shared/enums';
import { IAttributeDto, IWorkPlaceGetDto, IWorkPlacesPostDto, IWorkPlacesPutDto } from 'shared/interfaces/api';
import { IAddZonesDialog, IAllowWeekDaysTag, IDropDownOption, IPosition, IWorkPlaceDialog } from 'shared/interfaces/app';
import { isValidWPImage } from 'utils/FileUtils';
import WorkPlaceImageModel from './WorkPlaceImageModel';

class WorkPlaceModel {
  public constructor(dto?: IWorkPlaceGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.floorId = dto.floorId;
    this.floorName = dto.floorName;
    this.type = dto.type;
    this.attributes = dto.attributes;
    this.zoneIds = dto.zoneIds;
    this.comment = dto.comment;
    this.number = dto.number;
    this.isActive = dto.isActive;
    this.allowWeekDays = dto.allowWeekDays;
    this.position = dto.positionForNearestMap;
    this.mapId = dto.nearestMapId;
  }

  public id = '';

  public floorId = '';

  public floorName = '';

  public type = WorkPlaceType.WorkPlace;

  public attributes: IAttributeDto[] = [];

  public zoneIds: string[] = [];

  public removeImageIds: string[] = [];

  public comment = '';

  public number = '';

  public isActive = true;

  public allowWeekDays: boolean[] = [true, true, true, true, true, true, true];

  public position: IPosition = ZERO_POSITION;

  public mapId: string | null = null;

  public marker: Marker | null = null;

  public images: WorkPlaceImageModel[] = [];

  public workPlaceImages: WorkPlaceImageModel[] = [];

  public imagesPage = 0;

  public selectedImage = 0;

  public isValidImage = true;

  public get postImages(): FormData[] | null {
    if (this.workPlaceImages.length < 0) return null;

    // TODO mikhail.broch: Если нужно дублировать фотографии рабочего места при DuplicateAction
    // if (mapStore.isDuplicate) this.workPlaceImages.map((_) => (_.name = ''));

    const postFormDataArray = this.workPlaceImages
      .filter((_) => _.name === '')
      .map((item) => {
        const postFormData = new FormData();
        postFormData.append('image', item.imageBlob ?? '');
        return postFormData;
      });

    return postFormDataArray;
  }

  public get allowWeekDaysTag(): IAllowWeekDaysTag[] {
    const allowWeekDaysTagData = this.allowWeekDays.map((item, idx) => {
      return {
        id: idx.toString(),
        isActive: item,
      };
    });

    return allowWeekDaysTagData;
  }

  public get workPlaceImagesOnPage(): WorkPlaceImageModel[] {
    return this.workPlaceImages.slice(
      this.imagesPage * WORKPLACE_IMAGES_PER_PAGE,
      this.imagesPage * WORKPLACE_IMAGES_PER_PAGE + WORKPLACE_IMAGES_PER_PAGE
    );
  }

  public get lastImagesPage(): number {
    return Math.ceil(this.workPlaceImages.length / WORKPLACE_IMAGES_PER_PAGE) - 1;
  }

  public get postDto(): IWorkPlacesPostDto | null {
    if (!this.floorId || !this.type.toString() || !this.number || !this.allowWeekDays) return null;

    return {
      type: this.type,
      number: this.number,
      comment: this.comment,
      floorId: this.floorId,
      isActive: this.isActive,
      attributes: this.attributes,
      allowWeekDays: this.allowWeekDays,
      zoneIds: this.zoneIds,
    };
  }

  public get putDto(): IWorkPlacesPutDto | null {
    if (!this.floorId || !this.type.toString() || !this.number || !this.allowWeekDays) return null;

    return {
      type: this.type,
      number: this.number,
      comment: this.comment,
      floorId: this.floorId,
      isActive: this.isActive,
      attributes: this.attributes,
      allowWeekDays: this.allowWeekDays,
      zoneIds: this.zoneIds,
      removeImageIds: this.removeImageIds,
    };
  }

  public setId(id: string) {
    this.id = id;
  }

  public setSelectedImage(index: number) {
    this.selectedImage = index;
  }

  public setValidImage(isValid: boolean) {
    this.isValidImage = isValid;
  }

  public setNumber(number: string) {
    this.number = number;
  }

  public setImagesPage(page: number) {
    this.imagesPage = page;
  }

  public setRemoveImageIds(id: string) {
    const idx = this.removeImageIds.indexOf(id);
    if (idx === -1) {
      this.removeImageIds.push(id);
    } else {
      this.removeImageIds.splice(idx, 1);
    }
  }

  public setWorkPlaceImage(image: File) {
    const newImage = new WorkPlaceImageModel();
    newImage.setImage(image);
    newImage.seImageBlob(image);
    newImage.setId(uuidv4());
    this.workPlaceImages.push(newImage);
  }

  public setIsActive(isActive: boolean) {
    this.setAllowWeekDays(isActive);
    this.isActive = isActive;
  }

  public setComment(commen: string) {
    this.comment = commen;
  }

  public setFloorId(floorId: string) {
    this.floorId = floorId;
  }

  public setType(type: WorkPlaceType) {
    this.type = type;
  }

  public setAllowWeekDays(isActive: boolean) {
    if (isActive) {
      this.allowWeekDays = [true, true, true, true, true, true, true];
    } else {
      this.allowWeekDays = [false, false, false, false, false, false, false];
    }
  }

  public setPosition(coordinate: LatLngLiteral) {
    this.position.x = coordinate.lng;
    this.position.y = coordinate.lat;
  }

  public setMarker(marker: Marker): void {
    this.marker = marker;
    this.setPosition(marker.getLatLng());
  }

  public updateFromDialogForm(model: IWorkPlaceDialog) {
    this.number = model.number;
    this.zoneIds = model.zoneIds;
  }

  public updateFromAddZoneDialog(model: IAddZonesDialog) {
    this.zoneIds = model.zoneIds;
  }

  public getDropDownOption(): IDropDownOption {
    return { id: this.id!, name: this.number };
  }

  public uploadImage(event: any) {
    if (event.target.files) {
      if (isValidWPImage(event.target.files[0])) {
        this.setWorkPlaceImage(event.target.files[0]);
        this.setImagesPage(this.lastImagesPage);
        this.setValidImage(true);
      } else {
        this.setValidImage(false);
      }
    } else if (event.dataTransfer.files) {
      if (isValidWPImage(event.dataTransfer.files[0])) {
        this.setWorkPlaceImage(event.dataTransfer.files[0]);
        this.setImagesPage(this.lastImagesPage);
        this.setValidImage(true);
      } else {
        this.setValidImage(false);
      }
    } else this.setValidImage(false);
  }

  public getWorkPlaceIcon(): any {
    let markerURL;
    let iconSize;

    if (this.type == WorkPlaceType.WorkPlace) {
      markerURL = '/images/work-place-free-icon.svg';
      iconSize = [24, 24];
    } else {
      markerURL = '/images/meeting-room-free.svg';
      iconSize = [50, 30];
    }

    return { markerURL, iconSize };
  }

  public clear(): void {
    this.id = '';
    this.type = WorkPlaceType.WorkPlace;
    this.isActive = false;
    this.number = '';
    this.allowWeekDays = [false, false, false, false, false, false, false];
    this.comment = '';
    this.attributes = [];
    this.position = ZERO_POSITION;
    this.mapId = null;
    this.zoneIds = [];
    this.workPlaceImages = [];
    this.imagesPage = 0;
    this.removeMarker();
  }

  public removeMarker(): void {
    if (this.marker) this.marker.remove();
  }
}

export default WorkPlaceModel;
