import React, { useEffect } from 'react';
import L, { Marker } from 'leaflet';
import { observer } from 'mobx-react';
import { useMap } from 'react-leaflet';
import { mapStore, workPlacesStore } from 'stores';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

L.Icon.Default.imagePath = 'https://unpkg.com/leaflet@1.5.0/dist/images/';
const GEOMAN_DRAW_TYPE = 'Marker';

const DrawControl: React.FC = () => {
  const map = useMap();

  useEffect(() => {
    if (mapStore.isDraw) {
      map.pm.enableDraw(GEOMAN_DRAW_TYPE);
      (map.pm as any).setGlobalOptions({ snappable: false });

      map.on('pm:create', (e: any) => {
        if (map.pm.Draw.getActiveShape() !== GEOMAN_DRAW_TYPE) return;

        addMarkerOnMap(e.marker);
        map.pm.disableDraw(GEOMAN_DRAW_TYPE);
      });
    }
  }, [mapStore.isDraw]);

  useEffect(() => {
    if (mapStore.keyDownEvent && mapStore.keyDownEvent.key === 'Escape') {
      map.pm.disableDraw('Marker');
      mapStore.setIsDraw(false);
    }
  }, [mapStore.keyDownEvent]);

  const icon = new L.Icon({
    iconUrl: workPlacesStore.selectedWorkPlace.getWorkPlaceIcon(),
    iconSize: [24, 24],
  });

  const addMarkerOnMap = (newMarker: Marker) => {
    if (mapStore.isPosition) {
      workPlacesStore.setPositionMode(newMarker);
      newMarker.remove();
    } else if (mapStore.isDuplicate) {
      workPlacesStore.setDuplicateMode(newMarker);
      newMarker.remove();
    } else {
      workPlacesStore.setCreatingMode(newMarker);
      newMarker.setIcon(icon);
    }
  };

  return <div />;
};

export default observer(DrawControl);
