import styled from 'styled-components/macro';
import { ToggleButton as MuiToggleButton } from '@mui/material';

export const ToggleButton = styled(MuiToggleButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  border-radius: 16px;
  height: 48px;
  background-size: cover;
  position: relative;
`;

export const Img = styled.img`
  position: absolute;
  border-radius: 16px;
`;

export const ActionLayer = styled.div<{ checked?: boolean }>`
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.primary.main};
  opacity: ${(props) => (props.checked ? 0.6 : 0.5)};
  position: absolute;
  width: 160px;
  height: 100%;
`;
