import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { rolesStore, usersStore } from 'stores';
import { DropDown, Input } from 'components';
import Loupe from 'components/Icons/Loupe';
import * as S from './UserFilters.styles';

const UserFilters = () => {
  const changeFilterName = (event: ChangeEvent<{ value: string }>) => {
    usersStore.filterState.setPattern(event.target.value);
  };

  const changeFilterRole = (event: ChangeEvent<{ value: string }>) => {
    if (usersStore.filterState.roleId === event.target.value) {
      usersStore.filterState.roleId = '';
    } else {
      usersStore.filterState.setRole(event.target.value);
    }
  };
  return (
    <S.SearchInputWrapper>
      <S.FormControl>
        <Input
          mb={4}
          name="search-fullName"
          placeholder="Поиск по ФИО..."
          value={usersStore.filterState.pattern}
          endIcon={<Loupe />}
          onChange={changeFilterName}
        />
      </S.FormControl>
      <S.FormControl>
        <DropDown
          mb={4}
          name="roleId"
          placeholder="Выберите роль"
          options={rolesStore.rolesForOptions}
          value={usersStore.filterState.roleId}
          onChange={changeFilterRole}
        />
      </S.FormControl>
    </S.SearchInputWrapper>
  );
};

export default observer(UserFilters);
