import { makeAutoObservable } from 'mobx';
import { IDepartmentListGetDto } from 'shared/interfaces/api';
import { IDropDownOption } from 'shared/interfaces/app';

class DepartmentListModel {
  constructor(dto?: IDepartmentListGetDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.departmentName = dto.departmentName;
    this.companyName = dto.companyName;
    this.isDeleted = dto.isDeleted;
  }

  public id = '';

  public departmentName = '';

  public companyName = '';

  public isDeleted = false;

  public getDropDownOption(): IDropDownOption {
    return { id: this.id, name: this.departmentName };
  }
}

export default DepartmentListModel;
