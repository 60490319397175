import React from 'react';
import { Chip, Typography } from '@mui/material';
import { Table } from '../../../../components';
import { Column, IPassesFotTable } from '../../../../shared/interfaces/app';
import { passesStore } from '../../../../stores';
import { observer } from 'mobx-react';
import ChipStatus from '../ChipStatus';

const PassesTablet = () => {
  const createRowData = () => {
    return passesStore.passesForTable.map((data: IPassesFotTable) => {
      const dates: JSX.Element = (
        <Typography>
          {data?.createdAt} <br />
          {data?.plannedAt}
        </Typography>
      );

      const name: JSX.Element = <Typography>{data?.fullUserName}</Typography>;
      const description: JSX.Element = <Typography>{data?.description}</Typography>;
      const chip = <ChipStatus value={data?.isConfirmed} />;

      return [...[dates], ...[name], ...[description], ...[chip]];
    });
  };

  const columns: Column[] = [
    {
      id: 'dates',
      label: 'Дата',
      minWidth: 130,
    },
    {
      id: 'user',
      label: 'ФИО',
      minWidth: 100,
      disabled: !passesStore.isViewAll,
    },
    {
      id: 'description',
      label: 'Описание',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Статус',
      minWidth: 100,
      width: 100,
    },
  ];

  return <Table columns={columns} rows={createRowData()} />;
};

export default observer(PassesTablet);
