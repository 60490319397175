import React from 'react';
import { ITag } from 'shared/interfaces/app';
import Tag from './Components';

import * as S from './TagImages.styles';

interface IToggleButtonProps {
  tags: ITag[];
  onChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  onTagChange: (e: any, value: any) => void;
}

const TagImages: React.FC<IToggleButtonProps> = (props) => {
  const { tags, onChange, onTagChange } = props;

  return (
    <S.ToggleButtonGroup exclusive onChange={onChange}>
      {tags.map((el: ITag) => {
        return <Tag key={el.value} selected={el.selected} value={el.value} label={el.label} onChange={onTagChange} />;
      })}
    </S.ToggleButtonGroup>
  );
};

export default TagImages;
