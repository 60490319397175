import React from 'react';
import { Square } from 'react-feather';
import CheckIcon from 'components/Icons/CheckIcon';
import { CheckboxProps, FormControlLabelProps } from '@mui/material';

import * as S from './Checkbox.styles';

type ShowAllWithTypography = CheckboxProps & FormControlLabelProps;

type ICheckboxProps = Omit<ShowAllWithTypography, 'control'>;

const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const { checked, onChange, checkedIcon, icon, label, ...rest } = props;

  return (
    <>
      {label ? (
        <S.FormControlLabel
          {...rest}
          control={
            <S.Checkbox
              {...rest}
              disableRipple
              checked={checked}
              onChange={onChange}
              checkedIcon={checkedIcon ? checkedIcon : <CheckIcon />}
              icon={icon ? icon : <Square />}
            />
          }
          label={label}
        />
      ) : (
        <S.Checkbox
          {...rest}
          disableRipple
          checked={checked}
          onChange={onChange}
          checkedIcon={checkedIcon ? checkedIcon : <CheckIcon />}
          icon={icon ? icon : <Square />}
        />
      )}
    </>
  );
};

export default Checkbox;
