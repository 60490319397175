import styled from 'styled-components/macro';

export const FiltersWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(4)};
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: ${(_) => _.theme.spacing(2)};
    margin-bottom: ${(_) => _.theme.spacing(2)};
  }

  ${(_) => _.theme.breakpoints.down('sm')} {
    margin-top: 0;
    display: flex;
    margin-bottom: 0;
  }
`;

export const DropDownWrapper = styled.div`
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-bottom: ${(props) => props.theme.spacing(1)};
    margin-right: 0;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const DataPickerWrapper = styled.div`
  & > div:not(:last-child) {
    margin-right: ${(_) => _.theme.spacing(2)};
    margin-bottom: ${(_) => _.theme.spacing(2)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;

    & > div {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    align-items: center;

    & > div {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }
`;
