import React from 'react';
import { observer } from 'mobx-react';
import { mapStore } from 'stores';
import { TagImages } from 'components';
import { FloorImageModel, FloorModel } from 'shared/models/MapModel';
import { useBreakpoint } from 'utils/hooks';
import { FloorsPanel } from '../index';
import * as S from './MapSettings.style';

const MapSettings = () => {
  const bp = useBreakpoint();
  const isMobile = bp === 'sm';

  const initMap = async () => {
    await mapStore.getFloorsList();
    mapStore.selectedFloor = new FloorModel(mapStore.floors[0]);
    await mapStore.getFloorImagesList();
    mapStore.selectedFloorImage = new FloorImageModel(mapStore.floorImages[0]);
    await mapStore.getFloorImageById();
  };

  const handleChangeFloorType = async (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value !== null) {
      mapStore.selectedFloorType = parseInt(value);

      mapStore.floorTypes.forEach((item) => {
        if (item.selected) {
          item.selected = false;
        }
      });

      const index = mapStore.floorTypes.findIndex((item) => item.value === value);
      if (index > -1) {
        mapStore.floorTypes[index].selected = true;
      }

      await initMap();
    }
  };

  return (
    <S.MapSettingsWrapper>
      <S.MapToolbar>
        <TagImages
          tags={mapStore.floorTypes}
          onChange={handleChangeFloorType}
          onTagChange={() => {
            //TODO: mikhail.broch: fix this shit
          }}
        />
      </S.MapToolbar>
      {isMobile ? <FloorsPanel /> : null}
    </S.MapSettingsWrapper>
  );
};

export default observer(MapSettings);
