import React, { useRef, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import { PlusSquare } from 'react-feather';

import * as Yup from 'yup';
import { passTemplatesStore, rolesStore } from 'stores';

import { Input } from 'components';
import Trash from 'components/Icons/Trash';
import { PASS_TEMPLATE_ID } from 'shared/constants';
import { Permissions } from 'shared/enums';
import { IPassTemplatesDialog } from 'shared/interfaces/app';
import { useBreakpoint } from 'utils/hooks';
import { Box, Typography, Button } from '@mui/material';
import * as S from './PassTemplatesForm.styles';

const PassTemplatesForm = () => {
  const [validated, setValidated] = useState(false);

  const bp = useBreakpoint();

  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';

  const formRef = useRef<FormikProps<IPassTemplatesDialog>>(null);

  const onSubmit = async (values: IPassTemplatesDialog) => {
    passTemplatesStore.selectedPassTemplate.updateFromDialogForm(values);

    await passTemplatesStore.putPassTemplate();
    await passTemplatesStore.getPassTemplate(PASS_TEMPLATE_ID);
  };

  const handleSubmit = () => {
    if (formRef.current) formRef.current.handleSubmit();
  };

  const allowChange = rolesStore.isAllow(Permissions.PassesConstructorUpdating);

  return (
    <>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={onSubmit}
        validateOnMount
        initialValues={{
          email: passTemplatesStore.selectedPassTemplate.email,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().max(255).email('Неправильный email').required(),
        })}>
        {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
          setValidated(isValid);

          return (
            <>
              <Input
                name="email"
                inputLabel="Электронная почта для получения пропусков"
                value={values.email}
                onChange={handleChange}
                readonly={!allowChange}
                onBlur={handleBlur}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </>
          );
        }}
      </Formik>

      <Typography sx={{ mb: 3 }} variant="h3">
        Поля пропуска
      </Typography>
      {passTemplatesStore.selectedPassTemplate.fields.map((field, index) => {
        return (
          <S.CustomContent key={index}>
            <Box sx={{ width: '100%' }}>
              <Input
                mb={0}
                name={field.name}
                placeholder={field.name}
                readonly={!allowChange}
                value={field.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => (field.name = event.currentTarget.value)}
              />
            </Box>
            {allowChange && (
              <S.DropField onClick={() => passTemplatesStore.selectedPassTemplate.handleDeleteField(index)}>
                <Trash />
                {isMobile || isTablet ? null : (
                  <Typography sx={{ mt: 1, ml: 1 }} variant="body2">
                    Удалить
                  </Typography>
                )}
              </S.DropField>
            )}
          </S.CustomContent>
        );
      })}

      {allowChange && (
        <>
          <S.AddButton
            sx={{
              color: 'secondary.main',
              justifyContent: 'flex-start',
              m: 0,
              p: 0,
              width: '250px',
              bgcolor: '#fff',
              ':hover': {
                bgcolor: '#fff',
              },
            }}
            startIcon={<PlusSquare style={{ marginRight: 12 }} />}
            onClick={passTemplatesStore.selectedPassTemplate.handleAddField}>
            Добавить поле
          </S.AddButton>

          <Button disabled={!validated} size="large" variant="contained" color="secondary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </>
      )}
    </>
  );
};

export default observer(PassTemplatesForm);
