import styled from 'styled-components/macro';
import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel } from '@mui/material';

export const Checkbox = styled(MuiCheckbox)`
  height: ${(props) => props.theme.spacing(5)};
  width: ${(props) => props.theme.spacing(5)};
  margin-right: ${(props) => props.theme.spacing(3)};
  padding: 0;
  border-radius: ${(props) => props.theme.spacing(1)};

  svg {
    rect {
      height: ${(props) => props.theme.spacing(5)};
      width: ${(props) => props.theme.spacing(5)};
    }
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  display: flex;
  align-items: center;
  margin: 0;
`;
