import styled from 'styled-components/macro';
import { ListItem, ListItemText } from '@mui/material';

type CategoryType = {
  activeClassName?: string;
  onClick?: () => void;
  to?: string;
  component?: any;
  exact?: boolean;
};

export const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-bottom: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(5)};

  svg {
    color: ${(props) => props.theme.palette.common.white};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const CategoryCollapse = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;

  display: flex;
  flex-direction: column;

  svg {
    color: ${(props) => props.theme.palette.common.white};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const CategoryText = styled(ListItemText)`
  margin: 0;
  padding-left: ${(props) => props.theme.spacing(3)};
`;

export const CategoryCollapseText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.palette.common.white};
    font-size: 10px;

    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;
