import styled from 'styled-components/macro';
import { Dialog } from '@mui/material';

export const QrDialogWrapper = styled(Dialog)`
  ${(props) => props.theme.breakpoints.down('sm')} {
    & .MuiPaper-root {
      height: 100%;
      overflow-y: unset;
      margin: ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(3)}
        ${(props) => props.theme.spacing(2)};
    }

    & .MuiDialog-container {
      //align-items: stretch;
    }
  }
  min-width: 95%;
  height: 95%;
`;
