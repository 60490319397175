import React from 'react';

const XCircle: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00004 14.6668C11.6819 14.6668 14.6667 11.6821 14.6667 8.00016C14.6667 4.31826 11.6819 1.3335 8.00004 1.3335C4.31814 1.3335 1.33337 4.31826 1.33337 8.00016C1.33337 11.6821 4.31814 14.6668 8.00004 14.6668ZM5.52864 10.4716C5.26829 10.2112 5.26829 9.78911 5.52864 9.52876L7.05725 8.00014L5.5287 6.47159C5.26835 6.21124 5.26835 5.78913 5.5287 5.52878C5.78905 5.26843 6.21116 5.26843 6.47151 5.52878L8.00006 7.05733L9.52864 5.52876C9.78899 5.26841 10.2111 5.26841 10.4714 5.52876C10.7318 5.78911 10.7318 6.21122 10.4714 6.47157L8.94287 8.00014L10.4715 9.52879C10.7319 9.78914 10.7319 10.2112 10.4715 10.4716C10.2112 10.7319 9.78905 10.7319 9.5287 10.4716L8.00006 8.94295L6.47145 10.4716C6.2111 10.7319 5.78899 10.7319 5.52864 10.4716Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default XCircle;
