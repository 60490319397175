import React, { useEffect, useRef, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { departmentsStore, rolesStore, usersStore, userAuthStore } from 'stores';
import { Checkbox, DropDown, Input, ModalDialog } from 'components';
import { DEFAULT_COMPANY_ID } from 'shared/constants';
import { DialogStatus } from 'shared/enums';
import { IUserDialog } from 'shared/interfaces/app';
import { Permissions } from 'shared/enums';

import { Box } from '@mui/material';
import * as S from './UserDialog.styles';

const PHONE_REG_EXP = /^\+\d\s\(\d{3}\)\s\d{3}\-\d{2}\-\d{2}$/;

const UserDialog: React.FC = () => {
  const [validated, setValidated] = useState(false);
  const formRef = useRef<FormikProps<IUserDialog>>(null);
  const dialogTitle = usersStore.selectedUser.id ? 'Редактировать пользователя' : 'Создать пользователя';
  const createOrEditOrDeleteUserAllow =
    rolesStore.isAllow(Permissions.UserCreating) || rolesStore.isAllow(Permissions.UserRemoving) || rolesStore.isAllow(Permissions.UserUpdating);
  const isAdminCheckboxDisabled =
    (usersStore.selectedUser.id === userAuthStore.userId && userAuthStore.isAdmin) || (!userAuthStore.isAdmin && createOrEditOrDeleteUserAllow);

  useEffect(() => {
    departmentsStore.getDepartmentsList();
  }, []);

  const onSubmit = async (values: IUserDialog) => {
    usersStore.selectedUser.updateUserDialogForm(values);

    if (usersStore.dialogViewMode === DialogStatus.Creating) {
      await usersStore.createUser();
    } else if (usersStore.dialogViewMode === DialogStatus.Editing) {
      await usersStore.updateUser();
    }

    usersStore.closeUserDialog();
    await usersStore.getUsersList();
  };

  const userForm: JSX.Element = (
    <Formik
      enableReinitialize={true}
      innerRef={formRef}
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        email: usersStore.selectedUser.email,
        firstName: usersStore.selectedUser.firstName,
        lastName: usersStore.selectedUser.lastName,
        patronymic: usersStore.selectedUser.patronymic,
        roleId: usersStore.selectedUser.roleId,
        departmentId: usersStore.selectedUser.departmentId,
        subCompanyName: usersStore.selectedUser.subCompanyName,
        phone: usersStore.selectedUser.phone,
        position: usersStore.selectedUser.position,
        isAdmin: usersStore.selectedUser.isAdmin,
        departmentsOpt: departmentsStore.departmentsForOptions,
        rolesOpt: rolesStore.rolesForOptions,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Неверный формат email').max(255).required('Обязательное поле'),
        firstName: Yup.string().max(255).required('Обязательное поле'),
        lastName: Yup.string().max(255).required('Обязательное поле'),
        patronymic: Yup.string().max(255),
        phone: Yup.string().matches(PHONE_REG_EXP, 'Вы ввели недопустимые символы').required('Обязательное поле'),
      })}>
      {({ handleChange, handleBlur, values, isValid, errors, touched, setFieldValue }) => {
        setValidated(isValid);

        return (
          <S.UserDialogWrapper>
            <Input
              mb={5}
              name="lastName"
              inputLabel="Фамилия *"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />

            <Input
              mb={5}
              name="firstName"
              inputLabel="Имя *"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <Input
              mb={5}
              name="patronymic"
              inputLabel="Отчество"
              value={values.patronymic}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.patronymic && errors.patronymic)}
              helperText={touched.patronymic && errors.patronymic}
            />

            <Input
              mb={5}
              name="position"
              inputLabel="Должность"
              value={values.position}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.position && errors.position)}
              helperText={touched.position && errors.position}
            />

            <S.ContactField>
              <NumberFormat
                customInput={Input}
                inputLabel="Номер телефона *"
                name="phone"
                type="tel"
                format="+7 (###) ###-##-##"
                allowEmptyFormatting
                mask="_"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />

              <Input
                name="email"
                inputLabel="Электронная почта *"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </S.ContactField>

            <Box sx={{ mb: 5 }}>
              <Checkbox
                label="Администратор"
                disabled={isAdminCheckboxDisabled}
                checked={values.isAdmin}
                onChange={() => setFieldValue('isAdmin', !values.isAdmin)}
              />
            </Box>

            <Input
              mb={4}
              name="subCompanyName"
              inputLabel="Подкомпания"
              disabled={values.isAdmin}
              placeholder="Введите подкомпанию"
              value={values.subCompanyName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <DropDown
              mb={5}
              dropDownLabel="Принадлежность к отделу"
              name="departmentId"
              placeholder="Выберите отдел"
              disabled={values.isAdmin}
              options={values.departmentsOpt}
              value={values.departmentId}
              onChange={handleChange}
            />

            <DropDown
              mb={5}
              disabled={values.isAdmin}
              dropDownLabel="Роль"
              placeholder="Выберите роль"
              name="roleId"
              options={values.rolesOpt}
              value={values.roleId}
              onChange={handleChange}
            />
          </S.UserDialogWrapper>
        );
      }}
    </Formik>
  );

  const handleCloseDialog = () => {
    usersStore.closeUserDialog();
    departmentsStore.clear();
  };

  return (
    <ModalDialog
      isModelHasId={usersStore.selectedUser.id}
      title={dialogTitle}
      isValid={validated}
      isOpen={usersStore.dialogViewMode !== DialogStatus.Closed}
      onClose={handleCloseDialog}
      onCancel={handleCloseDialog}
      onSubmit={() => {
        if (formRef.current) formRef.current.handleSubmit();
      }}>
      {userForm}
    </ModalDialog>
  );
};

export default observer(UserDialog);
