import React from 'react';
import { observer } from 'mobx-react';
import { workPlacesStore } from 'stores';
import WorkPlaceAvailableBooking from './WorkPlaceAvailableBooking';

const WorkPlacesAvailableBooking: React.FC = () => {
  return (
    <>
      {workPlacesStore.workPlacesAvailableBooking.map((_) => (
        <WorkPlaceAvailableBooking key={_.id} mapObject={_} />
      ))}
    </>
  );
};

export default observer(WorkPlacesAvailableBooking);
