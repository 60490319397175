import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { calendarStore, reservationStore } from 'stores';
import { ContextMenu } from 'components';
import DangerCircle from 'components/Icons/DangerCircle';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import QrIcon from 'components/Icons/QrIcon';
import TickSquare from 'components/Icons/TickSquare';
import Trash from 'components/Icons/Trash';

import { CalendarViewMode } from 'shared/enums';
import { Button, Divider, IconButton, Typography } from '@mui/material';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useBreakpoint } from 'utils/hooks';
import * as S from './EventCard.styles';

const EventCard = (props: any) => {
  const bp = useBreakpoint();

  const [isOpen, setOpen] = useState(false);

  const isMobile = bp === 'sm';
  const isTablet = bp === 'md';

  const viewData = calendarStore.getEventParams(props.event.id);

  const onCancelClick = () => {
    if (!viewData) return;

    reservationStore.cancelDialog(viewData.getDto()).then(() => calendarStore.getCalendarViewData());
  };

  const onConfirmClick = () => {
    if (!viewData) return;

    reservationStore.confirmDialog(viewData.getDto()).then(() => reservationStore.get(props.event.id));
  };

  const onConfirmQrClick = () => {
    if (!viewData) return;

    reservationStore.confirmQrDialog();
  };

  const onEditClick = () => {
    if (!viewData) return;

    reservationStore.editDialog(props.event.id).finally();
  };

  const onEditWeekClick = () => {
    if (!viewData) return;

    reservationStore.editDialog(reservationStore.reservation!.id!).finally();
  };

  const dayMenuItems = [<></>];

  const buttons = calendarStore.showActionButton
    ? reservationStore.getActiveButtons(props.event.status, props.event.userId)
    : reservationStore.getActiveButtons();

  const weekMenuItems = [];

  if (buttons.accept) {
    weekMenuItems.push(
      <S.BtnWrapper key="1">
        <Button color="secondary" fullWidth variant="contained" onClick={onConfirmClick}>
          Подтвердить
        </Button>
      </S.BtnWrapper>
    );
  }

  if (buttons.acceptQr) {
    weekMenuItems.push(
      <S.BtnWrapper key="2">
        <Button color="secondary" fullWidth variant="contained" onClick={onConfirmQrClick}>
          Подтвердить по QR
        </Button>
      </S.BtnWrapper>
    );
  }

  if (buttons.edit) {
    weekMenuItems.push(
      <S.BtnWrapper key="3">
        <Button color="primary" fullWidth variant="outlined" onClick={onEditWeekClick}>
          Редактировать
        </Button>
      </S.BtnWrapper>
    );
  }
  if (buttons.cancel) {
    weekMenuItems.push(
      <S.BtnWrapper key="4">
        <Button color="primary" fullWidth variant="outlined" onClick={onCancelClick}>
          Отменить
        </Button>
      </S.BtnWrapper>
    );
  }

  const dayViewModeActions = (
    <S.CardActions>
      {calendarStore.showActionButton && (
        <IconButton
          onClick={(e: any) => {
            calendarStore.setAnchorEl(e, props.event.id);
            setOpen(true);
          }}>
          <DangerCircle />
        </IconButton>
      )}

      {buttons.accept && (
        <IconButton onClick={onConfirmClick}>
          <TickSquare />
        </IconButton>
      )}

      {buttons.acceptQr && (
        <IconButton onClick={onConfirmQrClick}>
          <QrIcon />
        </IconButton>
      )}

      {buttons.edit && (
        <IconButton onClick={onEditClick}>
          <EditPencilIcon />
        </IconButton>
      )}

      {buttons.cancel && (
        <IconButton onClick={onCancelClick}>
          <Trash />
        </IconButton>
      )}
    </S.CardActions>
  );

  const weekViewModeActions = calendarStore.showActionButton && (
    <IconButton
      onClick={(e: any) => {
        calendarStore.setAnchorEl(e, props.event.id);
        setOpen(true);
      }}>
      <DangerCircle />
    </IconButton>
  );

  const data = reservationStore.reservation;
  const widthOffset = calendarStore.viewPeriodMode === CalendarViewMode.Day ? 10 : 0;
  const isMobileOrTablet = isMobile || isTablet;
  const actionsSet = calendarStore.viewPeriodMode === CalendarViewMode.Week ? weekViewModeActions : dayViewModeActions;

  return (
    <S.CardContainer
      widthInPercents={viewData?.widthInPercents ?? 100}
      widthOffset={widthOffset}
      height={props.style?.height ?? 100}
      style={{
        position: 'absolute',
        top: `${props.style?.top}%`,
        left: `${viewData?.offsetInPercents}%`,
      }}>
      <S.CardStatusLine viewMode={calendarStore.viewPeriodMode} isActive={props.event.isMy} isMobileOrTablet={isMobileOrTablet} />
      <S.CardHeader>
        <S.CardHolderName viewMode={calendarStore.viewPeriodMode}>{props.event.userName}</S.CardHolderName>
        <S.CardTitleRow isMobileOrTablet={isMobileOrTablet} viewMode={calendarStore.viewPeriodMode}>
          {actionsSet}
        </S.CardTitleRow>
      </S.CardHeader>

      {calendarStore.showActionButton && (
        <ContextMenu
          maxWidth={'500px'}
          anchorEl={calendarStore.anchorEl}
          open={isOpen}
          onClose={() => {
            setOpen(false);
            calendarStore.hideInfo();
          }}
          menuItems={calendarStore.viewPeriodMode === CalendarViewMode.Day ? dayMenuItems : weekMenuItems}>
          <S.InfoWrapper>
            <Typography sx={{ mb: 2 }} variant="body2">
              Забронировано
            </Typography>

            <Typography sx={{ display: 'block', mt: 2, color: 'text.secondary' }} variant="caption">
              {data?.getTime}
            </Typography>

            <Divider sx={{ height: 14 }} orientation="horizontal" />

            {data && data.userId && (
              <>
                <div style={{ fontWeight: 'bold' }}>{data.fullUserName}</div>
                {!!data.userDetails && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <span style={{ width: '130px' }}>E-Mail:</span>
                      <span>{data.userDetails.email}</span>
                    </div>
                    {data.userDetails.phone && (
                      <div style={{ display: 'flex' }}>
                        <span style={{ width: '130px' }}>Телефон:</span>
                        <span>{data.userDetails.phone}</span>
                      </div>
                    )}
                    {data.userDetails.position && (
                      <div style={{ display: 'flex' }}>
                        <span style={{ width: '130px' }}>Должность:</span>
                        <span>{data.userDetails.position}</span>
                      </div>
                    )}
                    {data.userDetails.subCompanyName && (
                      <div style={{ display: 'flex' }}>
                        <span style={{ width: '130px' }}>Подразделение:</span>
                        <span>{data.userDetails.subCompanyName}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </S.InfoWrapper>
        </ContextMenu>
      )}
    </S.CardContainer>
  );
};

export default observer(EventCard);
