import React, { useEffect, useRef, useState } from 'react';

import { Formik, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { userAuthStore } from 'stores';
import { Input } from 'components';
import SuccessCircle from 'components/Icons/SuccessCircle';
import { IResetPassForm } from 'shared/interfaces/app';
import { Button, Typography } from '@mui/material';

import * as S from './ResetPassword.styles';

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const formRef = useRef<FormikProps<IResetPassForm>>(null);

  useEffect(() => {
    return () => {
      userAuthStore.isSuccessResetPass = false;
      userAuthStore.mailForResetPassword = '';
    };
  }, []);

  const onSubmit = (values: IResetPassForm) => {
    userAuthStore.updateFromResetPasswordForm(values);
    userAuthStore.resetPassword().finally();
  };

  const FormTitle = userAuthStore.isSuccessResetPass ? 'Временный пароль восстановлен' : 'Восстановление пароля';
  const FormSubTitle = userAuthStore.isSuccessResetPass
    ? `На почту ${userAuthStore.mailForResetPassword} был отправлен временный пароль и инструкция о дальнейших действиях`
    : ' На адрес почты будет отправлен временный пароль';

  return (
    <>
      {userAuthStore.isSuccessResetPass && <SuccessCircle />}

      <Typography sx={{ mb: 2 }} variant="h2" align="center">
        {FormTitle}
      </Typography>

      <Typography sx={{ mb: 5, color: 'text.secondary', height: '24px' }} variant="body1" align="center">
        {FormSubTitle}
      </Typography>

      {!userAuthStore.isSuccessResetPass && (
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          onSubmit={onSubmit}
          validateOnMount
          initialValues={{
            email: userAuthStore.mailForResetPassword,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Неверный формат email').max(255).required('Обязательное поле'),
          })}>
          {({ handleChange, handleBlur, values, isValid, errors, touched }) => {
            setValidated(isValid);
            return (
              <S.Content>
                <Input
                  name="email"
                  type="email"
                  inputLabel="Введите email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </S.Content>
            );
          }}
        </Formik>
      )}

      {!userAuthStore.isSuccessResetPass ? (
        <>
          <Button
            fullWidth
            disabled={!validated}
            sx={{ mb: 4 }}
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => {
              if (formRef.current) formRef.current.handleSubmit();
            }}>
            Восстановить пароль
          </Button>
          <Button fullWidth variant="outlined" size="large" color="primary" onClick={() => history.push('/auth/sign-in')}>
            Закрыть
          </Button>
        </>
      ) : (
        <Button fullWidth sx={{ mt: 4 }} size="large" variant="contained" color="secondary" onClick={() => history.push('/auth/sign-in')}>
          Понятно
        </Button>
      )}
    </>
  );
};

export default observer(ResetPassword);
