import { useEffect } from 'react';
import userAuthStore from 'stores/userAuthStore';

const useInitializeMe = (): void => {
  useEffect(() => {
    (async () => userAuthStore.initializeMe())();
  }, []);
};

export default useInitializeMe;
