import styled from 'styled-components/macro';
import { TextField as MuiTextField, Typography, List, ListItem } from '@mui/material';

export const ColorPickerListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

export const ColorPickerButton = styled.button`
  width: ${(props) => props.theme.spacing(6)};
  height: ${(props) => props.theme.spacing(6)};
  margin-right: ${(props) => props.theme.spacing(4)};
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;

export const ColorPickerTitle = styled(Typography)``;

export const ColorPickerLabel = styled(Typography)`
  // max-width: 140px;
`;

export const ColorPickerList = styled(List)`
  padding: 0;
  margin-right: ${(props) => props.theme.spacing(4)};
`;

export const ColorPickerListItem = styled(ListItem)`
  &.MuiListItem-root {
    padding: ${(props) => props.theme.spacing(2)} 0;
    margin-right: ${(props) => props.theme.spacing(4)};
  }
`;
