import { makeAutoObservable } from 'mobx';
import { ZonesApi } from 'api';
import { DialogStatus } from 'shared/enums';
import { IDropDownOption, IZonesTableRow } from 'shared/interfaces/app';
import { ZoneModel, ZoneListModel } from 'shared/models';

class ZonesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public zones: ZoneListModel[] = [];

  public selectedZone = new ZoneModel();

  public dialogViewMode = DialogStatus.Closed;

  public isFetching = false;

  public get zonesForTable(): IZonesTableRow[] {
    const zonesTaleData = this.zones.map(({ id, name, workplacesCount, roles, departments }) => {
      return { id, name, workplacesCount, roles, departments };
    });

    return zonesTaleData;
  }

  public get zonesForOptions(): IDropDownOption[] {
    return this.zones.map((item) => item.getDropDownOption());
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public async setCreatingMode() {
    this.selectedZone = new ZoneModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public async setEditingMode(zoneId: string) {
    await this.getZoneById(zoneId);
    this.setDialogViewMode(DialogStatus.Editing);
  }

  public async getZonesList() {
    try {
      this.isFetching = true;
      const zones = await ZonesApi.getZones();
      if (!zones) return;
      this.zones = zones.map((item) => new ZoneListModel(item));
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async getZoneById(zoneId: string) {
    try {
      this.isFetching = true;
      const zone = await ZonesApi.getZone(zoneId);
      if (!zone) return;
      this.selectedZone = new ZoneModel(zone);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async createZone(): Promise<void> {
    if (this.selectedZone.postPutDto === null) return;
    try {
      this.isFetching = true;
      const newZone = await ZonesApi.postZone(this.selectedZone.postPutDto);
      if (!newZone) return;
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updateZone(): Promise<void> {
    if (this.selectedZone.postPutDto === null || this.selectedZone.id === null) return;
    try {
      this.isFetching = true;
      await ZonesApi.putZone(this.selectedZone.id, this.selectedZone.postPutDto);
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updateWorkPlaceZones(workplacesIds: string[], zonesIds: string[]): Promise<void> {
    try {
      this.isFetching = true;
      await ZonesApi.postUpdateWorkPlaces({ workplacesIds, zonesIds });
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async removeZone(zoneId?: string): Promise<void> {
    const id = zoneId ? zoneId : this.selectedZone.id;
    try {
      this.isFetching = true;
      await ZonesApi.deleteZone(id);
      this.getZonesList();
    } catch (e) {
      //ignore
    } finally {
      this.isFetching = false;
    }
  }
}

export default new ZonesStore();
