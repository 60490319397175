import React from 'react';
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { AdminLayout } from 'pages/Layouts';
import AuthLayout from 'pages/Layouts/AuthLayout';
import MainLayout from 'pages/Layouts/MainLayout';
import ReservationLayout from 'pages/Layouts/ReservationLayout';
import { ChildElementType, RouteInfoType } from 'shared/interfaces/app';
import { useInitializeMe } from 'utils/hooks';

import {
  main as mainRoutes,
  auth as authRoutes,
  admin as adminRoutes,
  reservation as reservationRoute,
  confirmPass,
  rejectPass,
  passesSingle,
} from './index';

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map((itemRout: RouteInfoType, index: number) => {
    const { component: Component, guard, children, path } = itemRout;
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element: ChildElementType) => {
        const GuardChildren = element.guard || React.Fragment;
        const ElementComponent = element.component || React.Fragment;

        return (
          <Route
            key={element.path}
            path={element.path}
            exact
            render={(props: RouteComponentProps) => (
              <Layout>
                <GuardChildren>
                  <ElementComponent {...props} />
                </GuardChildren>
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props: RouteComponentProps) => (
          <Layout>
            <Guard>
              <Component {...props} />
            </Guard>
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => {
  useInitializeMe();

  return (
    <BrowserRouter>
      <Switch>
        {/* Подтверждение пропуска */}
        <Route
          key={confirmPass.path}
          path={confirmPass.path}
          exact
          render={() => {
            const Component = confirmPass.component!;
            return <Component />;
          }}
        />

        {/* Отклонение пропуска */}
        <Route
          key={rejectPass.path}
          path={rejectPass.path}
          exact
          render={() => {
            const Component = rejectPass.component!;
            return <Component />;
          }}
        />

        {/* Одиночный календарь объекта */}
        <Route
          key={passesSingle.path}
          path={passesSingle.path}
          exact
          render={() => {
            const Component = passesSingle.component!;
            return <Component />;
          }}
        />

        {childRoutes(AuthLayout, authRoutes)}

        {childRoutes(MainLayout, mainRoutes)}

        {childRoutes(AdminLayout, adminRoutes)}

        {childRoutes(ReservationLayout, [reservationRoute])}

        {/* Если бракованный */}
        <Route
          render={() => {
            const Component = mainRoutes[0].component!;
            return (
              <MainLayout>
                <Component />
              </MainLayout>
            );
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
