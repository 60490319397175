import { DateRange } from '@mui/lab';
import { makeAutoObservable } from 'mobx';
import { IMessagesQuery } from 'shared/interfaces/app';
import { getDateLastWeek, getEndOfDay } from 'utils/DateTimeUtils';

class ReservationQueryModel {
  constructor(dto?: IMessagesQuery) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.pageIndex = dto.pageIndex;
    this.pageSize = dto.pageSize;
    this.from = dto.from;
    this.to = dto.to;
    this.userId = dto.userId;
  }

  public pageIndex = 0;
  public pageSize = 6;
  public from: Date | null = ReservationQueryModel.queryDateRange[0];
  public to: Date | null = ReservationQueryModel.queryDateRange[1];
  public userId = '';

  private static get queryDateRange(): DateRange<Date> {
    return [getDateLastWeek(new Date()), getEndOfDay()];
  }
}

export default ReservationQueryModel;
