import styled from 'styled-components/macro';
import { Button as MuiButton, Box as MuiBox, TextField } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  margin-left: ${(props) => props.theme.spacing(10)};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchInput = styled(TextField)`
  width: 350px;
  height: 48px;
`;

export const Button = styled(MuiButton)`
  margin: ${(props) => props.theme.spacing(6)};
  color: grey;
  border-color: grey;
`;

export const Box = styled(MuiBox)`
  ${(props) => props.theme.breakpoints.between('xs', 'md')} {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  width: 528px;
  & > div:first-child {
    margin-bottom: ${(props) => props.theme.spacing(5)};
  }
`;
