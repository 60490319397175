import { SyntheticEvent } from 'react';
import { makeAutoObservable } from 'mobx';
import { userAuthStore, usersStore, workPlacesStore } from 'stores';
import { DEFAULT_COMPANY_ID, MESSAGES_WP_RECEIVERS_ID, COMPANY_OPTION_ID, DEPARTMENT_OPTION_ID } from 'shared/constants';
import { MessagesTypes, WorkPlaceType } from 'shared/enums';
import { IMessagesPostDto, IMessagesRow } from 'shared/interfaces/api';
import { IMessagesDialog, IMessagesSelect } from 'shared/interfaces/app';

class MessagesModel {
  constructor(dto?: IMessagesRow) {
    makeAutoObservable(this, undefined, { autoBind: true });

    if (!dto) return;

    this.id = dto.id;
    this.createdAt = dto.createdAt;
    this.content = dto.content;
    this.caption = dto.caption;
    this.isRead = dto.isRead;
    this.userSenderId = dto.userSenderId;
    this.userSenderName = dto.userSenderName;
    this.type = dto.type;
    this.receiverId = dto.receiverId;
    this.userReceiverName = dto.userReceiverName;
  }

  public id = '';
  public createdAt: Date | null = null;
  public content = '';
  public caption = '';
  public isRead = false;
  public userSenderName = '';
  public userReceiverName = '';
  public receiverId = '';
  public userSenderId = '';
  public type = MessagesTypes.Company;
  public inputFullNameValue = '';

  public get fullNameValue(): string {
    if (!this.inputFullNameValue && this.receiverId) {
      return this.receivers.find(({ id }) => id === this.receiverId)?.fullName ?? '';
    }
    return this.inputFullNameValue;
  }

  public get messagePostDto(): IMessagesPostDto {
    return {
      content: this.content,
      caption: this.caption,
      userSenderId: userAuthStore.userId,
      receiverId: this.receiverId,
      type: this.type,
    };
  }

  public get selectedOption(): IMessagesSelect | null {
    return this.receivers.find(({ fullName }) => fullName === this.inputFullNameValue) ?? null;
  }

  public get receivers(): IMessagesSelect[] {
    if (usersStore.users.length === 0) usersStore.getUsersList();

    const receivers = usersStore.usersForSelect;

    if (userAuthStore.companyName) {
      const company = {
        id: userAuthStore.companyId,
        fullName: userAuthStore.companyName,
        type: MessagesTypes.Company,
      };
      receivers.unshift(company);
    }

    if (userAuthStore.departmentName) {
      const department = {
        id: userAuthStore.departmentId,
        fullName: userAuthStore.departmentName,
        type: MessagesTypes.Department,
      };
      receivers.unshift(department);
    }

    return receivers;
  }

  public handleSelectByFullName(event: SyntheticEvent<Element, Event>, value: string) {
    this.inputFullNameValue = value;
  }

  public sortedFullUserNames(): string[] {
    return this.receivers
      .slice()
      .sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }
        return 0;
      })
      .map(({ fullName }) => fullName);
  }

  public updateFromDialogForm(model: IMessagesDialog) {
    this.caption = model.caption;
    this.content = model.content;

    if (model.userId === COMPANY_OPTION_ID) {
      //Если выбрали из дропдауна значение "Всем пользователям", то подставляем значение компании
      this.type = MessagesTypes.Company;
      this.receiverId = DEFAULT_COMPANY_ID;
    } else if (model.userId === DEPARTMENT_OPTION_ID) {
      //Если выбрали из дропдауна значение "Отделу", то подставляем значение отдела текущего пользователя
      this.type = MessagesTypes.Department;
      this.receiverId = userAuthStore.departmentId;
    } else if (!model.userId) {
      //Значение пользователя не задаётся, когда сообщение отправляется по рабочему месту всем админам
      this.type = MessagesTypes.RB;
      this.receiverId = MESSAGES_WP_RECEIVERS_ID;
    } else {
      this.type = MessagesTypes.Private;
      this.receiverId = model.userId;
    }
  }

  public updateContent(newContent: string) {
    this.content = newContent;
  }

  public clear() {
    this.id = '';
    this.createdAt = null;
    this.content = '';
    this.caption = '';
    this.isRead = false;
    this.userSenderId = '';
    this.userSenderName = '';
    this.receiverId = '';
    this.type = MessagesTypes.Company;
    this.inputFullNameValue = '';
  }
}

export default MessagesModel;
