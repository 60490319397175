import styled from 'styled-components/macro';
import { MenuItem as MuiMenuItem, Select as MuiSelect, ListSubheader as MuiListSubheader, FormControl as MuiFormControl } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .MuiFormControl-root {
    margin-bottom: 0;
  }
`;

export const ListSubheader = styled(MuiListSubheader)`
  padding-top: ${(props) => props.theme.spacing(3)};
  padding-right: ${(props) => props.theme.spacing(3)};
  padding-left: ${(props) => props.theme.spacing(3)};
  height: 100%;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const MenuItem = muiStyled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#F2F5F7',
  },
}));

export const MenuItemNotSelect = muiStyled(MuiMenuItem)(({ theme }) => ({
  fontStyle: 'italic',
  opacity: 0.8,
  padding: theme.spacing(3),
  '&:hover': {
    backgroundColor: '#F2F5F7',
  },
}));

export const FormControl = muiStyled(MuiFormControl)(({ theme, mb }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  width: '100%',
  marginBottom: theme.spacing(mb),
}));

export const Select = muiStyled(MuiSelect)(({}) => ({
  height: '48px',
  minWidth: '200px',
  '&.Mui-focused': {
    '&.MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
      borderColor: '#fff',
    },
  },
}));

export const MenuItemRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;
