import React from 'react';
import { makeAutoObservable } from 'mobx';
import { appStore } from 'stores';
import { CompaniesApi } from 'api';

import { DEFAULT_COMPANY_ID, EMPTY_GUID, THEMES } from 'shared/constants';
import { getBase64Image, ImageFormat } from 'shared/constants/fileFormats';
import { DialogStatus } from 'shared/enums';
import { THEME_TYPE } from 'shared/interfaces/api';
import { ICompaniesFotTable, IDropDownOption, ICompanyDialog } from 'shared/interfaces/app';
import CompaniesModel from 'shared/models/CompaniesModel';
import { convertBase64ToFile } from 'utils/FileUtils';

class CompaniesStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public companies: CompaniesModel[] = [];

  public selectedCompany = new CompaniesModel();

  public isFetching = false;

  public dialogViewMode = DialogStatus.Closed;

  public searchInputValue = '';

  public get companiesForTable(): ICompaniesFotTable[] {
    const companiesData = this.companies.map(({ id, name, themeType, departmentsCount = 0 }) => {
      return {
        id,
        name,
        themeType: themeType === THEME_TYPE.DEFAULT ? 'По умолчанию' : 'Настраиваемая',
        departmentsCount,
      };
    });

    if (this.searchInputValue.length > 0) {
      return companiesData.filter(({ name }) => name?.search(this.searchInputValue) !== -1);
    }

    return companiesData;
  }

  public get companiesForOptions(): IDropDownOption[] {
    return this.companies.map((item) => item.getDropDownOption());
  }

  public get companyThemeColors() {
    return {
      common: {
        white: this.selectedCompany.themeData?.fontColor.inverted,
      },
      primary: {
        main: this.selectedCompany.themeData?.mainColor.primary,
        disabled: '#003A5D33',
      },
      secondary: {
        main: this.selectedCompany.themeData?.mainColor.secondary,
      },
      error: {
        light: this.selectedCompany.themeData?.statusColor.error,
        main: '#CE5148',
      },
      warning: {
        main: this.selectedCompany.themeData?.statusColor.warning,
      },
      success: {
        main: this.selectedCompany.themeData?.statusColor.success,
      },
      info: {
        main: 'rgba(0, 58, 93, 0.3)',
      },
      grey: {
        50: '#B3C4CE',
      },
      background: {
        default: '#F7F9FC',
        paper: this.selectedCompany.themeData?.backgroundColor.secondary,
      },
      text: {
        primary: this.selectedCompany.themeData?.fontColor.primary,
        secondary: this.selectedCompany.themeData?.fontColor.secondary,
        disabled: 'rgba(0, 58, 93, 0.4)',
      },
      divider: '#E4E9EC',
      icons: {
        stroke: '#B3C4CE',
      },
    };
  }

  public handleCompaniesFilter(event: React.SyntheticEvent, value: string) {
    this.searchInputValue = value;
  }

  public setDialogViewMode(newDialogViewMode: DialogStatus) {
    this.dialogViewMode = newDialogViewMode;
  }

  public setCreatingMode() {
    this.selectedCompany = new CompaniesModel();
    this.setDialogViewMode(DialogStatus.Creating);
  }

  public async setEditingMode(companyId: string) {
    await this.getCompany(companyId);
    this.setDialogViewMode(DialogStatus.Editing);
  }

  public async setDeletingMode(companyId: string) {
    await this.getCompany(companyId);
    this.setDialogViewMode(DialogStatus.Deleting);
  }

  public closeCompanyDialog() {
    this.setDialogViewMode(DialogStatus.Closed);
  }

  public sortedCompanies(): string[] {
    return this.companies
      .slice()
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map(({ name }) => name);
  }

  public selectedOption(): CompaniesModel | null {
    return this.companies.find(({ name }) => name === this.searchInputValue) ?? null;
  }

  public async getCompanies() {
    try {
      this.isFetching = false;

      const companies = await CompaniesApi.getCompanies();

      if (!companies) return;

      this.companies = companies.map((company) => {
        return new CompaniesModel(company);
      });
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = true;
    }
  }

  public async getCompany(companyId: string) {
    if (!companyId) return;
    if (companyId === EMPTY_GUID) return;

    try {
      this.isFetching = true;

      const company = await CompaniesApi.getCompany(companyId);

      if (!company) return;

      this.selectedCompany = new CompaniesModel({
        id: company.id,
        name: company.name,
        themeData: JSON.parse(company.themeData),
        themeType: company.themeType,
        logoImage: company.logoImageBase64 ? convertBase64ToFile(getBase64Image(ImageFormat.SVG, company.logoImageBase64 as string)) : undefined,
        logoImageString: company.logoImageBase64 ? getBase64Image(ImageFormat.SVG, company.logoImageBase64 as string) : undefined,
        smtpParams: company.smtpParams,
        isDeleted: company.isDeleted,
      });

      appStore.setCurrentTheme(this.selectedCompany.themeType === THEME_TYPE.CUSTOMIZED ? THEMES.CUSTOM : THEMES.DEFAULT);
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async postCompany() {
    try {
      this.isFetching = true;

      if (!this.selectedCompany.postPutDto) return;

      const id = await CompaniesApi.postCompany(this.selectedCompany.postPutDto);

      if (!id) return;
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async updateCompany() {
    try {
      this.isFetching = true;

      const companyId = DEFAULT_COMPANY_ID;
      const formData = this.selectedCompany.postPutDto;
      await CompaniesApi.putCompany(companyId, formData);
      this.getCompany(DEFAULT_COMPANY_ID).finally();
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async deleteCompany() {
    try {
      this.isFetching = true;

      await CompaniesApi.deleteCompany(this.selectedCompany.id!);
      await this.getCompanies();
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async sendTestEmail(values: ICompanyDialog) {
    try {
      this.isFetching = true;
      const sendEmailDto = this.selectedCompany.getSendEmailDto(values);
      await CompaniesApi.sendTestEmail(sendEmailDto);
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }

  public async checkSmtpByCompany() {
    try {
      this.isFetching = true;
      await CompaniesApi.checkSmtpByCompany();
    } catch (e) {
      // ignore
    } finally {
      this.isFetching = false;
    }
  }
}

export default new CompaniesStore();
