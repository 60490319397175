import React from 'react';

import ReactDOM from 'react-dom';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Typography } from '@mui/material';
import * as S from './customDateHeaderCell.styles';

interface IProps {
  parentNode: HTMLElement;
}

const CustomDateHeaderCell = (props: IProps) => {
  const { parentNode } = props;

  const date = parentNode.outerHTML.toString().match(/\d+ /);
  const day = parentNode.outerHTML.toString().match(/пнд|втр|срд|чтв|птн|суб|вск/);

  const dayFormatted = day?.toString() === 'суб' ? 'СБ' : day?.toString().substring(0, 2).toUpperCase();

  const dateNode = (
    <div>
      <S.DateWrapper>
        <Typography variant="body1">{date}</Typography>
      </S.DateWrapper>
      <S.DayWrapper isWeekEnd={dayFormatted === 'СБ' || dayFormatted === 'ВС'}>
        <Typography variant="subtitle2">{dayFormatted}</Typography>
      </S.DayWrapper>
    </div>
  );

  return ReactDOM.createPortal(dateNode, parentNode);
};

export default CustomDateHeaderCell;
