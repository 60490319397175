import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { rolesStore, zonesStore } from 'stores';
import { ModalDialog, PopupMenu, Table } from 'components';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import Trash from 'components/Icons/Trash';
import { DialogStatus, Permissions } from 'shared/enums';
import { Column, IZonesTableRow } from 'shared/interfaces/app';
import { MenuItem, Typography } from '@mui/material';

import ZoneDialog from './Components';
import * as S from './Zones.styles';

const columns: Column[] = [
  {
    id: 'name',
    label: 'Название зоны',
    minWidth: 240,
  },
  {
    id: 'workplacesCount',
    label: 'Количество мест',
    minWidth: 240,
  },
  {
    id: 'roles',
    label: 'Роли',
    minWidth: 240,
  },
  {
    id: 'departments',
    label: 'Отделы',
    minWidth: 240,
  },
];

const Zones: React.FC = () => {
  useEffect(() => {
    zonesStore.getZonesList();
  }, []);

  const isEditDisabled = !rolesStore.isAllow(Permissions.ZonesUpdating);
  const isDeleteDisabled = !rolesStore.isAllow(Permissions.ZoneRemoving);

  const createRowData = () => {
    const rows = zonesStore.zonesForTable.map((data: IZonesTableRow) => {
      const menuItems = [
        <MenuItem
          sx={{ '&:hover': { color: 'primary.main' } }}
          disableRipple
          disabled={isEditDisabled}
          key={data.id}
          onClick={() => {
            zonesStore.setEditingMode(data.id);
          }}>
          <EditPencilIcon />
          <Typography variant="caption" sx={{ ml: 3 }}>
            Редактировать
          </Typography>
        </MenuItem>,
        <MenuItem
          sx={{ '&:hover': { color: 'primary.main' } }}
          disableRipple
          disabled={isDeleteDisabled}
          key={data.id}
          onClick={async () => {
            await zonesStore.getZoneById(data.id);
            zonesStore.setDialogViewMode(DialogStatus.Deleting);
          }}>
          <Trash />
          <Typography variant="caption" sx={{ ml: 3 }}>
            Удалить
          </Typography>
        </MenuItem>,
      ];

      const row: Array<JSX.Element> = Object.values(data)
        .map((value, index) => {
          if (index === Object.values(data).length - 1) {
            return (
              <S.CellWrapper key={value.id}>
                <Typography>{value}</Typography>
                <PopupMenu key={data.id} menuItems={menuItems} />
              </S.CellWrapper>
            );
          }

          return <Typography key={value.id}>{value}</Typography>;
        })
        .slice(1);

      return row;
    });

    return rows;
  };

  const handleCloseConfirmDialog = () => {
    zonesStore.setDialogViewMode(DialogStatus.Closed);
  };

  const handleDeleteZone = async () => {
    await zonesStore.removeZone();
    zonesStore.setDialogViewMode(DialogStatus.Closed);
  };

  return (
    <S.ZonesWrapper>
      <Table columns={columns} rows={createRowData()} />
      {zonesStore.dialogViewMode !== DialogStatus.Closed && zonesStore.dialogViewMode !== DialogStatus.Deleting && <ZoneDialog />}
      <ModalDialog
        isOpen={zonesStore.dialogViewMode !== DialogStatus.Closed && zonesStore.dialogViewMode === DialogStatus.Deleting}
        title="Вы уверены, что хотите удалить зону?"
        submitBtnLabel="Удалить"
        submitBtnColor="error"
        onCancel={handleCloseConfirmDialog}
        onSubmit={handleDeleteZone}
      />
    </S.ZonesWrapper>
  );
};

export default observer(Zones);
