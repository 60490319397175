import styled from 'styled-components/macro';
import { Box, Button as MuiButton } from '@mui/material';

export const Button = styled(MuiButton)`
  margin: ${(_) => _.theme.spacing(6)};
  color: grey;
  border-color: grey;
`;

export const BoxWrapper = styled(Box)`
  ${(_) => _.theme.breakpoints.between('xs', 'md')} {
    margin-bottom: ${(_) => _.theme.spacing(1)};
  }
`;
